import * as React from 'react';

interface Props {
  content: string;
  lineHeight?: number;
  color?: string;
}

export const TextToHtml: React.FunctionComponent<Props> = (props) => {
  
  return (
    <div style={{lineHeight: props.lineHeight || 2, color: props.color, whiteSpace: 'pre-line'}} dangerouslySetInnerHTML={{ __html: props.content}}/>                   
  )
  
}