import * as React from 'react';
import { Link } from 'react-router-dom';
import {AppBar as AppBarMui, Toolbar, Typography} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import IconUser from '@mui/icons-material/AccountCircle';
import { Icon, InputImage, Transition } from '.';
import AppService from '../services/app.service';

interface Props {
  color?: any;
  logo: React.ReactNode;
  avatar?:{
    name?: string,
    imageUrl?: string,
    onClick?: () => void,
  }
  icon?: any;
  menu?: {
    caption: string, 
    url?: string,
    icon?: string,
    section?: string,
  }[];
  onClick?: () => void;
  onScrollEnd?: () => void;
  menuColor?: any;
  window?: () => Window;
  children: React.ReactNode;
}


export const AppBar: React.FunctionComponent<Props> = (props) => {
  const [isMobile, setIsMobile] = React.useState(AppService.isMobile());

  const logo = () => {
    return (
      <Typography component='div' sx={{typography: {xs: 'subtitle1', md: 'h6', flexGrow: 1 }}}>
        {props.logo}
      </Typography>
    )
  }

  const menu = () => {
    if (props.menu)
    return (
      <>
      {props.menu?.map(menu => {
        if (menu.caption!=='')
        return (
          <Link key={menu.caption} to={menu.url||''} style={{textDecoration: 'none'}} onClick={()=>goSection(menu.section||'')}>
            <Typography sx={{typography: {xs: 'caption', md: 'subtitle1'}, lineHeight: {xs: 1, md: 1}}} color= {props.menuColor || 'primary.contrastText'} textAlign='center' mr={3}>
            {menu.icon && <Icon name={menu.icon} size='small'/>} 
            {menu.icon && isMobile? <br/> : ''} {menu.caption}
            </Typography>
          </Link>
        )
      })}
      </>
    );
  }

  const avatar = () => {
    if (props.avatar)
    return (
      <>
      <Typography variant='overline' color= {props.menuColor || 'primary.contrastText'}>
        {isMobile? '':props.avatar.name}
      </Typography>
      <IconButton size='large' color='secondary' sx={{padding:0}} onClick={props.avatar.onClick}>
        {props.avatar.imageUrl? <InputImage name='imageUrl' label='' value={props.avatar.imageUrl ||''} maxHeight={50} circle readOnly/>
        :<IconUser sx={{fontSize : '40px', color:'white'}}/>
        }
      </IconButton>
      </>
    )
  }
  
  const goSection = async (id: string) => {
    await props.onClick?.();
    document.getElementById(id)?.scrollIntoView(false);
    await props.onScrollEnd?.();
  };

  const onResize = React.useCallback((e: UIEvent) => {setIsMobile(AppService.isMobile())}, []);  

  React.useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => {
      window.addEventListener('resize', onResize);
    }
    
  },[onResize])

  return (
    <>
    
    {isMobile?
    <>
      <Transition variant='hideOnScroll' >
        <AppBarMui sx={{ top: 'auto', bottom: 0, borderTop: 1}}>
          <Toolbar sx={{backgroundColor : props.color, justifyContent:'center'}}>
            {menu()}
            {avatar()}
          </Toolbar>
        </AppBarMui>
      </Transition>
      <Toolbar sx={{backgroundColor : props.color || 'primary.main', padding:3}}> {logo()} </Toolbar>
    </>

    : //Web view
    <>
      <Transition variant='hideOnScroll' direction={'down'}>
        <AppBarMui>
          <Toolbar sx={{backgroundColor : props.color}}>
            {logo()}
            {menu()}
            {avatar()}
          </Toolbar>
        </AppBarMui>
      </Transition>
      <Toolbar sx={{backgroundColor :  props.color ||'primary.main', paddingBottom:15}} />
    </>
    }
    
     {props.children}
    </>
  );
}