import * as React from 'react';
import { History } from 'history';
import Model from './personalinfotype-model';
import BaseList from '../base/base-list.component';

interface IProps {  
  history: History;
  root?: string;
  caption?: string;
  type?: string;
  actions?: any;
  disableBackButton?: boolean;
  disableCard?: boolean;
}

interface IState {
}

export default class PersonalinfotypeList extends React.Component<IProps, IState> {
  public render(): React.ReactNode {
    return (
      <BaseList history={this.props.history} caption={(this.props.caption || Model.caption)+'s'} endPointFilter={Model.endPointFilter()+(this.props.type? '&filters[type]='+this.props.type : '')} url={(this.props.root||'')+'/'+(this.props.type || 'personalinfo')+'type'} model={Model} actions={this.props.actions} disableBackButton={this.props.disableBackButton} disableCard={this.props.disableCard}/>
    );
  }
}
