import OwnerModel from "../owner/owner-model";

export default class MessageModel {
    id?: number;
    name: string;
    description: string;
    status: string;
    type: string;
    owner: any;
    ownerCaption: string;
    sent: string;
    from: string;
    to: string;
    cc: string;
    bcc: string;
    replyto: string;
    subject: string;
    text: string;
    html: string;

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.type = dataRow.attributes.type || 'email';
        this.owner =  dataRow.attributes.owner?.data?.id || null;
        this.ownerCaption =  dataRow.attributes.owner?.data?.attributes.name || dataRow.attributes.owner?.data?.id; 
        this.sent = dataRow.attributes.sent || 'yes';
        this.from = dataRow.attributes.from;
        this.to = dataRow.attributes.to;
        this.cc = dataRow.attributes.cc;
        this.bcc = dataRow.attributes.bcc;
        this.replyto = dataRow.attributes.replyto;
        this.subject = dataRow.attributes.subject;
        this.text = dataRow.attributes.text;
        this.html = dataRow.attributes.html;
    } 

    public static endPoint: string = 'messages' 
    public static endPointMedia: string = 'api::message.message';
    public static url: string = '/message';
    public static caption: string = 'Message';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active',
        type: 'email',
        owner: OwnerModel.getOwner(),
        ownerCaption: OwnerModel.getOwnerCaption(),   
        sent: 'yes',
        from: '',
        to: 'lengkah@yahoo.com',
        cc: '',
        bcc: '',
        replyto: '',
        subject: '',
        text: '', 
        html: '', 
  
        }
    }
    
    public static validations = [
        { name: 'to', required: true, minLength: 3 },
        { name: 'subject', required: true, minLength: 3 },
        { name: 'text', required: true, minLength: 3 },
    ]

    public static columns = [
        { name: 'to', caption: 'To' },
        { name: 'subject', caption: 'Subject' },
        { name: 'type', caption: 'Type', type: 'Type' },
    ]

    public static columnPicklist = [
        { name: 'to', caption: 'To' },
        { name: 'subject', caption: 'Subject' },
        { name: 'type', caption: 'Type' },
    ]

    public static optionType = [
        { key: 'email', value: 'Email' },
        { key: 'sms', value: 'SMS' },
        { key: 'whatsapp', value: 'WhatsApp' },
    ]
}