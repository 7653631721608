import * as React from 'react';
import Model from './testingtool-model';
import BasePicklist from '../base/base-picklist.component';

interface IProps { 
  caption?: string;
  parentId?: string;
  page?: number;
  pageSize?: number;
  currentId?: string;
  getDataRow: (data: any) => void;
  autoSelect?: boolean;
  disableColumnHeader?: boolean;
}

interface IState {
}

export default class TestingtoolPicklist extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }



  public render(): React.ReactNode {
    return (
      <BasePicklist caption={this.props.caption || 'Select '+Model.caption} endPointFilter={this.props.parentId? Model.endPointFilter2+this.props.parentId : Model.endPointFilter()}  model={Model} page={this.props.page} pageSize={this.props.pageSize} currentId={this.props.currentId} getDataRow={this.props.getDataRow}  autoSelect={this.props.autoSelect}  disableColumnHeader={this.props.disableColumnHeader}/>
    );
  }
}
