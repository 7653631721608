import OwnerModel from '../owner/owner-model';
import UserModel from '../user/user-model';
import AppService from '../../services/app.service';
import ApiService from '../../services/api.service';

export default class EvaluationModel {
    id?: number;
    name: string;
    description: string;
    status: string;
    owner: any;
    ownerCaption: string;
    parent: any;
    parentCaption: string;
    transaction: any;
    transactionCaption: string;
    customer: any;
    customerCaption: string;
    testingtool: any;
    testingtoolCaption: string;
    testingtoolId: any;
    evaluationdetail: any;
    evaluationdetailCaption: string;
    user: any;
    userCaption: string;
    userPersonalinfo: any;
    evaluationLength: number;
    startdate: any;
    enddate: any;
    totalvalue: number;
    totalvalueadj: number;
    image: any;
    imageId: any;
    imageUrl: string;

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.owner =  dataRow.attributes.owner.data?.id || null;
        this.ownerCaption =  dataRow.attributes.owner.data?.attributes.name || dataRow.attributes.owner.data?.id;
        this.parent =  dataRow.attributes.parent?.data?.id || null;
        this.parentCaption =  dataRow.attributes.parent?.data?.attributes.name || dataRow.attributes.parent?.data?.id;
        this.transaction =  dataRow.attributes.transaction?.data?.id || null;
        this.transactionCaption =  dataRow.attributes.transaction?.data?.attributes.name || dataRow.attributes.transaction?.data?.id;
        this.customer =  dataRow.attributes.transaction?.data?.attributes.customer?.data?.id || null;
        this.customerCaption =  dataRow.attributes.transaction?.data?.attributes.customer?.data?.attributes.name || dataRow.attributes.transaction?.data?.attributes.customer?.data?.id;
        this.testingtool =  dataRow.attributes.testingtool?.data || null;
        this.testingtoolCaption =  dataRow.attributes?.parent?.data?.id? dataRow.attributes?.testingtool?.data[0]?.attributes?.name : '';
        this.testingtoolId =  dataRow.attributes.parent?.data?.id? dataRow.attributes.testingtool?.data[0]?.id : null;
        this.evaluationdetail =  dataRow.attributes.evaluationdetail?.data || null;
        this.evaluationdetailCaption = '';
        this.user =  dataRow.attributes.user?.data || null;
        this.userCaption =  dataRow.attributes.parent?.data?.id? dataRow.attributes.user?.data[0].attributes?.username : '';
        this.userPersonalinfo =  dataRow.attributes.user?.data?.[0]?.attributes?.personalinfo?.data?.[0]?.attributes || null;
        this.evaluationLength =  dataRow.attributes.evaluation.data.length || 0;
        this.startdate = dataRow.attributes.startdate;
        this.enddate = dataRow.attributes.enddate;
        this.totalvalue = dataRow.attributes.totalvalue;
        this.totalvalueadj = dataRow.attributes.totalvalueadj;
        this.image = dataRow.attributes.image?.data || null;
        this.imageId = dataRow.attributes.image?.data?.[0]?.id || null;
        this.imageUrl = dataRow.attributes.image?.data?.[0]? ApiService.svrUrl+dataRow.attributes.image.data[0].attributes.url : '';
        
    } 

    public static endPoint: string = 'evaluations' 
    public static endPointMedia: string = 'api::evaluation.evaluation';
    public static endPointFilter = ()=>{
        const owner = OwnerModel.getOwner();
        return ( '?populate=*'+(owner==='null' || owner===null? '' : '&filters[owner]='+owner)+'&filters[parent][id][$null]=true')
    } 
    public static endPointFilter2: string = '?populate=*&filters[parent]=';
    public static endPointFilter3: string = '?populate[owner][sort]=id'+
                                            '&populate[parent][sort]=id'+
                                            '&populate[transaction][sort]=id'+
                                            '&populate[testingtool][sort]=id'+
                                            '&populate[user][sort]=id'+
                                            '&populate[evaluation][sort]=id'+
                                            '&populate[evaluationdetail][populate]=question'+
                                            '&populate[evaluationdetail][populate]=questionoption'+
                                            '&filters[parent]=';
    public static endPointFilter4: string = '?populate[owner][sort]=id'+
                                            '&populate[parent][sort]=id'+
                                            '&populate[transaction][sort]=id'+
                                            '&populate[transaction][populate]=customer'+
                                            '&populate[testingtool][sort]=id'+
                                            '&populate[user][populate]=personalinfo'+
                                            '&populate[evaluation][sort]=id'+
                                            '&populate[evaluationdetail][populate]=question'+
                                            '&populate[evaluationdetail][populate]=questionoption'+
                                            '&filters[id]=';
    public static endPointFilterUser = () => {
        const user = UserModel.getUser();
        return ('?populate=*&filters[user]='+user+'&filters[parent][id][$null]=true');
    }
    public static endPointFilterUser2 = () => {
        const user = UserModel.getUser();
        return ('?populate=*&filters[user]='+user+'&filters[parent][id][$null]=false&filters[testingtool]=');
    }
    public static endPointFilterUser3 = () => {
        const user = UserModel.getUser();
        return ('?populate=*&filters[user]='+user+'&filters[parent][id][$null]=false&filters[testingtool][id][$null]=true');
    }
    public static url: string = '/evaluation';
    public static caption: string = 'Evaluation';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active',
        owner: OwnerModel.getOwner(),
        ownerCaption: OwnerModel.getOwnerCaption(),    
        parent: null,
        parentCaption: '',
        transaction: null,
        transactionCaption: '',
        customer: null,
        customerCaption: '',
        testingtool: [],
        testingtoolCaption: '',
        testingtoolId: null,
        evaluationdetail: null,
        evaluationdetailCaption: '',
        user: [],
        userCaption: '',
        userPersonalinfo: [],
        evaluationLength: 0,
        startdate: AppService.dateCurrent(),
        enddate: null,
        totalvalue: 0,
        totalvalueadj: 0,
        image: null,
        imageId: null,
        imageUrl: '',
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'description', caption: 'Description' },
        { name: 'status', caption: 'State', type: 'state', default: 'active' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'description', caption: 'Description' },
        { name: 'startdate', caption: 'Start Date', type: 'date' },
    ]

    public static optionStatus = [
        { key: 'active', value: 'Active' },
        { key: 'inactive', value: 'Inactive' },
        { key: 'extratime', value: 'Extra Time' },
    ]
}