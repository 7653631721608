import ApiService from '../../services/api.service';
import OwnerModel from '../owner/owner-model';

export default class ProductModel {
    id?: number;
    name: string;
    description: string;
    status: string;
    owner: any;
    ownerCaption: string;
    producttype: any;
    producttypeCaption: string;
    qty: number;
    qtymin: number;
    qtymax: number;
    pricebase: number;
    pricesale: number;
    image: any;
    imageId: any;
    imageUrl: string;
    journalgroup: any;
    journalgroupCaption: string;

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.owner =  dataRow.attributes.owner.data?.id || null;
        this.ownerCaption =  dataRow.attributes.owner.data?.attributes.name || dataRow.attributes.owner.data?.id;
        this.producttype =  dataRow.attributes.producttype.data?.id || null;
        this.producttypeCaption =  dataRow.attributes.producttype.data?.attributes.name || dataRow.attributes.producttype.data?.id;
        this.qty = dataRow.attributes.qty || '0';
        this.qtymin = dataRow.attributes.qtymin || '0';
        this.qtymax = dataRow.attributes.qtymax || '0';
        this.pricebase = dataRow.attributes.pricebase || '0';
        this.pricesale = dataRow.attributes.pricesale || '0';
        this.image = dataRow.attributes.image?.data || null;
        this.imageId = dataRow.attributes.image?.data?.[0]?.id || null;
        this.imageUrl = dataRow.attributes.image?.data?.[0]? ApiService.svrUrl+dataRow.attributes.image.data[0].attributes.url : '';
        this.journalgroup =  dataRow.attributes.journalgroup.data?.id || null;
        this.journalgroupCaption =  dataRow.attributes.journalgroup.data?.attributes.name || dataRow.attributes.journalgroup.data?.id;
        
    } 

    public static endPoint: string = 'products' 
    public static endPointMedia: string = 'api::product.product';
    public static endPointFilter = ()=>{
       const owner = OwnerModel.getOwner();
        return ( '?populate=*'+(owner==='null' || owner===null? '' : '&filters[owner]='+owner))
    } 
    public static url: string = '/product';
    public static caption: string = 'Product';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active',
        owner: OwnerModel.getOwner(),
        ownerCaption: OwnerModel.getOwnerCaption(),         
        producttype: null,
        producttypeCaption: '',
        qty: 0,
        qtymin: 0,
        qtymax: 0,
        pricebase: 0,
        pricesale: 0,
        image: null,
        imageId: null,
        imageUrl: '',
        journalgroup: null,
        journalgroupCaption: '',
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'description', caption: 'Description' },
        { name: 'status', caption: 'State', type: 'state', default: 'active' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'description', caption: 'Description' },
        { name: 'pricebase', caption: 'Price', type: 'money' },
        { name: 'qty', caption: 'Qty', type: 'number' },
    ]

}