import React from 'react';
import { History } from 'history';
import { InputNumber, InputMoney, Picklist } from '../../components';
import BaseView from '../base/base-view.component'; 
import Model from './transactiondetail-model';  //editable..

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string },
    }
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class TransactiondetailView extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    render() {
        return (
            //editable..
            <BaseView history={this.props.history} match={this.props.match} caption={'View '+ Model.caption} 
                model={Model} getDataRow={this.getDataRow}>              
                
                <Picklist name='product' label='Product' value={this.state.dataRow.product?.toString()} caption={this.state.dataRow.productCaption} readOnly/>
                <InputMoney name='price' label='Price' value={this.state.dataRow.price} readOnly/>
                <InputNumber name='number' label='Qty' value={this.state.dataRow.qty} readOnly/>
                <InputMoney name='value' label='Total' value={this.state.dataRow.value} readOnly/>
              </BaseView>
        );
    }
}