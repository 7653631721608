import ApiService from '../../services/api.service';
import AppService from '../../services/app.service';
import OwnerModel from '../owner/owner-model';

export default class UserModel {
    id?: number;
    username: string;
    status: string;
    email: string;
    emailmasking: string;
    identifier: string;
    code: string;
    password: string;
    currentPassword: string;
    passwordConfirmation: string;
    confirmation: string;
    owner: any;
    ownerCaption: string;
    image: any;
    imageId: any;
    imageUrl: string;
    personalinfo: any;
    personalinfoCaption: string;

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.username = dataRow.username;
        this.status = dataRow.status || 'active';
        this.email = dataRow.email;
        this.emailmasking = AppService.emailMasking(dataRow.email);
        this.identifier = dataRow.identifier;
        this.code = dataRow.code;
        this.password = dataRow.password;
        this.currentPassword = dataRow.password;
        this.passwordConfirmation = dataRow.password;
        this.confirmation = dataRow.confirmationToken;
        this.owner =  dataRow.owner || null;
        this.ownerCaption =  '';
        this.image = dataRow.image || null;
        this.imageId = dataRow.image?.[0]?.id || null;
        this.imageUrl = dataRow.image?.[0]? ApiService.svrUrl+dataRow.image[0].url : '';
        this.personalinfo =  dataRow.personalinfo?.[0]?.id || null;
        this.personalinfoCaption =  dataRow.personalinfo?.[0]?.name || dataRow.personalinfo?.[0]?.id; 

        
    } 

    public static endPoint: string = 'users';
    public static endPointFilter = ()=>{
        const owner = OwnerModel.getOwner();
        return ( '?populate=*&filters[owner]='+owner)
    }     
    public static endPointFilter1 = ()=>{
        const owner = OwnerModel.getOwner();
        return ( '?populate=*&filters[personalinfo][id][$null]=true&filters[owner]='+owner)
    } 
    public static endPointAuth: string = 'auth';
    public static endPointMedia: string = 'plugin::users-permissions.user';
    public static url: string = '/user';
    public static caption: string = 'User';

    public static defaultValue = ()=> {
        return {
        id: 0,
        username: '',
        status: 'active',
        email: '',
        emailmasking: '',
        identifier: '',
        code: '',
        password: '',
        currentPassword: '',
        passwordConfirmation: '',
        confirmation: '',
        owner: [{id: +(OwnerModel.getOwner()||0)}],
        ownerCaption: '',   
        image: null,
        imageId: null,
        imageUrl: '',
        personalinfo: null,
        personalinfoCaption: '',
  
        }
    }

    public static defaultValueChangePassword = ()=> {
        return {
        password: '',
        currentPassword: '',
        passwordConfirmation: '',
        }
    }

    public static defaultValueForgotPassword = ()=> {
        return {
        email: '',
        }
    }

    public static defaultValueResetPassword = ()=> {
        return {
        code: '',
        password: '',
        passwordConfirmation: '',
        }
    }

    public static defaultValueEmailConfirmation = ()=> {
        return {
        confirmation: '',
        }
    }

    public static defaultValueSendEmailConfirmation = ()=> {
        return {
        email: '',
        }
    }

    public static defaultValueGetToken = ()=> {
        return {
        email: '',
        }
    }

    public static defaultValueSigninUseToken = ()=> {
        return {
        code: '',
        }
    }

    public static validations = [
        { name: 'username', required: true, minLength: 3 },
        { name: 'email', required: true },
        { name: 'password', required: true },
        { name: 'owner', required: true },
    ]

    public static validationSignin = [
        { name: 'identifier', nameAlias: 'email', required: true },
        { name: 'password', required: true },
    ]

    public static validationChangePassword = [
        { name: 'password', required: true },
        { name: 'currentPassword', nameAlias:'Current Password', required: true },
        { name: 'passwordConfirmation', nameAlias:'Password Confirmation', required: true },
    ]

    public static validationForgotPassword = [
        { name: 'email', required: true },
    ]

    public static validationResetPassword = [
        { name: 'code', nameAlias:'Verification Code', required: true },
        { name: 'password', required: true },
        { name: 'passwordConfirmation', nameAlias:'Password Confirmation', required: true },
    ]

    public static validationSendEmailConfirmation = [
        { name: 'email', required: true },
    ]

    public static validationEmailConfirmation = [
        { name: 'confirmation', nameAlias:'Confirmation Code', required: true },
    ]

    public static columns = [
        { name: 'username', caption: 'User Name' },
        { name: 'emailmasking', caption: 'Email' },
        { name: 'status', caption: 'State', type: 'state', default: 'active' },
    ]

    public static columnPicklist = [
        { name: 'username', caption: 'User Name' },
        { name: 'emailmasking', caption: 'Email' },
    ]    
    
    public static columnUpload = [
        { name: 'username', caption: 'User Name' },
        { name: 'emailmasking', caption: 'Email' },
        { name: 'validate', caption: 'Validate', type: 'validate', default: 'success' },
        { name: 'error', caption: ' ', type: 'error' },
    ]

    public static setUser(id: string, caption: string, token: string) {
        localStorage.setItem('user',id);
        localStorage.setItem('userCaption',caption);
        localStorage.setItem('userToken',token);
    }

    public static setUserImage(imageUrl: string) {
        localStorage.setItem('userImageUrl',imageUrl);
    }

    public static removeUser() {
        localStorage.removeItem('user');
        localStorage.removeItem('userCaption');
        localStorage.removeItem('userToken');
        localStorage.removeItem('userImageUrl');
    }

    public static getUser() {
        return localStorage.getItem('user') || '';
    }

    public static getUserCaption() {
        return AppService.strCapitalize(localStorage.getItem('userCaption') || '');
    }

    public static getUserToken() {
        return localStorage.getItem('userToken') || '';
    }

    public static getUserImageUrl() {
        const userImage = localStorage.getItem('userImageUrl') || '';
        return userImage==='undefined'? '' : userImage;
    }

    public static isSignin() {
        return this.getUserToken()!=='' && this.getUser()!=='';
    }
}