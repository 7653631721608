import React from 'react';
import { History } from 'history';
import { Input, InputMultiline, InputNumber, InputMoney, Switch, Picklist, InputImage } from '../../components';
import BaseCopy from '../base/base-copy.component'; 
import Model from './product-model';  //editable..
import MedialibraryModel from '../medialibrary/medialibrary-model';
import OwnerPicklist from '../owner/owner-picklist.component';
import ProducttypePicklist from '../producttype/producttype-picklist.component';
import JournalgroupPicklist from '../journalgroup/journalgroup-picklist.component';
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string },
    }
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowMedia: any;
}

export default class ProductCopy extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            dataRowMedia: null,
        }
        this.onFieldValueChange = this.onFieldValueChange.bind(this);
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }
    
    private getDataRowOwner = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'owner' : data?.id || null, 'ownerCaption' : data?.name || ''  }  });
    };
    
    private getDataRowProducttype = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'producttype' : data?.id || null, 'producttypeCaption' : data?.name || ''  }  });
    };

    private getDataRowJournalgroup = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'journalgroup' : data?.id || null, 'journalgroupCaption' : data?.name || ''  }  });
    };

    private getFiles = (data:any) => {
        this.setState({dataRowMedia: {...this.state.dataRowMedia, endPoint: MedialibraryModel.endPoint, endPointDelete: MedialibraryModel.endPoint2, dataRow:{id:this.state.dataRow.imageId, files: data, ref: Model.endPointMedia, field:'image'}}});
    };

    private getDataError = (data: any) => {
        this.setState({dataError: data});
    };
    
    private onFieldValueChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    render() {
        return (
            //editable..
            <BaseCopy history={this.props.history} match={this.props.match} caption={'Duplicate '+ Model.caption} 
                model={Model} dataRow={this.state.dataRow} dataRowMedia={this.state.dataRowMedia} getDataRow={this.getDataRow} getDataError={this.getDataError}>              
                
                <Input name='name' label='Name' value={this.state.dataRow.name} error={this.state.dataError?.name} onChange={this.onFieldValueChange} />
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} error={this.state.dataError?.description} onChange={this.onFieldValueChange} />
                <Switch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' error={this.state.dataError?.status} onChange={this.onFieldValueChange}/>
                {AppService.isAppFullVersion() &&
                <Picklist name='owner' label='Owner' value={this.state.dataRow.owner?.toString()} caption={this.state.dataRow.ownerCaption} error={this.state.dataError?.owner} onChange={this.onFieldValueChange} >
                    <OwnerPicklist currentId={this.state.dataRow.owner?.toString()} getDataRow={this.getDataRowOwner}/>
                </Picklist>
                } 
                <Picklist name='producttype' label='Product Type' value={this.state.dataRow.producttype?.toString()} caption={this.state.dataRow.producttypeCaption} error={this.state.dataError?.producttype} onChange={this.onFieldValueChange} >
                    <ProducttypePicklist currentId={this.state.dataRow.producttype?.toString()} getDataRow={this.getDataRowProducttype}/>
                </Picklist> 
                <InputNumber name='qty' label='Qty' value={this.state.dataRow.qty} error={this.state.dataError?.qty} onChange={this.onFieldValueChange} />
                <InputNumber name='qtymin' label='Qty Min' value={this.state.dataRow.qtymin} error={this.state.dataError?.qtymin} onChange={this.onFieldValueChange} />
                <InputNumber name='qtymax' label='Qty Max' value={this.state.dataRow.qtymax} error={this.state.dataError?.qtymax} onChange={this.onFieldValueChange} />
                <InputMoney name='pricebase' label='Price Base' value={this.state.dataRow.pricebase} error={this.state.dataError?.pricebase} onChange={this.onFieldValueChange} />
                <InputMoney name='pricesale' label='Price Sale' value={this.state.dataRow.pricesale} error={this.state.dataError?.pricesale} onChange={this.onFieldValueChange} />
                <InputImage name='imageUrl' label='Image' value={this.state.dataRow.imageUrl} getFiles={this.getFiles} error={this.state.dataError?.image} onChange={this.onFieldValueChange} />
                <Picklist name='journalgroup' label='Journal group' value={this.state.dataRow.journalgroup?.toString()} caption={this.state.dataRow.journalgroupCaption} error={this.state.dataError?.journalgroup} onChange={this.onFieldValueChange} >
                    <JournalgroupPicklist currentId={this.state.dataRow.journalgroup?.toString()} getDataRow={this.getDataRowJournalgroup}/>
                </Picklist> 
                
            </BaseCopy>
        );
    }
}