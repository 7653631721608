import OwnerModel from '../owner/owner-model';
import UserModel from '../user/user-model';
import AppService from '../../services/app.service';

export default class MenuModel {
    id?: number;
    name: string;
    description: string;
    status: string;
    owner: any;
    ownerCaption: string;
    path: string;
    icon: string;

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.owner =  dataRow.attributes.owner.data?.id || null;
        this.ownerCaption =  dataRow.attributes.owner.data?.attributes.name || dataRow.attributes.owner.data?.id;
        this.path = dataRow.attributes.path;
        this.icon = dataRow.attributes.icon;
        
    } 

    public static endPoint: string = 'menus' 
    public static endPointFilter = ()=>{
        const owner = OwnerModel.getOwner();
        return ( '?populate=*'+(owner==='null' || owner===null? '' : '&filters[owner]='+owner))
    } 
    public static endPointFilter2 = ()=>{
        const owner = OwnerModel.getOwner();
        const user = UserModel.getUser();
        return ( '?populate=*&filters[menugroup][app][id]='+AppService.appId()+ '&filters[menugroup][accessowner][id]='+owner+'&filters[menugroup][user][id]='+user)
    } 
    public static url: string = '/menu';
    public static caption: string = 'Menu';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active',
        owner: OwnerModel.getOwner(),
        ownerCaption: OwnerModel.getOwnerCaption(),   
        path: '',
        icon: '',
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
        { name: 'path', required: true },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'description', caption: 'Description' },
        { name: 'status', caption: 'State', type: 'state', default: 'active' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'description', caption: 'Description' },
    ]


    public static columnPicklistCard = [
        { name: 'icon', type: 'icon' },
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'description', caption: 'Description' },
    ]
}