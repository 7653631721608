import React from 'react';
import { History } from 'history';
import { Grid, Typography } from '@mui/material';
import IconPassword from '@mui/icons-material/Key';
import IconLogout from '@mui/icons-material/Logout';
import IconEdit from '@mui/icons-material/Edit';
import { Button, Card, InputImage, TextToSpeech } from '../../components';
import BaseView from '../base/base-view.component';
import Model from './user-model';  //editable..

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string },
    };
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowMedia: any;
    mousePos: string;
}

export default class UserAuthProfile extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            dataRowMedia: null,
            mousePos: '',
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    
    private imgdisplay () {
        
        return (
        <Grid item >
            <Typography sx={{typography: {xs: 'caption', md: 'body2'}}}> {this.state.mousePos} </Typography>
            <img src='https://cdn4.iconfinder.com/data/icons/professions-1-5/66/16-512.png' alt= '' height={300} width={300}  useMap="#imgmap" onMouseMove={this.printMousePos}/>
            <map id = "imgmap" >
                <TextToSpeech text='Wajah'>
                    <area shape='poly' title='wajah' alt= '' coords='138,26,164,26,162,45,143,48' href=''/>
                </TextToSpeech>
                <TextToSpeech text='Lengan Kiri'>
                    <area shape='poly' title='Lengan Kiri' alt= '' coords='186,59,203,102,184,155,176,139,192,99,178,82' href='' />
                </TextToSpeech>
                <TextToSpeech text='Lengan Kanan'>
                    <area shape='poly' title='Lengan Kanan' alt= '' coords='112,59,98,102,124,155,128,139,110,99,122,82' href='' />
                </TextToSpeech>
                <TextToSpeech text='Perut'>
                    <area shape='rect' title='Perut' alt= '' coords='125,117,170,134' href=''/>
                </TextToSpeech>
                
            </map>
        </Grid>
        )
    }
    private printMousePos = (e: React.MouseEvent) => {
        this.setState({mousePos:  "x: " + (e.clientX-349) + " - y: " + (e.clientY-198)})
    }
      
    render() {
        return (
            //editable..
            <BaseView history={this.props.history} match={{isExact:true, params: {id: Model.getUser()}}} caption={Model.caption+' Profile'} 
                model={Model} getDataRow={this.getDataRow} disableCard>              
                    
                <Grid container direction='column' >
                    <Grid item container direction='column' alignItems='center' >
                        <Grid item textAlign='center' sx={{ position: 'absolute'}}>
                            <InputImage name='imageUrl' label='' value={this.state.dataRow.imageUrl} maxHeight={150} circle readOnly/>
                            <Typography sx={{typography: {xs: 'h4', md: 'h3'}, textTransform: 'capitalize'}}>
                                {this.state.dataRow.username}
                            </Typography>
                            <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}} >
                                {this.state.dataRow.email}
                            </Typography>
                            <Button sx={{margin: 3}} startIcon={<IconEdit/>} onClick={()=>{this.props.history.push('/user/profileedit')}} label='Change Profile' />
                        </Grid>  
                    </Grid>

                    <Grid item sx={{marginTop: '70px'}}>
                        <Card color='secondary.dark' gradientColor='white'>
                            <Grid item sx={{marginTop: '250px'}}>
                                <Typography variant='caption' >
                                    Full Name 
                                </Typography>
                                <Typography variant='subtitle1' mb={3}>
                                    {this.state.dataRow.personalinfoCaption}
                                </Typography>
    
                            </Grid>
                        </Card>

                                
                        <Button sx={{margin: 3}} startIcon={<IconPassword/>} onClick={()=>{this.props.history.push('/user/changepassword')}} label='Change Password' />
                        <Button sx={{margin: 3, pullRight: 1}}  variant='contained' startIcon={<IconLogout/>} onClick={()=>{this.props.history.push('/user/signout')}} label='Logout' />
                        
                    </Grid>
                </Grid>
            </BaseView>
        );
    }
}