import OwnerModel from '../owner/owner-model';

export default class TransactiontypeModel {
    id?: number;
    name: string;
    description: string;
    status: string;
    owner: any;
    ownerCaption: string;
    type: string;
    pricetype: string;

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.owner =  dataRow.attributes.owner?.data?.id || null;
        this.ownerCaption =  dataRow.attributes.owner?.data?.attributes.name || dataRow.attributes.owner?.data?.id;
        this.type = dataRow.attributes.type || 'general';
        this.pricetype = dataRow.attributes.pricetype || 'pricebase';
        
    } 

    public static endPoint: string = 'transaction-types';
    public static endPointFilter = ()=>{
       const owner = OwnerModel.getOwner();
        return ( '?populate=*'+(owner==='null' || owner===null? '' : '&filters[owner]='+owner))
    } 
    public static url: string = '/transactiontype';
    public static caption: string = 'Transaction Type';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active',
        owner: OwnerModel.getOwner(),
        ownerCaption: OwnerModel.getOwnerCaption(),         
        type: 'general',
        pricetype: 'pricebase'

  
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'description', caption: 'Description' },
        { name: 'status', caption: 'State', type: 'state', default: 'active' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'description', caption: 'Description' },
    ]

    public static optionType = [
        { key: 'general', value: 'General' },
        { key: 'purchase', value: 'Purchase' },
        { key: 'sales', value: 'Sales' },
    ]

    public static optionPricetype = [
        { key: 'pricebase', value: 'Price Base' },
        { key: 'pricesale', value: 'Price Sale' },
    ]
}