import React from 'react';
import * as toastr from 'toastr';
import { Card, FormHeader } from '../../components';
import ApiService from '../../services/api.service';

interface IProps { 
    history?: any;
    match: any;
    caption: string;
    captionColor?: string;
    endPoint?: string;
    endPointFilter?: string;
    model: any;
    getDataRow: (data: any) => void;
    getDataError?: (data: any) => void;
    disableBackButton?: boolean;
    disableCard?: boolean;
    children: React.ReactNode;
}

interface IState {
}

export default class BaseView extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
    }

    private isLoading: boolean = false;
    
    public componentDidMount() { 
        if (this.isLoading) return;
        this.isLoading = true;
        
        ApiService.get<typeof this.props.model>((this.props.endPoint||this.props.model.endPoint)+(this.props.endPointFilter||'?populate=*&filters[id]='), this.props.match.params.id).then(
            (rp) => {
                if (rp.Status) { if (rp.Data.length>0) var data = new this.props.model(rp.Data[0]); this.props.getDataRow(data);} 
                else { this.props.getDataError && rp.Errors? this.props.getDataError(rp.Errors) : rp.Messages && toastr.error(rp.Messages); }
                this.isLoading = false;
            }
        );
    }

    render() {
            return (
            <>
                {this.props.children && 
                <>
                <FormHeader history={this.props.history} label={this.props.caption} color={this.props.captionColor} disableBackButton={this.props.disableBackButton}/>
                {this.props.disableCard? this.props.children:
                    <Card>{this.props.children}</Card>           
                }            
                </>
            }
            </>
        );
    }
}