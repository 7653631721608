import AppService from "../../services/app.service";

export default class JournalModel {
    id?: number;
    name: string;
    description: string;
    status: string;
    journaldate: any;
    seq: number;
    sign: string;    
    value: number;
    coa: any;
    coaCaption: string;
    transaction: any;
    transactionCaption: string;

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'draft';
        this.journaldate = dataRow.attributes.journaldate;
        this.seq = dataRow.attributes.seq;
        this.sign = dataRow.attributes.sign || 'debet';
        this.value = dataRow.attributes.value;
        this.coa =  dataRow.attributes.coa.data?.id || null;
        this.coaCaption =  dataRow.attributes.coa.data?.attributes.name || dataRow.attributes.coa.data?.id;
        this.transaction =  dataRow.attributes.transaction.data?.id || null;
        this.transactionCaption =  dataRow.attributes.transaction.data?.attributes.name || dataRow.attributes.transaction.data?.id;
       
    } 

    public static endPoint: string = 'journals' 
    public static endPointFilter: string = '?populate=*&filters[transaction]=';
    public static url: string = '/journal';
    public static caption: string = 'Journal Detail';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'draft',       
        journaldate: AppService.dateCurrent(),
        seq: 0,
        sign: 'debet',    
        value: 0,
        coa: null,
        coaCaption: '',
        transaction: null,
        transactionCaption: '',
  
        }
    }
    
    public static columns = [
        { name: 'coaCaption', caption: 'COA' },
        { name: 'sign', caption: 'Debet/Credit' },
        { name: 'value', caption: 'Value', type: 'money' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'description', caption: 'Description' },
    ]

    public static optionSign = [
        { key: 'debet', value: 'Debet' },
        { key: 'credit', value: 'Credit' },
    ]

    public static optionStatus = [
        { key: 'draft', value: 'Draft' },
        { key: 'approved', value: 'Approved' },
        { key: 'rejected', value: 'Rejected' },
    ]
}