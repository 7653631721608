import OwnerModel from '../owner/owner-model';
import AppService from "../../services/app.service";
import ApiService from '../../services/api.service';

export default class AppModel {
    id?: number;
    name: string;
    description: string;
    status: string;
    owner: any;
    ownerCaption: string;
    product: any;
    productCaption: string;
    productkey: string;
    expireddate: any;
    menugroup: any;
    menugroupCaption: string;
    image: any;
    imageId: any;
    imageUrl: string;
    
    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.owner =  dataRow.attributes.owner?.data?.id || null;
        this.ownerCaption =  dataRow.attributes.owner?.data?.attributes.name || dataRow.attributes.owner?.data?.id;
        this.product =  dataRow.attributes.product?.data?.id || null;
        this.productCaption =  dataRow.attributes.product?.data?.attributes?.name || dataRow.attributes.product?.data?.id;
        this.productkey = dataRow.attributes.productkey;
        this.expireddate = dataRow.attributes.expireddate;
        this.menugroup =  dataRow.attributes.menugroup?.data || null;
        this.menugroupCaption =  dataRow.attributes.menugroup?.data?.[0]? dataRow.attributes.menugroup?.data?.[0]?.attributes.name+' ...' : '';
        this.image = dataRow.attributes.image?.data || null;
        this.imageId = dataRow.attributes.image?.data?.[0]?.id || null;
        this.imageUrl = dataRow.attributes.image?.data?.[0]? ApiService.svrUrl+dataRow.attributes.image.data[0].attributes.url : '';
        

    } 

    public static endPoint: string = 'apps';
    public static endPointMedia: string = 'api::app.app';
    public static url: string = '/app';
    public static caption: string = 'Application';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active',
        owner: OwnerModel.getOwner(),
        ownerCaption: OwnerModel.getOwnerCaption(),         
        product: null,
        productCaption: '',         
        productkey: '',
        expireddate: AppService.dateCurrent(),
        menugroup: [],
        menugroupCaption: '',
        image: null,
        imageId: null,
        imageUrl: '',
  
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'description', caption: 'Description' },
        { name: 'status', caption: 'State', type: 'state', default: 'active' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'description', caption: 'Description' },
    ]
}