import * as React from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import IconSearch from '@mui/icons-material/Search';

interface Props {
  name: string;
  label: string;
  placeHolder?: string;
  value?: string;
  readOnly?: boolean;
  onBlur?: () => void;
  onChange?: (fieldName: string, value: string) => void;
  onClick?: () => void;
  error?: string;
}

export const InputSearch: React.FunctionComponent<Props> = (props) => {
  
  const onBlur = (props: Props) => (e: React.FocusEvent<HTMLInputElement>) => { 
    props.onBlur?.();
  };

  const onChange = (props: Props) => (e: React.ChangeEvent<HTMLInputElement>) => { 
    props.onChange?.(e.target.name, e.target.value);
   
  };
  
  const onClick = () => {
    props.onClick?.();
  };

  
  return (
    <TextField
      margin='normal'
      id={props.name}
      name={props.name}
      label={props.label}
      type='input'
      value={props.value?.toString()}
      placeholder={props.placeHolder || 'Search..'}
      InputProps={{
        readOnly: props.readOnly || false,
        sx: {fontSize: {xs: '16px', md: '18px'}, borderRadius: 20, paddingLeft: 3},
        endAdornment: 
          <InputAdornment position='end'>
            <IconButton onClick={onClick} >
              <IconSearch sx= {{fontSize: {xs: '20px', md: '24px'}}}/>
            </IconButton>
          </InputAdornment>,
        
      }}
      InputLabelProps={{
        sx: {fontSize: {xs: '14px', md: '16px'}},
      }}
      fullWidth
      helperText={props.error}
      error= {props.error? true: false}
      onBlur={onBlur(props)}
      onChange={onChange(props)}
    />
  )
};