import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import UserList from './user-list.component';
import UserCreate from './user-create.component';
import UserView from './user-view.component';
import UserEdit from './user-edit.component';
import UserCopy from './user-copy.component';
import UserDelete from './user-delete.component';

interface Props {
  root?: string;
}

export const UserRoute: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>
      <Route path={(props.root||'')+'/user/create'} component={UserCreate} />
      <Route path={(props.root||'')+'/user/view/:id'} component={UserView} />
      <Route path={(props.root||'')+'/user/edit/:id'} component={UserEdit} />
      <Route path={(props.root||'')+'/user/copy/:id'} component={UserCopy} />
      <Route path={(props.root||'')+'/user/delete/:id'} component={UserDelete} />
      <Route path={(props.root||'')+'/user'} render={(params)=> <UserList {...params} root={props.root} />} />
    </Switch>
  );
};
