import React from 'react';
import { History } from 'history';
import { Typography } from '@mui/material';
import { Button, InputEmail, InputPassword } from '../../components';
import BaseCreate from '../base/base-create.component'; 
import Model from './user-model';  //editable..

interface IProps { 
    history: History;
    root?: string;
    caption?: string;
    onSave?: (data: any) => void;
    disableAutoBack?: boolean;
    disableBackButton?: boolean;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class UserAuthSignin extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        this.onFieldValueChange = this.onFieldValueChange.bind(this);
    }

    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onSave = (data: any) => {
        Model.setUser(data.user?.id, data.user?.username, data.jwt)
        this.props.onSave?.(data)
    };
    
    private onFieldValueChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }
    
    render() {
        return (
            //editable..          
            <>
            <BaseCreate history={this.props.history} caption={this.props.caption || Model.caption + ' Sign in'} buttonCaption='Login'
                endPoint={Model.endPointAuth+'/local'} model={Model} url={this.props.root} dataRow={this.state.dataRow} validations={Model.validationSignin} getDataError={this.getDataError} buttonOnBottom onSave={this.onSave} disableBackButton={this.props.disableBackButton} disableAutoBack={this.props.disableAutoBack}>              
                <InputEmail name='identifier' label='Email' value={this.state.dataRow.identifier} error={this.state.dataError?.identifier || this.state.dataError?.[0]} onChange={this.onFieldValueChange} />
                <InputPassword name='password' label='Password' value={this.state.dataRow.password} error={this.state.dataError?.password} onChange={this.onFieldValueChange} />
                <Button sx={{textTransform: 'none', margin: -2, marginBottom: 1, display: 'inline'}} onClick={()=>{this.props.history.push('/user/forgotpassword')}} label='Forget Password?' />
            </BaseCreate>
            
            <Typography variant='body2' className='mt-3 mb-3'>
                Do not have an account?
                <Button sx={{textTransform: 'none'}} onClick={()=>{this.props.history.push('/user/signup')}} label='Register Now' />
            </Typography>
            

            </>
        );
    }     
     
}