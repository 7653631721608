import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import UserAuthSignin from './user-auth-signin.component';
import UserAuthSignout from './user-auth-signout.component';
import UserAuthSignup from './user-auth-signup.component';
import UserAuthProfile from './user-auth-profile.component';
import UserAuthProfileEdit from './user-auth-profileedit.component';
import UserAuthChangePassword from './user-auth-changepassword.component';
import UserAuthForgotPassword from './user-auth-forgotpassword.component';
import UserAuthResetPassword from './user-auth-resetpassword.component';
import UserAuthEmailConfirmation from './user-auth-emailconfirmation.component';
import UserAuthSendEmailConfirmation from './user-auth-sendemailconfirmation.component';
import UserAuthCheckToken from './user-auth-checktoken.component';
import UserAuthGetToken from './user-auth-gettoken.component';

interface Props {
  root?: string;
  isSignin?: boolean;
}

export const UserRouteAuth: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>
      {props.isSignin?
      <>
      <Route path={(props.root||'')+'/user/signout'} component={UserAuthSignout} />
      <Route path={(props.root||'')+'/user/profile'} component={UserAuthProfile} />
      <Route path={(props.root||'')+'/user/profileedit'} component={UserAuthProfileEdit} />
      <Route path={(props.root||'')+'/user/changepassword'} component={UserAuthChangePassword} />
      <Route path={(props.root||'')+'/user/resetpassword/:id'} component={UserAuthResetPassword} />
      <Route path={(props.root||'')+'/user/sendemailconfirmation/:id'} component={UserAuthSendEmailConfirmation} />
      <Route path={(props.root||'')+'/user/linkverification/:id'} component={UserAuthGetToken} />
      <Route path={(props.root||'')+'/user/linkverification/:id'} component={UserAuthCheckToken} />
      </>
      :
      <>
      <Route path={(props.root||'')+'/user/signin'} component={UserAuthSignin} />
      <Route path={(props.root||'')+'/user/signup'} component={UserAuthSignup} />
      <Route path={(props.root||'')+'/user/forgotpassword'} component={UserAuthForgotPassword} />
      <Route path={(props.root||'')+'/user/emailconfirmation/:id'} component={UserAuthEmailConfirmation} />
      <Route path={(props.root||'')+'/user/linkverification/:id'} component={UserAuthCheckToken} />
      </>
      }
    </Switch>
  );
};
