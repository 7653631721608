import * as React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';

interface Props {
  label: string;
  url?: string;
  logoUrl?: string;
  titleColor?: any;
  disableMargin?: boolean;
}

export const Logo : React.FunctionComponent<Props> = (props) => {

  return (
    <Link to={props.url || '/'} style={{textDecoration: 'none', color:'inherit'}}>
      <Grid container direction='row' alignItems='center' m={props.disableMargin? 0: 3} mb={props.disableMargin? 0: 5}>
          <img src={props.logoUrl || (document.querySelector("link[rel~='icon']") as HTMLAnchorElement).href} alt='logo' height={50} width={50}/>
          <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}} ml={2} color={props.titleColor}>
            <b>{props.label}</b>
          </Typography>            
      </Grid>
    </Link>
  );
};
