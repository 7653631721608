import React from 'react';
import { History } from 'history';
import { Input, InputNumber, InputMultiline, InputSelect, Layout,  InputImage, Switch } from '../../components';
import BaseView from '../base/base-view.component'; 
import Model from './question-model';  //editable..
import QuestionoptionList from '../questionoption/questionoption-list.component';

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string },
    };
    root?: string;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class QuestionView extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    render() {
        return (
            //editable..
            <BaseView history={this.props.history} match={this.props.match} caption={'View '+ Model.caption} 
                model={Model} getDataRow={this.getDataRow} disableCard>              
                
                <Layout content={[
                    {caption: 'Details', child: 
                    <>
                        <Input name='name' label='Name' value={this.state.dataRow.name} readOnly/>
                        <InputMultiline name='description' label='Description' value={this.state.dataRow.description} readOnly/>
                        <Switch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' readOnly/>
                        <Input name='title' label='Title' value={this.state.dataRow.title} readOnly/>
                        <InputMultiline name='content' label='Content' value={this.state.dataRow.content} readOnly/>
                        <InputImage name='imageUrl' label='Image' value={this.state.dataRow.imageUrl} readOnly/>
                        <InputNumber name='seq' label='Seq' value={this.state.dataRow.seq} readOnly/>
                        <InputSelect name='type' label='Type' value={this.state.dataRow.type} options={Model.optionType} readOnly/>
                        <InputSelect name='level' label='Level' value={this.state.dataRow.level} options={Model.optionLevel} readOnly/>
                        <InputNumber name='timelimit' label='Time Limit (Seconds)' value={this.state.dataRow.timelimit} readOnly/>
                    </>
                    },

                    {caption: 'Question Options', child: 
                    this.state.dataRow.id &&
                        <QuestionoptionList history={this.props.history} root={this.props.root} questionId={this.state.dataRow.id?.toString()} actions={['view']} disableBackButton disableCard/>                            
                    },
                    ]}

                />
                             
            </BaseView>
        );
    }
}