import * as React from 'react';
import { History } from 'history';
import { Box, Container, Grid, Typography } from '@mui/material';
import AppModel from '../../app/app-model';
import OwnerModel from '../../owner/owner-model';
import UserModel from '../../user/user-model';
import UserAuthSignin from '../../user/user-auth-signin.component';
import { AppBar, Button, Card, Dialog, Trapezoid, Transition, ParallaxMouseMove } from '../../../components';
import MenuPicklistcard from '../../menu/menu-picklistcard.component';
import BaseView from '../../base/base-view.component';
import EvaluationPicklistcard from '../../evaluation/evaluation-picklistcard.component';
import LogoApp from './logoapp.component';
import AppService from '../../../services/app.service';

interface IProps {
  history: History;
  logoUrl?: string;
}

interface IState {
  triggerTransition: boolean;
  dataRowApp: AppModel;
  dataRowOwner: OwnerModel;
  dataRowUser: UserModel;
  openDialog: boolean;
}

export default class Home extends React.Component<IProps, IState > {
  
  constructor(props: IProps) {
    super(props);
    this.state = {
      triggerTransition : false,
      dataRowApp: AppModel.defaultValue(),
      dataRowOwner: OwnerModel.defaultValue(),
      dataRowUser: UserModel.defaultValue(),
      openDialog: false,
    }
  }

  private getDataRowApp = (data:any) => {
    this.setState({dataRowApp: data})
  }

  private getDataRowOwner = (data:any) => {
    this.setState({dataRowOwner: data})
  }

  private getDataRowUser = (data:any) => {
      this.setState({dataRowUser: data})
      UserModel.setUserImage(data?.imageUrl || '')
  }

  private onClickAppBar = () =>{
    this.setState({ triggerTransition: true})
  }

  private onScrollEndAppBar = () =>{
    this.setState({ triggerTransition: false })
  }

  private avatarClick = () =>{
    if (UserModel.isSignin()) this.props.history.push('/user/profile')
    else this.setState({openDialog: true});
  }

  private UserSignin(){
    return (
      <Dialog open={this.state.openDialog} onClose={()=>this.setState({openDialog: false})}>
        <Card borderColor='white'>
          <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}} color='primary.main' mt={3} mb={3}>Login</Typography>
          <UserAuthSignin caption=' ' history={this.props.history} root='/' onSave={()=>this.setState({openDialog: false})} disableBackButton disableAutoBack></UserAuthSignin>
        </Card>
      </Dialog>
    )
  }
  
  private isMobile: boolean = AppService.isMobile();

  private header(){
    return (
      <Trapezoid position='bottomLeft' color='secondary.light'>
        <Container>
          <Grid container spacing={5} sx={{ padding: {xs: 5, md:0}, paddingBottom: {xs: 5, md:5}}}>
            <Grid item xs={12} md={6} pb={3}>
              <Transition variant='growCenter' direction='down' trigger>
              <Typography sx={{typography: {xs: 'h5', md: 'h4'}}} component='div' mb={3}>
                <b>Mari kita <></>
                <Grid display='inline' color='white'>Kemon</Grid>!
                </b>
              </Typography>              
              </Transition>
              
              <Transition variant='slide' direction='right' trigger>
              <Box>
              <Typography sx={{typography: {xs: 'h4', md: 'h2'}, lineHeight: {md: 0.8} }}>
                <b>Buat undangan</b>mu <br/>
                jadi berbeda <br/>
              </Typography>
              <Typography sx={{typography: {xs: 'caption', md: 'subtitle1'}}} pt={2} mb={3}>
                Dengan cara berbeda, kini kamu bisa membuat undangan 
                sendiri secara digital kapan saja dan dimana saja. <br/>
                Berbagai tema Gratis dan Eksklusif kami persembahkan hanya kamu.
              </Typography>
              <Button color='secondary' label='Buat Undangan Gratis'/>
              </Box>
              </Transition>
            </Grid>
            <Grid item xs={12} md={6}>
              <Transition variant='slide' direction='down' trigger>
                  <img src="https://png.pngtree.com/png-clipart/20221121/ourmid/pngtree-illustration-of-muslim-romantic-couple-png-image_6473662.png" width='auto' height={this.isMobile? 300:450} alt="Hero img" />
              </Transition>
              {this.isMobile?'':
              <Transition variant='slide' direction='up' trigger>
                <Box>
                <ParallaxMouseMove imageUrl='https://images.vexels.com/media/users/3/135018/isolated/preview/1971549b5f209b1b3c68521efdd9b85c-cloud-cartoon-04-by-vexels.png' left='30%' top='25%' width='auto' height={450} alt='Parallax1' />
                <ParallaxMouseMove variant='moveAllReverse' imageUrl='https://images.vexels.com/media/users/3/135018/isolated/preview/1971549b5f209b1b3c68521efdd9b85c-cloud-cartoon-04-by-vexels.png' top='35%' width='auto' height={450} alt='Parallax2' />
                </Box>
              </Transition>
              }
            </Grid>

            <Card color={this.isMobile? 'secondary.light':''} borderColor={this.isMobile? 'secondary.light':''} padding={0}>
            <Grid container spacing={this.isMobile? 1 : 15} textAlign='center'> 
              <Transition variant='zoom' trigger>        
              <Grid item xs={4} md={4}>
                <Card borderColor='white'>
                <Typography sx={{typography: {xs: 'h4', md: 'h3'}}} color='primary.main'>
                  <b>200</b>+
                </Typography> 
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} color='primary.light' mt={2}>
                  <b>Tema Menarik</b>
                </Typography>
                </Card>
              </Grid>
              </Transition>

              <Transition variant='zoom' addTime={500} trigger>
              <Grid item xs={4} md={4}>
                <Card borderColor='white'>
                <Typography sx={{typography: {xs: 'h4', md: 'h3'}}} color='primary.main'>
                  <b>20</b>+
                </Typography>    
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} color='primary.light' mt={2}>
                  <b>Fitur Canggih</b>
                </Typography>
                </Card>
              </Grid>
              </Transition>

              <Transition variant='zoom' addTime={1000} trigger>
              <Grid item xs={4} md={4}>
                <Card borderColor='white'>
                <Typography sx={{typography: {xs: 'h4', md: 'h3'}}} color='primary.main'>
                  <b>10K</b>+
                </Typography>           
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} color='primary.light' mt={2}>
                  <b>Kisah Terindah</b>
                </Typography>
                </Card>
              </Grid>
              </Transition>

            </Grid>
            </Card>
          </Grid>

        </Container>
      </Trapezoid>
    )
  }

  private invitationlists(){
    return (
      <Card id='invitationlists' borderColor='white'>
        <Transition parentId='invitationlists'>
          <Container >
          {UserModel.isSignin()?
            <Card color='secondary.light'>
              <EvaluationPicklistcard history={this.props.history} caption='Undangan kamu'/>
            </Card>
          :''}
          </Container>
        </Transition>
      </Card>
    )
  }

  private products(){
    return (
      <Card id='products' borderColor='white' color='white'>
        <Transition parentId='products' variant='slide' trigger={this.state.triggerTransition}>
          <Container>
            <Grid container spacing={2} pt={10}>
              <Transition parentId='products' addTime={1000}>
                <Typography sx={{typography: {xs: 'h5', md: 'h4'}}} textAlign='center'>
                  <b>Tema Gratis</b>
                </Typography>
              </Transition>
              <Card borderColor='white'>
                <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}} color='primary.main' mb={3} >Tema gratis khusus untuk kamu</Typography>
                {UserModel.isSignin()?
                <MenuPicklistcard history={this.props.history} caption=' ' cardNumber={4}/> 
                :''}
              </Card>
              <Transition parentId='products' addTime={2000}>
                <Typography sx={{typography: {xs: 'h5', md: 'h4'}}} textAlign='center'>
                  <b>Tema Eksklusif</b>
                </Typography>
              </Transition>
              <Card borderColor='white'>
                <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}} color='primary.main' mb={3} >Paling banyak dipakai</Typography>
                {UserModel.isSignin()?
                <MenuPicklistcard history={this.props.history} caption=' ' cardNumber={4}/> 
                :''}
              </Card>
              <Card borderColor='white'>
                <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}} color='primary.main' mb={3} >Modern</Typography>
                {UserModel.isSignin()?
                <MenuPicklistcard history={this.props.history} caption=' ' cardNumber={1}/> 
                :''}
              </Card>
              <Card borderColor='white'>
                <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}} color='primary.main' mb={3} >Klasik</Typography>
                {UserModel.isSignin()?
                <MenuPicklistcard history={this.props.history} caption=' ' cardNumber={1}/> 
                :''}
              </Card>
            </Grid>
          </Container>
        </Transition>
      </Card>
    )
  }

  private features(){
    return (
      <Card id='features' borderColor='whitesmoke' color='whitesmoke'>
        <Transition parentId='features' variant='fade' trigger={this.state.triggerTransition}>
          <Container>
            <Grid container direction='row' spacing={3} pt={10} pb={10} alignItems='center'>            
              <Grid item xs={12} md={3} textAlign='right'>
                <Transition parentId='features' addTime={1000}>
                <Typography sx={{typography: {xs: 'h4', md: 'h3'}, lineHeight: {md:0.8}}} color='primary.main' mb={3}>
                  <b>makin banyak FITUR MENARIK</b>
                </Typography>
                </Transition>
                <Transition parentId='features' addTime={2000}>
                <Typography sx={{typography: {xs: 'h5', md: 'h4'}, lineHeight: {md:1}}}>
                  <b>undanganmu semakin<br/> estetik</b>
                </Typography>
                </Transition>
              </Grid>
              <Grid item xs={12} md={8}>
                <Transition parentId='features' addTime={3000}>
                <img src="https://img.freepik.com/free-vector/gradient-process-infographic-template_52683-65835.jpg?w=1380&t=st=1687851874~exp=1687852474~hmac=5db20cdc5e1bd3232b8802bbf61b9512d0a7f37694a251c0d72eb98fcffb07de" width='100%' height='auto' alt="Hero img" />
                </Transition>
              </Grid>
            </Grid>
          </Container>
        </Transition>
      </Card>
    )
  }

  private invitations(){
    return (
      <Trapezoid id='invitations' position='topLeft' color='white' colorOthers='white'>
        <Transition parentId='invitations' variant='fade' trigger={this.state.triggerTransition}>
          <Container>
            <Grid container direction='row' pt={10} pb={10} alignItems='center'>   
              <Grid item xs={12} md={3}>
                <img src="https://img.freepik.com/free-photo/modern-muslim-woman-wearing-headscarf-walking-street_1303-27637.jpg?w=360&t=st=1687459247~exp=1687459847~hmac=b4eeb1f8c95efdb1979599a803584b26d8a6d03c91983e1c22c13d7e61f1899d" width={350} height='auto' alt="Hero img" />
              </Grid>
              <Grid item xs={12} md={3} >
                <img src="https://img.freepik.com/free-photo/asian-woman-glasses-smart-blouse-skirt-posing-studio-with-tablet_1098-17489.jpg?w=360&t=st=1687459012~exp=1687459612~hmac=41035a5f21d96fcffbe0db09ab2f625ae49fc3a467654db5079300549f302384" width={350} height='auto' alt="Hero img" />
              </Grid>
              <Grid item xs={12} md={4}>
                <img src="https://img.freepik.com/free-photo/confident-businessman_1098-16852.jpg?w=360&t=st=1687459085~exp=1687459685~hmac=0625f84521fff0f6e49c725f46b16e70301eecea5ad7ea3b68e5d819a8b20131" width={350} height='auto' alt="Hero img" />
              </Grid>         
              <Grid item xs={12} md={2} textAlign='left'>
                
                <Transition parentId='invitations' addTime={2000}>
                  <Typography sx={{typography: {xs: 'h4', md: 'h3'}, lineHeight: {md: 1}}} color='primary.main' mb={3}>
                    <b>Kisah Indah</b>
                  </Typography>
                </Transition>
                <Transition parentId='invitations' addTime={3000}>
                  <Typography sx={{typography: {xs: 'h5', md: 'h4'}, lineHeight: {md: 1}}}>
                    <b>yang tak terlupakan</b>
                  </Typography>
                </Transition>
              </Grid>
            </Grid>
          </Container>
        </Transition>
      </Trapezoid>
    )
  }

  private footer(){
    
    return (
      <Trapezoid id='footer' position='topLeft' color='secondary.light' colorOthers='white'>
        <Transition parentId='footer' trigger={this.state.triggerTransition}>
          <Grid container spacing={5} pb={5}>
            <Transition parentId='invitationlists' addTime={1000}>
              <Grid item xs={12} md={4}>
                <LogoApp disableMargin/>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                {this.state.dataRowApp.description}
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                {this.state.dataRowOwner.name}
                </Typography>
              </Grid>
            </Transition>
            
            <Transition parentId='footer' addTime={2000}>
              <Grid item xs={12} md={2}>
                <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}}>
                  <b>Hubungi Kami</b>
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                  Call Center <br/>
                  {this.state.dataRowOwner.phone}
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                  Email <br/>
                  {this.state.dataRowOwner.email}
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                  Instagram
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                  Facebook
                </Typography>
              </Grid>
            </Transition>

            <Transition parentId='footer' addTime={3000}>
              <Grid item xs={12} md={2}>
                <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}}>
                  <b>Kebijakan</b>
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                  Kebijakan Pribadi
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                  Syarat & Ketentuan
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                  Sering ditanyakan
                </Typography>
              </Grid>
            </Transition>
          </Grid>
        </Transition>
      </Trapezoid>
    )
  }

  private home(){
    return (
      <>      
      <BaseView  history = {null} match={ {'isExact': true, 'params': { 'id':AppService.appId() }}} caption={''} 
        model={AppModel} getDataRow={this.getDataRowApp}>    
      </BaseView>
      
      <BaseView  history = {null} match={ {'isExact': true, 'params': { 'id':OwnerModel.getOwner() }}} caption={''} 
        model={OwnerModel} getDataRow={this.getDataRowOwner}>    
      </BaseView>

      {UserModel.isSignin() &&
        <BaseView  history = {null} match={ {'isExact': true, 'params': { 'id':UserModel.getUser() }}} caption={''} 
          model={UserModel} getDataRow={this.getDataRowUser}>    
        </BaseView>
      }
      
      {this.UserSignin()}
      {this.header()}
      {this.invitationlists()}
      {this.products()}
      {this.features()}
      {this.invitations()}
      {this.footer()}
      </>
    )
  }
  
  public render(): React.ReactNode {  
    
    return (
      <AppBar 
        color = 'secondary.light'
        menuColor = 'primary.main'
        logo = {<LogoApp disableMargin={false}/>}
        menu = {[{caption:'Undanganmu', section:'invitationlists'}, {caption:'Tema', section:'products'}, {caption:'Fitur', section:'features'}, {caption:UserModel.isSignin()?'Dashboard':'', url:'/dashboard'}]}
        avatar = {{name:this.state.dataRowUser.username, imageUrl:this.state.dataRowUser.imageUrl, onClick: this.avatarClick}}
        onClick={this.onClickAppBar}
        onScrollEnd={this.onScrollEndAppBar}
      >
        {this.home()}
      </AppBar>
    )
  }
}