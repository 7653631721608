import React from 'react';
import { History } from 'history';
import { Input, InputEmail, InputImage, InputMultiline, Switch } from '../../components';
import BaseEdit from '../base/base-edit.component'; 
import Model from './owner-model';  //editable..
import MedialibraryModel from '../medialibrary/medialibrary-model';

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string },
    };
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowMedia: any;
}

export default class OwnerEdit extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            dataRowMedia: null,
        }
        this.onFieldValueChange = this.onFieldValueChange.bind(this);
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    private getFiles = (data:any) => {
        this.setState({dataRowMedia: {...this.state.dataRowMedia, endPoint: MedialibraryModel.endPoint, endPointDelete: MedialibraryModel.endPoint2, dataRow:{id:this.state.dataRow.imageId, files: data, ref: Model.endPointMedia, field:'image'}}});
    }
    
    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onFieldValueChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    render() {
        return (
            //editable..
            <BaseEdit history={this.props.history} match={this.props.match} caption={'Edit '+ Model.caption} 
                model={Model} dataRow={this.state.dataRow} dataRowMedia={this.state.dataRowMedia} getDataRow={this.getDataRow} getDataError={this.getDataError}>              
                
                <Input name='name' label='Name' value={this.state.dataRow.name} error={this.state.dataError?.name} onChange={this.onFieldValueChange} />
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} error={this.state.dataError?.description} onChange={this.onFieldValueChange} />
                <Switch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' error={this.state.dataError?.status} onChange={this.onFieldValueChange}/>
                <Input name='phone' label='Phone' value={this.state.dataRow.phone} error={this.state.dataError?.phone} onChange={this.onFieldValueChange} />
                <InputEmail name='email' label='Email' value={this.state.dataRow.email} error={this.state.dataError?.email} onChange={this.onFieldValueChange} />
                <InputMultiline name='address' label='Address' value={this.state.dataRow.address} error={this.state.dataError?.address} onChange={this.onFieldValueChange} />
                <InputImage name='imageUrl' label='Logo' value={this.state.dataRow.imageUrl} getFiles={this.getFiles} error={this.state.dataError?.image} onChange={this.onFieldValueChange} />
            </BaseEdit>
        );
    }
}