import ApiService from '../../services/api.service';

export default class SectionModel {
    id?: number;
    name: string;
    description: string;
    status: string;
    type: string;
    title: string;
    content: string;
    seq: number;
    imagetitle: string;
    image: any;
    imageId: any;
    imageUrl: string;
    page: any;
    pageCaption: string;

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.type = dataRow.attributes.type || 'general';
        this.title = dataRow.attributes.title;
        this.content = dataRow.attributes.content;
        this.seq = dataRow.attributes.seq;
        this.imagetitle = dataRow.attributes.imagetitle;
        this.image = dataRow.attributes.image?.data || null;
        this.imageId = dataRow.attributes.image?.data?.[0]?.id || null;
        this.imageUrl = dataRow.attributes.image?.data?.[0]? ApiService.svrUrl+dataRow.attributes.image.data[0].attributes.url : '';
        this.page =  dataRow.attributes.page?.data?.id || null;
        this.pageCaption =  dataRow.attributes.page?.data?.attributes.name || dataRow.attributes.page?.data?.id;
        
    } 

    public static endPoint: string = 'sections' 
    public static endPointMedia: string = 'api::section.section';
    public static endPointFilter: string = '?populate=*&sort=seq&filters[page]=';

    public static url: string = '/section';
    public static caption: string = 'Section';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active',
        type: 'general',  
        title: '',
        content: '',
        seq: 0,
        imagetitle: '',
        image: null,
        imageId: null,
        imageUrl: '',
        page: null,
        pageCaption: '',
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
        { name: 'type', required: true },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'description', caption: 'Description' },
        { name: 'seq', caption: 'Seq', type: 'number' },
        { name: 'status', caption: 'State', type: 'state', default: 'active' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'description', caption: 'Description' },
        { name: 'startdate', caption: 'Start Date', type: 'date' },
    ]

    public static optionStatus = [
        { key: 'active', value: 'Active' },
        { key: 'inactive', value: 'Inactive' },
    ]
    
    public static optionType = [
        { key: 'general', value: 'General' },
        { key: 'cover', value: 'Cover' },
        { key: 'header', value: 'Header' },
        { key: 'personalinfo', value: 'Personal Info' },
        { key: 'image', value: 'Image' },
        { key: 'address', value: 'Address' },
        { key: 'footer', value: 'Footer' },
    ]
}