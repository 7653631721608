import React from 'react';
import { History} from 'history';
import { Container, Grid } from '@mui/material';
import { Trapezoid, Logo } from '../../../components';
import { EvaluationRouteSheet } from '../../evaluation/evaluation-route-sheet';

interface IProps { 
    history: History;
    root?: string;
}

interface IState {
}

export default class Invitation extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
        }
    }


    render() {
        
        return (
            //editable..
            <Trapezoid position='bottomLeft' color='secondary.light' colorOthers='secondary.main'>
                <Container>
                    <Grid container>
                    <Logo label={document.title} titleColor='green'/>
                    <EvaluationRouteSheet/>
                    </Grid>
                </Container>
            </Trapezoid>
        );
    }
}