import * as React from 'react';
import { History } from 'history';
import Model from './questionoption-model';
import BaseList from '../base/base-list.component';

interface IProps {  
  history: History;
  root?: string;
  questionId?: string;
  actions?: any;
  disableBackButton?: boolean;
  disableCard?: boolean;
  getDataRow?: (data: any) => void;
}

interface IState {
}

export default class QuestionoptionList extends React.Component<IProps, IState> {
  
  constructor(props: IProps) {
    super(props);
  }
  
  public render(): React.ReactNode {
    return (
      <BaseList history={this.props.history} parentId={this.props.questionId} caption={Model.caption+'s'} endPointFilter={Model.endPointFilter+this.props.questionId} url={(this.props.root||'')+Model.url} model={Model} actions={this.props.actions} disableBackButton={this.props.disableBackButton} disableCard={this.props.disableCard}/>
    );
  }
}
