import * as React from 'react';
import * as toastr from 'toastr';
import { Card, FormHeader, Table } from '../../components';
import ApiService from '../../services/api.service';
import BaseValidate from './base-validate.component';
import BaseCreate from './base-create.component';

interface IProps { 
  history?: any;
  caption: string;
  captionColor?: string;
  endPoint?: string;
  model: any;
  defaultValue?: any;
  dataSet?: Array<any>;
  getDataSet?: (data: any) => void;
  validations?: any;
  page?: number;
  pageSize?: number;
  searchField?: string;
  actions?: any;
  disableTable?: boolean;
  disableBackButton?: boolean;
  disableCard? : boolean;
}

interface IState {
  dataSetOrigin: any;
  dataSetAll: Array<any>;
  dataSet: Array<any>;
  dataRow: any,
  tableHeader: Array<any>;
  page: number;
  pageSize: number;
  pageCount: number;
  actions: any;
  saveCount: number;
  searchText: string;
  isSearch: boolean;
  isValidateError: boolean;
  isReady: boolean;
  isError: boolean;
}

export default class BaseUpload extends React.Component<IProps, IState> {
  
  constructor(props: IProps) {
    super(props);
    this.state = {
      dataSetOrigin: this.props.dataSet,
      dataSetAll: [],
      dataSet: [],
      dataRow: null,
      tableHeader: this.props.model.columnUpload ||  this.props.model.columns,
      page: this.props.page || 1,
      pageSize: this.props.pageSize || 10,
      pageCount: 0,
      actions: [{name: 'upload', caption: 'Upload'}],
      saveCount: -1,
      searchText: '',
      isSearch: this.props.model.columns?.findIndex((d: { name: string; }) => d.name === (this.props.searchField || 'description'))===1,
      isValidateError: false,
      isReady: false,
      isError: false,
    };
    
    this.onChangePage = this.onChangePage.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.onClickSearch = this.onClickSearch.bind(this);
    this.onClickUpload = this.onClickUpload.bind(this);
  }
  
  private isLoading: boolean = false;

  public static uploadImage (refid: number, data: any) {
    if (data.dataRow.id)
        ApiService.delete(data.endPointDelete, data.dataRow.id).then(
            (rp) =>  {
                if (rp.Status) {toastr.success('Media deleted.'); }
                else { toastr.error(rp.Messages); }
            }
    );
    
    if (data.dataRow.files) {
        var formData = new FormData();
        formData.append('files', data.dataRow.files);
        formData.append('ref', data.dataRow.ref);
        formData.append('field', data.dataRow.field);
        formData.append('refId', refid.toString());
        
        ApiService.create(data.endPoint, formData).then(
            (rp) =>  {
                if (rp.Status) { toastr.success('Media uploaded.'); return rp.Data.id}
                else { toastr.error(rp.Messages); }
            }
        );
    }

  }

  public componentDidMount() {
    var data = this.loadDataAll(this.state.dataSetOrigin)
    this.loadData(data, this.state.page);
  }

  public componentDidUpdate() {
    if (this.props.page && (this.props.page !== this.state.page)) {
      this.setState({page: this.props.page})
      this.loadData(this.state.dataSetAll, this.props.page, this.state.searchText);
    }
  }
  
  private loadDataAll (data: any) {
    var isValidateError: boolean = false;
    var dataSet = new Array<typeof this.props.model>();
    (data || []).forEach((dataRow: any, i: number) => {
      dataRow = {...this.props.model.defaultValue(), ...dataRow, ...this.props.defaultValue};
      dataSet.push(new this.props.model(this.props.model.endPointAuth? dataRow : {attributes: dataRow})); 
      var validateError = BaseValidate.validate(dataSet[i], this.props.validations || this.props.model.validations);
      
      if (validateError) {
        dataSet[i]['validate']='error';
        dataSet[i]['error']=validateError[Object.keys(validateError).toString()];
        isValidateError = true;
      }
    });

    this.setState({ dataSetAll: dataSet, isValidateError: isValidateError });
    return dataSet;
  }

  private loadData (data: any, page: number, searchText?: string) {
    if (this.isLoading) return;
    this.isLoading = true;
    this.setState({ isReady: false});
    
    var dataSet = new Array<typeof this.props.model>();
    const pageCount = Math.ceil((data.length||1)/this.state.pageSize);    
    const search = searchText;
    (data || []).slice((page-1)*this.state.pageSize, page*this.state.pageSize).forEach((dataRow: any, i: number) => {
      dataSet.push(dataRow); 
    });
    
    this.setState({ dataSet: dataSet, pageCount: pageCount, isReady: true });
    this.isLoading = false;
  }

  private getDataSet = (data: any) => {
    this.setState({ dataSetOrigin: data})
    data = this.loadDataAll(data);
    this.loadData(data, this.state.page);
  }

  private getDataError = async(data: any) => {
    if (data) {
      const saveCount = this.state.saveCount+1
      this.state.dataSetAll[saveCount-1]['validate'] = 'error';
      this.state.dataSetAll[saveCount-1]['error'] = data?.[0];
      await this.setState({saveCount: saveCount});
    }
  }

  private onClickUpload() { 
    this.setState({saveCount: 0});
  }

  private onSave = async(data: any) => {
    const saveCount = this.state.saveCount+1
    await this.setState({saveCount: saveCount});
  }

  private onChangePage (e: React.ChangeEvent<unknown>, value: number) {
    this.setState({page: value});
    this.loadData(this.state.dataSetAll, value, this.state.searchText);
  };
  
  private onChangeSearch(fieldName: string, value: string) { 
    this.setState({searchText: value});
    this.loadData(this.state.dataSetAll, this.state.page, value);
  }
  
  private onClickSearch() { 
    this.loadData(this.state.dataSetAll, this.state.page, this.state.searchText);
  }

  private table(){
    return <Table history={this.props.history} tableHeader={this.state.tableHeader} dataSet={this.state.dataSet} getDataRow={this.getDataSet} page={this.state.page} pageCount={this.state.pageCount} actions={this.state.actions} searchText={this.state.searchText} isValidateError={this.state.isValidateError} isSearch={this.state.isSearch} isLoading={this.isLoading} isReady={this.state.isReady} isError={this.state.isError} onChangePage={this.onChangePage} onChangeSearch={this.onChangeSearch} onClickSearch={this.onClickSearch} onClickUpload={this.onClickUpload} readOnly />
  }
  
  public render(): React.ReactNode {  
    return (
      <>
      {!this.props.disableTable &&
      <>
        <FormHeader history={this.props.history} label={this.props.caption} color={this.props.captionColor} disableBackButton={this.props.disableBackButton} /> 
        {this.props.disableCard? this.table():
          <Card>{this.table()}</Card>           
        }           
      </>
      }
      {this.state.dataSetAll?.map((dataRow, i) =>{
        return (
          <BaseCreate key={i} caption=' ' buttonCaption=' '
            endPoint={this.props.endPoint} model={this.props.model} dataRow={dataRow} getDataError={this.getDataError} onSave={this.onSave} save={this.state.saveCount===i} disableAutoBack disableBackButton>
          </BaseCreate>
        )
      })}
      </>
    );
  }
}
