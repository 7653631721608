import React from 'react';
import { History } from 'history';
import { Input, InputNumber, InputSelect, InputImage, InputMultiline } from '../../components';
import BaseDelete from '../base/base-delete.component'; 
import Model from './section-model';  //editable..

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string },
    };
    root?: string;
}

interface IState {
    dataRow: Model;
    dataError: any;
    hasParent: boolean;
}

export default class SectionDelete extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            hasParent: false,
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data, hasParent: data.parent? false:true});
    }

    render() {
        
        return (
            //editable..
            <BaseDelete history={this.props.history} match={this.props.match} caption={'Delete '+ Model.caption} 
                model={Model} getDataRow={this.getDataRow}>                  

                <Input name='name' label='Name' value={this.state.dataRow.name} readOnly/>
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} readOnly/>
                <InputSelect name='status' label='Status' value={this.state.dataRow.status} options={Model.optionStatus} readOnly />
                <Input name='title' label='Title' value={this.state.dataRow.title} readOnly/>
                <InputMultiline name='content' label='Content' value={this.state.dataRow.content} readOnly/>
                <InputImage name='imageUrl' label='Image' value={this.state.dataRow.imageUrl} readOnly/>
                <InputNumber name='seq' label='Seq' value={this.state.dataRow.seq} readOnly/>
                <InputSelect name='type' label='Type' value={this.state.dataRow.type} options={Model.optionType} readOnly/>
                                    
            </BaseDelete>
        )
    }
}