import * as React from 'react';
import { History } from 'history';
import Model from './transaction-model';
import BaseList from '../base/base-list.component';

interface IProps {  
  history: History;
  root?: string;
  caption?: string;
  type?: string;
  status?: string;
  url?: string;
}

interface IState {
}

export default class TransactionList extends React.Component<IProps, IState> {

  public render(): React.ReactNode {
    return (
      <BaseList history={this.props.history} caption={(this.props.caption || Model.caption)+'s'} endPointFilter={Model.endPointFilter()+'&filters[status]='+(this.props.status || 'draft')+(this.props.type? '&filters[transactiontype][type]='+this.props.type : '')} url={(this.props.root||'')+(this.props.url || Model.url)} model={Model}/>
    );
  }
}
