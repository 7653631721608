import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import TransactionList from './transaction-list.component';
import TransactionCreate from './transaction-create.component';
import TransactionView from './transaction-view.component';
import TransactionEdit from './transaction-edit.component';
import TransactionCopy from './transaction-copy.component';
import TransactionDelete from './transaction-delete.component';

interface Props {
  root?: string;
}

export const TransactionRouteSales: React.FunctionComponent<Props> = (props) => {
  const disableFields = ['owner','transactionType','vendor','journal'];
  
  return (
    <Switch>
        <Route path={(props.root||'')+'/transactionsales/create'} render={(params)=> <TransactionCreate {...params} root={props.root} caption={'Sales Transaction'} transactionType={'3'} disableFields={disableFields} />} />        
        <Route path={`${props.root||''}/transactionsales/view/:id`} render={(params)=> <TransactionView {...params} root={props.root} caption={'Sales Transaction'} disableFields={disableFields} />} />        
        <Route path={`${props.root||''}/transactionsales/edit/:id`} render={(params)=> <TransactionEdit {...params} root={props.root} caption={'Sales Transaction'} disableFields={disableFields} />} />        
        <Route path={`${props.root||''}/transactionsales/copy/:id`} render={(params)=> <TransactionCopy {...params} root={props.root} caption={'Sales Transaction'} disableFields={disableFields} />} />        
        <Route path={`${props.root||''}/transactionsales/delete/:id`} render={(params)=> <TransactionDelete {...params} root={props.root} caption={'Sales Transaction'} disableFields={disableFields} />} />        
        <Route path={(props.root||'')+'/transactionsales'} render={(params)=> <TransactionList {...params} root={props.root}  caption='Sales Transaction' type='sales' url='/transactionsales'/>} /> 
    </Switch>
  );
};
