import * as React from 'react';
import { FormHeader, Table } from '../../components';
import ApiService from '../../services/api.service';

interface IProps {
  history?: any;
  caption: string;
  captionColor?: string;
  endPointFilter?: string;
  model: any;
  currentId?: any;
  getDataRow: (data: any) => void;
  page?: number;
  pageSize?: number;
  searchField?: string;
  cardNumber?: number;
  cardBorderRadius?: number;
  cardBoxShadow?: number;
  cardPadding?: number;
  cardTextAlign?: 'left'|'center'|'right';
  readOnly?: boolean;
  disableBackButton?: boolean;
}

interface IState {
  dataSet: Array<any>;
  tableHeader: Array<any>;
  page: number;
  pageSize: number;
  pageCount: number;
  currentId?: any;
  searchText: string;
  isSearch: boolean;
  isReady: boolean;
  isError: boolean;
}

export default class BaseChecklistCard extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      dataSet: this.props.model,
      tableHeader: this.props.model.columnPicklist,
      page: this.props.page || 1,
      pageSize: this.props.pageSize || 10,
      pageCount: 0,
      searchText: '',
      isSearch: this.props.model.columnPicklist.findIndex((d: { name: string; }) => d.name === (this.props.searchField || 'description'))===1,
      isReady: false,
      isError: false,
    };
    
    this.onChangePage = this.onChangePage.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.onClickSearch = this.onClickSearch.bind(this);
  }
  
  private isLoading: boolean = false;

  public componentDidMount() {
    this.loadData(this.state.page);
  }

  private loadData (page: number, searchText?: string) {
    if (this.isLoading) return;
    this.isLoading = true;
    this.setState({ isReady: false});

    const pagination = '&pagination[page]='+page+'&pagination[pageSize]='+this.state.pageSize;
    const search = searchText? '&filters['+(this.props.searchField || 'description')+'][$contains]='+searchText : '';
    ApiService.getAll<typeof this.props.model>(this.props.model.endPoint+(this.props.endPointFilter||'?populate=*')+search+pagination).then((rp) => {
      if (rp.Status) {
        const pageCount = Math.ceil(rp.Meta?.pagination?.total/this.state.pageSize);
        const data = rp.Data; 
        const dataSet = new Array<typeof this.props.model>();
        (data || []).forEach((dataRow: any, i:number) => {dataSet.push(new this.props.model(dataRow)); });
        this.setState({ dataSet: dataSet, pageCount:pageCount, isReady: true });
        if (this.state.pageSize===1) this.props.getDataRow(dataSet[0])
      } 
      else { this.setState({ isReady: true, isError: true }); }
      this.isLoading = false;
    });
  }

  private onChangePage (e: React.ChangeEvent<unknown>, value: number) {
    this.setState({page: value});
    this.loadData(value);
  };
  
  private onChangeSearch(fieldName: string, value: string) { 
    this.setState({searchText: value});
    this.loadData(this.state.page, value);
  }
  
  private onClickSearch() { 
    this.loadData(this.state.page, this.state.searchText);
  }
  
  private table(){
    return <Table history={this.props.history} tableHeader={this.state.tableHeader} dataSet={this.state.dataSet} currentId={this.props.currentId} getDataRow={this.props.getDataRow} page={this.state.page} pageCount={this.state.pageCount} searchText={this.state.searchText} isSearch={this.state.isSearch} isLoading={this.isLoading} isReady={this.state.isReady} isError={this.state.isError} onChangePage={this.onChangePage} onChangeSearch={this.onChangeSearch} onClickSearch={this.onClickSearch} cardNumber={this.props.cardNumber} cardBorderRadius={this.props.cardBorderRadius} cardBoxShadow={this.props.cardBoxShadow} cardPadding={this.props.cardPadding} cardTextAlign={this.props.cardTextAlign} readOnly={this.props.readOnly} checklistView cardView/> 
  }

  public render(): React.ReactNode {  
    return (
      <>
        <FormHeader history={this.props.history} label={this.props.caption} color={this.props.captionColor} disableBackButton/> 
        {this.table()}
      </>
    );
  }
}
