import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import EvaluationdetailList from './evaluationdetail-list.component';
import EvaluationdetailCreate from './evaluationdetail-create.component';
import EvaluationdetailView from './evaluationdetail-view.component';
import EvaluationdetailEdit from './evaluationdetail-edit.component';
import EvaluationdetailCopy from './evaluationdetail-copy.component';
import EvaluationdetailDelete from './evaluationdetail-delete.component';

interface Props {
  root?: string;
}

export const EvaluationdetailRoute: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>
      <Route path={(props.root||'')+'/evaluationdetail/create/:id'} component={EvaluationdetailCreate} />
      <Route path={(props.root||'')+'/evaluationdetail/view/:id'} component={EvaluationdetailView} />
      <Route path={(props.root||'')+'/evaluationdetail/edit/:id'} component={EvaluationdetailEdit} />
      <Route path={(props.root||'')+'/evaluationdetail/copy/:id'} component={EvaluationdetailCopy} />
      <Route path={(props.root||'')+'/evaluationdetail/delete/:id'} component={EvaluationdetailDelete} />
      <Route path={(props.root||'')+'/evaluationdetail'} component={EvaluationdetailList} />
    </Switch>
  );
};
