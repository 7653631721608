import * as React from 'react';

interface Props {
  variant?: 'moveHorz'|'moveHorzReverse'|'moveVert'|'moveVertReverse'|'moveAll'|'moveAllReverse'|'moveHorzAndVertReverse'|'moveVertAndHorzReverse';
  imageUrl: string;
  left?: string | number;
  top?: string | number;
  height?: string | number;
  width?: string | number;
  alt?: string;
}

export const ParallaxMouseMove: React.FunctionComponent<Props> = (props) => {;
  const [transform, setTransform] = React.useState('');
  const variant = props.variant || 'moveHorz';
  const signX = variant.includes('AllReverse') || variant.includes('HorzReverse')? 1 : -1;
  const signY = variant.includes('AllReverse') || variant.includes('VertReverse')? 1 : -1;
  const move = 5;

  const onMouseMove = React.useCallback((e: MouseEvent) => { 
    const offsetX = (window.innerWidth + signX * e.pageX * move) / 90
    const offsetY = (window.innerWidth + signY * e.pageY * move) / 90
    setTransform(`translateX(${offsetX}px) translateY(${offsetY}px)`);
  }, []);

  React.useEffect(() => {
    window.addEventListener('mousemove', onMouseMove);

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
    }
  }, [onMouseMove])

  
  return (
    <img src={props.imageUrl} style={{position: 'absolute', left: props.left, top:props.top,  transform: transform }} height={props.height} width={props.width} alt={props.alt}/>
    )
};
