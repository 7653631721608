import React from 'react';
import { History } from 'history';
import { InputDate, InputMoney, Picklist } from '../../components';
import BaseView from '../base/base-view.component'; 
import Model from './coabalance-model';  //editable..

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string },
    }
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class CoabalanceView extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    render() {
        return (
            //editable..
            <BaseView history={this.props.history} match={this.props.match} caption={'View '+ Model.caption} 
                model={Model} getDataRow={this.getDataRow}>              
                
                <InputDate name='balancedate' label='Date' value={this.state.dataRow.balancedate} readOnly/>
                <InputMoney name='value' label='Value' value={this.state.dataRow.value} readOnly/>
                <Picklist name='coa' label='COA' value={this.state.dataRow.coa?.toString()} readOnly caption={this.state.dataRow.coaCaption} /> 
            </BaseView>
        );
    }
}