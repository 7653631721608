import * as React from 'react';
import { Box, Container, Grid } from '@mui/material';
import AppService from '../services/app.service';

interface Props {
  id?: string;
  color?: any;
  colorOthers?: any;
  position: 'topLeft'|'topRight'|'bottomRight'|'bottomLeft';
  children?: React.ReactNode;
}

export const Trapezoid: React.FunctionComponent<Props> = (props) => {

  const isMobile = AppService.isMobile()
  const trapezoidPos = () => {
    switch (props.position){
      case 'topRight': return <Box sx={{height: 100, backgroundColor: props.colorOthers||'white', borderStyle: 'solid', borderWidth: 0, borderColor: props.color||'primary.main', borderBottomWidth: 100, borderLeftWidth: window.screen.width+154, borderLeftColor: 'transparent'}}/>
      case 'bottomRight': return <Box sx={{height: 100, backgroundColor: props.colorOthers||'white', borderStyle: 'solid', borderWidth: 0, borderColor: props.color||'primary.main', borderTopWidth: 100, borderLeftWidth: window.screen.width+154, borderLeftColor: 'transparent'}}/> 
      case 'bottomLeft': return <Box sx={{height: 100, backgroundColor: props.colorOthers||'white', borderStyle: 'solid', borderWidth: 0, borderColor: props.color||'primary.main', borderTopWidth: 100, borderRightWidth: window.screen.width+154, borderRightColor: 'transparent'}}/>
      default : return <Box sx={{height: 100, backgroundColor: props.colorOthers||'white', borderStyle: 'solid', borderWidth: 0, borderColor: props.color||'primary.main', borderBottomWidth: 100, borderRightWidth: window.screen.width+154, borderRightColor: 'transparent'}}/>
    }
  }

  return (        

    <Grid id={props.id} sx={{height: 'auto', backgroundColor:props.color||'primary.main'}}> 
      {props.position.includes('top')? trapezoidPos() : ''}
      {isMobile? props.children : <Container>{props.children}</Container>}
      {props.position.includes('bottom')? trapezoidPos() : ''}
    </Grid>
   
  );
};
