import React from 'react';
import {Route } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import Home from './home.component';
import Dashboard from './dashboard.component';
import Invitation from './invitation.component';
import AppThemes from '../applayout-themes';
import { UserRouteAuth } from '../../user/user-route-auth';
import UserModel from '../../user/user-model';

interface IProps { 
    getThemes: (data: any) => void;
}

interface IState {
}

export default class Index extends React.Component<IProps, IState> {

    constructor(props: IProps) {
      super(props);
      this.state = {
      }
      this.props.getThemes(AppThemes('green'))
    }

    render() {

        return (
          //editable..
          <Box overflow='hidden'>
            <Container> <UserRouteAuth isSignin={UserModel.isSignin()}/></Container>
            {UserModel.isSignin() &&
            <>
            <Route path='/dashboard' render={(params)=> <Dashboard {...params} root='/dashboard' />}/> 
            <Route path='/invitation' render={(params)=> <Invitation {...params} />}/> 
            </>
            }
            <Route path='/' component={Home} exact/>  
          </Box>
        );
    }
}