import React from 'react';
import { History } from 'history';
import { InputMoney, Picklist } from '../../components';
import BaseCopy from '../base/base-copy.component'; 
import Model from './transactionamount-model';  //editable..
import AmounttypePicklist from '../amounttype/amounttype-picklist.component';

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string },
    }
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class TransactionamountCopy extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        this.onFieldValueChange = this.onFieldValueChange.bind(this);
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }
    
    private getDataRowAmounttype = (data: any) => {
        this.onFieldValueChange('value', data?.defaultvalue>0? data?.defaultvalue : data?.defaultpercentage)
        this.setState({ dataRow: { ...this.state.dataRow, 'amounttype' : data?.id || null, 'amounttypeCaption' : data?.name || '', 'value': data.defaultvalue }  });
    };


    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onFieldValueChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    render() {
        return (
            //editable..
            <BaseCopy history={this.props.history} match={this.props.match} caption={'Duplicate '+ Model.caption} 
                model={Model} dataRow={this.state.dataRow} getDataRow={this.getDataRow} getDataError={this.getDataError}>              
                
                <Picklist name='amounttype' label='Type' value={this.state.dataRow.amounttype?.toString()} caption={this.state.dataRow.amounttypeCaption} error={this.state.dataError?.amounttype} onChange={this.onFieldValueChange} >
                    <AmounttypePicklist currentId={this.state.dataRow.amounttype?.toString()} getDataRow={this.getDataRowAmounttype}/>
                </Picklist>                 
                <InputMoney name='value' label='Amount' value={this.state.dataRow.value} error={this.state.dataError?.value} onChange={this.onFieldValueChange} />
              
            </BaseCopy>
        );
    }
}