import * as React from 'react';
import { Grid, IconButton, TextField, Typography } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import IconClear from '@mui/icons-material/Clear';

interface Props {
  name: string;
  label: string;
  type?: string;
  placeHolder?: string;
  value: string;
  readOnly?: boolean;
  clearValue?: boolean;
  disableTime?: boolean;
  onChange?: (fieldName: string, value: any) => void;
  error?: string;
}

export const InputDate: React.FunctionComponent<Props> = (props) => {
  const [value, setValue] = React.useState<any>(null);

  const onClickClear = () => {
    setValue(null);
    props.onChange?.(props.name, null);
  };

  React.useEffect(() => {
    setValue(props.value);
    
  },[props.value])

  return (
    <Grid>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        
        <DateTimePicker
          value={value}
          readOnly={props.readOnly}
          inputFormat='DD-MMM-YYYY HH:mm'
          ampm={false}
          hideTabs={props.disableTime? true : false}
          disableMaskedInput
          disableOpenPicker = {props.readOnly}
          onChange={(newValue) => {
            setValue(newValue || null);
            props.onChange?.(props.name, newValue || null);
          }}
          InputProps={{
            readOnly: true,
            sx: {fontSize: {xs: '14px', md: '16px'}},
            startAdornment: (
              <>
              {(!props?.readOnly) && (value || props.value)?
                <IconButton onClick={onClickClear} style={{order: 1, padding: 0}}>
                  <IconClear sx= {{fontSize: {xs: '16px', md: '20px'}}}/>
                </IconButton>
              :''}
              </>
            ),
          }}
          InputAdornmentProps={{
            position: 'end',
            sx: {order: 2, margin: 0}
          }}
          PopperProps={{
            sx: {
              '.MuiPaper-root': {
                padding: 5,
                backgroundColor:'secondary.light'
              },
              '.MuiPickersDay-dayWithMargin':{
                backgroundColor:'secondary.light'
              }
            },
          }}
          renderInput={(params) => 
            <TextField  {...params}         
              margin='normal'           
              label={props.label}     
              variant='standard'
              fullWidth
            /> 
          }
        />
        
      </LocalizationProvider>
      <Typography variant='caption' color='error' display='block' >
        {props.error}
      </Typography>
    </Grid>
  )
};