import * as React from 'react';
import { History } from 'history';
import { Button, Card, FormHeader } from '.';

interface Props {
  history?: History;
  caption: string;
  captionColor?: string;
  buttonCaption?: string;
  buttonColor?: string;
  buttonOnBottom?: boolean;
  onSubmit?: (data: any) => void;
  disableBackButton?: boolean;
  disableCard?: boolean;
  children?: React.ReactNode;
}

export const Form: React.FunctionComponent<Props> = (props) => {

  const displayButton = (marginTop: number) => {
    const buttonCaption = props.buttonCaption || 'submit';
    if (props.buttonCaption?.trim()!=='')
        return <Button variant='contained' label={buttonCaption} color={props.buttonColor} sx={{marginTop: marginTop}} onClick={props.onSubmit} />      
  }
  
  return (
    <form onSubmit={e => e.preventDefault()}>
      <FormHeader history={props.history} label={props.caption} color={props.captionColor} disableBackButton={props.disableBackButton}>  
        {props.buttonOnBottom? '' : displayButton(0)}
      </FormHeader>
      {props.disableCard? props.children:
      <Card>{props.children}</Card>           
      }                          
      {props.buttonOnBottom? displayButton(3) : ''}
    </form>
  );
};
