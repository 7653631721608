import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import TransactionView from './transaction-view.component';
import TransactionList from './transaction-list.component';

interface Props {
  root?: string;
}

export const TransactionRouteJournal: React.FunctionComponent<Props> = (props) => {
  const disableFields = ['owner','transactionType','employee','customer','vendor','transactiondetail','transactionamount','transactionpayment'];
    
  return (
    <Switch>
        <Route path={`${props.root||''}/transactionjournal/view/:id`} render={(params)=> <TransactionView {...params} root={props.root} caption={'Transaction Journal'} disableFields={disableFields} />} />        
        <Route path={(props.root||'')+'/transactionjournal'} render={(params)=> <TransactionList {...params} root={props.root} caption='Transaction Journal' status='approved' url='/transactionjournal'/>} /> 
    </Switch>
  );
};
