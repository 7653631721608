
export default class AppService {

    public static appId(){
        return `${process.env.REACT_APP_APPID}` ;  
    } 

    public static isAppFullVersion(){
        return this.appId() === '1';
    }
    
    public static isMobile(){
        return window.screen.width < 485;//window.matchMedia && window.matchMedia('(max-width: 485px)').matches;
    }
    
    public static isMac(){
        return /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
    }

    public static dateCurrent(){
        const date = new Date();
        return date.toISOString().substring(0,10) + ' ' + date.toTimeString().substring(0,8)
    }

    public static dateISO(date: string, addHours?: number, addMinutes?: number, addSeconds?: number){
        var date1 = new Date(date) //convert to local time 
        
        if (addHours) date1.setHours(date1.getHours() + addHours);
        if (addMinutes) date1.setMinutes(date1.getMinutes() + addMinutes);
        if (addSeconds) date1.setSeconds(date1.getSeconds() + addSeconds);
        return date1.toLocaleDateString('en-CA') + ' ' + date1.toTimeString().substring(0,8)
    }
    
    public static dateFormat(date: string, format?: string){
        if (date==='') return '';
        date = this.dateISO(date); 
        
        const month = ['Januari','Februari','Maret','April','Mei','Juni','Juli','Agustus','September','Oktober','November','Desember']
        const mon = ['Jan','Feb','Mar','Apr','Mei','Jun','Jul','Agu','Sep','Okt','Nov','Des']
        const y = date.substring(0,4);
        const m = date.substring(5,7);
        const d = date.substring(8,10);
        const t = date.substring(11,19);
        const h = date.substring(11,13);
        const n = date.substring(14,16);
        const s = date.substring(17,19);
        
        var fmt = format || 'd-m-y';
        fmt = fmt
        .replace('month', '???')
        .replace('mon', '??')
        .replace('m', '?')
        .replace('y',y)
        .replace('d',d)
        .replace('t',t)
        .replace('h',h)
        .replace('n',n)
        .replace('s',s)
        .replace('???', month[+m-1])
        .replace('??', mon[+m-1])
        .replace('?', m)

        return fmt;
    }

    public static thousandSeparator = '.';
    public static decimalSeparator = ',';
    
    public static strToMoney(value: string) {
        let i = 0;
        const pattern = '###.###.###.###.###'.replaceAll('.', this.thousandSeparator);
        const v = value? (+value.toString().replace(/[^0-9]/g, _ => '')+'') : '0';
        const x = (v.length + Math.floor((v.length-1) / 3))
        const patternNew = pattern.substr(pattern.length - x, x);
        return patternNew.replace(/#/g, _ => v[i++] || '');
    }

    public static strCapitalize(value: string){
        return value.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    }

    public static emailMasking(value: string){
        return value.replace(/^(.)(.*)(.@.*)$/, (_, a, b, c) => a + b.replace(/./g, '*') + c);
    }
}