import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import CivilnumberList from './civilnumber-list.component';
import CivilnumberCreate from './civilnumber-create.component';
import CivilnumberView from './civilnumber-view.component';
import CivilnumberEdit from './civilnumber-edit.component';
import CivilnumberCopy from './civilnumber-copy.component';
import CivilnumberDelete from './civilnumber-delete.component';

interface Props {
  root?: string;
}

export const CivilnumberRoute: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>
      <Route path={(props.root||'')+'/civilnumber/create/:id'} component={CivilnumberCreate} />
      <Route path={(props.root||'')+'/civilnumber/view/:id'} component={CivilnumberView} />
      <Route path={(props.root||'')+'/civilnumber/edit/:id'} component={CivilnumberEdit} />
      <Route path={(props.root||'')+'/civilnumber/copy/:id'} component={CivilnumberCopy} />
      <Route path={(props.root||'')+'/civilnumber/delete/:id'} component={CivilnumberDelete} />
      <Route path={(props.root||'')+'/civilnumber'} component={CivilnumberList} /> 
    </Switch>
  );
};
