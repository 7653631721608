import * as React from 'react';
import { Grid } from '@mui/material';
import { Card, Tabs } from '.';
import AppService from '../services/app.service';

interface Props {
  direction?: 'row'|'row-reverse'|'column'|'column-reverse';
  content: {
    caption: string, 
    hide?: boolean,
    leftPosition?: boolean,
    child?: React.ReactNode,
  }[];
  reverseChild?: boolean;
}


export const Layout: React.FunctionComponent<Props> = (props) => {
  const [tabIdx, setTabIdx] = React.useState(AppService.isMobile()? 0 : -1);
  const getTabIndex = (index: number) => {
    setTabIdx(index);    
  };


  return (
    <>      
    {tabIdx>=0 && <Tabs tab={props.content} getTabIndex={getTabIndex}/>}

    <Grid container direction={props.direction} spacing={2} mt={1}>

      <Grid item container xs={12} md={4} spacing={2} alignContent='flex-start'>
        {props.content?.map((content, i) => {
          if ((i===0 || (i>0 && content.leftPosition)) && [-1,i].includes(tabIdx) && !content.hide)
          return (
            <Card key={i}>
              {content.child}
            </Card>
          )
        })}
      </Grid>
      
      <Grid item container direction={props.reverseChild? 'column-reverse' : 'column'} xs={12} md={8} spacing={2} justifyContent='start'>
          {props.content?.map((content, i) => {
            if (i>0 && !content.leftPosition && [-1,i].includes(tabIdx) && !content.hide)
            return (
              <Grid key={i} item container>
                <Card>
                  {content.child}
                </Card>
              </Grid>
            )
          })}
      </Grid>
    </Grid>  
    </>
    
  );
}