import * as React from 'react';
import {Tabs as TabsMui, Tab, Box} from '@mui/material';

interface Props {
  variant?: 'scrollable';
  value?: number;
  tab:{
    caption: string,
    hide?: boolean,
  }[];
  getTabIndex?: (index: number) => void;
}


export const Tabs: React.FunctionComponent<Props> = (props) => {  
  const [value, setValue] = React.useState(props.value || 0);
  const onChange = (e: React.SyntheticEvent<Element, Event>, newValue: number) => { 
    setValue(newValue);
    props.getTabIndex?.(newValue)
  };

  return(
    <Box sx={{maxWidth: {xs: 400 }}} >
      <TabsMui variant={props.variant || 'scrollable'} value={value} scrollButtons onChange={onChange}>
      
          {props.tab?.map((tab,i) => {
            return  <Tab key={tab.caption} label={tab.caption} hidden={tab.hide} sx={{textTransform: 'none'}}/>
        })}
      </TabsMui>
    </Box>
  )
}