import React from 'react';
import { History } from 'history';
import { Input, InputDate, InputMoney, InputMultiline, InputSelect, Layout, Picklist } from '../../components';
import BaseDelete from '../base/base-delete.component'; 
import Model from './transaction-model';  //editable..
import TransactiondetailList from '../transactiondetail/transactiondetail-list.component';
import TransactionamountList from '../transactionamount/transactionamount-list.component';
import TransactionpaymentList from '../transactionpayment/transactionpayment-list.component';
import JournalList from '../journal/journal-list.component';
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string },
    };
    root?: string;
    caption?: string;
    disableFields?: any;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class TransactionDelete extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }
    
    render() {
        return (
            //editable..
            <BaseDelete history={this.props.history} match={this.props.match} caption={this.props.caption || 'Delete '+ Model.caption} 
                model={Model} getDataRow={this.getDataRow} disableCard>              
                                                
                <Layout content={[
                    {caption: 'Details', child: 
                    <>
                        <Input name='name' label='Name' value={this.state.dataRow.name} readOnly/>
                        <InputMultiline name='description' label='Description' value={this.state.dataRow.description} readOnly/>
                        <InputSelect name='status' label='Status' value={this.state.dataRow.status} options={Model.optionStatus} readOnly/>
                        {!this.props.disableFields?.includes('owner') && AppService.isAppFullVersion() &&
                        <Picklist name='owner' label='Owner' value={this.state.dataRow.owner?.toString()} caption={this.state.dataRow.ownerCaption} readOnly/>
                        }
                        {!this.props.disableFields?.includes('transactionType') &&
                        <Picklist name='transactiontype' label='Transaction Type' value={this.state.dataRow.transactiontype?.toString()} caption={this.state.dataRow.transactiontypeCaption} readOnly/>
                        }
                        <Picklist name='Transactionref' label='Transaction Ref' value={this.state.dataRow.transactionref?.toString()} caption={this.state.dataRow.transactionrefCaption} readOnly/>
                        <InputDate name='transactiondate' label='Date' value={this.state.dataRow.transactiondate} readOnly/>
                        {!this.props.disableFields?.includes('customer') &&
                        <Picklist name='customer' label='Customer' value={this.state.dataRow.customer?.toString()} caption={this.state.dataRow.customerCaption} readOnly/>
                        } 
                        {!this.props.disableFields?.includes('vendor') &&
                        <Picklist name='vendor' label='Vendor' value={this.state.dataRow.vendor?.toString()} caption={this.state.dataRow.vendorCaption} readOnly/>
                        }
                        {!this.props.disableFields?.includes('employee') &&
                        <Picklist name='employee' label='Employee' value={this.state.dataRow.employee?.toString()} caption={this.state.dataRow.employeeCaption} readOnly/>
                        }
                    </>
                    },

                    {caption: 'Orders', hide: this.props.disableFields?.includes('transactiondetail','transactionamount'), child: 
                    this.state.dataRow.id &&
                    <>
                        {!this.props.disableFields?.includes('transactiondetail') &&
                            <>
                            <TransactiondetailList history={this.props.history} root={this.props.root} transactionId={this.state.dataRow.id.toString()} actions={['view']} disableBackButton disableCard/>
                            <InputMoney name='subtotal' label='Sub Total' value={this.state.dataRow.subtotal} readOnly/>
                            </>
                        }
                        {!this.props.disableFields?.includes('transactionamount') &&
                            <>
                            <TransactionamountList history={this.props.history} root={this.props.root} transactionId={this.state.dataRow.id.toString()} actions={['view']} disableBackButton disableCard/> 
                            <InputMoney name='total' label='Total' value={this.state.dataRow.totalpayment} readOnly/>
                            </>
                        }
                    </>
                    },

                    {caption:'Payments', hide: this.props.disableFields?.includes('transactionpayment'), child:
                    this.state.dataRow.id &&
                        <>
                        <TransactionpaymentList history={this.props.history} root={this.props.root} transactionId={this.state.dataRow.id.toString()} actions={['view']} disableBackButton disableCard/> 
                        <InputMoney name='total' label='Total' value={this.state.dataRow.total} readOnly/>
                        </>
                    },
 
                    {caption:'Journals', hide: this.props.disableFields?.includes('journal'), child:
                    this.state.dataRow.id &&
                        <JournalList history={this.props.history} root={this.props.root} transactionId={this.state.dataRow.id.toString()} actions={['view']} disableBackButton disableCard/> 
                    },
  
                   ]}
                    
                />

            </BaseDelete>
        );
    }
}