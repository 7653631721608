import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import TransactionpaymentList from './transactionpayment-list.component';
import TransactionpaymentCreate from './transactionpayment-create.component';
import TransactionpaymentView from './transactionpayment-view.component';
import TransactionpaymentEdit from './transactionpayment-edit.component';
import TransactionpaymentCopy from './transactionpayment-copy.component';
import TransactionpaymentDelete from './transactionpayment-delete.component';

interface Props {
  root?: string;
}

export const TransactionpaymentRoute: React.FunctionComponent<Props> = (props) => {
  
  return (
    <Switch>
        <Route path={(props.root||'')+'/transactiondetailpayment/create/:id'} component={TransactionpaymentCreate} />
        <Route path={(props.root||'')+'/transactiondetailpayment/view/:id'} component={TransactionpaymentView} />
        <Route path={(props.root||'')+'/transactiondetailpayment/edit/:id'} component={TransactionpaymentEdit} />
        <Route path={(props.root||'')+'/transactiondetailpayment/copy/:id'} component={TransactionpaymentCopy} />
        <Route path={(props.root||'')+'/transactiondetailpayment/delete/:id'} component={TransactionpaymentDelete} />
        <Route path={(props.root||'')+'/transactiondetailpayment'} component={TransactionpaymentList}/>
    </Switch>
  );
};
