import React from 'react';
import { History } from 'history';
import { Input, InputEmail, InputMultiline, Switch, Picklist, InputDate, InputImage } from '../../components';
import BaseCreate from '../base/base-create.component'; 
import Model from './personalinfo-model';  //editable..
import MedialibraryModel from '../medialibrary/medialibrary-model';
import OwnerPicklist from '../owner/owner-picklist.component';
import PersonalinfotypePicklist from '../personalinfotype/personalinfotype-picklist.component';
import RegionPicklist from '../region/region-picklist.component';
import UserPicklistPersonalinfo from '../user/user-picklist-personalinfo.component';
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
    caption?: string;
    type?: string;
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowMedia: any;
}

export default class PersonalinfoCreate extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            dataRowMedia: null,
        }
        this.onFieldValueChange = this.onFieldValueChange.bind(this);
    }
    
    private getDataRowOwner = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'owner' : data?.id || null, 'ownerCaption' : data?.name || ''  }  });
    };
    
    private getDataRowPersonalinfotype = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'personalinfotype' : data?.id || null, 'personalinfotypeCaption' : data?.name || ''  }  });
    };
    
    private getDataRowBirthplace = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'birthplace' : data?.id || null, 'birthplaceCaption' : data?.description || ''  }  });
    };
    
    private getDataRowUser = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'user' : data?.id || null, 'userCaption' : data?.username || ''  }  });
    };
    
    private getFiles = (data:any) => {
        this.setState({dataRowMedia: {...this.state.dataRowMedia, endPoint: MedialibraryModel.endPoint, endPointDelete: MedialibraryModel.endPoint2, dataRow:{files: data, ref: Model.endPointMedia, field:'image'}}});
    }

    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onFieldValueChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    render() {
        return (
            //editable..          
            <BaseCreate history={this.props.history} caption={'Create '+ (this.props.caption || Model.caption)} 
                model={Model} dataRow={this.state.dataRow} dataRowMedia={this.state.dataRowMedia} getDataError={this.getDataError} disableAutoBack>              
          
                <Input name='name' label='Name' value={this.state.dataRow.name} error={this.state.dataError?.name} onChange={this.onFieldValueChange} />
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} error={this.state.dataError?.description} onChange={this.onFieldValueChange} />
                <Switch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' error={this.state.dataError?.status} onChange={this.onFieldValueChange}/>
                {AppService.isAppFullVersion() &&
                <Picklist name='owner' label='Owner' value={this.state.dataRow.owner?.toString()} caption={this.state.dataRow.ownerCaption} error={this.state.dataError?.owner} onChange={this.onFieldValueChange} >
                    <OwnerPicklist currentId={this.state.dataRow.owner?.toString()} getDataRow={this.getDataRowOwner}/>
                </Picklist> 
                }            
                <Picklist name='personalinfotype' label='Type' value={this.state.dataRow.personalinfotype?.toString()} caption={this.state.dataRow.personalinfotypeCaption} error={this.state.dataError?.personalinfotype} onChange={this.onFieldValueChange} >
                    <PersonalinfotypePicklist currentId={this.state.dataRow.personalinfotype?.toString()} type={this.props.type} getDataRow={this.getDataRowPersonalinfotype}/>
                </Picklist>           
                <Input name='phone' label='Phone' value={this.state.dataRow.phone} error={this.state.dataError?.phone} onChange={this.onFieldValueChange} />
                <InputEmail name='email' label='Email' value={this.state.dataRow.email} error={this.state.dataError?.email} onChange={this.onFieldValueChange} />
                <Picklist name='birthplace' label='Place of Birth' value={this.state.dataRow.birthplace?.toString()} caption={this.state.dataRow.birthplaceCaption} error={this.state.dataError?.birthplace} onChange={this.onFieldValueChange} >
                    <RegionPicklist caption='Select Place of Birth' currentId={this.state.dataRow.birthplace?.toString()} regionType='city' getDataRow={this.getDataRowBirthplace}/>
                </Picklist>  
                <InputDate name='birthdate' label='Date of Birth' value={this.state.dataRow.birthdate} error={this.state.dataError?.birthdate} onChange={this.onFieldValueChange} />
                <InputImage name='imageUrl' label='Photo' value={this.state.dataRow.imageUrl} getFiles={this.getFiles} error={this.state.dataError?.image} onChange={this.onFieldValueChange} />
                <Picklist name='user' label='User' value={this.state.dataRow.user?.toString()} caption={this.state.dataRow.userCaption} error={this.state.dataError?.user} clearValue={this.getDataRowUser} onChange={this.onFieldValueChange} >
                    <UserPicklistPersonalinfo currentId={this.state.dataRow.user?.toString()} getDataRow={this.getDataRowUser}/>
                </Picklist>     
                    
            </BaseCreate>
        );
    }     
     
}