import React from 'react';
import {Route } from 'react-router-dom';
import { Box } from '@mui/material';
import Home from './home.component';
import AppThemes from '../applayout-themes';

interface IProps { 
    getThemes: (data: any) => void;
}

interface IState {
}

export default class Index extends React.Component<IProps, IState> {

    constructor(props: IProps) {
      super(props);
      this.state = {
      }
      this.props.getThemes(AppThemes(''))
    }

    render() {
      return (
        //editable..
        <Box overflow='hidden'>
          <Route path='/' component={Home} exact/>  
        </Box>
      )
    }
}