import * as React from 'react';
import { History } from 'history';
import Model from './menu-model';
import BasePicklistcard from '../base/base-picklistcard.component';

interface IProps {
  history: History;
  caption?: string;
  currentId?: string;
  cardNumber?: number;
  root?: string;
}

interface IState {
}
export default class MenuPicklistcard extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  private getDataRowMenu = (data: any) => {
      this.props.history.push(((this.props.root||''))+data.path)
  };


  public render(): React.ReactNode {
    return (
      <BasePicklistcard caption={this.props.caption || 'Select '+Model.caption} endPointFilter={Model.endPointFilter2()} model={Model} currentId={this.props.currentId} getDataRow={this.getDataRowMenu} cardNumber={this.props.cardNumber}/>
    );
  }
}
