import React from 'react';
import { History } from 'history';
import { Button, Input } from '../../components';
import Model from './user-model';  //editable..
import BaseView from '../base/base-view.component';

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string },
    }
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class UserAuthEmailConfirmation extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        this.onFieldValueChange = this.onFieldValueChange.bind(this);
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onFieldValueChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }
    
    render() {
        return (
            <BaseView history={this.props.history} match={this.props.match} caption={Model.caption+' Email Confirmation'} 
                endPoint={Model.endPointAuth+'/email-confirmation'} endPointFilter='?confirmation=' model={Model} getDataRow={this.getDataRow} getDataError={this.getDataError} disableBackButton>              
                 <Input name='confirmation' label='Confirmation Code' value={this.props.match.params.id} error={this.state.dataError?.confirmation} readOnly/>
                 <Button label='Back' variant='contained' onClick={()=>this.props.history.push('/')}/>
            </BaseView>
        )
    }     
     
}