import * as React from 'react';
import { Button, Box, Card as CardMui, CardMedia, Grid, IconButton, Typography} from '@mui/material';
import IconImageUpload from '@mui/icons-material/AddPhotoAlternate';
import IconImageRemove from '@mui/icons-material/DeleteForever';
import IconNoImage from '@mui/icons-material/HideImage';

interface Props {
  name: string;
  label: string;
  value: string;
  readOnly?: boolean;
  getFiles?: (data: any) => void;
  maxHeight?: number;
  circle?: boolean;
  onChange?: (fieldName: string, value: string) => void;
  error?: string;
  children?: React.ReactNode;
}

export const InputImage: React.FunctionComponent<Props> = (props) => {
  const [value, setValue] = React.useState<any>('');
  const ref = React.useRef<any>();

  const onChange = (props: Props) => (e: React.ChangeEvent<HTMLInputElement>) => { 
    if (e.target.files?.length===1) {
      setValue(URL.createObjectURL(e.target.files[0]) );
      props.onChange?.(props.name, URL.createObjectURL(e.target.files[0]) );
      props.getFiles?.(e.target.files[0]);
    }
  };

  const onDelete = () => {
    setValue(null);
    ref.current.value = null;
    props.getFiles?.(null);
  }

  React.useEffect(() => {
    setValue(props.value);

  },[props.value])

  return (
    <Grid mt={1}>   
      {props.label?  
      <Typography variant='caption' color='text.secondary' display='block' gutterBottom>
        {props.label} 
      </Typography>
      :''}
      <Grid container>
      <Button component='label' disabled={props.readOnly}>
        <input hidden accept='image/*' type='file' ref={ref} onChange={onChange(props)}/>
        <CardMui variant='outlined' sx={{ minHeight: props.maxHeight || 150, maxHeight: props.maxHeight || 280, minWidth: props.maxHeight || 150, maxWidth: props.maxHeight || 280, backgroundColor: 'whitesmoke', borderRadius: props.circle? '50%':''}}>
          <CardMedia
            component='img'
            image={value}
          >
            
          </CardMedia>
            {value? ''
              :<>
              {props.readOnly?
                <IconNoImage className='text-secondary' style={{fontSize: '40px'}}/>
                :<IconImageUpload className='text-secondary' style={{fontSize: '40px'}}/> 
                }
              </>
            }
            
  
        </CardMui>    
      </Button>    
      {value && (!props.readOnly) ? 
        <Box sx={{ position: 'absolute', backgroundColor:'red', borderRadius:'50%'}} >
          <IconButton onClick={onDelete}><IconImageRemove className='text-white' /></IconButton>
        </Box>
      :''}
      </Grid>  
    </Grid>    
  )
};


