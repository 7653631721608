import React from 'react';
import { History } from 'history';
import { Input, InputDate, InputMultiline, InputNumber, InputSelect, Layout, InputImage, Picklist } from '../../components';
import BaseDelete from '../base/base-delete.component'; 
import Model from './evaluation-model';  //editable..
import TestingtoolChecklist from '../testingtool/testingtool-checklist.component';
import UserChecklist from '../user/user-checklist.component';
import EvaluationList from './evaluation-list.component';
import EvaluationdetailList from '../evaluationdetail/evaluationdetail-list.component';
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string },
    };
    root?: string;
}

interface IState {
    dataRow: Model;
    dataError: any;
    hasParent: boolean;
}

export default class EvaluationDelete extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            hasParent: false,
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data, hasParent: data.parent? false:true});
    }

    private getDataRowEvaluationTotal = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'totalvalue' : data?.total || 0, 'totalvalueadj' : data?.totaladj || 0 } });
    };

    render() {
        
        return (
            //editable..
            <BaseDelete history={this.props.history} match={this.props.match} caption={'Delete '+ Model.caption} 
                model={Model} getDataRow={this.getDataRow} disableCard>                  
                <Layout content={[
                    {caption: 'Details', child: 
                    <>
                        <Input name='name' label='Name' value={this.state.dataRow.name} readOnly/>
                        <InputMultiline name='description' label='Description' value={this.state.dataRow.description} readOnly/>
                        <InputSelect name='status' label='Status' value={this.state.dataRow.status} options={Model.optionStatus} readOnly />
                        {AppService.isAppFullVersion() &&
                        <Picklist name='owner' label='Owner' value={this.state.dataRow.owner?.toString()} caption={this.state.dataRow.ownerCaption} readOnly/>
                        }
                        <Picklist name='parent' label='Parent' value={this.state.dataRow.parent?.toString()} caption={this.state.dataRow.parentCaption} readOnly/>
                        <Picklist name='transaction' label='Transaction' value={this.state.dataRow.transaction?.toString()} caption={this.state.dataRow.transactionCaption} readOnly/>
                        <Picklist name='testingtool' label='Testing Tool' value='' caption={this.state.dataRow.testingtoolCaption} readOnly={!this.state.hasParent || this.state.dataRow.testingtool} disableAutoClose>
                            <TestingtoolChecklist currentId={this.state.dataRow.testingtool} readOnly disableBackButton/>
                        </Picklist> 
                        <Picklist name='user' label='Participant' value='' caption={this.state.dataRow.userCaption} readOnly={!this.state.hasParent || this.state.dataRow.user} disableAutoClose>
                            <UserChecklist currentId={this.state.dataRow.user} readOnly disableBackButton/>
                        </Picklist> 
                        <InputDate name='startdate' label='Start Date' value={this.state.dataRow.startdate} readOnly/>
                        <InputDate name='enddate' label='End Date' value={this.state.dataRow.enddate} readOnly/>
                        <InputNumber name='totalvalue' label='Total Value' value={this.state.dataRow.totalvalue} readOnly/>
                        <InputNumber name='totalvalueadj' label='Value Adjustment' value={this.state.dataRow.totalvalueadj} readOnly/>
                        <InputImage name='imageUrl' label='Image' value={this.state.dataRow.imageUrl} readOnly/>                  
                    </>
                    },

                    {caption: this.state.dataRow.evaluationLength>0? 'Sheets' : 'Questions', child: 
                    this.state.dataRow.id &&
                    <>
                        {this.state.dataRow.evaluationLength>0?
                            <EvaluationList history={this.props.history} root={this.props.root} caption='Evaluation Sheet' parentId={this.state.dataRow.id?.toString()} getDataRow={this.getDataRowEvaluationTotal} actions={['view']} disableBackButton disableCard/>
                            :<EvaluationdetailList history={this.props.history} root={this.props.root} caption='Evaluation Question' evaluationId={this.state.dataRow.id?.toString()} getDataRow={this.getDataRowEvaluationTotal} actions={['view']} disableBackButton disableCard/>
                        }
                        <InputNumber name='totalvalue' label='Total Value' value={this.state.dataRow.totalvalue} readOnly/>
                        <InputNumber name='totalvalueadj' label='Value Adjustment' value={this.state.dataRow.totalvalueadj} readOnly/>
                    </>
                    },
                    
                ]}
                />
            </BaseDelete>
        )
    }
}