import React from 'react';
import { History } from 'history';
import { Box, Chip, Grid, Typography } from '@mui/material';
import IconTime from '@mui/icons-material/Today';
import IconTimeEnd from '@mui/icons-material/EventAvailable';
import { Card, ParallaxScroll, ProgressBar, ScreenGuard, TextToHtml, Webcam } from '../../components';
import Model from './evaluation-model';  //editable..
import UserModel from '../user/user-model';
import testingtoolModel from '../testingtool/testingtool-model';  
import MedialibraryModel from '../medialibrary/medialibrary-model';
import BaseView from '../base/base-view.component';
import BaseEdit from '../base/base-edit.component';
import QuestionListUser from '../question/question-list-user.component';
import TestingtoolListUser from '../testingtool/testingtool-list-user.component';
import AppService from '../../services/app.service';
import EvaluationViewUser from './evaluation-view-user.component';

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string },
    };
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowParent: Model;
    dataRowEdit: Model;
    dataRowTestingtool: testingtoolModel;
    dataRowMedia: any;
    totalQuestion: number;
    valueQuestion: number;
    timeLeft: number;
    stopCapture: boolean;
    saveCapture: boolean;
    isOnline: boolean;
    allowCamera: boolean;
    offlineDuration: number;
}



export default class EvaluationSheet extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            dataRowParent: Model.defaultValue(),
            dataRowEdit: Model.defaultValue(),
            dataRowTestingtool: testingtoolModel.defaultValue(),
            dataRowMedia: null,
            totalQuestion: 0,
            valueQuestion: 0,
            timeLeft: -1,
            stopCapture: false,
            saveCapture: false,
            isOnline: true,
            allowCamera: false,
            offlineDuration: 0,
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data, valueQuestion: data.evaluationdetail?.length});
    }

    private getDataRowParent = (data:any) => {
        this.setState({dataRowParent:  data});
    }

    private getDataRowEdit = (data:any) => {
        const endDate = localStorage.getItem('endDate');
        if (endDate) localStorage.removeItem('endDate');
        this.setState({dataRow: {...data, enddate : endDate || AppService.dateCurrent()}}); 
    }

    private getDataRowTestingtool = (data: any) => {
        this.setState({dataRowTestingtool:  data});
    };

    private getDataRowQuestion = (data:any) => {
        this.setState({totalQuestion:  data.total});
    }

    private getImage = (data: any) => {
        const filename = 'evaluation'+this.state.dataRow.id+'_'+new Date().getTime()+'.png';
        const file = new File( [data.uint8], filename, { lastModified: new Date().getTime(), type: data.mime,} )
        data.value && this.setState({dataRowMedia: {...this.state.dataRowMedia, endPoint: MedialibraryModel.endPoint, endPointDelete: MedialibraryModel.endPoint2, dataRow:{id:this.state.dataRow.imageId, files: file, ref: Model.endPointMedia, field:'image'}}, stopCapture: true, saveCapture: true,});
    }

    private getTimeRemaining(counter: number) {
        if (counter>0) {
            var endDate = new Date(this.state.dataRow.startdate);
            this.getNetworkStatus();
            endDate.setSeconds(endDate.getSeconds()+counter+this.state.offlineDuration);
            const timeLeft = Math.ceil((new Date(endDate).getTime() - new Date().getTime())/1000);
            this.state.isOnline && timeLeft>=0 && setTimeout(() => this.setState({timeLeft: timeLeft}), 1000);
        }
    }

    private getNetworkStatus() {
        setTimeout(() => {
            const offlineTime = localStorage.getItem('offlineTime');
            this.setState({isOnline: navigator.onLine})
            if (navigator.onLine) {
                if (offlineTime) {
                    this.setState({offlineDuration: this.state.offlineDuration + Math.ceil((new Date().getTime() - +offlineTime)/1000)})
                    localStorage.removeItem('offlineTime')
                }
            }
            else if (!offlineTime) localStorage.setItem('offlineTime',new Date().getTime().toString());
        }, 6000)
    }

    private getPermission = (data: any) => {
        this.setState({allowCamera: data})
    }

    private onSaveQuestion = (data:any) => {
        if (data) this.setState({valueQuestion: this.state.valueQuestion+1})
    }

    private onSave = (data: any) => {
        this.setState({saveCapture: false});
    }  


    private displayMsg(imgUrl: string, title: string, content: string, actionButton?: React.ReactNode) {
        return (
            <Card>
                <Grid container direction='row' justifyContent='center'>
                    <img src={imgUrl} width={350} height='auto' alt='img' />                    
                    <Typography component='div' sx={{typography: {xs: 'subtitle1', md: 'h6'},  padding: {xs:1, md: 5}, textAlign: {xs: 'center', md: 'right'}}} >
                        <b>{title}</b><br/><br/>
                        <TextToHtml content={content}/>                   
                        <br/><br/>
                        <Grid container justifyContent={{xs: 'center', md: 'right'}}>
                        {actionButton}
                        </Grid>
                    </Typography>
                </Grid>
            </Card>
        )
    }

    private displayMsgWelcome() {
        return this.displayMsg(
            'https://img.freepik.com/free-vector/welcome-word-flat-cartoon-people-characters_81522-4207.jpg?w=900&t=st=1692630043~exp=1692630643~hmac=ad41d758004893abfa6c9b2e3dba033eb50c1fe67f8ddd45194a80e2483fddb2',
            'Hai '+UserModel.getUserCaption()+',',
            '<b>Selamat datang di '+this.state.dataRow.name+'!</b> <br><br> Selanjutnya mohon untuk mengaktifkan kamera, <br> tetap tenang dan semoga berhasil!',
            <EvaluationViewUser history={this.props.history} parentId={this.state.dataRow.id?.toString()||''} />
        )
    }

    private displayMsgTimeout() {
        return this.displayMsg(
            'https://img.freepik.com/free-vector/deadline-concept-illustration_114360-6003.jpg?w=740&t=st=1688314925~exp=1688315525~hmac=6eec512377def350aa53daca201cd61eb2c0317a3895ac2d9e82acf980ada081',
            'Sesi Pengujian Telah Berakhir!',
            'Waktu pengujian telah habis, <br> silahkan lanjut ke sesi berikutnya.',
            <BaseEdit history={this.props.history} match={this.props.match} caption=' ' buttonCaption='Lanjut'
                model={Model} dataRow={this.state.dataRow} getDataRow={this.getDataRowEdit} disableBackButton disableCard>  
            </BaseEdit>
        )
    }

    private displayMsgExtraTime() {
        return this.displayMsg(
            'https://img.freepik.com/free-vector/flat-design-time-management-concept_23-2148813012.jpg?w=740&t=st=1689087361~exp=1689087961~hmac=d4654cae3c1372bc22e14f3a273fdc0aa4f5f903fa31d58709acc09cdbc17bc6',                    
            'Maaf, Sesi Pengujian Telah Berakhir!',
            'Jika pengujian belum selesai </br> karena adanya kendala teknis, <br> silahkan hubungi admin untuk <br> melakukan perpanjangan.',
            )
    }

    private displayMsgOffline() {
        return this.displayMsg(
            'https://img.freepik.com/free-vector/no-connection-concept-illustration_114360-5947.jpg?w=740&t=st=1689572410~exp=1689573010~hmac=4f33bda2337d572799a3c01198e9442cb8ce62bdfea24feee70d2546bade51ba',           
            'Koneksi Tidak Terhubung!',
            'Sepertinya koneksi internetmu tidak tersedia, </br> silahkan cek kembali.',
            )
    }

    private displayMsgPermission() {
        return this.displayMsg(
            'https://img.freepik.com/free-vector/huge-camera-tiny-people-taking-pictures-photographer-with-camera-photos-landscapes-flat-vector-illustration-photography-occupation-concept-banner-website-design-landing-web-page_74855-23089.jpg?w=996&t=st=1694412220~exp=1694412820~hmac=7199948b54c0be434071ee461c9b569585c6ef3dda8d54235790c95582db7317',
            'Kamera Tidak Aktif!',
            'Mohon untuk mengaktifkan kamera melalui <br> <i>Permission setting</i> di browser',
        )
    }

    private displayMsgNextTest() {
        if ((this.state.totalQuestion>0) && (this.state.valueQuestion===this.state.totalQuestion))
        return this.displayMsg(
            'https://img.freepik.com/free-vector/successful-businessman-celebrating-victory_1150-39772.jpg?w=1060&t=st=1689088401~exp=1689089001~hmac=4d601f80e8497c25373b8e955d8201a1448c5334c657050cc8cb4d0d57ace033',
            'Yeay! Kamu berhasil menyelesaikan sesi ini!',
            'Silahkan periksa kembali jawabanmu, </br> jika sudah silahkan lanjut ke sesi berikutnya',
            <BaseEdit history={this.props.history} match={this.props.match} caption=' ' buttonCaption='Lanjut'
                model={Model} dataRow={this.state.dataRow} getDataRow={this.getDataRowEdit} disableBackButton disableCard>  
            </BaseEdit>
            )
    }

    private displayLists() {
        if (this.state.dataRow.id)
        return (
            this.state.dataRow.parent?
                !this.state.allowCamera? 
                    this.displayMsgPermission()
                : 
                    (this.state.dataRowTestingtool.testingtoolLength===0) && this.state.dataRowTestingtool.id?
                        <>
                        {this.displayCamera()}
                        <Card color='secondary.light'>
                            <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}} mb={3}>
                                {this.state.dataRowTestingtool.title}
                            </Typography>
                            <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mb={3} style={{whiteSpace: 'pre-line'}}>
                                {this.state.dataRowTestingtool.content}
                            </Typography>
                        </Card>
                        <QuestionListUser history={this.props.history} match={this.props.match} testingtoolId={this.state.dataRowTestingtool.id.toString()} viewStyle={this.state.dataRowTestingtool.viewstyle} getDataRow={this.getDataRowQuestion} onSave={this.onSaveQuestion} />
                        </>
                    :
                        this.state.dataRow.testingtoolId?
                        <TestingtoolListUser history={this.props.history} parentId={this.state.dataRow.testingtoolId} evaluationId={this.state.dataRow.id.toString()} viewStyle={this.state.dataRowTestingtool.viewstyle}/>
                        : <TestingtoolListUser history={this.props.history} evaluationParentId={this.state.dataRow.parent.toString()} evaluationId={this.state.dataRow.id.toString()} viewStyle={this.state.dataRowTestingtool.viewstyle}/>
            
            : this.displayMsgWelcome()
        )
    }

    private displayFinished() {
        if (this.state.dataRow.enddate) 
            return (AppService.dateFormat(this.state.dataRow.enddate || '', 'd mon y t') )
        else {
            if (this.state.dataRowTestingtool.timelimit>0) {
                this.getTimeRemaining(this.state.dataRowTestingtool.timelimit);
                if (this.state.timeLeft===0) localStorage.setItem('endDate', AppService.dateCurrent());
            }
            return ('[On going]');
        }
    }

    private displayCamera() {
        if (this.state.dataRow.imageId) return;
        if (!this.state.stopCapture) 
            return (
                <Webcam hideCam shotTimeout={5000} stop={this.state.stopCapture} width={200} height={200} getImage={this.getImage}/>  
            )
        
        else if (this.state.saveCapture) 
            return (
                <BaseEdit history={this.props.history} match={this.props.match} caption=' ' buttonCaption=' ' save={this.state.saveCapture}
                    model={Model} dataRow={this.state.dataRow} dataRowMedia={this.state.dataRowMedia} getDataRow={this.getDataRow} onSave={this.onSave} disableAutoBack disableBackButton>              
                </BaseEdit>
            )
    }

    render() {
        const isQuestions = this.state.dataRow.parent && (this.state.dataRowTestingtool.testingtoolLength===0) && this.state.dataRowTestingtool.id;
        
        return (
            //editable..
            <ParallaxScroll backgroundElement={
                <BaseView history={this.props.history} match={this.props.match} caption={(isQuestions? ' ' : this.state.dataRow.name) || 'Invalid Evaluation'} captionColor='primary.light'
                    model={Model} getDataRow={this.getDataRow}
                    disableBackButton = {isQuestions} disableCard>   
                    {this.state.dataRow.testingtool?.[0] &&
                        <BaseView match={{isExact:true, params: {id: this.state.dataRow.testingtool[0].id.toString()}}} caption=' '
                            model={testingtoolModel} getDataRow={this.getDataRowTestingtool} disableBackButton>
                        </BaseView>
                    }
                    {this.state.dataRow.parent &&
                        <BaseView match={{isExact:true, params: {id: this.state.dataRow.parent}}}  caption=' ' model={Model} getDataRow={this.getDataRowParent} disableBackButton>
                        </BaseView>
                    }
                </BaseView>
            }
            >
                <ScreenGuard />
                <Webcam hideCam stop={true} getPermission={this.getPermission}/>
                <Card color='secondary.dark' gradientColor='white'>
                    {this.displayMsgNextTest()}
                    
                    <Grid container alignItems='center' justifyContent='space-around' mt={3} mb={5}>
                        <Grid container direction='column'>
                            <Typography sx={{typography: {xs: 'h5', md: 'h4'}}} ml={2} mb={3}>
                                <b>{UserModel.getUserCaption()}</b>
                            </Typography>
                            <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} ml={5}>
                                <IconTime/> <b>Start</b> {AppService.dateFormat(this.state.dataRow.startdate ||'', 'd mon y t')}
                            </Typography>
                            <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} ml={5}>
                                <IconTimeEnd/> <b>Finish</b> {this.displayFinished()} :{!this.state.dataRow.testingtoolId && this.state.dataRow.parent && 'welcome'}
                            </Typography>                                
                            <Typography component='div' sx={{typography: {xs: 'caption', md: 'body2'}}} ml={5}>
                                {this.state.dataRow.status==='extratime' && <Chip label='Perpanjangan Waktu' color='info'/> }
                            </Typography>
                        </Grid>
                        {this.state.totalQuestion===0?'':
                            <Box position='fixed' sx={{right: {xs: '5%', md:'18%'}, top:{xs: '15%', md:'8%'}}}>
                                <ProgressBar value={this.state.valueQuestion} totalValue={this.state.totalQuestion} />
                            </Box>
                        }
                        {this.state.dataRowTestingtool.timelimit>0 && this.state.timeLeft>0 && this.state.timeLeft<60?
                            <Box position='fixed' sx={{right: {xs: '5%', md:'18%'}, top: {xs: '27%', md:'22%'}}}>
                                <ProgressBar value={this.state.timeLeft} totalValue={60} showValue/>
                            </Box>
                            :''
                        }
                    </Grid>
                    <Grid container spacing={1}>
                        {this.state.isOnline?
                            <>
                            {((this.state.dataRowParent.enddate < AppService.dateCurrent()) )?  
                                <> {this.state.dataRow.status!=='extratime'? this.displayMsgExtraTime() : this.displayLists()} </> 
                                : <> {(this.state.dataRowTestingtool.timelimit>0 && localStorage.getItem('endDate'))? this.displayMsgTimeout() : this.displayLists()}</>
                            }
                            </>
                        : this.displayMsgOffline()
                        }
                    </Grid>
                </Card>
            </ParallaxScroll>
        );
    }
}