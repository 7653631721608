import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import JournalgroupList from './journalgroup-list.component';
import JournalgroupCreate from './journalgroup-create.component';
import JournalgroupView from './journalgroup-view.component';
import JournalgroupEdit from './journalgroup-edit.component';
import JournalgroupCopy from './journalgroup-copy.component';
import JournalgroupDelete from './journalgroup-delete.component';

interface Props {
  root?: string;
}

export const JournalgroupRoute: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>
      <Route path={(props.root||'')+'/journalgroup/create'} render={(params)=> <JournalgroupCreate {...params} root={props.root} />} />        
      <Route path={`${props.root||''}/journalgroup/view/:id`} render={(params)=> <JournalgroupView {...params} root={props.root} />} />        
      <Route path={`${props.root||''}/journalgroup/edit/:id`} render={(params)=> <JournalgroupEdit {...params} root={props.root} />} />        
      <Route path={`${props.root||''}/journalgroup/copy/:id`} render={(params)=> <JournalgroupCopy {...params} root={props.root} />} />        
      <Route path={`${props.root||''}/journalgroup/delete/:id`} render={(params)=> <JournalgroupDelete {...params} root={props.root} />} />        
      <Route path={(props.root||'')+'/journalgroup'} render={(params)=> <JournalgroupList {...params} root={props.root} />} /> 
    </Switch>
  );
};
