import * as React from 'react';
import { Box, Card as CardMui, Grid } from '@mui/material';
import { Circle } from '.';

interface Props {
  id?: string;
  caption?: string;
  color?: any;
  gradientColor?: any;
  borderColor?: any;
  borderWidth?: number;
  children?: React.ReactNode;
  
}

export const CardTicket: React.FunctionComponent<Props> = (props) => {

  let height = 400;
  let width = 800;
  let circleSize = 80;
  let circleSmallSize = 30;
  let circleSmallSizeHalf = circleSmallSize/2
  let circleSmallLeft = width/3;
  let borderWidth = props.borderWidth || 3;
  let borderColor = props.borderColor || 'silver';
  let color = props.color || 'white';
  let background = props.gradientColor? 'linear-gradient(90deg, '+props.gradientColor+', transparent)' : '';

  return (
    <Grid id={props.id} container alignItems='center' xs={12}>     
      <Circle size={circleSmallSize} color={color} borderColor={borderColor} borderWidth={borderWidth} position='bottom' left={circleSmallLeft} top={-193}/>
      <Box position='relative' color='silver' sx={{height: height-circleSmallSize*2, left: circleSmallLeft-(circleSmallSizeHalf), border: 2, borderStyle: 'dashed'  }} bgcolor='transparent'></Box>
      <Circle size={circleSmallSize} color={color} borderColor={borderColor} borderWidth={borderWidth} position='top' left={circleSmallLeft-circleSmallSize} top={192}/>
      <Circle size={circleSize} color={color} borderColor={borderColor} borderWidth={borderWidth} position='right' left={circleSize-1}/>
      <Circle size={circleSize} color={color} borderColor={borderColor} borderWidth={borderWidth} position='left' left={width}/>
      
      <CardMui variant='outlined' sx={{height: height, width: width, background: background, backgroundColor: color, borderWidth: borderWidth, borderColor: borderColor, borderRadius: 5, paddingLeft: 20, paddingTop: 5}}>
      {props.children }
      </CardMui>      

    </Grid>    
  )
};


