import React from 'react';
import { History } from 'history';
import { InputNumber, InputMoney, Picklist } from '../../components';
import BaseEdit from '../base/base-edit.component'; 
import Model from './transactiondetail-model';  //editable..
import ProductPicklist from '../product/product-picklist.component';

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string},
    };
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class TransactiondetailEdit extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        
        this.onFieldValueChange = this.onFieldValueChange.bind(this);
    }
    
    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    private getDataRowProduct = (data: any) => {
        const price = this.state.dataRow.transactiontypePricetype==='pricesale'? data?.pricesale : data?.pricebase + '';
        this.onFieldValueChange('price', price);
        this.setState({ dataRow: { ...this.state.dataRow, 'product' : data?.id || null, 'productCaption' : data?.name || '', 
            'price' : price, 'value' : price * this.state.dataRow.qty }  
        });
    };
    
    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onFieldValueChange(fieldName: string, value: string) { 
        
        var  valueField = this.state.dataRow.value;
        if (fieldName==='price') { valueField= this.state.dataRow.qty * +value }
        else if (fieldName==='qty') { valueField= this.state.dataRow.price * +value }

        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value, value: valueField,
            }
        };
        this.setState(nextState);
    }

    render() {
        return (
            //editable..
            <BaseEdit history={this.props.history} match={this.props.match} caption={'Edit '+ Model.caption} 
                endPointFilter={Model.endPointFilter2} model={Model} dataRow={this.state.dataRow} getDataRow={this.getDataRow} getDataError={this.getDataError}>              
                
                <Picklist name='product' label='Product' value={this.state.dataRow.product?.toString()} caption={this.state.dataRow.productCaption} error={this.state.dataError?.product} onChange={this.onFieldValueChange} >
                    <ProductPicklist currentId={this.state.dataRow.product?.toString()} getDataRow={this.getDataRowProduct}/>
                </Picklist>                 
                <InputMoney name='price' label='Price' value={this.state.dataRow.price} error={this.state.dataError?.price} onChange={this.onFieldValueChange} />
                <InputNumber name='qty' label='Qty' value={this.state.dataRow.qty} error={this.state.dataError?.qty} onChange={this.onFieldValueChange} />
                <InputMoney name='value' label='Total' value={this.state.dataRow.value} error={this.state.dataError?.value} onChange={this.onFieldValueChange} />
                        
            </BaseEdit>
        );
    }
}