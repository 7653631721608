import * as React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";

import 'bootstrap/dist/css/bootstrap.min.css'; 
import 'toastr/build/toastr.min.css'; 
import './App.css'

import BaseView from './pages/base/base-view.component'; 
import AppModel from './pages/app/app-model'; 
import OwnerModel from './pages/owner/owner-model';  
import AppLayout from './pages/applayout/applayout.component';
import AppService from './services/app.service';
import ApiService from './services/api.service';

function App() {
  
  const [appData, setAppData] = React.useState(null);
  const [themes, setThemes] = React.useState(createTheme({}));
  
  const appInit = () => {

    const getDataRowApp = (data: any) => {
      setAppData(data);
      OwnerModel.setOwner(data?.owner, data?.ownerCaption);
      document.title = data?.name;  
      const favicon = document.querySelector("link[rel~='icon']") as HTMLAnchorElement; 
      if (favicon) favicon.href = data?.imageUrl;
    }
    
    return (
      <BaseView  history = {null} match={ {'isExact': true, 'params': { 'id':AppService.appId() }}} caption=' '
        model={AppModel} getDataRow={getDataRowApp}>    
      </BaseView>
    )
  }

  const getThemes = (data: any) => {
    setThemes(data)
  }
  
  return (
    <>
    {appInit()}
    {(appData?.['id'] || !ApiService.apiUrl) &&
    
      <ThemeProvider theme={themes}> 
        <CssBaseline />
        <BrowserRouter>
          <Switch>
          <AppLayout getThemes={getThemes}/>
          </Switch>
        </BrowserRouter>
      </ThemeProvider>

    }
    </> 
  );
}

export default App;
