import React from 'react';
import AppService from '../../services/app.service';

interface IProps {
}

interface IState {
}

export default class BaseValidate extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        
    }
    
    public static validate (dataRow: any, validations: any) {
        
        var dataError : any;
        validations?.map((validation: any)=>{
            const validationName = validation.name;
            const validationNameCapitalize  = AppService.strCapitalize(validation.nameAlias || validationName);

            if (!dataError?.[validationName]) {
                if (validation.required && ['', null].includes(dataRow[validationName]))
                 {
                    if (!dataError) dataError = {};
                    dataError[validationName] =  validationNameCapitalize+ ' cannot be blank'
                }
                else if (validation.minLength && dataRow[validationName]?.length < validation.minLength) {
                    if (!dataError) dataError = {};
                    dataError[validationName] = validationNameCapitalize+ ' must be at least '+validation.minLength+' characters';
                }
            }
        })

        return dataError;
    } 
     
    render() {
        return <></>;
    }     
     
}