import React from 'react';
import { History } from 'history';
import { Input, InputMultiline, Switch, Picklist } from '../../components';
import BaseView from '../base/base-view.component'; 
import Model from './menugroup-model';  //editable..
import MenuChecklist from '../menu/menu-checklist.component';
import OwnerChecklist from '../owner/owner-checklist.component';
import UserChecklist from '../user/user-checklist.component';
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string },
    }
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class MenugroupDelete extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    render() {
        return (
            //editable..
            <BaseView history={this.props.history} match={this.props.match} caption={'View '+ Model.caption} 
                model={Model} getDataRow={this.getDataRow}>              
                
                <Input name='name' label='Name' value={this.state.dataRow.name} readOnly/>
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} readOnly/>
                <Switch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' readOnly/>
                {AppService.isAppFullVersion() &&
                <Picklist name='owner' label='Owner' value={this.state.dataRow.owner?.toString()} caption={this.state.dataRow.ownerCaption} readOnly/>
                }
                <Picklist name='menu' label='Menu Access List' value='' caption={this.state.dataRow.menuCaption} readOnly={this.state.dataRow.menu? false: true} disableAutoClose>
                    <MenuChecklist history={this.props.history} currentId={this.state.dataRow.menu} readOnly disableBackButton/>
                </Picklist> 
                {AppService.isAppFullVersion() &&
                <Picklist name='accessowner' label='Owner Access List' value='' caption={this.state.dataRow.accessownerCaption} readOnly={this.state.dataRow.accessowner? false: true} disableAutoClose>
                    <OwnerChecklist history={this.props.history} currentId={this.state.dataRow.accessowner} readOnly disableBackButton/>
                </Picklist> 
                }
                <Picklist name='user' label='User Access List' value='' caption={this.state.dataRow.userCaption} readOnly={this.state.dataRow.user? false: true} disableAutoClose>
                    <UserChecklist history={this.props.history} currentId={this.state.dataRow.user} readOnly disableBackButton/>
                </Picklist>
            </BaseView>
        );
    }
}