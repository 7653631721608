export default class TransactionamountModel {
    id?: number;
    transaction: any;
    transactionCaption: string;
    amounttype: any;
    amounttypeCaption: string;
    value: number;
    
    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.transaction =  dataRow.attributes.transaction?.data?.id || null;
        this.transactionCaption =  dataRow.attributes.transaction?.data?.attributes.name || dataRow.attributes.transaction?.data?.id; 
        this.amounttype =  dataRow.attributes.amounttype?.data?.id || null;
        this.amounttypeCaption =  dataRow.attributes.amounttype?.data?.attributes.name || dataRow.attributes.amounttype?.data?.id; 
        this.value = dataRow.attributes.value;
    } 

    public static endPoint: string = 'transaction-amounts';
    public static endPointFilter: string = '?populate=*&filters[transaction]=';
    public static url: string = '/transactiondetailamount';

    public static caption: string = 'Additional Values';

    public static defaultValue = ()=> {
        return {
        id: 0,
        transaction: null,
        transactionCaption: '',
        amounttype: null,
        amounttypeCaption: '',
        value: 0,

  
        }
    }
    
    public static columns = [
        { name: 'amounttypeCaption', caption: '' },
        { name: 'value', caption: '', type: 'money' },
              
    ]

    public static columnPicklist = [
        { name: 'amounttypeCaption', caption: 'Type' },
        { name: 'value', caption: 'Amount', type: 'money' },
    ]
    
}