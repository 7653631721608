import OwnerModel from '../owner/owner-model';

export default class CoaModel {
    id?: number;
    name: string;
    description: string;
    status: string;
    owner: any;
    ownerCaption: string;
    parent: any;
    parentCaption: string;
    coagroup: any;
    coagroupCaption: string;
    coabalancevalue: number;

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.owner =  dataRow.attributes.owner?.data?.id || null;
        this.ownerCaption =  dataRow.attributes.owner?.data?.attributes.name || dataRow.attributes.owner?.data?.id; 
        this.parent =  dataRow.attributes.parent?.data?.id || null;
        this.parentCaption =  dataRow.attributes.parent?.data?.attributes.name || dataRow.attributes.parent?.data?.id; 
        this.coagroup =  dataRow.attributes.coagroup?.data?.id || null;
        this.coagroupCaption =  dataRow.attributes.coagroup?.data?.attributes.name || dataRow.attributes.coagroup?.data?.id; 
        this.coabalancevalue =  dataRow.attributes.coabalance?.data[dataRow.attributes.coabalance?.data?.length-1]?.attributes.value || 0;
        
    } 

    public static endPoint: string = 'coas';
    public static endPointFilter = ()=>{
        const owner = OwnerModel.getOwner();
        return ( '?populate=*'+(owner==='null' || owner===null? '' : '&filters[owner]='+owner))
    } 
    public static url: string = '/coa';
    public static caption: string = 'Chart of Account';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active',
        owner: OwnerModel.getOwner(),
        ownerCaption: OwnerModel.getOwnerCaption(),         
        parent: null,
        parentCaption: '',
        coagroup: null,
        coagroupCaption: '',
        coabalancevalue: 0,
  
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'description', caption: 'Description' },
        { name: 'coabalancevalue', caption: 'Last Balance', type: 'money' },
        { name: 'status', caption: 'State', type: 'state', default: 'active' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'description', caption: 'Description' },
    ]
    
}