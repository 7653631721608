import ApiService from "../../services/api.service";

export default class QuestionModel {
    id?: number;
    name: string;
    description: string;
    status: string;
    testingtool: any;
    testingtoolCaption: any;
    type: string;
    title: string;
    content: string;
    image: any;
    imageId: any;
    imageUrl: string;
    seq: number;
    level: string;
    timelimit: number;
    questionoptionLength: number;

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.testingtool =  dataRow.attributes.testingtool.data?.id || null;
        this.testingtoolCaption =  '';//dataRow.attributes.testingtool.data?.attributes.name || dataRow.attributes.testingtool.data?.id;
        this.type = dataRow.attributes.type || 'boolean';
        this.title = dataRow.attributes.title;
        this.content = dataRow.attributes.content;
        this.image = dataRow.attributes.image?.data || null;
        this.imageId = dataRow.attributes.image?.data?.[0]?.id || null;
        this.imageUrl = dataRow.attributes.image?.data?.[0]? ApiService.svrUrl+dataRow.attributes.image.data[0].attributes.url : '';
        this.seq = dataRow.attributes.seq;
        this.level = dataRow.attributes.level;
        this.timelimit = dataRow.attributes.timelimit;
        this.questionoptionLength = dataRow.attributes.questionoption.data?.length || 0;
        
    } 

    public static endPoint: string = 'questions' 
    public static endPointMedia: string = 'api::question.question';
    public static endPointFilter: string = '?populate=*&filters[testingtool]=';
    public static url: string = '/question';
    public static caption: string = 'Question';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        testingtool: null,
        testingtoolCaption: '',
        status: 'active',
        type: 'boolean',  
        title: '',
        content: '',
        image: null,
        imageId: null,
        imageUrl: '',
        seq: 0,
        level: 'low',
        timelimit: 0,
        questionoptionLength: 0,
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'description', caption: 'Description' },
        { name: 'status', caption: 'State', type: 'state', default: 'active' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'title', caption: 'Title' },
    ]
    
    public static optionType = [
        { key: 'boolean', value: 'True or False' },
        { key: 'optional', value: 'Optional' },
        { key: 'multiple', value: 'Multiple' },
        { key: 'multiplematch', value: 'Multiple Match' },
        { key: 'freetext', value: 'Free Text' },
        { key: 'letter', value: 'Letter Only' },
        { key: 'letternumber', value: 'Letter & Number' },
        { key: 'number', value: 'Number' },
        { key: 'decimal', value: 'Decimal' },
        { key: 'money', value: 'Money' },
        { key: 'rating', value: 'Rating' },
    ]

    public static optionLevel = [
        { key: 'low', value: 'low' },
        { key: 'medium', value: 'medium' },
        { key: 'high', value: 'High' },
    ]

}