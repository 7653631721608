import React from 'react';
import { History } from 'history';
import { Input, InputMultiline, InputDate, InputNumber, InputMoney, InputSelect, Picklist } from '../../components';
import BaseCreate from '../base/base-create.component'; 
import Model from './journal-model';  //editable..
import CoaPicklist from '../coa/coa-picklist.component';

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string},
    };
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class JournalCreate extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        this.onFieldValueChange = this.onFieldValueChange.bind(this);
    }
    
    private getDataRowTransaction = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'transaction' : data?.id || null, 'transactionCaption' : data?.name || ''  }  });
    };
    
    private getDataRowCoa = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'coa' : data?.id || null, 'coaCaption' : data?.name || ''  }  });
    };
    
    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onFieldValueChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    public componentDidMount(): void {        
        this.getDataRowTransaction({'id':this.props.match.params.id, 'name':this.props.match.params.id});
    }

    render() {
        return (
            //editable..          
            <BaseCreate history={this.props.history} caption={'Create '+ Model.caption} 
                model={Model} dataRow={this.state.dataRow} getDataError={this.getDataError}>               
                
                <Picklist name='transaction' label='Transaction Id' value={this.state.dataRow.transaction?.toString()} caption={this.state.dataRow.transactionCaption} readOnly/>
                <Input name='name' label='Name' value={this.state.dataRow.name} error={this.state.dataError?.name} onChange={this.onFieldValueChange} />
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} error={this.state.dataError?.description} onChange={this.onFieldValueChange} />
                <InputSelect name='status' label='status' value={this.state.dataRow.status} options={Model.optionStatus} error={this.state.dataError?.status} onChange={this.onFieldValueChange} />
                <InputDate name='journaldate' label='Date' value={this.state.dataRow.journaldate} error={this.state.dataError?.journaldate} onChange={this.onFieldValueChange} />
                <InputNumber name='seq' label='Sequence' value={this.state.dataRow.seq} error={this.state.dataError?.seq} onChange={this.onFieldValueChange} />
                <InputSelect name='sign' label='Debet/Credit' value={this.state.dataRow.sign} options={Model.optionSign} error={this.state.dataError?.sign} onChange={this.onFieldValueChange} />
                <InputMoney name='value' label='Value' value={this.state.dataRow.value} error={this.state.dataError?.value} onChange={this.onFieldValueChange} />
                <Picklist name='coa' label='COA' value={this.state.dataRow.coa?.toString()} caption={this.state.dataRow.coaCaption} error={this.state.dataError?.coa} onChange={this.onFieldValueChange} >
                    <CoaPicklist currentId={this.state.dataRow.coa?.toString()} getDataRow={this.getDataRowCoa}/>
                </Picklist>
            </BaseCreate>
        );
    }     
     
}