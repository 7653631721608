import React from 'react';
import { History } from 'history';
import { Input, InputMultiline, Switch, Picklist, Layout } from '../../components';
import BaseDelete from '../base/base-delete.component'; 
import Model from './journalgroup-model';  //editable..
import JournalgroupdetailList from '../journalgroupdetail/journalgroupdetail-list.component';
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string },
    };
    root?: string;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class JournalgroupDelete extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    render() {
        return (
            //editable..
            <BaseDelete history={this.props.history} match={this.props.match} caption={'Delete '+ Model.caption} 
                model={Model} getDataRow={this.getDataRow} disableCard>              
                            
                <Layout content={[
                    {caption: 'Details', child: 
                    <>
                        <Input name='name' label='Name' value={this.state.dataRow.name} readOnly/>
                        <InputMultiline name='description' label='Description' value={this.state.dataRow.description} readOnly/>
                        <Switch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' readOnly/>
                        {AppService.isAppFullVersion() &&
                        <Picklist name='owner' label='Owner' value={this.state.dataRow.owner?.toString()} caption={this.state.dataRow.ownerCaption} readOnly/>
                        }
                    </>
                    },

                    {caption: 'Jurnal Group Details', child:
                    this.state.dataRow.id && 
                        <JournalgroupdetailList history={this.props.history} root={this.props.root} journalgroupId={this.state.dataRow.id?.toString()} actions={['view']} disableBackButton  disableCard/> 
                    },
                    ]}
                    
                /> 
            </BaseDelete>
        );
    }
}