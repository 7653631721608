import React from 'react';
import { History } from 'history';
import { Grid } from '@mui/material';
import {Input, InputEmail, InputImage } from '../../components';
import BaseEdit from '../base/base-edit.component'; 
import Model from './user-model';  //editable..
import MedialibraryModel from '../medialibrary/medialibrary-model';

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string },
    };
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowMedia: any;
}

export default class UserAuthProfileEdit extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            dataRowMedia: null,
        }
        this.onFieldValueChange = this.onFieldValueChange.bind(this);
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    private getFiles = (data:any) => {
        this.setState({dataRowMedia: {...this.state.dataRowMedia, endPoint: MedialibraryModel.endPoint, endPointDelete: MedialibraryModel.endPoint2, dataRow:{id:this.state.dataRow.imageId, files: data, ref: Model.endPointMedia, field:'image'}}});
    }

    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onFieldValueChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }


    render() {
        return (
            //editable..
            <BaseEdit history={this.props.history} match={{isExact:true, params: {id: Model.getUser()}}} caption={Model.caption+' Profile'} 
                model={Model} dataRow={this.state.dataRow} dataRowMedia={this.state.dataRowMedia} getDataRow={this.getDataRow} getDataError={this.getDataError} disableAutoBack>              
                
                
                <Grid container direction='row' spacing={2}>
                    <Grid item xs={12} md={4} >
                    <InputImage name='imageUrl' label='Photo' value={this.state.dataRow.imageUrl} getFiles={this.getFiles} error={this.state.dataError?.image} onChange={this.onFieldValueChange} />
                    
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Input name='username' label='User Name' value={this.state.dataRow.username} error={this.state.dataError?.username} onChange={this.onFieldValueChange} />
                        <InputEmail name='email' label='Email' value={this.state.dataRow.email} readOnly/>
                    </Grid>
                </Grid>
            </BaseEdit>
        );
    }
}