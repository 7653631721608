import React from 'react';
import { History} from 'history';
import { Trapezoid, Logo, ParallaxScroll } from '../../../components';
import { EvaluationRouteSheet } from '../../evaluation/evaluation-route-sheet';
import AppService from '../../../services/app.service';

interface IProps { 
    history: History;
    root?: string;
}

interface IState {
}

export default class Evaluation extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
        }
    }

    render() {
        const isMobile = AppService.isMobile();

        return (
            //editable..
            <Trapezoid position='bottomLeft' color='secondary.light' colorOthers='secondary.main'>
                <ParallaxScroll height={isMobile? 10 : 20} 
                    backgroundElement={<Logo label={document.title} titleColor='green'/>}
                >
                    <EvaluationRouteSheet/>
                </ParallaxScroll>
            </Trapezoid>
        );
    }
}