import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import TestingtoolresumeList from './testingtoolresume-list.component';
import TestingtoolresumeCreate from './testingtoolresume-create.component';
import TestingtoolresumeView from './testingtoolresume-view.component';
import TestingtoolresumeEdit from './testingtoolresume-edit.component';
import TestingtoolresumeCopy from './testingtoolresume-copy.component';
import TestingtoolresumeDelete from './testingtoolresume-delete.component';

interface Props {
  root?: string;
}

export const TestingtoolresumeRoute: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>      
      <Route path={(props.root||'')+'/testingtoolresume/create'} component={TestingtoolresumeCreate} />
      <Route path={(props.root||'')+'/testingtoolresume/create/:id'} component={TestingtoolresumeCreate} />      
      <Route path={`${props.root||''}/testingtoolresume/view/:id`} render={(params)=> <TestingtoolresumeView {...params} root={props.root} key={params.match.params.id} />} />        
      <Route path={`${props.root||''}/testingtoolresume/edit/:id`} render={(params)=> <TestingtoolresumeEdit {...params} root={props.root} key={params.match.params.id} />} />        
      <Route path={`${props.root||''}/testingtoolresume/copy/:id`} component={TestingtoolresumeCopy} />
      <Route path={`${props.root||''}/testingtoolresume/delete/:id`} render={(params)=> <TestingtoolresumeDelete {...params} root={props.root} key={params.match.params.id} />} />        
      <Route path={(props.root||'')+'/testingtoolresume'} render={(params)=> <TestingtoolresumeList {...params} root={props.root} />} /> 
    </Switch>
  );
};
