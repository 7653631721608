import * as React from 'react';
import { Button, Grid, Typography} from '@mui/material';
import { Dialog, Icon } from '.';

interface Props {
  name: string;
  label: string;
  fileType?: string;
  outFormat?: 'json';
  getDataSet?: (data: any) => void;
  buttonView?: boolean;
  readOnly?: boolean;
  error?: string;
  children?: React.ReactNode;
}

export const InputUpload: React.FunctionComponent<Props> = (props) => {
  const [open, setOpen] = React.useState(false);
  
  const onClose = () => {setOpen(false)};
  const onChange = (props: Props) => (e: React.ChangeEvent<HTMLInputElement>) => { 
    if (e.target.files?.length===1) {
      const reader = new FileReader();
      reader.onload = () => {
        const result: any = reader.result;
        props.getDataSet?.(parseData(result)); 
        setOpen(true)
      }
      reader.readAsText(e.target.files[0]);
    }
  };

  const parseData = (data: string, separator?: string) => {
    switch (props.outFormat) {
      case 'json' : return parseCsvToJson(data, separator)
      default : return data;
    }

  }

  const parseCsvToJson = (data: string, separator?: string) => {
    separator = separator || ',';
    const result = [];
    const lines = data.split('\n');
    const header = lines[0].trim().split(separator);

    for (let i = 1; i < lines.length; i++) {        
      if (!lines[i]) continue;
      var resultRow: any = {};
      const currentLine = lines[i].split(',');
      for (let j = 0; j < header.length; j++) 
        resultRow[header[j].trim()] = currentLine[j].trim();
      result.push(resultRow);
    }
    return result;
  }

  return (
    <Grid mt={1}>   
      {props.label && props.children &&
      <Typography variant='caption' color='text.secondary' display='block' gutterBottom>
        {props.label} 
      </Typography>
      }
      <Grid container>
        <Button component='label' variant={props.buttonView? 'outlined' : 'text'} startIcon={props.buttonView && <Icon name='folder'/>} disabled={props.readOnly}>
          <input  hidden accept={'.'+(props.fileType || 'csv')} type='file' value='' onChange={onChange(props)}/>
          {props.buttonView? <>&nbsp;{props.label}</> : <Icon name='cloud_upload'/>}
        </Button>    
      </Grid>  
      {props.children && <Dialog open={open} onClose={onClose}>{open? props.children : ''}</Dialog>}
    </Grid>    
  )
};


