import React from 'react';
import { History } from 'history';
import { CardMedia, Typography } from '@mui/material';
import { Card, Input, InputMoney, InputMultiline, InputNumber, Picklist } from '../../components';
import BaseCopy from '../base/base-copy.component'; 
import Model from './evaluationdetail-model';  //editable..
import questionModel from '../question/question-model';
import questionoptionModel from '../questionoption/questionoption-model';
import QuestionPicklist from '../question/question-picklist.component';
import QuestionoptionChecklist from '../questionoption/questionoption-checklist.component';
import QuestionoptionPicklistcard from '../questionoption/questionoption-picklistcard.component';

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string },
    };
    caption?: string;
    buttonCaption?: string;
    questionId?: string;
    onSave?: (data:any)=>void;
    autoSave?: boolean;
    disableAutoBack? : boolean;
    disableBackButton? : boolean;
    disableCard?: boolean;
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowQuestion: questionModel;
    dataRowQuestionoption: questionoptionModel;
    save: boolean;
}

export default class EvaluationdetailCopy extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            dataRowQuestion: questionModel.defaultValue(),
            dataRowQuestionoption: questionoptionModel.defaultValue(),
            save: false,
        }
        this.onFieldValueChange = this.onFieldValueChange.bind(this);
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    private getDataRowQuestion = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'question' : data?.id || null, 'questionCaption' : data?.name || '', 'questionTitle' : data?.title || '', 'questionContent' : data?.content || '', 'questionType' : data?.type || ''  }  });
    }

    private getDataRowQuestionoption = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'questionoption' : this.state.dataRow.questionType==='multiple'? data: [data] }  });
    };

    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onFieldBlur() { 
        const data = this.state.dataRow.answer===this.state.dataRowQuestionoption.answer? this.state.dataRowQuestionoption : null;
        this.getDataRowQuestionoption(data)
    }

    private onFieldValueChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    private onSave = (data: any) => {
        this.props.onSave?.(data);
        if (this.props.autoSave) this.setState({save: false});
    }

    render() {
        return (
            //editable..
            <BaseCopy history={this.props.history} match={this.props.match} caption={'Duplicate '+ Model.caption} 
                model={Model} dataRow={this.state.dataRow} getDataRow={this.getDataRow} getDataError={this.getDataError} onSave={this.onSave} save={this.state.save} disableAutoBack={this.props.disableAutoBack} disableBackButton={this.props.disableBackButton}  disableCard={this.props.disableCard}>                 
                
                <Picklist name='question' label='Question' value={this.state.dataRow.question?.toString()} caption={this.state.dataRow.questionCaption} onChange={this.onFieldValueChange} >
                    <QuestionPicklist testingtoolId={this.state.dataRow.testingtool?.toString()} getDataRow={this.getDataRowQuestion}/>
                </Picklist>
                
                {this.state.dataRow.question?
                <>
                    <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}} className='mb-3'>
                        {this.state.dataRow.questionTitle}
                    </Typography>
                    {this.state.dataRow.questionImageurl?
                        <Card media={<CardMedia sx={{ height: 100, width: 'auto' }} image={this.state.dataRow.questionImageurl} component='img' />}/>
                    :''}
                    <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} className='mb-3'>
                        {this.state.dataRow.questionContent}
                    </Typography>
                    {this.state.dataRow.questionType==='boolean'?
                        <QuestionoptionPicklistcard caption=' ' questionId={this.state.dataRow.question} currentId={this.state.dataRow.questionoption?.[0]?.id.toString()} getDataRow={this.getDataRowQuestionoption} />
                    :''}
                    {this.state.dataRow.questionType==='optional'?
                        <QuestionoptionPicklistcard caption=' ' questionId={this.state.dataRow.question} currentId={this.state.dataRow.questionoption?.[0]?.id.toString()} getDataRow={this.getDataRowQuestionoption} />
                    :''}
                    {this.state.dataRow.questionType==='multiple'?
                        <QuestionoptionChecklist caption=' ' questionId={this.state.dataRow.question} currentId={this.state.dataRow.questionoption} getDataRow={this.getDataRowQuestionoption} disableBackButton/>
                    :''}
                    {this.state.dataRow.questionType==='freetext'?
                        <InputMultiline name='answer' label=' ' value={this.state.dataRow.answer} error={this.state.dataError?.answer} onBlur={this.onFieldBlur} onChange={this.onFieldValueChange} />
                    :''} 
                    {this.state.dataRow.questionType==='letter'?
                        <Input name='answer' label=' ' value={this.state.dataRow.answer.replace(/[^a-z\s]/gi,'')} error={this.state.dataError?.answer} onBlur={this.onFieldBlur} onChange={this.onFieldValueChange} />
                    :''}   
                    {this.state.dataRow.questionType==='letternumber'?
                        <Input name='answer' label=' ' value={this.state.dataRow.answer.replace(/[^a-z0-9\s]/gi,'')} error={this.state.dataError?.answer} onBlur={this.onFieldBlur} onChange={this.onFieldValueChange} />
                    :''}  
                    {this.state.dataRow.questionType==='number'?
                        <InputNumber name='answer' label=' ' value={+this.state.dataRow.answer} error={this.state.dataError?.answer} onBlur={this.onFieldBlur} onChange={this.onFieldValueChange} />
                    :''}  
                    {this.state.dataRow.questionType==='decimal'?
                        <InputNumber name='answer' label=' ' value={+this.state.dataRow.answer} error={this.state.dataError?.answer} onBlur={this.onFieldBlur} onChange={this.onFieldValueChange} />
                    :''}  
                    {this.state.dataRow.questionType==='money'?
                        <InputMoney name='answer' label=' ' value={+this.state.dataRow.answer} error={this.state.dataError?.answer} onBlur={this.onFieldBlur} onChange={this.onFieldValueChange} />
                    :''}
                </>
                :''}
            </BaseCopy>
        );
    }
}