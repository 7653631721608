import React from 'react';
import { History } from 'history';
import { CardMedia, Typography } from '@mui/material';
import { Card, Input, InputMoney, InputMultiline, InputNumber } from '../../components';
import BaseView from '../base/base-view.component'; 
import Model from './evaluationdetail-model';  //editable..
import QuestionoptionPicklistcard from '../questionoption/questionoption-picklistcard.component';
import QuestionoptionChecklistCard from '../questionoption/questionoption-checklistcard.component';

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string },
    }
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class EvaluationdetailView extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    private getDataRowQuestionoption = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'questionoption' : data?.length? data : data?.id || [] }  });
    };

    render() {
        return (
            //editable..
            <BaseView history={this.props.history} match={this.props.match} caption={'View '+ Model.caption} 
                model={Model} getDataRow={this.getDataRow}>              
                <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}} className='mb-3'>
                    {this.state.dataRow.questionCaption}
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} className='mb-5'>
                    {this.state.dataRow.questionDescription}
                </Typography>
                <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}} className='mb-3'>
                    {this.state.dataRow.questionTitle}
                </Typography>
                {this.state.dataRow.questionImageurl?
                    <Card media={<CardMedia sx={{ height: 100, width: 'auto' }} image={this.state.dataRow.questionImageurl} component='img' />}/>
                :''}
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} className='mb-3'>
                    {this.state.dataRow.questionContent}
                </Typography>
                {this.state.dataRow.questionType==='boolean'?
                    <QuestionoptionPicklistcard caption=' ' questionId={this.state.dataRow.question} currentId={this.state.dataRow.questionoption?.[0]?.id.toString()} getDataRow={this.getDataRowQuestionoption} readOnly/>
                :''}
                {this.state.dataRow.questionType==='optional'?
                    <QuestionoptionPicklistcard caption=' ' questionId={this.state.dataRow.question} currentId={this.state.dataRow.questionoption?.[0]?.id.toString()} getDataRow={this.getDataRowQuestionoption} readOnly/>
                :''}
                {this.state.dataRow.questionType==='multiple'?
                    <QuestionoptionChecklistCard caption=' ' questionId={this.state.dataRow.question} currentId={this.state.dataRow.questionoption} getDataRow={this.getDataRowQuestionoption} cardNumber={1} readOnly disableBackButton/>
                :''}
                {this.state.dataRow.questionType==='freetext'?
                    <InputMultiline name='answer' label='Answer' value={this.state.dataRow.answer} readOnly/>
                :''} 
                {this.state.dataRow.questionType==='letter'?
                    <Input name='answer' label='Answer' value={this.state.dataRow.answer.replace(/[^a-z]/gi,'')} readOnly/>
                :''}   
                {this.state.dataRow.questionType==='letternumber'?
                    <Input name='answer' label='Answer' value={this.state.dataRow.answer.replace(/[^a-z,0-9]/gi,'')} readOnly/>
                :''}  
                {this.state.dataRow.questionType==='number'?
                    <InputNumber name='answer' label='Answer' value={+this.state.dataRow.answer} readOnly/>
                :''}  
                {this.state.dataRow.questionType==='decimal'?
                    <InputNumber name='answer' label='Answer' value={+this.state.dataRow.answer} readOnly/>
                :''}  
                {this.state.dataRow.questionType==='money'?
                    <InputMoney name='answer' label='Answer' value={+this.state.dataRow.answer} readOnly/>
                :''}
            </BaseView>
        );
    }
}