import * as React from 'react';
import { History } from 'history';
import Model from './app-model';
import BaseList from '../base/base-list.component';

interface IProps {  
  history: History;
  root?: string;
}

interface IState {
}

export default class AppList extends React.Component<IProps, IState> {
  public render(): React.ReactNode {
    return (
      <BaseList history={this.props.history} caption={Model.caption+'s'} url={(this.props.root||'')+Model.url} model={Model}/>
    );
  }
}
