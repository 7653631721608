import React from 'react';
import { History } from 'history';
import { Button } from '../../components';
import Model from './evaluation-model';  //editable..
import UserModel from '../user/user-model';
import TestingtoolModel from '../testingtool/testingtool-model';
import BaseCreate from '../base/base-create.component';
import BaseView from '../base/base-view.component'; 
import AppService from '../../services/app.service';

interface IProps { 
    history: History,
    parentId: string;
    testingtoolId?: string;
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowEdit: Model;
    dataRowParent: Model;
}

export default class EvaluationViewUser extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            dataRowEdit: Model.defaultValue(),
            dataRowParent: Model.defaultValue(),
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow: data});
    }

    private getDataRowNew = (data:any) => {
        this.setState({dataRow: data});
        this.props.history.push('/evaluationsheet/'+data.id)
    }
    
    private getDataRowParent = (data:any) => {
        this.setState({dataRowParent: data});
    }

    private getDataRowTestingtool = (data: any) => {
        const userName = AppService.strCapitalize(UserModel.getUserCaption());
        var name1 = this.state.dataRowParent?.name+ (data? '/'+data?.name : '');
        name1 = name1.includes(userName)? name1 : userName+'/'+name1;
        
        this.setState({dataRow: { ...this.state.dataRow, 
            name : name1,
            description: name1,
            status: 'active',  
            parent: this.state.dataRowParent.id,
            owner: this.state.dataRowParent?.owner,
            user: UserModel.getUser(),
            testingtool: data?.id,
            startdate: AppService.dateCurrent(),
            enddate: null,
        }});
    };
  
    private onStart = () =>{
        if (this.state.dataRow.id)
            this.props.history.push('/evaluationsheet/'+this.state.dataRow.id)
    }

    
    render() {
        return (
            //editable..
            <>
            <BaseView match={{isExact:true, params: {id: this.props.testingtoolId}}} caption=' '
                endPointFilter={this.props.testingtoolId? Model.endPointFilterUser2() : Model.endPointFilterUser3()} model={Model} getDataRow={this.getDataRow} disableBackButton>
            </BaseView>  
                
            <BaseView match={{isExact:true, params: {id: this.props.parentId}}} caption=' ' 
                model={Model} getDataRow={this.getDataRowParent}  disableBackButton>              
            </BaseView>

            {this.state.dataRowParent.name?
                !this.state.dataRow?.id? 
                <>
                <BaseView match={{isExact:true, params: {id: this.props.testingtoolId}}} caption=' ' 
                    model={TestingtoolModel} getDataRow={this.getDataRowTestingtool}  disableBackButton>              
                </BaseView>
                <BaseCreate caption=' ' buttonCaption='Mulai'
                    model={Model} dataRow={this.state.dataRow} onSave={this.getDataRowNew} disableAutoBack disableBackButton disableCard>              
                </BaseCreate>
                </>
                : <Button variant='contained' sx={{marginBottom: 3, float: 'right'}} onClick={this.onStart} label='Lanjut' />
            : ''}
            

                                        
            </>
        );
    }
}