import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import EvaluationSheet from './evaluation-sheet.component';

interface Props {
  root?: string;
}

export const EvaluationRouteSheet: React.FunctionComponent<Props> = (props) => {
  
  return (
    <Switch>
      <Route path={`${props.root||''}/evaluationsheet/:id`} render={(params)=> <EvaluationSheet {...params} key={params.match.params.id} />} />        
    </Switch>
  );
};
