import * as React from 'react';
import { Link } from 'react-router-dom';
import { Menu, List, ListItem, ListItemButton, ListItemIcon } from '@mui/material';
import IconView from '@mui/icons-material/Segment';
import IconCreate from '@mui/icons-material/Add';
import IconEdit from '@mui/icons-material/DriveFileRenameOutline';
import IconCopy from '@mui/icons-material/QueueOutlined';
import IconDelete from '@mui/icons-material/Delete';
import IconPreview from '@mui/icons-material/FindInPage';
import { SwipeDrawer } from '.';
import AppService from '../services/app.service';

interface Props {
  anchorEl: HTMLElement | null,  
  action?: {
    caption: string, 
    url?: string,
    icon?: React.ReactNode,
  }[];
  onClose: () => void;
}

export const ActionButton: React.FunctionComponent<Props> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(props.anchorEl);
  
  const onClose = () => {    
    setAnchorEl(null);
    props.onClose();
  };

  const actions = () => {
    const actionIcon = (caption: string) => {
      switch (caption.toLowerCase()){
        case 'view' : return <IconView />;
        case 'create' : return <IconCreate />;
        case 'edit' : return <IconEdit />;
        case 'duplicate' : return <IconCopy />;
        case 'delete' : return <IconDelete className='text-danger'/>;
        case 'preview' : return <IconPreview />;
      }
    }

    return (
      <List>
      {props.action?.map(action => {
        if (action.url)
        return (
          <Link key={action.caption} to={action.url} className={['delete','remove','hapus'].includes(action.caption.toLowerCase())? 'text-danger':'text-dark'} style={{textDecoration: 'none'}}>
            <ListItem disablePadding> <ListItemButton><ListItemIcon>{action.icon || actionIcon(action.caption)}</ListItemIcon> {action.caption} </ListItemButton></ListItem>
          </Link>
        )
      })}
      </List>
    );
  }

  React.useEffect(()=>{
    setAnchorEl(props.anchorEl)
  }, [props])

  return (
    <>
    {AppService.isMobile()?
      <SwipeDrawer variant='bottom' open={anchorEl? true : false}>
        {actions()}   
      </SwipeDrawer>
      
    : //Web view
      <Menu anchorEl={anchorEl} open={anchorEl? true : false} onClose={onClose}>
        {actions()}
      </Menu>
    }  
    </>
  )
};



