export default class TransactiondetailModel {
    id?: number;
    transaction: any;
    transactionCaption: string;
    transactiontype: any;
    transactiontypePricetype: any;
    product: any;
    productCaption: string;
    price: number;
    qty: number;
    value: number;
    
    constructor(dataRow:any) {
        
        this.id = dataRow.id;
        this.transaction =  dataRow.attributes.transaction?.data?.id || null;
        this.transactionCaption =  dataRow.attributes.transaction?.data?.attributes.name || dataRow.attributes.transaction?.data?.id; 
        this.transactiontype =  dataRow.attributes.transaction?.data?.attributes?.transactiontype?.data?.id || null;
        this.transactiontypePricetype =  dataRow.attributes.transaction?.data?.attributes.transactiontype?.data?.attributes.pricetype || null;
        this.product =  dataRow.attributes.product?.data?.id || null;
        this.productCaption =  dataRow.attributes.product?.data?.attributes.name || dataRow.attributes.product?.data?.id; 
        this.price = dataRow.attributes.price;
        this.qty = dataRow.attributes.qty;
        this.value = dataRow.attributes.value; 
    } 

    public static endPoint: string = 'transaction-details';    
    public static endPointFilter: string = '?populate=*&filters[transaction]=';
    public static endPointFilter2: string = '?populate[product][sort]=id'+
                                            '&populate[transaction][sort]=id'+
                                            '&populate[transaction][populate]=transactiontype'+
                                            '&filters[id]='
    public static url: string = '/transactiondetail';

    public static caption: string = 'Order Detail';

    public static defaultValue = ()=> {
        return {
        id: 0,
        transaction: null,
        transactionCaption: '',
        transactiontype: null,
        transactiontypePricetype: null,
        product: null,
        productCaption: '',
        price: 0,
        qty: 0,
        value: 0,

  
        }
    }
    
    public static columns = [
        { name: 'productCaption', caption: 'Product' },
        { name: 'price', caption: 'Price', type: 'money' },
        { name: 'qty', caption: 'Qty', type: 'number' },  
        { name: 'value', caption: 'Total', type: 'money' },
              
    ]

    public static columnPicklist = [
        { name: 'productCaption', caption: 'Product' },
        { name: 'price', caption: 'Price', type: 'money' },
        { name: 'qty', caption: 'Qty', type: 'number' },  
        { name: 'value', caption: 'Total', type: 'money' },
    ]
    
}