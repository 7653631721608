import React from 'react';
import { History } from 'history';
import { Input, InputNumber, InputMultiline, Switch, Picklist, InputSelect } from '../../components';
import BaseCreate from '../base/base-create.component'; 
import Model from './testingtoolresume-model';  //editable..
import OwnerPicklist from '../owner/owner-picklist.component';
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string},
    }    
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class TestingtoolresumeCreate extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        this.onFieldValueChange = this.onFieldValueChange.bind(this);
    }
    
    private getDataRowOwner = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'owner' : data?.id || null, 'ownerCaption' : data?.name || ''  }  });
    };
    
    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onFieldValueChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    render() {
        return (
            //editable..          
            <BaseCreate history={this.props.history} caption={'Create '+ Model.caption} 
                model={Model} dataRow={this.state.dataRow} getDataError={this.getDataError}>               
          
                <Input name='name' label='Name' value={this.state.dataRow.name} error={this.state.dataError?.name} onChange={this.onFieldValueChange} />
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} error={this.state.dataError?.description} onChange={this.onFieldValueChange} />
                <Switch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' error={this.state.dataError?.status} onChange={this.onFieldValueChange}/>
                {AppService.isAppFullVersion() &&
                <Picklist name='owner' label='Owner' value={this.state.dataRow.owner?.toString()} caption={this.state.dataRow.ownerCaption} error={this.state.dataError?.owner} onChange={this.onFieldValueChange} >
                    <OwnerPicklist currentId={this.state.dataRow.owner?.toString()} getDataRow={this.getDataRowOwner}/>
                </Picklist>
                } 
                <Input name='title' label='Title' value={this.state.dataRow.title} error={this.state.dataError?.title} onChange={this.onFieldValueChange} />
                <InputMultiline name='content' label='Content' value={this.state.dataRow.content} error={this.state.dataError?.content} onChange={this.onFieldValueChange} />
                <InputNumber name='seq' label='Seq' value={this.state.dataRow.seq} error={this.state.dataError?.seq} onChange={this.onFieldValueChange} />
                <InputNumber name='valuemin' label='Value Min.' value={this.state.dataRow.valuemin} error={this.state.dataError?.valuemin} onChange={this.onFieldValueChange} />
                <InputNumber name='valuemax' label='Value Max.' value={this.state.dataRow.valuemax} error={this.state.dataError?.valuemax} onChange={this.onFieldValueChange} />
                <InputNumber name='scale' label='Scale' value={this.state.dataRow.scale} error={this.state.dataError?.scale} onChange={this.onFieldValueChange} />
                <InputSelect name='calc' label='Calc Type' value={this.state.dataRow.calc} options={Model.optionCalc} error={this.state.dataError?.calc} onChange={this.onFieldValueChange} />
                <InputNumber name='factor' label='Factor' value={this.state.dataRow.factor} error={this.state.dataError?.scale} onChange={this.onFieldValueChange} />
                
            </BaseCreate>
        );
    }     
     
}