import * as React from 'react';
import { History } from 'history';
import Model from './questionoption-model';
import BaseChecklistCard from '../base/base-checklistcard.component';

interface IProps { 
  history?: History;
  caption?: string;
  questionId: string;
  readOnly?: boolean
  disableBackButton? : boolean
  currentId?: any;
  getDataRow: (data: any) => void;
  cardNumber?: number;
  cardBorderRadius?: number;
}

interface IState {
}

export default class QuestionoptionChecklistCard extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  public render(): React.ReactNode {
    return (
      <BaseChecklistCard history={this.props.history} caption={this.props.caption? this.props.caption : 'Select '+Model.caption} endPointFilter={Model.endPointFilter+this.props.questionId}  model={Model} readOnly={this.props.readOnly} currentId={this.props.currentId} getDataRow={this.props.getDataRow} cardNumber={this.props.cardNumber} cardBorderRadius={this.props.cardBorderRadius} cardBoxShadow={5} cardPadding={1} disableBackButton={this.props.disableBackButton}/>
    );
  }
}
