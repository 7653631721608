import React from 'react';
import { History } from 'history';
import { Input, InputMultiline, InputMoney, InputNumber, InputSelect, Switch, Picklist } from '../../components';
import BaseCreate from '../base/base-create.component'; 
import Model from './amounttype-model';  //editable..
import OwnerPicklist from '../owner/owner-picklist.component';
import JournalgroupPicklist from '../journalgroup/journalgroup-picklist.component';
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class AmounttypeCreate extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        this.onFieldValueChange = this.onFieldValueChange.bind(this);
    }
    
    private getDataRowOwner = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'owner' : data?.id || null, 'ownerCaption' : data?.name || ''  }  });
    };
      
    private getDataRowJournalgroup = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'journalgroup' : data?.id || null, 'journalgroupCaption' : data?.name || ''  }  });
    };
    
    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onFieldValueChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    render() {
        return (
            //editable..          
            <BaseCreate history={this.props.history} caption={'Create '+ Model.caption} 
                model={Model} dataRow={this.state.dataRow} getDataError={this.getDataError}>               
          
                <Input name='name' label='Name' value={this.state.dataRow.name} error={this.state.dataError?.name} onChange={this.onFieldValueChange} />
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} error={this.state.dataError?.description} onChange={this.onFieldValueChange} />
                <Switch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' error={this.state.dataError?.status} onChange={this.onFieldValueChange}/>
                {AppService.isAppFullVersion() &&
                <Picklist name='owner' label='Owner' value={this.state.dataRow.owner?.toString()} caption={this.state.dataRow.ownerCaption} error={this.state.dataError?.owner} onChange={this.onFieldValueChange} >
                    <OwnerPicklist currentId={this.state.dataRow.owner?.toString()} getDataRow={this.getDataRowOwner}/>
                </Picklist>
                } 
                <InputMoney name='defaultvalue' label='Default Value' value={this.state.dataRow.defaultvalue} error={this.state.dataError?.defaultvalue} onChange={this.onFieldValueChange} />
                <InputNumber name='defaultpercentage' label='Default Percentage' value={this.state.dataRow.defaultpercentage} error={this.state.dataError?.defaultpercentage} onChange={this.onFieldValueChange} />
                <InputSelect name='calc' label='Calculation' value={this.state.dataRow.calc} options={Model.optionCalc} error={this.state.dataError?.calc} onChange={this.onFieldValueChange} />
                <Picklist name='journalgroup' label='Journal Group' value={this.state.dataRow.journalgroup?.toString()} caption={this.state.dataRow.journalgroupCaption} error={this.state.dataError?.journalgroup} onChange={this.onFieldValueChange} >
                    <JournalgroupPicklist currentId={this.state.dataRow.journalgroup?.toString()} getDataRow={this.getDataRowJournalgroup}/>
                </Picklist>

            </BaseCreate>
        );
    }     
     
}