import React from 'react';
import { History } from 'history';
import { CardMedia, Typography } from '@mui/material';
import { Card, Input, InputMoney, InputMultiline, InputNumber, Picklist, Skeleton } from '../../components';
import BaseCreate from '../base/base-create.component'; 
import BaseView from '../base/base-view.component';
import Model from './evaluationdetail-model';  //editable..
import evaluationModel from '../evaluation/evaluation-model'; 
import questionModel from '../question/question-model'; 
import questionoptionModel from '../questionoption/questionoption-model';
import QuestionPicklist from '../question/question-picklist.component';
import QuestionoptionPicklistcard from '../questionoption/questionoption-picklistcard.component';
import QuestionoptionChecklistCard from '../questionoption/questionoption-checklistcard.component';


interface IProps { 
    history?: History;
    match:{ 
        isExact: boolean
        params: { id:string},
    };
    caption?: string;
    buttonCaption?: string;
    questionId?: string;
    onSave?: (data:any)=>void;
    autoSave?: boolean;
    disableAutoBack? : boolean;
    disableBackButton? : boolean;
    disableCard?: boolean;
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowQuestionoption: questionoptionModel;
    save: boolean;
}

export default class EvaluationdetailCreate extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            dataRowQuestionoption: questionoptionModel.defaultValue(),
            save: false,
        }
        this.onFieldBlur = this.onFieldBlur.bind(this);
        this.onFieldValueChange = this.onFieldValueChange.bind(this);
    }

    private getDataRowEvaluation = (data:any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 
            'name' : data?.name  +'/'+this.state.dataRow.questionCaption,
            'description' : data?.name  +'/'+this.state.dataRow.questionCaption,
            'evaluation' : data?.id || null, 
            'testingtool' : data?.testingtool?.['0']?.id || null, }
        });
    }

    private getDataRowQuestion = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow,  'question' : data?.id || null, 'questionCaption' : data?.name || '', 'questionTitle' : data?.title || '', 'questionContent' : data?.content || '', 'questionType' : data?.type || '', 'questionImageurl' : data?.imageUrl || ''} });
    };

    private getDataRowQuestionoption = (data: any) => {
        data = ['boolean','optional'].includes(this.state.dataRow.questionType)? [data] : data; 
        this.setState({ dataRow: { ...this.state.dataRow, 'questionoption' : data }  , save: this.props.autoSave? true: false});
    };

    private getDataRowQuestionoption2 = (data: any) => {
        this.setState({dataRowQuestionoption : data});
    };
    
    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onFieldBlur() { 
        const data = this.state.dataRow.answer===this.state.dataRowQuestionoption.answer? this.state.dataRowQuestionoption : null;
        this.getDataRowQuestionoption(data)
    }
    
    private onFieldValueChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value
            }
        };
        this.setState(nextState);
    }

    private onSave = (data: any) => {
        this.props.onSave?.(data);
        if (this.props.autoSave) this.setState({save: false});
    }

    render() {
        return (
            //editable..  
            <>     
            <BaseView match={this.props.match} caption={''} 
                model={evaluationModel} getDataRow={this.getDataRowEvaluation}>              
            </BaseView> 
            {this.state.dataRow?
            <BaseCreate history={this.props.history} caption={this.props.caption || 'Create '+ Model.caption} buttonCaption={this.props.buttonCaption}
                model={Model} dataRow={this.state.dataRow} getDataError={this.getDataError} onSave={this.onSave} save={this.state.save} disableAutoBack={this.props.disableAutoBack} disableBackButton={this.props.disableBackButton}  disableCard={this.props.disableCard}>              
 
                {this.props.questionId?
                    <>
                    <BaseView match={{isExact:true, params: {id: this.props.questionId}}} caption={''} 
                        model={questionModel} getDataRow={this.getDataRowQuestion}>              
                    </BaseView> 
                    {['freetext','letter','letternumber','number','decimal','money'].includes(this.state.dataRow.questionType)?
                        <BaseView match={{isExact:true, params: {id: ''}}} caption={''} 
                            endPointFilter={questionoptionModel.endPointFilter+this.props.questionId} model={questionoptionModel} getDataRow={this.getDataRowQuestionoption2}>              
                        </BaseView> 
                    :''}
                    </>
                    :
                    <Picklist name='question' label='Question' value={this.state.dataRow.question?.toString()} caption={this.state.dataRow.questionCaption} onChange={this.onFieldValueChange} >
                        <QuestionPicklist testingtoolId={this.state.dataRow.testingtool?.toString()} getDataRow={this.getDataRowQuestion}/>
                    </Picklist>
                }
                {this.state.dataRow.question?
                <>
                    <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}} className='mb-3'>
                        {this.state.dataRow.questionTitle}
                    </Typography>
                    {this.state.dataRow.questionImageurl?
                        <Card media={<CardMedia sx={{ height: 100, width: 'auto' }} image={this.state.dataRow.questionImageurl} component='img' />}/>
                    :''}
                    <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} className='mb-3'>
                        {this.state.dataRow.questionContent}
                    </Typography>
                    {this.state.dataRow.questionType==='boolean'?
                        <QuestionoptionPicklistcard caption=' ' questionId={this.state.dataRow.question} getDataRow={this.getDataRowQuestionoption} cardNumber={1} cardBorderRadius={5}/>
                    :''}
                    {this.state.dataRow.questionType==='optional'?
                        <QuestionoptionPicklistcard caption=' ' questionId={this.state.dataRow.question} getDataRow={this.getDataRowQuestionoption} cardNumber={1}/>
                    :''}
                    {this.state.dataRow.questionType.includes('multiple')?
                        <QuestionoptionChecklistCard caption=' ' questionId={this.state.dataRow.question} currentId={this.state.dataRow.questionoption} getDataRow={this.getDataRowQuestionoption} cardNumber={1} disableBackButton/>
                    :''}
                    {this.state.dataRow.questionType==='freetext'?
                        <InputMultiline name='answer' label=' ' value={this.state.dataRow.answer} error={this.state.dataError?.answer} onBlur={this.onFieldBlur} onChange={this.onFieldValueChange} />
                    :''} 
                    {this.state.dataRow.questionType==='letter'?
                        <Input name='answer' label=' ' value={this.state.dataRow.answer.replace(/[^a-z\s]/gi,'')} error={this.state.dataError?.answer} onBlur={this.onFieldBlur} onChange={this.onFieldValueChange} />
                    :''}   
                    {this.state.dataRow.questionType==='letternumber'?
                        <Input name='answer' label=' ' value={this.state.dataRow.answer.replace(/[^a-z0-9\s]/gi,'')} error={this.state.dataError?.answer} onBlur={this.onFieldBlur} onChange={this.onFieldValueChange} />
                    :''}  
                    {this.state.dataRow.questionType==='number'?
                        <InputNumber name='answer' label=' ' value={+this.state.dataRow.answer} error={this.state.dataError?.answer} onBlur={this.onFieldBlur} onChange={this.onFieldValueChange} />
                    :''}  
                    {this.state.dataRow.questionType==='decimal'?
                        <InputNumber name='answer' label=' ' value={+this.state.dataRow.answer} error={this.state.dataError?.answer} onBlur={this.onFieldBlur} onChange={this.onFieldValueChange} />
                    :''}  
                    {this.state.dataRow.questionType==='money'?
                        <InputMoney name='answer' label=' ' value={+this.state.dataRow.answer} error={this.state.dataError?.answer} onBlur={this.onFieldBlur} onChange={this.onFieldValueChange} />
                    :''}
                </>
                : <Skeleton/>}
            </BaseCreate>
            :''}
            </>
        );
    }     
     
}