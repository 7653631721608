import React from 'react';
import * as toastr from 'toastr';
import {Form } from '../../components';
import ApiService from '../../services/api.service';

interface IProps { 
    history?: any;
    match: any;
    caption: string;
    captionColor?: string;
    buttonCaption?: string;
    successCaption?: string;
    endPointFilter?: string;
    model: any;
    getDataRow: (data: any) => void;
    getDataError?: (data: any) => void;
    onDelete?: () => void;
    buttonOnBottom?: boolean;
    disableAutoBack?: boolean;
    disableBackButton?: boolean;
    disableCard?: boolean;
    children: React.ReactNode;
}

interface IState {
    dataRow: Array<any>;  
}

export default class BaseDelete extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
    }

    private isLoading: boolean = false;
    
    public componentDidMount() { 
        if (this.isLoading) return;
        this.isLoading = true;

        ApiService.get<typeof this.props.model>(this.props.model.endPoint+(this.props.endPointFilter||'?populate=*&filters[id]='), this.props.match.params.id).then(
            (rp) => {
                if (rp.Status) { if (rp.Data.length>0) var data = new this.props.model(rp.Data[0]); this.props.getDataRow(data);} 
                else { toastr.error(rp.Messages); }
                this.isLoading = false;
            }
        );
    }

    private onDelete = () => {
        ApiService.delete(this.props.model.endPoint+'/', this.props.match.params.id).then(
            (rp) => {
                if (rp.Status) {                 
                    this.props.onDelete?.();
                    (!this.props.successCaption || this.props.successCaption!=='') && toastr.success(this.props.successCaption || 'Data deleted.');
                    if (!this.props.disableAutoBack) {this.props.history?.goBack();}
                } 
                else { this.props.getDataError && rp.Errors? this.props.getDataError(rp.Errors) : toastr.error(rp.Messages); }
            }
        );
    }

    render() {
        return(
            <Form history={this.props.history} caption={this.props.caption} captionColor={this.props.captionColor} buttonCaption={this.props.buttonCaption || 'delete'} buttonColor='error' buttonOnBottom={this.props.buttonOnBottom} onSubmit={this.onDelete} disableBackButton={this.props.disableBackButton} disableCard={this.props.disableCard}>              
                {this.props.children}
            </Form>
        );
    }     
}