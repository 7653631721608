import * as React from 'react';
import { History } from 'history';
import { Box, Container, Grid, Typography } from '@mui/material';
import AppModel from '../../app/app-model';
import OwnerModel from '../../owner/owner-model';
import UserModel from '../../user/user-model';
import UserAuthSignin from '../../user/user-auth-signin.component';
import { AppBar, Button, Card, Transition, Trapezoid } from '../../../components';
import MenuPicklistcard from '../../menu/menu-picklistcard.component';
import BaseView from '../../base/base-view.component';
import LogoApp from './logoapp.component';
import AppService from '../../../services/app.service';

interface IProps {
  history: History;
  getDataRow: (data: any) => void;
}

interface IState {
  triggerTransition: boolean;
  dataRowApp: AppModel;
  dataRowOwner: OwnerModel;
  dataRowUser: UserModel;
}

export default class Home extends React.Component<IProps, IState> {
  
  constructor(props: IProps) {
    super(props);
    this.state = {
      triggerTransition : false,
      dataRowApp: AppModel.defaultValue(),
      dataRowOwner: OwnerModel.defaultValue(),
      dataRowUser: UserModel.defaultValue(),
    }
  }

  private getDataRowApp = (data:any) => {
    this.setState({dataRowApp: data})
  }

  private getDataRowOwner = (data:any) => {
    this.setState({dataRowOwner: data})
  }

  private getDataRowUser = (data:any) => {
      this.setState({dataRowUser: data})
      UserModel.setUserImage(data?.imageUrl || '')
  }

  private onClickAppBar = () =>{
    this.setState({ triggerTransition: true})
  }

  private onScrollEndAppBar = () =>{
    this.setState({ triggerTransition: false })
  }

  private avatarClick = () =>{
    if (UserModel.isSignin()) this.props.history.push('/user/profile')
  }
  
  private isMobile: boolean = AppService.isMobile();

  private header(){
    return (
      <Trapezoid position='bottomLeft'>
        <Container>
          <Grid container >

            <Grid item xs={12} md={6} pb={3}>
              <Typography variant='h2'>
                <b>cepat.</b> <br/>
                akurat. <br/>
                <Box display='inline' color='white'>
                  Jatara Apps
                </Box>
              </Typography>

              <Typography pt={2} mb={3}>
                Pengembangan aplikasi lebih cepat dan akurat <br/>
                dengan platform jatara frameworks.
              </Typography>
              <Button color='secondary' label='Lihat Modul'/>
            </Grid>

            <Grid item xs={12} md={6}>
              <Container>
              {UserModel.isSignin()?
                <img src="https://img.freepik.com/free-psd/coffee-cup-icon-isolated-3d-render-illustration_47987-8773.jpg?w=740&t=st=1687532989~exp=1687533589~hmac=1b58bc5f1ba4fd2e91fbc3542237ddfa70449e55f8f511c26cab2fcf4daef06c" width='auto' height={500} alt="Hero img" />
                :
                <Card borderColor='white'>
                  <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}} color='primary.main' mt={3} mb={3}>Login</Typography>
                  <UserAuthSignin caption=' ' history={this.props.history} root='/' disableBackButton disableAutoBack/> 
                </Card>
              }
              </Container>
              
            </Grid>

            <Card color={this.isMobile? 'primary.main':''} borderColor={this.isMobile? 'primary.main':''} padding={0}>
            <Grid container spacing={this.isMobile? 1 : 15} textAlign='center'> 
              <Transition variant='zoom' trigger>        
              <Grid item xs={4} md={4}>
                <Card borderColor='white'>
                <Typography sx={{typography: {xs: 'h4', md: 'h3'}}} color='primary.main'>
                  <b>10K</b>+
                </Typography> 
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} color='primary.light' mt={2}>
                  <b>Perusahaan</b>
                </Typography>
                </Card>
              </Grid>
              </Transition>

              <Transition variant='zoom' addTime={500} trigger>
              <Grid item xs={4} md={4}>
                <Card borderColor='white'>
                <Typography sx={{typography: {xs: 'h4', md: 'h3'}}} color='primary.main'>
                  <b>99</b>+
                </Typography>    
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} color='primary.light' mt={2}>
                  <b>Modul terintegrasi</b>
                </Typography>
                </Card>
              </Grid>
              </Transition>

              <Transition variant='zoom' addTime={1000} trigger>
              <Grid item xs={4} md={4}>
                <Card borderColor='white'>
                <Typography sx={{typography: {xs: 'h4', md: 'h3'}}} color='primary.main'>
                  <b>20</b>+
                </Typography>           
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} color='primary.light' mt={2}>
                  <b>Pengembang Berpengalaman</b>
                </Typography>
                </Card>
              </Grid>
              </Transition>

            </Grid>
            </Card>
          </Grid>

        </Container>
      </Trapezoid>
    )
  }

  private products(){
    return (
      <Card borderColor='white'>
      <Container >
        <Grid container spacing={2} pt={10}>
          <Typography variant='h4' textAlign='center'>
            <b>Semua Aplikasi</b>
          </Typography>
          <Card borderColor='white'>
            <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}} color='primary.main' mb={3} >Paling banyak dicari</Typography>
            {UserModel.isSignin()?
            <MenuPicklistcard history={this.props.history} caption=' ' cardNumber={4}/> 
            :''}
          </Card>
          <Card borderColor='white'>
            <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}} color='primary.main' mb={3} >Test Tingkat Manajemen</Typography>
            {UserModel.isSignin()?
            <MenuPicklistcard history={this.props.history} caption=' ' cardNumber={1}/> 
            :''}
          </Card>
        </Grid>
      </Container>
      </Card>
    )
  }

  private mentors(){
    return (
      <Container>
        <Grid container direction='row' spacing={3} pt={10} pb={10} alignItems='center'>            
          <Grid item xs={12} md={5} textAlign='right'>
            <Typography variant='h3' color='primary.main'>
              <b>Pengembang</b>
            </Typography>
            <Typography variant='h4' color='primary.main'>
              <b>yang telah</b>
            </Typography>
            <Typography variant='h4' color='primary.main'>
              <b>Berpengalaman</b>
            </Typography>
            <Typography variant='h5' color='primary.main'>
              50 tahun <br/>di bidangnya
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <img src="https://img.freepik.com/free-photo/modern-muslim-woman-wearing-headscarf-walking-street_1303-27637.jpg?w=360&t=st=1687459247~exp=1687459847~hmac=b4eeb1f8c95efdb1979599a803584b26d8a6d03c91983e1c22c13d7e61f1899d" width='auto' height={350} alt="Hero img" />
          </Grid>
          <Grid item xs={12} md={2} >
            <img src="https://img.freepik.com/free-photo/asian-woman-glasses-smart-blouse-skirt-posing-studio-with-tablet_1098-17489.jpg?w=360&t=st=1687459012~exp=1687459612~hmac=41035a5f21d96fcffbe0db09ab2f625ae49fc3a467654db5079300549f302384" width='auto' height={350} alt="Hero img" />
          </Grid>
          <Grid item xs={12} md={2}>
            <img src="https://img.freepik.com/free-photo/confident-businessman_1098-16852.jpg?w=360&t=st=1687459085~exp=1687459685~hmac=0625f84521fff0f6e49c725f46b16e70301eecea5ad7ea3b68e5d819a8b20131" width='auto' height={350} alt="Hero img" />
          </Grid>
        </Grid>
      </Container>
    )
  }

  private footer(){
    return (
      <Trapezoid position='topLeft' color='secondary.light' colorOthers='whitesmoke'>
        <Grid container spacing={5} sx={{ padding: {xs: 5, md:0}, paddingBottom: {xs: 5, md:5}}}>
          <Grid item xs={12} md={4}>
            <LogoApp disableMargin/>
            <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
            {this.state.dataRowApp.description}
            </Typography>
            <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
            {this.state.dataRowOwner.name}
            </Typography>
          </Grid>
          
          <Grid item xs={12} md={2}>
            <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}}>
              <b>Hubungi Kami</b>
            </Typography>
            <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
              Call Center <br/>
              {this.state.dataRowOwner.phone}
            </Typography>
            <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
              Email <br/>
              {this.state.dataRowOwner.email}
            </Typography>
            <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
              Instagram
            </Typography>
            <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
              Facebook
            </Typography>
          </Grid>

          <Grid item xs={12} md={2}>
            <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}}>
              <b>Kebijakan</b>
            </Typography>
            <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
              Kebijakan Pribadi
            </Typography>
            <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
              Syarat & Ketentuan
            </Typography>
            <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
              Sering ditanyakan
            </Typography>
          </Grid>
        </Grid>
      </Trapezoid>
    )
  }
  private home(){
    return (
      <>      
      <BaseView  history = {null} match={ {'isExact': true, 'params': { 'id':AppService.appId() }}} caption={''} 
        model={AppModel} getDataRow={this.getDataRowApp}>    
      </BaseView>
      
      <BaseView  history = {null} match={ {'isExact': true, 'params': { 'id':OwnerModel.getOwner() }}} caption={''} 
        model={OwnerModel} getDataRow={this.getDataRowOwner}>    
      </BaseView>

      {UserModel.isSignin() &&
      <BaseView  history = {null} match={ {'isExact': true, 'params': { 'id':UserModel.getUser() }}} caption={''} 
        model={UserModel} getDataRow={this.getDataRowUser}>    
      </BaseView>
      }
      {this.header()}
      {this.products()}
      {this.mentors()}
      {this.footer()}
      </>
    )
  }
  
  public render(): React.ReactNode {  
    return (
      <AppBar 
        logo = {<LogoApp disableMargin/>}
        menu = {[{caption:'Blog', url:'/blog'}, {caption:UserModel.isSignin()?'Dashboard':'', url:'/dashboard'}]}
        avatar = {{name:this.state.dataRowUser.username, imageUrl:this.state.dataRowUser.imageUrl, onClick: this.avatarClick}}
        onClick={this.onClickAppBar}
        onScrollEnd={this.onScrollEndAppBar}
      >
        {this.home()}
      </AppBar>
    )
  }
}