import * as React from 'react';
import { History } from 'history';
import Model from './evaluationdetail-model';
import BaseList from '../base/base-list.component';

interface IProps {  
  history: History;
  root?: string;
  caption?: string;
  evaluationId: string;
  actions?: any;
  disableBackButton?: boolean;
  disableCard? : boolean;
  getDataRow: (data: any) => void;
}

interface IState {
}

export default class EvaluationdetailList extends React.Component<IProps, IState> {
    
  constructor(props: IProps) {
    super(props);
  }

  private getDataSet = (data:any) => {
    if (this.props.getDataRow) {
      var total = 0;
      var totaladj = 0;
      data.forEach((dataRow: { value: number, valueadj: number; }, i: any) => {
        total = total + dataRow.value;
        totaladj = totaladj + dataRow.valueadj;
      });
      this.props.getDataRow({'total':total, 'totaladj':totaladj});
    } 
  }
  
  public render(): React.ReactNode {
    return (
      <BaseList history={this.props.history} parentId={this.props.evaluationId} caption={(this.props.caption || Model.caption)+'s'} endPointFilter={Model.endPointFilter+this.props.evaluationId} url={(this.props.root||'')+Model.url} model={Model} actions={this.props.actions} disableBackButton={this.props.disableBackButton} disableCard={this.props.disableCard} getDataSet={this.getDataSet}/>
    );
  }
}
