import * as React from 'react';
import { History } from 'history';
import { Link } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import IconArrowBack from '@mui/icons-material/West';

interface Props {
  history?: History;
  label: string;
  color?: string;
  disableBackButton?: boolean;
  children?: React.ReactNode;
}

export const FormHeader: React.FunctionComponent<Props> = (props) => {
  const margin = props.label.trim()===''? 0 : 3;

  const onClick = () => {
    props.history?.goBack();
  }

  return (
    <Grid container mt={margin} mb={margin} mr={2} justifyContent='space-between'>
      <Grid item xs={9} md={10}>
        <Link to='#' onClick={onClick} className={props.disableBackButton? 'nav-link disabled' : ''} style={{textDecoration: 'none'}} >
          <Typography component='div' sx={{typography: {xs: 'subtitle1', md: 'h6'}, lineHeight: {xs:1.5}, color: props.color || 'primary.main'}}>
            <Grid container direction='row'>
              {props.disableBackButton?  '' : <IconArrowBack sx={{fontSize: {xs: '25px', md: '36px'}, marginLeft: 1, marginRight: 2}} className='mr-5'/>}
              <Grid item xs={9}>{props.label}</Grid>
            </Grid>
          </Typography>
        </Link> 
      </Grid>
      <Grid item xs={2} md={1} mr={3}>
        {props.children}
      </Grid>
    </Grid>
  );
};
