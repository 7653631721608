import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import QuestionoptionList from './questionoption-list.component';
import QuestionoptionCreate from './questionoption-create.component';
import QuestionoptionView from './questionoption-view.component';
import QuestionoptionEdit from './questionoption-edit.component';
import QuestionoptionCopy from './questionoption-copy.component';
import QuestionoptionDelete from './questionoption-delete.component';

interface Props {
  root?: string;
}

export const QuestionoptionRoute: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>
      <Route path={(props.root||'')+'/questionoption/create/:id'} component={QuestionoptionCreate} />
      <Route path={(props.root||'')+'/questionoption/view/:id'} component={QuestionoptionView} />
      <Route path={(props.root||'')+'/questionoption/edit/:id'} component={QuestionoptionEdit} />
      <Route path={(props.root||'')+'/questionoption/copy/:id'} component={QuestionoptionCopy} />
      <Route path={(props.root||'')+'/questionoption/delete/:id'} component={QuestionoptionDelete} />
      <Route path={(props.root||'')+'/questionoption'} component={QuestionoptionList} />
    </Switch>
  );
};
