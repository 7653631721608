import * as React from 'react';
import IconMui from '@mui/material/Icon';

interface Props {
  name: string;
  size?: 'small'|'medium'|'large'|'extralarge';
  color?: string;
}

export const Icon: React.FunctionComponent<Props> = (props) => {
  
  const fontSize = () =>{
    switch (props.size) {
      case 'small': return 24;
      case 'medium': return 36;
      case 'large': return 48;
      case 'extralarge': return 56;
      default : return 36;
    }
  }
  
  return (
    <IconMui sx={{fontSize: fontSize(), color: props.color, verticalAlign: 'middle'}} >{props.name.toLowerCase()}</IconMui>
  )
  
}