import * as React from 'react';
import { Box } from '@mui/material';

interface Props {
  id?: string;
  color?: any;
  borderColor?: any;
  size?: number;
  borderWidth?: number;
  left?: number;
  top?: number;
  position?: 'top'|'bottom'|'left'|'right';
}

export const Circle: React.FunctionComponent<Props> = (props) => {
  let color = props.color || 'white';
  let borderWidth = props.borderWidth || 5;
  let borderColor = props.borderColor || 'silver';
  let size = props.size || 80;
  let sizeHalf = size/2;
  let position = props.position || '';

  return (  
    <Box position= {(props.left || props.top)? 'relative' : 'inherit'} sx={{
      height: ['left', 'right', ''].includes(position)? size : sizeHalf, 
      width: ['left', 'right'].includes(position)? sizeHalf : size, 
      left: props.left,
      top: props.top,
      color: borderColor, 
      bgcolor: 'white', 
      border: borderWidth, 
      borderColor: borderColor, 
      borderTopColor: ['bottom'].includes(position)? color : '', 
      borderBottomColor: ['top'].includes(position)? color : '', 
      borderLeftColor: ['right'].includes(position)? color : '', 
      borderRightColor: ['left'].includes(position)? color : '', 
      borderTop: ['bottom'].includes(position)? 0 : borderWidth, 
      borderBottom: ['top'].includes(position)? 0 : borderWidth, 
      borderLeft: ['right'].includes(position)? 0 : borderWidth, 
      borderRight: ['left'].includes(position)? 0 : borderWidth, 
      borderTopLeftRadius: ['top', 'left', '',].includes(position)?  sizeHalf : 0,
      borderTopRightRadius: ['top', 'right', ''].includes(position)?  sizeHalf : 0,
      borderBottomLeftRadius: ['bottom', 'left', ''].includes(position)? sizeHalf : 0,
      borderBottomRightRadius: ['bottom', 'right', ''].includes(position)? sizeHalf : 0}}
    />

  );
};
