import * as React from 'react';
import Model from './questionoption-model';
import BasePicklistcard from '../base/base-picklistcard.component';

interface IProps {
  questionId: string;
  caption?: string;
  currentId?: string;
  getDataRow: (data: any) => void;
  readOnly?: boolean;
  cardNumber?: number;
  cardBorderRadius?: number;
}

interface IState {
}

export default class QuestionoptionPicklistcard extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  public render(): React.ReactNode {
    return (
      <BasePicklistcard caption={this.props.caption || 'Select '+Model.caption} endPointFilter={Model.endPointFilter+this.props.questionId} model={Model}  currentId={this.props.currentId} getDataRow={this.props.getDataRow} readOnly={this.props.readOnly} cardNumber={this.props.cardNumber} cardBorderRadius={this.props.cardBorderRadius} cardBoxShadow={5} cardPadding={1}/>
    );
  }
}
