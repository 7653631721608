import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import PersonalinfoList from './personalinfo-list.component';
import PersonalinfoCreate from './personalinfo-create.component';
import PersonalinfoView from './personalinfo-view.component';
import PersonalinfoEdit from './personalinfo-edit.component';
import PersonalinfoCopy from './personalinfo-copy.component';
import PersonalinfoDelete from './personalinfo-delete.component';

interface Props {
  root?: string;
}

export const PersonalinfoRouteCustomer: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>
      <Route path={`${props.root||''}/customer/create`} render={(params)=> <PersonalinfoCreate {...params} caption='Customer' type='customer' />} />    
      <Route path={`${props.root||''}/customer/view/:id`} render={(params)=> <PersonalinfoView {...params} root={props.root} caption='Customer' />} />        
      <Route path={`${props.root||''}/customer/edit/:id`} render={(params)=> <PersonalinfoEdit {...params} root={props.root} caption='Customer' type='customer' />} />        
      <Route path={`${props.root||''}/customer/copy/:id`} render={(params)=> <PersonalinfoCopy {...params} caption='Customer' type='customer' />} />     
      <Route path={`${props.root||''}/customer/delete/:id`} render={(params)=> <PersonalinfoDelete {...params} caption='Customer' root={props.root}/>} />        
      <Route path={(props.root||'')+'/customer'} render={(params)=> <PersonalinfoList {...params} root={props.root} caption='Customer' type='customer' />} /> 
    </Switch>
  );
};
