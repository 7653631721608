import * as React from 'react';
import Model from './product-model';
import BasePicklist from '../base/base-picklist.component';

interface IProps { 
  currentId?: string;  
  getDataRow: (data: any) => void;
}

interface IState {
}

export default class ProductPicklist extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }



  public render(): React.ReactNode {
    return (
      <BasePicklist caption={'Select '+Model.caption} endPointFilter={Model.endPointFilter()}  model={Model} currentId={this.props.currentId} getDataRow={this.props.getDataRow}/>
    );
  }
}
