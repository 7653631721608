import ApiService from '../../services/api.service';

export default class OwnerModel {
    id?: number;
    name: string;
    description: string;
    status: string;
    phone: string;
    email: any;
    address: string;
    image: any;
    imageId: any;
    imageUrl: string;

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.phone = dataRow.attributes.phone;
        this.email = dataRow.attributes.email;
        this.address = dataRow.attributes.address;
        this.image = dataRow.attributes.image?.data || null;
        this.imageId = dataRow.attributes.image?.data?.[0]?.id || null;
        this.imageUrl = dataRow.attributes.image?.data?.[0]? ApiService.svrUrl+dataRow.attributes.image.data[0].attributes.url : '';

    } 

    public static endPoint: string = 'owners' 
    public static endPointMedia: string = 'api::owner.owner';
    public static endPointFilter2 = ()=>{
        const owner = OwnerModel.getOwner();
        return ( '?populate=*&filters[id]='+owner)
    } 
    public static url: string = '/owner';
    public static caption: string = 'Owner';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active',
        phone: '',
        email: null,
        address: '',
        image: null,
        imageId: null,
        imageUrl: '',
  
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'description', caption: 'Description' },
        { name: 'status', caption: 'State', type: 'state', default: 'active' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'description', caption: 'Description' },
    ]

    public static setOwner(id: string, caption: string) {
        localStorage.setItem('owner',id);
        localStorage.setItem('ownerCaption',caption);
    }

    public static getOwner() {
        const owner = localStorage.getItem('owner');
        return owner==='null'? null : owner;
    }

    public static getOwnerCaption() {
        return localStorage.getItem('ownerCaption') || '';
    }
}