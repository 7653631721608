import React from 'react';
import { History } from 'history';
import { Input, InputDate, InputImage, InputMultiline, InputNumber, InputSelect, Layout, Picklist } from '../../components';
import BaseEdit from '../base/base-edit.component'; 
import Model from './evaluation-model';  //editable..
import MedialibraryModel from '../medialibrary/medialibrary-model';
import OwnerPicklist from '../owner/owner-picklist.component';
import EvaluationPicklist from '../evaluation/evaluation-picklist.component';
import TransactionPicklist from '../transaction/transaction-picklist.component';
import UserChecklist from '../user/user-checklist.component';
import TestingtoolChecklist from '../testingtool/testingtool-checklist.component';
import EvaluationList from './evaluation-list.component';
import EvaluationdetailList from '../evaluationdetail/evaluationdetail-list.component';
import AppService from '../../services/app.service';
import UserUpload from '../user/user-upload.component';
import { InputUpload } from '../../components/inputupload';

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string },
    };
    root?: string;
}

interface IState {
    dataRow: Model;
    dataSetUser: Array<any>;
    dataError: any;
    dataRowMedia: any;
    hasParent: boolean;
}

export default class EvaluationEdit extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataSetUser: [],
            dataError: null,
            dataRowMedia: null,
            hasParent: false,
        }
        this.onFieldValueChange = this.onFieldValueChange.bind(this);
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data, hasParent: data.parent? false:true});
    }

    private getDataRowOwner = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'owner' : data?.id || null, 'ownerCaption' : data?.name || ''  }  });
    };

    private getDataRowParent = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'parent' : data?.id || null, 'parentCaption' : data?.name || ''  }  });
    };

    private getDataRowTransaction = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'transaction' : data?.id || null, 'transactionCaption' : data?.name || ''  }  });
    };
    
    private getDataRowTestingtool = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'testingtool' : data|| null}  });
    };
    
    private getDataRowUser = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'user' : data|| null}  });
    };

    private getDataRowEvaluationTotal = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'totalvalue' : data?.total || 0, 'totalvalueadj' : data?.totaladj || 0 } });
    };


    private getDataSetUserUpload = (data: any) => {
        this.setState({ dataSetUser: data });
    };

    private getDataSetUser = (data: any) => {
        if (data) console.log(data)
        //this.setState({ dataSetUser: data });
    };

    private getFiles = (data:any) => {
        this.setState({dataRowMedia: {...this.state.dataRowMedia, endPoint: MedialibraryModel.endPoint, endPointDelete: MedialibraryModel.endPoint2, dataRow:{id:this.state.dataRow.imageId, files: data, ref: Model.endPointMedia, field:'image'}}});
    }
    
    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onFieldValueChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    render() {
        return (
            //editable..
            <BaseEdit history={this.props.history} match={this.props.match} caption={'Edit '+ Model.caption} 
                model={Model} dataRow={this.state.dataRow} dataRowMedia={this.state.dataRowMedia} getDataRow={this.getDataRow} getDataError={this.getDataError} disableCard>  
                
                <Layout content={[
                    {caption: 'Details', child: 
                    <>
                        <Input name='name' label='Name' value={this.state.dataRow.name} error={this.state.dataError?.name} onChange={this.onFieldValueChange} />
                        <InputMultiline name='description' label='Description' value={this.state.dataRow.description} error={this.state.dataError?.description} onChange={this.onFieldValueChange} />
                        <InputSelect name='status' label='Status' value={this.state.dataRow.status} options={Model.optionStatus} error={this.state.dataError?.status} onChange={this.onFieldValueChange} />
                        {AppService.isAppFullVersion() &&
                        <Picklist name='owner' label='Owner' value={this.state.dataRow.owner?.toString()} caption={this.state.dataRow.ownerCaption} error={this.state.dataError?.owner} onChange={this.onFieldValueChange} >
                            <OwnerPicklist currentId={this.state.dataRow.owner?.toString()} getDataRow={this.getDataRowOwner}/>
                        </Picklist> 
                        } 
                        <Picklist name='parent' label='Parent' value={this.state.dataRow.parent?.toString()} caption={this.state.dataRow.parentCaption} clearValue={this.getDataRowParent} error={this.state.dataError?.parent} onChange={this.onFieldValueChange} >
                            <EvaluationPicklist currentId={this.state.dataRow.parent?.toString()} getDataRow={this.getDataRowParent}/>
                        </Picklist>
                        <Picklist name='transaction' label='Transaction' value={this.state.dataRow.transaction?.toString()} caption={this.state.dataRow.transactionCaption} error={this.state.dataError?.transaction} onChange={this.onFieldValueChange} >
                            <TransactionPicklist currentId={this.state.dataRow.transaction?.toString()} getDataRow={this.getDataRowTransaction}/>
                        </Picklist>
                        <Picklist name='testingtool' label='Testing Tool' value={this.state.dataRow.testingtool?.toString()} caption={this.state.dataRow.testingtoolCaption} disableAutoClose clearValue={this.getDataRowTestingtool} error={this.state.dataError?.testingtool} onChange={this.onFieldValueChange} readOnly={!this.state.hasParent}>
                            <TestingtoolChecklist currentId={this.state.dataRow.testingtool} getDataRow={this.getDataRowTestingtool} disableBackButton/>
                        </Picklist> 
                        <Picklist name='user' label='Participant' value={this.state.dataRow.user?.toString()} caption={this.state.dataRow.userCaption} disableAutoClose clearValue={this.getDataRowUser} error={this.state.dataError?.user} onChange={this.onFieldValueChange}  readOnly={!this.state.hasParent}>
                            <UserChecklist caption='Select Participant' currentId={this.state.dataRow.user} getDataRow={this.getDataRowUser} disableBackButton/>
                        </Picklist> 
                        <InputUpload name='userupload' label='Upload Participant (CSV)' outFormat='json' getDataSet={this.getDataSetUserUpload} >
                            <UserUpload caption='Upload Participant (CSV)' getDataSet={this.getDataSetUser} dataSet={this.state.dataSetUser} disableBackButton disableCard/>
                        </InputUpload>
                        <InputDate name='startdate' label='Start Date' value={this.state.dataRow.startdate} clearValue error={this.state.dataError?.startdate} onChange={this.onFieldValueChange} />
                        <InputDate name='enddate' label='End Date' value={this.state.dataRow.enddate} clearValue error={this.state.dataError?.enddate} onChange={this.onFieldValueChange} />
                        <InputImage name='imageUrl' label='Image' value={this.state.dataRow.imageUrl} getFiles={this.getFiles} error={this.state.dataError?.image} onChange={this.onFieldValueChange} />
                    </>
                    },

                    {caption: this.state.dataRow.evaluationLength>0? 'Sheets' : 'Questions', child: 
                    this.state.dataRow.id &&
                    <>
                        {this.state.dataRow.evaluationLength>0?
                            <EvaluationList history={this.props.history} root={this.props.root} caption='Evaluation Sheet' parentId={this.state.dataRow.id?.toString()} getDataRow={this.getDataRowEvaluationTotal} disableBackButton disableCard/>
                            :<EvaluationdetailList history={this.props.history} root={this.props.root} caption='Evaluation Question' evaluationId={this.state.dataRow.id?.toString()} getDataRow={this.getDataRowEvaluationTotal} actions={['view']} disableBackButton disableCard/>
                        }
                        <InputNumber name='totalvalue' label='Total Value' value={this.state.dataRow.totalvalue} error={this.state.dataError?.totalvalue} onChange={this.onFieldValueChange} />
                        <InputNumber name='totalvalueadj' label='Value Adjustment' value={this.state.dataRow.totalvalueadj} error={this.state.dataError?.totalvalueadj} onChange={this.onFieldValueChange} />
                            
                    </>
                    },
                    
                ]}
                />            
            
            </BaseEdit>
        )
    }
}