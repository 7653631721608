import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import PageList from './page-list.component';
import PageCreate from './page-create.component';
import PageView from './page-view.component';
import PageEdit from './page-edit.component';
import PageCopy from './page-copy.component';
import PageDelete from './page-delete.component';
import PagePreview from './page-preview.component';

interface Props {
  root?: string;
}

export const PageRoute: React.FunctionComponent<Props> = (props) => {
  return (
    <Switch>
      <Route path={(props.root||'')+'/page/create'} component={PageCreate} />
      <Route path={`${props.root||''}/page/view/:id`} render={(params)=> <PageView {...params} root={props.root} key={params.match.params.id} />} />        
      <Route path={`${props.root||''}/page/edit/:id`} render={(params)=> <PageEdit {...params} root={props.root} key={params.match.params.id} />} />        
      <Route path={(props.root||'')+'/page/copy/:id'} component={PageCopy} />
      <Route path={`${props.root||''}/page/delete/:id`} render={(params)=> <PageDelete {...params} root={props.root} key={params.match.params.id} />} />        
      <Route path={`${props.root||''}/page/preview/:id/:dataId`} render={(params)=> <PagePreview {...params} root={props.root} key={params.match.params.id} />} />        
      <Route path={(props.root||'')+'/page'} render={(params)=> <PageList {...params} root={props.root} />} /> 
    </Switch>
  );
};
