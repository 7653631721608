import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import CoagroupList from './coagroup-list.component';
import CoagroupCreate from './coagroup-create.component';
import CoagroupView from './coagroup-view.component';
import CoagroupEdit from './coagroup-edit.component';
import CoagroupCopy from './coagroup-copy.component';
import CoagroupDelete from './coagroup-delete.component';

interface Props {
  root?: string;
}

export const CoagroupRoute: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>
        <Route path={(props.root||'')+'/coagroup/create'} component={CoagroupCreate} />
        <Route path={(props.root||'')+'/coagroup/view/:id'} component={CoagroupView} />
        <Route path={(props.root||'')+'/coagroup/edit/:id'} component={CoagroupEdit} />
        <Route path={(props.root||'')+'/coagroup/copy/:id'} component={CoagroupCopy} />
        <Route path={(props.root||'')+'/coagroup/delete/:id'} component={CoagroupDelete} />
        <Route path={(props.root||'')+'/coagroup'} render={(params)=> <CoagroupList {...params} root={props.root} />} /> 
    </Switch>
  );
};
