import React from 'react';
import { History} from 'history';
import { Redirect } from 'react-router-dom';
import { AddressRoute } from '../address/address-route';
import { AmounttypeRoute } from '../amounttype/amounttype-route';
import { AppRoute } from '../app/app-route';
import { CivilnumberRoute } from '../civilnumber/civilnumber-route';
import { CoabalanceRoute } from '../coabalance/coabalance-route';
import { CoagroupRoute } from '../coagroup/coagroup-route';
import { CoaRoute } from '../coa/coa-route';
import { EvaluationdetailRoute } from '../evaluationdetail/evaluationdetail-route';
import { EvaluationRoute } from '../evaluation/evaluation-route';
import { JournalgroupdetailRoute } from '../journalgroupdetail/journalgroupdetail-route';
import { JournalgroupRoute } from '../journalgroup/journalgroup-route';
import { JournalRoute } from '../journal/journal-route';
import { MenugroupRoute } from '../menugroup/menugroup-route';
import { MenuRoute } from '../menu/menu-route';
import { MessageRoute } from '../message/message-route';
import { OwnerRoute } from '../owner/owner-route';
import { PageRoute } from '../page/page-route';
import { PaymenttypeRoute } from '../paymenttype/paymenttype-route';
import { PersonalinfoRoute } from '../personalinfo/personalinfo-route';
import { PersonalinfoRouteCustomer } from '../personalinfo/personalinfo-route-customer';
import { PersonalinfoRouteEmployee } from '../personalinfo/personalinfo-route-employee';
import { PersonalinfoRouteVendor } from '../personalinfo/personalinfo-route-vendor';
import { PersonalinfotypeRoute } from '../personalinfotype/personalinfotype-route';
import { PersonalinfotypeRouteCustomer } from '../personalinfotype/personalinfotype-route-customer';
import { PersonalinfotypeRouteEmployee } from '../personalinfotype/personalinfotype-route-employee';
import { PersonalinfotypeRouteVendor } from '../personalinfotype/personalinfotype-route-vendor';
import { ProductRoute } from '../product/product-route';
import { ProducttypeRoute } from '../producttype/producttype-route';
import { QuestionoptionRoute } from '../questionoption/questionoption-route';
import { QuestionRoute } from '../question/question-route';
import { RegionRoute } from '../region/region-route';
import { SectionRoute } from '../section/section-route';
import { TestingtoolresumeRoute } from '../testingtoolresume/testingtoolresume-route';
import { TestingtoolRoute } from '../testingtool/testingtool-route';
import { TransactionamountRoute } from '../transactionamount/transactionamount-route';
import { TransactiondetailRoute } from '../transactiondetail/transactiondetail-route';
import { TransactionpaymentRoute } from '../transactionpayment/transactionpayment-route';
import { TransactionRoute } from '../transaction/transaction-route';
import { TransactionRouteApproved } from '../transaction/transaction-route-approved';
import { TransactionRouteGeneral } from '../transaction/transaction-route-general';
import { TransactionRouteJournal } from '../transaction/transaction-route-journal';
import { TransactionRoutePurchase } from '../transaction/transaction-route-purchase';
import { TransactionRouteSales } from '../transaction/transaction-route-sales';
import { TransactiontypeRoute } from '../transactiontype/transactiontype-route';
import { UserRoute } from '../user/user-route';
import UserModel from '../user/user-model';
import MenuModel from '../menu/menu-model';
import BaseView from '../base/base-view.component';

interface IProps {  
    history: History;
    root?: string;
 }

interface IState { 
    dataRow: MenuModel;
 }

export default class ApplayoutRoutes extends React.Component<IProps, IState> { 

    constructor(props: IProps) { 
        super(props);
        this.state = { 
            dataRow: MenuModel.defaultValue(),
         }
     }

    private getDataRowMenu = (data:any) => { 
        this.setState({dataRow:  data});
     }

    private getMenuByPath = (path: string) => { 
        return (
            <BaseView history={ this.props.history } match={ { isExact:true, params: { id: path } } } caption=' ' 
                endPointFilter={ MenuModel.endPointFilter2()+'&filters[path]=' } model={ MenuModel } getDataRow={ this.getDataRowMenu } disableBackButton disableCard>              
            </BaseView>
        )
    }
    
    public componentDidUpdate() {
        var path =  '/'+this.props.history.location.pathname.replace(this.props.root || '', '').split('/')[1]+'';
        
        if (this.props.root && (path !== this.state.dataRow.path)) 
            this.getMenuByPath(path)
    }

    render() { 
        return (
            //editable..
            <>
                {UserModel.isSignin() && this.state.dataRow?
                    <>
                    <AddressRoute root={ this.props.root }/>
                    <AmounttypeRoute root={ this.props.root }/> 
                    <AppRoute root={ this.props.root }/>
                    <CivilnumberRoute root={ this.props.root }/>
                    <CoabalanceRoute root={ this.props.root }/>
                    <CoagroupRoute root={ this.props.root }/>
                    <CoaRoute root={ this.props.root }/>
                    <EvaluationdetailRoute root={ this.props.root }/> 
                    <EvaluationRoute root={ this.props.root }/> 
                    <JournalgroupdetailRoute root={ this.props.root }/>
                    <JournalgroupRoute root={ this.props.root }/>
                    <JournalRoute root={ this.props.root }/>
                    <MenugroupRoute root={ this.props.root }/>
                    <MenuRoute root={ this.props.root }/>
                    <MessageRoute root={ this.props.root }/> 
                    <OwnerRoute root={ this.props.root }/>  
                    <PageRoute root={ this.props.root }/> 
                    <PaymenttypeRoute root={ this.props.root }/>
                    <PersonalinfoRoute root={ this.props.root }/> 
                    <PersonalinfoRouteCustomer root={ this.props.root }/> 
                    <PersonalinfoRouteEmployee root={ this.props.root }/> 
                    <PersonalinfoRouteVendor root={ this.props.root }/> 
                    <PersonalinfotypeRoute root={ this.props.root }/> 
                    <PersonalinfotypeRouteCustomer root={ this.props.root }/> 
                    <PersonalinfotypeRouteEmployee root={ this.props.root }/> 
                    <PersonalinfotypeRouteVendor root={ this.props.root }/> 
                    <ProductRoute root={ this.props.root }/> 
                    <ProducttypeRoute root={ this.props.root }/> 
                    <QuestionoptionRoute root={ this.props.root }/> 
                    <QuestionRoute root={ this.props.root }/> 
                    <RegionRoute root={ this.props.root }/>
                    <SectionRoute root={ this.props.root }/> 
                    <TestingtoolresumeRoute root={ this.props.root }/> 
                    <TestingtoolRoute root={ this.props.root }/> 
                    <TransactionamountRoute root={ this.props.root }/> 
                    <TransactiondetailRoute root={ this.props.root }/> 
                    <TransactionpaymentRoute root={ this.props.root }/> 
                    <TransactionRoute root={ this.props.root }/> 
                    <TransactionRouteApproved root={ this.props.root }/> 
                    <TransactionRouteGeneral root={ this.props.root }/> 
                    <TransactionRouteJournal root={ this.props.root }/> 
                    <TransactionRoutePurchase root={ this.props.root }/> 
                    <TransactionRouteSales root={ this.props.root }/> 
                    <TransactiontypeRoute root={ this.props.root }/> 
                    <UserRoute root={ this.props.root }/> 
                    </>
                : <Redirect to={ { pathname: this.props.root } } /> 
                }
                
            </>
        );
     }
 }