import ApiService from "../../services/api.service";

export default class QuestionoptionModel {
    id?: number;
    name: string;
    description: string;
    status: string;
    question: any;
    questionCaption: string;
    title: string;
    content: string;
    image: any;
    imageId: any;
    imageUrl: string;
    seq: number;
    answer: string;
    value: number;
    testingtoolresume: any;
    testingtoolresumeCaption: string;

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.question =  dataRow.attributes.question.data?.id || null;
        this.questionCaption =  '';
        this.title = dataRow.attributes.title;
        this.content = dataRow.attributes.content;
        this.image = dataRow.attributes.image?.data || null;
        this.imageId = dataRow.attributes.image?.data?.[0]?.id || null;
        this.imageUrl = dataRow.attributes.image?.data?.[0]? ApiService.svrUrl+dataRow.attributes.image.data[0].attributes.url : '';
        this.seq = dataRow.attributes.seq;
        this.answer = dataRow.attributes.answer;
        this.value = dataRow.attributes.value;
        this.testingtoolresume =  dataRow.attributes.testingtoolresume.data || null;
        this.testingtoolresumeCaption =  dataRow.attributes.testingtoolresume.data?.[0]? dataRow.attributes.testingtoolresume.data?.[0]?.attributes.name+' ...' : '';
        
    } 

    public static endPoint: string = 'question-options';
    public static endPointMedia: string = 'api::question-option.question-option';
    public static endPointFilter: string = '?populate=*&filters[question]=';
    public static url: string = '/questionoption';
    public static caption: string = 'Question Option';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active',
        question: null,
        questionCaption: '',
        title: '',
        content: '',
        image: null,
        imageId: null,
        imageUrl: '',
        seq: 0,
        answer: '',
        value: 0,
        testingtoolresume: [],
        testingtoolresumeCaption: '',   
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'description', caption: 'Description' },
        { name: 'status', caption: 'State', type: 'state', default: 'active' },
    ]

    public static columnPicklist = [
        { name: 'title', caption: '', type: 'caption' },
        { name: 'content', caption: '' },
        { name: 'imageUrl', caption: '', type:'media' },
    ]
}