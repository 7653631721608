import React from 'react';
import { History } from 'history';
import { Input, InputDate, InputMultiline, InputSelect, Picklist } from '../../components';
import BaseCopy from '../base/base-copy.component'; 
import Model from './transaction-model';  //editable..
import OwnerPicklist from '../owner/owner-picklist.component';
import TransactiontypePicklist from '../transactiontype/transactiontype-picklist.component';
import TransactionPicklist from '../transaction/transaction-picklist.component';
import PersonalinfoPicklist from '../personalinfo/personalinfo-picklist.component';
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string },
    };
    root?: string;
    caption?: string;
    type?: string;
    disableFields?: any;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class TransactionCopy extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        this.onFieldValueChange = this.onFieldValueChange.bind(this);
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    private getDataRowOwner = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'owner' : data?.id || null, 'ownerCaption' : data?.name || ''  }  });
    };
    
    private getDataRowTransactiontype = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'transactiontype' : data?.id || null, 'transactiontypeCaption' : data?.name || ''  }  });
    };
    
    private getDataRowCustomer = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'customer' : data?.id || null, 'customerCaption' : data?.name || ''  }  });
    };
    
    private getDataRowEmployee = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'employee' : data?.id || null, 'employeeCaption' : data?.name || ''  }  });
    };
    
    private getDataRowVendor = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'vendor' : data?.id || null, 'vendorCaption' : data?.name || ''  }  });
    };
    
    private getDataRowTransactionref = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'transactionref' : data?.id || null, 'transactionrefCaption' : data?.name || ''  }  });
    };

    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onFieldValueChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    render() {
        return (
            //editable..
            <BaseCopy history={this.props.history} match={this.props.match} caption={this.props.caption || 'Duplicate '+ Model.caption} 
                model={Model} dataRow={this.state.dataRow} getDataRow={this.getDataRow} getDataError={this.getDataError}>              
                
                <Input name='name' label='Name' value={this.state.dataRow.name} error={this.state.dataError?.name} onChange={this.onFieldValueChange} />
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} error={this.state.dataError?.description} onChange={this.onFieldValueChange} />
                <InputSelect name='status' label='Status' value={this.state.dataRow.status} options={Model.optionStatus} error={this.state.dataError?.status} onChange={this.onFieldValueChange} />
                {!this.props.disableFields?.includes('owner') && AppService.isAppFullVersion() &&
                <Picklist name='owner' label='Owner' value={this.state.dataRow.owner?.toString()} caption={this.state.dataRow.ownerCaption} error={this.state.dataError?.owner} onChange={this.onFieldValueChange} >
                    <OwnerPicklist currentId={this.state.dataRow.owner?.toString()} getDataRow={this.getDataRowOwner}/>
                </Picklist>
                }
                {!this.props.disableFields?.includes('transactionType') &&
                <Picklist name='transactiontype' label='Transaction Type' value={this.state.dataRow.transactiontype?.toString()} caption={this.state.dataRow.transactiontypeCaption} error={this.state.dataError?.transactiontype} onChange={this.onFieldValueChange} >
                    <TransactiontypePicklist type={this.props.type} currentId={this.state.dataRow.transactiontype?.toString()} getDataRow={this.getDataRowTransactiontype}/>
                </Picklist>
                }
                <Picklist name='transactionref' label='Transaction Ref' value={this.state.dataRow.transactionref?.toString()} caption={this.state.dataRow.transactionrefCaption} error={this.state.dataError?.transactionref} onChange={this.onFieldValueChange} >
                    <TransactionPicklist currentId={this.state.dataRow.transactionref?.toString()} getDataRow={this.getDataRowTransactionref}/>
                </Picklist>
                <InputDate name='transactiondate' label='Date' value={this.state.dataRow.transactiondate} error={this.state.dataError?.transactiondate} onChange={this.onFieldValueChange} />
                {!this.props.disableFields?.includes('customer') &&
                <Picklist name='customer' label='Customer' value={this.state.dataRow.customer?.toString()} caption={this.state.dataRow.customerCaption} clearValue={this.getDataRowCustomer} error={this.state.dataError?.customer} onChange={this.onFieldValueChange} >
                    <PersonalinfoPicklist type='customer' currentId={this.state.dataRow.customer?.toString()} getDataRow={this.getDataRowCustomer}/>
                </Picklist>
                } 
                {!this.props.disableFields?.includes('vendor') &&
                <Picklist name='vendor' label='Vendor' value={this.state.dataRow.vendor?.toString()} caption={this.state.dataRow.vendorCaption} clearValue={this.getDataRowVendor} error={this.state.dataError?.vendor} onChange={this.onFieldValueChange} >
                    <PersonalinfoPicklist type='vendor' currentId={this.state.dataRow.vendor?.toString()} getDataRow={this.getDataRowVendor}/>
                </Picklist>
                }  
                {!this.props.disableFields?.includes('employee') &&
                <Picklist name='employee' label='Employee' value={this.state.dataRow.employee?.toString()} caption={this.state.dataRow.employeeCaption} clearValue={this.getDataRowEmployee} error={this.state.dataError?.employee} onChange={this.onFieldValueChange} >
                    <PersonalinfoPicklist type='employee' currentId={this.state.dataRow.employee?.toString()} getDataRow={this.getDataRowEmployee}/>
                </Picklist>
                } 
            </BaseCopy>
        );
    }
}