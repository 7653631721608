import * as React from 'react';
import Model from './question-model';
import BasePicklist from '../base/base-picklist.component';

interface IProps { 
  testingtoolId: string;
  page?: number;
  pageSize?: number;
  getDataRow: (data: any) => void;
  autoSelect?: boolean;
  disableColumnHeader?: boolean;
}

interface IState {
}

export default class QuestionPicklist extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }



  public render(): React.ReactNode {
    return (
      <BasePicklist caption={'Select '+Model.caption+'s'} endPointFilter={Model.endPointFilter+this.props.testingtoolId}  model={Model} page={this.props.page} pageSize={this.props.pageSize} getDataRow={this.props.getDataRow}  autoSelect={this.props.autoSelect}  disableColumnHeader={this.props.disableColumnHeader}/>
    );
  }
}
