import React from 'react';
import { History} from 'history';
import { Box, Grid } from "@mui/material";
import '../../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import 'toastr/build/toastr.min.css';
import { FormHeader, SideBar } from '../../../components';
import UserModel from '../../user/user-model';
import MenuPicklistcard from '../../menu/menu-picklistcard.component';
import LogoApp from './logoapp.component';
import AppService from '../../../services/app.service';
import ApplayoutRoutes from '../applayout-routes';

interface IProps { 
    history: History;
    root?: string;
}

interface IState {
}

export default class Dashboard extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
        }
    }


    render() {
        const isMobile = AppService.isMobile();
        const isRootPath = this.props.history.location.pathname.replaceAll('/','')===this.props.root?.replaceAll('/','');
                
        return (
            //editable..
            <>
            {isMobile && !isRootPath? '' :<Box position='fixed' height={16} width='100%' bgcolor='secondary.light'/>}
            <Grid container pt={2}>
                {isMobile && !isRootPath? '' :
                <Grid item width={400}>
                    <SideBar logo={<LogoApp/>}>
                        {isMobile && isRootPath && <FormHeader history={this.props.history} label={'Hai '+UserModel.getUserCaption()}/>}
                        <MenuPicklistcard  history={this.props.history} caption=' ' cardNumber={12} root={this.props.root}/>
                    </SideBar>
                </Grid>
                }
                <Grid item xs={12} md={8} p={1}>
                    {isRootPath && <FormHeader history={this.props.history} label={'Hai '+UserModel.getUserCaption()}/>}
                    <ApplayoutRoutes history={this.props.history} root={this.props.root} />
                </Grid>
            </Grid>
            </>
        );
    }
}