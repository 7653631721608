import { createTheme } from '@mui/material/styles';

const themeDefault = createTheme({
  palette: {
    primary: {
      light: "#1769aa",
      main: "#2196f3",
      dark: "#4dabf5",
      contrastText: "#fff",
    },
    secondary: {
      main: "#bbdefb",
      light: "#c8e4fb",
      dark: "#e8f4fd",
      contrastText: "#fff",
    },
    info: {
      main: "#0e4686",
      light: "#1565c0",
      dark: "#4383cc",
      contrastText: "#fff",
    },
    success: {
      main: "#205723",
      light: "#2e7d32",
      dark: "#57975b",
      contrastText: "#fff",
    },
    warning: {
      main: "#a74b00", 
      light: "#ef6c00", 
      dark: "#f28933", 
      contrastText: "#fff",
    },
    background: {
      default: "#2196f3"
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        action: {
          active: "#2196f3",
          hover: "#4dabf5",
          hoverOpacity: 0.7,
          focus: "#2196f3",
          focusOpacity: 1,
          selected: "#4dabf5",
          selectedOpacity: 1
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 28,
          padding: 10,
          paddingLeft: 20,
          paddingRight: 20,
        },
      }, 
    },
  },
});

const themeBrown = createTheme({
  palette: {
    primary: {
      light: "#b28704", 
      main: "#ffc107", 
      dark: "#ffcd38", 
      contrastText: "#fff",
    },
    secondary: {
      main: "#ffd54f", 
      light: "#ffdd72", 
      dark: "#ffecb3", 
      contrastText: "#fff",
    },
    info: {
      main: "#ae7519",
      light: "#f9a825",
      dark: "#fab950",
      contrastText: "#fff",
    },
    success: {
      main: "#205723",
      light: "#2e7d32",
      dark: "#57975b",
      contrastText: "#fff",
    },
    warning: {
      main: "#a74b00", 
      light: "#ef6c00", 
      dark: "#f28933", 
      contrastText: "#fff",
    },
    background: {
      default: 'whitesmoke'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        action: {
          active: "#b28704",
          hover: "#ffcd38",
          hoverOpacity: 0.7,
          focus: "#b28704",
          focusOpacity: 1,
          selected: "#ffcd38",
          selectedOpacity: 1
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 28,
          padding: 10,
          paddingLeft: 20,
          paddingRight: 20,
        },
      }, 
    },
  },
});

const themeGreen = createTheme({
  
  palette: {
    primary: {
      light: "#357a38", 
      main: "#357a38", 
      dark: "#6fbf73", 
      contrastText: "#fff",
    },
    secondary: {
      main: "#9aa58c", 
      light: "#dcedc8", 
      dark: "#e3f0d3", 
      contrastText: "#fff",
    },
    info: {
      main: "#aeea00",
      light: "#beee33",
      dark: "#79a300",
      contrastText: "#000",
    },
    success: {
      main: "#205723", 
      light: "#2e7d32", 
      dark: "#57975b", 
      contrastText: "#fff",
    },
    warning: {
      main: "#a74b00", 
      light: "#ef6c00", 
      dark: "#f28933", 
      contrastText: "#fff",
    },
    background: {
      default: 'whitesmoke'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        action: {
         active: "#357a38",
          hover: "#6fbf73",
          hoverOpacity: 0.7,
          focus: "#357a38",
          focusOpacity: 1,
          selected: "#6fbf73",
          selectedOpacity: 1
        }
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 28,
          padding: 10,
          paddingLeft: 20,
          paddingRight: 20,
        },
      }, 
    },
  },
});
  
function AppThemes(theme?: string) {
    switch (theme) {
    case 'brown': return themeBrown; 
    case 'green': return themeGreen;
    default: return themeDefault;
  }

}

export default AppThemes;
