import * as React from 'react';
import { History } from 'history';
import Model from './region-model';
import BaseList from '../base/base-list.component';

interface IProps {  
  history: History;
  root?: string;
  caption?: string;
  parentId?: string;
  actions?: any;
  disableBackButton?: boolean;
  disableCard?: boolean;
}

interface IState {
}

export default class RegionList extends React.Component<IProps, IState> {
  public render(): React.ReactNode {
    return (
      <BaseList history={this.props.history} parentId={this.props.parentId} caption={(this.props.caption || Model.caption)+'s'} endPointFilter={this.props.parentId? Model.endPointFilter2+this.props.parentId : Model.endPointFilter} url={(this.props.root||'')+Model.url} model={Model} actions={this.props.actions} disableBackButton={this.props.disableBackButton} disableCard={this.props.disableCard}/>
    );
  }
}
