import React from 'react';
import { History } from 'history';
import { Input, InputMultiline, InputDate, InputImage, Switch, Picklist } from '../../components';
import BaseEdit from '../base/base-edit.component'; 
import Model from './app-model';  //editable..
import MedialibraryModel from '../medialibrary/medialibrary-model';
import OwnerPicklist from '../owner/owner-picklist.component';
import ProductPicklist from '../product/product-picklist.component';
import MenugroupChecklist from '../menugroup/menugroup-checklist.component';
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string },
    };
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowMedia: any;
}

export default class AppEdit extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            dataRowMedia: null,
        }
        this.onFieldValueChange = this.onFieldValueChange.bind(this);
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }
    
    private getDataRowOwner = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'owner' : data?.id || null, 'ownerCaption' : data?.name || ''  }  });
    };

    private getDataRowProduct = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'product' : data?.id || null, 'productCaption' : data?.name || ''  }  });
    };
      
    private getDataRowMenugroup = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'menugroup' : data || null}  });
    };

    private getFiles = (data:any) => {
        this.setState({dataRowMedia: {...this.state.dataRowMedia, endPoint: MedialibraryModel.endPoint, endPointDelete: MedialibraryModel.endPoint2, dataRow:{id:this.state.dataRow.imageId, files: data, ref: Model.endPointMedia, field:'image'}}});
    }
    
    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onFieldValueChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    render() {
        return (
            //editable..
            <BaseEdit history={this.props.history} match={this.props.match} caption={'Edit '+ Model.caption} 
                model={Model} dataRow={this.state.dataRow} dataRowMedia={this.state.dataRowMedia} getDataRow={this.getDataRow} getDataError={this.getDataError}>              
                
                <Input name='name' label='Name' value={this.state.dataRow.name} error={this.state.dataError?.name} onChange={this.onFieldValueChange} />
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} error={this.state.dataError?.description} onChange={this.onFieldValueChange} />
                <Switch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' error={this.state.dataError?.status} onChange={this.onFieldValueChange}/>
                {AppService.isAppFullVersion() &&
                <Picklist name='owner' label='Owner' value={this.state.dataRow.owner?.toString()} caption={this.state.dataRow.ownerCaption} error={this.state.dataError?.owner} onChange={this.onFieldValueChange} >
                    <OwnerPicklist currentId={this.state.dataRow.owner?.toString()} getDataRow={this.getDataRowOwner}/>
                </Picklist>
                } 
                <Picklist name='product' label='Product' value={this.state.dataRow.product?.toString()} caption={this.state.dataRow.productCaption} error={this.state.dataError?.product} onChange={this.onFieldValueChange} >
                    <ProductPicklist currentId={this.state.dataRow.product?.toString()} getDataRow={this.getDataRowProduct}/>
                </Picklist>                  
                <Input name='productkey' label='Product Key' value={this.state.dataRow.productkey} onChange={this.onFieldValueChange} />
                <InputDate name='expireddate' label='Expired Date' value={this.state.dataRow.expireddate} error={this.state.dataError?.expireddate} onChange={this.onFieldValueChange} />
                <Picklist name='menugroup' label='Menu Group' value={this.state.dataRow.menugroup?.toString()} caption={this.state.dataRow.menugroupCaption} disableAutoClose clearValue={this.getDataRowMenugroup} error={this.state.dataError?.menugroup} onChange={this.onFieldValueChange} >
                    <MenugroupChecklist history={this.props.history} currentId={this.state.dataRow.menugroup} getDataRow={this.getDataRowMenugroup} disableBackButton/>
                </Picklist>     
                <InputImage name='imageUrl' label='Logo' value={this.state.dataRow.imageUrl} getFiles={this.getFiles} error={this.state.dataError?.image} onChange={this.onFieldValueChange} />
                             
            </BaseEdit>
        );
    }
}