import React, {Suspense} from 'react';
import { History } from 'history';
import {Grid, Typography } from '@mui/material';
import { ScreenGuard, TextToHtml } from '../../components';
import BasePreview from '../base/base-preview.component';
import Model from './page-model';  //editable..
import ApiService from '../../services/api.service';
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string, dataId:string  },
    };
    root?: string;
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowResult: any;
    fileOptions: any;
}

export default class PagePreview extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            dataRowResult: null,
            fileOptions: {
                fileName: 'result',
                fileType: 'a4',
                properties : {
                    title: 'Evaluation Result',
                    subject: 'Evaluation Result',
                    author: document.title,
                    keywords: 'psychogram, psychotest, psikogram, psikotes',
                    creator: document.title,
                },
                margin: 20,
                marginAdj: [{pageNumber: 10, value: -25}, {pageNumber: 13, value: -108}, {pageNumber: 14, value: -55}, {pageNumber: 15, value: -50}, {pageNumber: 17, value: 55}, {pageNumber: 19, value: -72}],
                headerText: {
                    start: 0,
                    left: '',
                    center: '',
                    right: ''
                },
                footerText: {
                    start: 2,
                    left: 'Confidential Report',
                    center: 'Page $pagenumber',
                    right: ''
                }
            },
        }
        
    }

    private isMobile: boolean = AppService.isMobile();
    private isReady: boolean = false;
    private htmlId =  'section';

    private getDataRow = (data:any) => {
        this.setState({ dataRow: data });
    }

    private getDataRowResult = (data:any) => {
        this.setState({ dataRowResult:  data });
        this.setState({ fileOptions: {...this.state.fileOptions, 
            fileName: data.filename, 
            footerText: {...this.state.fileOptions.footerText, right: data.username}
        }});
        this.isReady = true;
    }

    private getResult(url: string){
        const Component = React.lazy(() => import(`../${url.replace(/[<>]/gi,'')}.component`).catch(()=>{return ({default: ()=>{}})} ));
        
        return (
            <Suspense>
                <Component match={{isExact: true, params: { id: this.props.match.params.dataId}}} getDataRow={this.getDataRowResult}></Component>
            </Suspense>
        )
    }

    private displaySection(){
        const sectionStyle : any = {
            general:{ 
                frame: {paddingTop: {xs: 1, md: 2}, paddingBottom: {xs: 1, md: 2}},
                image: {justifyContent:'center', marginTop: {xs: 5, md: 10}, marginBottom: {xs: 5, md: 10}},
                imagetitle: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 1}},
                    typography: {typography: {xs: 'caption', md: 'h6'}, textAlign: 'center'},
                },
                title: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 2}},
                    typography: {typography: {xs: 'subtitle1', md: 'h5'}, textAlign: 'center'},
                },
                content: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 2}},
                    typography: {typography: {xs: 'caption', md: 'h6'}, textAlign: 'justify'},
                },
            },

            cover:{ 
                frame: {paddingTop: {xs: 1, md: 2}, paddingBottom: {xs: 1, md: 2}},
                imagetitle: {
                    frame: {justifyContent:'right'},
                    typography: {typography: {xs: 'body2', md: 'h5'}, textAlign: 'center', color: 'navy', border: {xs: 2, md: 3}, padding: {xs: 1, md: 2}},
                },
                image: {justifyContent:'center', marginTop: {xs: 15, md: 25}, marginBottom: {xs: 15, md: 25}},
                title: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 2}},
                    typography: {typography: {xs: 'subtitle1', md: 'h4'}, textAlign: 'center'},
                },
                content: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 2}},
                    typography: {typography: {xs: 'caption', md: 'h6'}, textAlign: 'center'},
                },
            },

            header:{ 
                frame: {paddingTop: {xs: 1, md: 2}, paddingBottom: {xs: 1, md: 2}},
                image: {justifyContent:'center', marginTop: {xs: 5, md: 10}, marginBottom: {xs: 5, md: 10}},
                imagetitle: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 1}},
                    typography: {typography: {xs: 'caption', md: 'h6'}, textAlign: 'center'},
                },
                title: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 10, md: 20}},
                    typography: {typography: {xs: 'subtitle1', md: 'h5'}, textAlign: 'center'},
                },
                content: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 2}},
                    typography: {typography: {xs: 'caption', md: 'h6'}, textAlign: 'justify'},
                },
            },

            footer:{ 
                frame: {paddingTop: {xs: 1, md: 2}, paddingBottom: {xs: 1, md: 2}},
                image: {justifyContent:'center', marginTop: {xs: 20, md: 40}, marginBottom: {xs: 10, md: 20}},
                imagetitle: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 1}},
                    typography: {typography: {xs: 'caption', md: 'h6'}, textAlign: 'center'},
                },
                title: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 10, md: 20}},
                    typography: {typography: {xs: 'subtitle1', md: 'h5'}, textAlign: 'center'},
                },
                content: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 2}},
                    typography: {typography: {xs: 'caption', md: 'h6'}, textAlign: 'justify'},
                },
            },
        }

        return this.state.dataRow?.section?.map((section: any, i: number)=> {
            var imagetitle = section.attributes.imagetitle;
            var title = section.attributes.title;
            var content = section.attributes.content;

            if (this.state.dataRowResult)
                Object.keys(this.state.dataRowResult).forEach((key)=>
                    content = content.replace('['+key+']', this.state.dataRowResult[key]) 
                );

            return (
                <Grid container key={i} borderTop={i>0? 10 : 0} borderColor='whitesmoke' pl={this.isMobile? 2: 20} pr={this.isMobile? 2: 20}>
                    <Grid id={this.htmlId} item container sx={sectionStyle[section.attributes.type].frame}>
                        {imagetitle &&
                        <Grid item container sx={sectionStyle[section.attributes.type].imagetitle.frame}>
                            <Typography sx={sectionStyle[section.attributes.type].imagetitle.typography}>
                                <b>{imagetitle}</b>
                            </Typography>                
                        </Grid>
                        }
                        {section.attributes.image.data &&
                        <Grid item container sx={sectionStyle[section.attributes.type].image}>
                            <img src={ApiService.svrUrl+section.attributes.image.data[0].attributes.url} width='auto' height={this.isMobile? 100:200} alt={section.attributes.name} />
                        </Grid>
                        }
                        <Grid item container sx={sectionStyle[section.attributes.type].title.frame}>
                            <Typography sx={sectionStyle[section.attributes.type].title.typography}>
                                <b>{title}</b>
                            </Typography>                
                        </Grid>
                        <Grid item container sx={sectionStyle[section.attributes.type].content.frame}>
                            {content.includes('<<','>>')? this.getResult(content)
                            : 
                            <Typography component='div' sx={sectionStyle[section.attributes.type].content.typography} >
                                <TextToHtml content={content}/>        
                            </Typography>
                            }    
                        </Grid>
                    </Grid>
                </Grid>
            )
        })
    }

    render() {
        return (
            //editable..
            <BasePreview history={this.props.history} match={this.props.match} caption={'Preview '+ Model.caption} 
                endPointFilter={Model.endPointFilter2} model={Model} getDataRow={this.getDataRow} pdfOptions={this.state.fileOptions} htmlId={this.htmlId} disableCard>   
                <ScreenGuard/>
                <Grid container bgcolor='white' >
                    {!this.isReady && this.state.dataRow?.datasource?.includes('<<') && this.getResult(this.state.dataRow.datasource)}
                    {this.displaySection()}
                </Grid>
            </BasePreview>
        );
    }
}