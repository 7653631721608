import React from 'react';
import { History } from 'history';
import { Input, InputDate, InputMultiline, InputSelect, Picklist } from '../../components';
import BaseCreate from '../base/base-create.component'; 
import Model from './evaluation-model';  //editable..
import OwnerPicklist from '../owner/owner-picklist.component';
import EvaluationPicklist from '../evaluation/evaluation-picklist.component';
import TransactionPicklist from '../transaction/transaction-picklist.component';
import TestingtoolChecklist from '../testingtool/testingtool-checklist.component';
import UserChecklist from '../user/user-checklist.component';
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class EvaluationCreate extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        this.onFieldValueChange = this.onFieldValueChange.bind(this);
    }
    
    private getDataRowOwner = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'owner' : data?.id || null, 'ownerCaption' : data?.name || ''  }  });
    };

    private getDataRowParent = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'parent' : data?.id || null, 'parentCaption' : data?.name || ''  }  });
    };

    private getDataRowTransaction = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'transaction' : data?.id || null, 'transactionCaption' : data?.name || ''  }  });
    };

    private getDataRowTestingtool = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'testingtool' : data || null}  });
    };
    
    private getDataRowUser = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'user' : data|| null}  });
    };
    
    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onFieldValueChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    render() {
        return (
            //editable..          
            <BaseCreate history={this.props.history} caption={'Create '+ Model.caption} 
                model={Model} dataRow={this.state.dataRow} getDataError={this.getDataError}>               
          
                <Input name='name' label='Name' value={this.state.dataRow.name} error={this.state.dataError?.name} onChange={this.onFieldValueChange} />
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} error={this.state.dataError?.description} onChange={this.onFieldValueChange} />
                <InputSelect name='status' label='Status' value={this.state.dataRow.status} options={Model.optionStatus} error={this.state.dataError?.status} onChange={this.onFieldValueChange} />
                {AppService.isAppFullVersion() &&
                <Picklist name='owner' label='Owner' value={this.state.dataRow.owner?.toString()} caption={this.state.dataRow.ownerCaption} error={this.state.dataError?.owner} onChange={this.onFieldValueChange} >
                    <OwnerPicklist currentId={this.state.dataRow.owner?.toString()} getDataRow={this.getDataRowOwner}/>
                </Picklist>
                } 
                <Picklist name='parent' label='Parent' value={this.state.dataRow.parent?.toString()} caption={this.state.dataRow.parentCaption} clearValue={this.getDataRowParent} error={this.state.dataError?.parent} onChange={this.onFieldValueChange} >
                    <EvaluationPicklist currentId={this.state.dataRow.parent?.toString()} getDataRow={this.getDataRowParent}/>
                </Picklist>
                <Picklist name='transaction' label='Transaction' value={this.state.dataRow.transaction?.toString()} caption={this.state.dataRow.transactionCaption} error={this.state.dataError?.transaction} onChange={this.onFieldValueChange} >
                    <TransactionPicklist currentId={this.state.dataRow.transaction?.toString()} getDataRow={this.getDataRowTransaction}/>
                </Picklist>
                <Picklist name='testingtool' label='Testing Tool' value={this.state.dataRow.testingtool?.toString()} caption={this.state.dataRow.testingtoolCaption} disableAutoClose clearValue={this.getDataRowTestingtool} error={this.state.dataError?.testingtool} onChange={this.onFieldValueChange} >
                    <TestingtoolChecklist history={this.props.history} currentId={this.state.dataRow.testingtool} getDataRow={this.getDataRowTestingtool} disableBackButton/>
                </Picklist> 
                <Picklist name='user' label='Participant' value={this.state.dataRow.user?.toString()} caption={this.state.dataRow.userCaption} disableAutoClose clearValue={this.getDataRowUser} error={this.state.dataError?.user} onChange={this.onFieldValueChange} >
                    <UserChecklist history={this.props.history} currentId={this.state.dataRow.user} getDataRow={this.getDataRowUser} disableBackButton/>
                </Picklist> 
                <InputDate name='startdate' label='Start Date' value={this.state.dataRow.startdate} clearValue error={this.state.dataError?.startdate} onChange={this.onFieldValueChange} />
                <InputDate name='enddate' label='End Date' value={this.state.dataRow.enddate} clearValue error={this.state.dataError?.enddate} onChange={this.onFieldValueChange} />
             
            </BaseCreate>
        );
    }     
     
}