import OwnerModel from '../owner/owner-model';

export default class AmounttypeModel {
    id?: number;
    name: string;
    description: string;
    status: string;
    owner: any;
    ownerCaption: string;
    defaultvalue: number;
    defaultpercentage: number;
    calc: string;
    journalgroup: any;
    journalgroupCaption: string;
    
    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.owner =  dataRow.attributes.owner.data?.id || null;
        this.ownerCaption =  dataRow.attributes.owner.data?.attributes.name || dataRow.attributes.owner.data?.id;
        this.defaultvalue = dataRow.attributes.defaultvalue;
        this.defaultpercentage = dataRow.attributes.defaultpercentage;
        this.calc = dataRow.attributes.calc || 'increase';
        this.journalgroup =  dataRow.attributes.journalgroup.data?.id || null;
        this.journalgroupCaption =  dataRow.attributes.journalgroup.data?.attributes.name || dataRow.attributes.journalgroup.data?.id;
        
        
    } 

    public static endPoint: string = 'amount-types' 
    public static endPointFilter = ()=>{
       const owner = OwnerModel.getOwner();
        return ( '?populate=*'+(owner==='null' || owner===null? '' : '&filters[owner]='+owner))
    } 
    public static url: string = '/amounttype';
    public static caption: string = 'Amount Type';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active',
        owner: OwnerModel.getOwner(),
        ownerCaption: OwnerModel.getOwnerCaption(),         
        defaultvalue: 0,
        defaultpercentage: 0,
        calc: 'increase',
        journalgroup: null,
        journalgroupCaption: '',
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'description', caption: 'Description' },
        { name: 'status', caption: 'State', type: 'state', default: 'active' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'description', caption: 'Description' },
    ]

    public static optionCalc = [
        { key: 'increase', value: 'Increase' },
        { key: 'decrease', value: 'Decrease' },
    ]

}