import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import CoabalanceList from './coabalance-list.component';
import CoabalanceCreate from './coabalance-create.component';
import CoabalanceView from './coabalance-view.component';
import CoabalanceEdit from './coabalance-edit.component';
import CoabalanceCopy from './coabalance-copy.component';
import CoabalanceDelete from './coabalance-delete.component';

interface Props {
  root?: string;
}

export const CoabalanceRoute: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>
        <Route path={(props.root||'')+'/coabalance/create'} component={CoabalanceCreate} />
        <Route path={(props.root||'')+'/coabalance/view/:id'} component={CoabalanceView} />
        <Route path={(props.root||'')+'/coabalance/edit/:id'} component={CoabalanceEdit} />
        <Route path={(props.root||'')+'/coabalance/copy/:id'} component={CoabalanceCopy} />
        <Route path={(props.root||'')+'/coabalance/delete/:id'} component={CoabalanceDelete} />
        <Route path={(props.root||'')+'/coabalance'} component={CoabalanceList} />
    </Switch>
  );
};
