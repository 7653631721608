import * as React from 'react';
import { History } from 'history';
import Model from './transactiondetail-model';
import BaseList from '../base/base-list.component';

interface IProps { 
  history: History;
  root?: string;
  transactionId: string;
  actions?: any;
  disableBackButton?: boolean;
  disableCard?: boolean;
  getDataRow?: (data: any) => void;
}

interface IState {
}

export default class TransactiondetailList extends React.Component<IProps, IState> {
  
  constructor(props: IProps) {
    super(props);
  }

  private getDataSet = (data:any) => {
    if (this.props.getDataRow) {
      var total = 0;
      data.forEach((dataRow: { value: number; }, i: any) => {
        total = total + dataRow.value;
      });
      this.props.getDataRow({'total':total});
    } 
  }

  public render(): React.ReactNode {
    return (
      <BaseList history={this.props.history} parentId={this.props.transactionId} caption={Model.caption+'s'} endPointFilter={Model.endPointFilter+this.props.transactionId} url={(this.props.root||'')+Model.url} model={Model} getDataSet={this.getDataSet} actions={this.props.actions} disableBackButton={this.props.disableBackButton } disableCard={this.props.disableCard}/>
    );
  }
}
