import React from 'react';
import { History } from 'history';
import { Input, InputEmail, InputMultiline, InputSelect, Picklist, Switch } from '../../components';
import BaseCopy from '../base/base-copy.component'; 
import Model from './message-model';  //editable..
import AppService from '../../services/app.service';
import OwnerPicklist from '../owner/owner-picklist.component';

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string },
    };
    caption?: string;
    buttonCaption?: string;
    to?: string;
    toNumber?: string;
    subject?: string;
    text?: string;
    datasource?: any;
    simpleView?: boolean;
    buttonOnBottom?: boolean;
    disableBackButton?: boolean;
    disableAutoBack?: boolean;
    disableCard?: boolean;
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowMedia: any;
}

export default class MessageCopy extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            dataRowMedia: null,
        }
        this.onFieldValueChange = this.onFieldValueChange.bind(this);
    }

    private getDataRow = (data:any) => {
        data.to = data.type==='email'? this.props.to : this.props.toNumber;
        if (this.props.datasource)
        Object.keys(this.props.datasource).forEach((key)=>
            data.text = data.text.replace('['+key+']', this.props.datasource[key]) 
        );
        this.setState({dataRow:  data});
    }
    
    private getDataRowOwner = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'owner' : data?.id || null, 'ownerCaption' : data?.name || ''  }  });
    };
    
    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onFieldValueChange(fieldName: string, value: string) { 
        if (fieldName==='type' && this.props.toNumber){
            var to = value==='email'? this.props.to : this.props.toNumber;
        }
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value, 'to': to || '',
            }
        };
        this.setState(nextState);
    }

    public componentDidMount(){
        this.setState({ dataRow: { ...this.state.dataRow, 'to' : this.props.to || '', 'subject' : this.props.subject || '', 'text' : this.props.text || '', 'html' : this.props.text?.replaceAll('\n','<br/>') || ''}});    
    }

    render() {
        return (
            //editable..
            <BaseCopy history={this.props.history} match={this.props.match} caption={this.props.caption || ('Duplicate '+ Model.caption)} buttonCaption={this.props.buttonCaption}  
                endPoint={Model.endPoint} model={Model} dataRow={this.state.dataRow} dataRowMedia={this.state.dataRowMedia} getDataRow={this.getDataRow} getDataError={this.getDataError} buttonOnBottom={this.props.buttonOnBottom} disableBackButton={this.props.disableBackButton} disableAutoBack={this.props.disableAutoBack} disableCard={this.props.disableCard}>              
                
                {!this.props.simpleView &&
                <>
                <Input name='name' label='Name' value={this.state.dataRow.name} error={this.state.dataError?.name} onChange={this.onFieldValueChange} />
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} error={this.state.dataError?.description} onChange={this.onFieldValueChange} />
                <Switch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' error={this.state.dataError?.status} onChange={this.onFieldValueChange}/>
                {AppService.isAppFullVersion() &&
                <Picklist name='owner' label='Owner' value={this.state.dataRow.owner?.toString()} caption={this.state.dataRow.ownerCaption} error={this.state.dataError?.owner} onChange={this.onFieldValueChange} >
                    <OwnerPicklist currentId={this.state.dataRow.owner?.toString()} getDataRow={this.getDataRowOwner}/>
                </Picklist>
                }
                </>
                }
                <InputSelect name='type' label='Type' value={this.state.dataRow.type} options={Model.optionType} error={this.state.dataError?.type} onChange={this.onFieldValueChange} />
                <Switch name='sent' label='Send Now?' value={this.state.dataRow.sent} defaultTrue='yes' defaultFalse='no' error={this.state.dataError?.sent} onChange={this.onFieldValueChange}/>
                {!this.props.simpleView &&
                <InputEmail name='from' label='From' value={this.state.dataRow.from} error={this.state.dataError?.from} onChange={this.onFieldValueChange} />
                }
                <InputEmail name='to' label='To' value={this.state.dataRow.to} error={this.state.dataError?.to} onChange={this.onFieldValueChange} />
                {!this.props.simpleView &&
                <>
                <InputEmail name='cc' label='Cc' value={this.state.dataRow.cc} error={this.state.dataError?.cc} onChange={this.onFieldValueChange} />
                <InputEmail name='bcc' label='Bcc' value={this.state.dataRow.bcc} error={this.state.dataError?.bcc} onChange={this.onFieldValueChange} />
                <InputEmail name='replyto' label='Reply to' value={this.state.dataRow.replyto} error={this.state.dataError?.replyto} onChange={this.onFieldValueChange} />
                </>
                }
                <Input name='subject' label='Subject' value={this.state.dataRow.subject} error={this.state.dataError?.subject} onChange={this.onFieldValueChange} />
                <InputMultiline name='text' label='Text' value={this.state.dataRow.text} error={this.state.dataError?.text} onChange={this.onFieldValueChange} />

            </BaseCopy>
        );
    }
}