export default class ResponseModel {
    public Status: boolean;
    public Data: any;
    public Meta: any;
    public Messages: string;
    public Errors: any;

    constructor(status: boolean, data: any, meta: any, messages: string, errors: any) {
        this.Status = status;
        this.Data = data;
        this.Meta = meta;
        this.Messages = messages;
        this.Errors = errors;
    }

}