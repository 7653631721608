import * as React from 'react';
import { Card, FormHeader, Table } from '../../components';
import ApiService from '../../services/api.service';

interface IProps { 
  history?: any;
  parentId?: string; //for nested list, need parent id to filling default value (eg. transaction Id)
  caption: string;
  captionColor?: string;
  endPointFilter?: string;
  model: any;
  url?: string;
  getDataSet?: (data: any, page?: any) => void;
  page?: number;
  pageSize?: number;
  searchField?: string;
  actions?: any;
  disableTable?: boolean;
  disableBackButton?: boolean;
  disableCard? : boolean;
}

interface IState {
  dataSet: Array<any>;
  tableHeader: Array<any>;
  parentId: string;
  page: number;
  pageSize: number;
  pageCount: number;
  actions: any;
  searchText: string;
  isSearch: boolean;
  isReady: boolean;
  isError: boolean;
}

export default class BaseList extends React.Component<IProps, IState> {
  
  constructor(props: IProps) {
    super(props);
    this.state = {
      dataSet: this.props.model,
      tableHeader: this.props.model.columns,
      parentId: this.props.parentId || '',
      page: this.props.page || 1,
      pageSize: this.props.pageSize || 10,
      pageCount: 0,
      actions: [{name: 'create', caption: 'Create', url: this.getUrl('create')}],
      searchText: '',
      isSearch: this.props.model.columns?.findIndex((d: { name: string; }) => d.name === (this.props.searchField || 'description'))===1,
      isReady: false,
      isError: false,
    };
    
    this.onChangePage = this.onChangePage.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.onClickSearch = this.onClickSearch.bind(this);
  }
  
  private isLoading: boolean = false;

  public componentDidMount() {
    this.loadData(this.state.page);
  }

  public componentDidUpdate() {
    if (this.props.page && (this.props.page !== this.state.page)) {
      this.setState({page: this.props.page})
      this.loadData(this.props.page, this.state.searchText);
    }
  }

  private loadData (page: number, searchText?: string) {
    if (this.isLoading) return;
    this.isLoading = true;
    this.setState({ isReady: false});
    
    const pagination = '&pagination[page]='+page+'&pagination[pageSize]='+this.state.pageSize;
    const search = searchText? '&filters['+(this.props.searchField || 'description')+'][$contains]='+searchText : '';
    ApiService.getAll<typeof this.props.model>(this.props.model.endPoint+(this.props.endPointFilter||'?populate=*')+search+pagination).then((rp) => {
      if (rp.Status) {
        const pageCount = Math.ceil((rp.Meta?.pagination?.total||1)/this.state.pageSize);
        const data = rp.Data;
        var dataSet = new Array<typeof this.props.model>();
        (data || []).forEach((dataRow: any) => {dataSet.push(new this.props.model(dataRow));});
        this.setState({ dataSet: dataSet, pageCount:pageCount, isReady: true });
        this.props.getDataSet?.(dataSet, rp.Meta.pagination);
      } 
      else { this.setState({ isReady: true, isError: true }); }
      this.isLoading = false;
    });
  }
  
  private getUrl (action: string, currentId?:string) {
    var id =  action==='create'? (this.props.parentId || '') : currentId;
    id = id==='0'? '' : id;
    id = action==='preview'? id+'/'+this.state.parentId : id;
    
    var url =  this.props.actions && !this.props.actions.includes(action) && !this.props.actions.includes('...')? '' : this.props.url + '/'+action + (id? '/'+id : '');
    url = action!=='create' && !id? '' : url;
    url = action==='preview' && !this.props.actions? '' : url;
    
    return url;
  }

  private getDataRow = (data: any) => {
    this.setState({ actions: [
      {name: 'view', caption: 'View', url: this.getUrl('view', data.id)}, 
      {name: 'edit', caption: 'Edit', url: this.getUrl('edit', data.id)}, 
      {name: 'copy', caption: 'Duplicate', url: this.getUrl('copy', data.id)}, 
      {name: 'delete', caption: 'Delete', url: this.getUrl('delete', data.id)}, 
      {name: 'preview', caption: 'Preview', url: this.getUrl('preview', data.id)}, 
      {name: 'create', caption: 'Create', url: this.getUrl('create', data.id)}, 
    ]
    })
  }

  private onChangePage (e: React.ChangeEvent<unknown>, value: number) {
    this.setState({page: value});
    this.loadData(value, this.state.searchText);
  };
  
  private onChangeSearch(fieldName: string, value: string) { 
    this.setState({searchText: value});
    this.loadData(this.state.page, value);
  }
  
  private onClickSearch() { 
    this.loadData(this.state.page, this.state.searchText);
  }
  
  private table(){
    return <Table history={this.props.history} urlCreate={this.getUrl('create')} tableHeader={this.state.tableHeader} dataSet={this.state.dataSet} getDataRow={this.getDataRow} page={this.state.page} pageCount={this.state.pageCount} actions={this.state.actions} searchText={this.state.searchText} isSearch={this.state.isSearch} isLoading={this.isLoading} isReady={this.state.isReady} isError={this.state.isError} onChangePage={this.onChangePage} onChangeSearch={this.onChangeSearch} onClickSearch={this.onClickSearch} />
  }

  public render(): React.ReactNode {  
    return (
      <>
      {!this.props.disableTable &&
      <>
        <FormHeader history={this.props.history} label={this.props.caption} color={this.props.captionColor} disableBackButton={this.props.disableBackButton} /> 
        {this.props.disableCard? this.table():
          <Card>{this.table()}</Card>           
        }          
      </>
      }
      </>
    );
  }
}
