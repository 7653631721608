import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import OwnerList from './owner-list.component';
import OwnerCreate from './owner-create.component';
import OwnerView from './owner-view.component';
import OwnerEdit from './owner-edit.component';
import OwnerCopy from './owner-copy.component';
import OwnerDelete from './owner-delete.component';

interface Props {
  root?: string;
}

export const OwnerRoute: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>
      <Route path={(props.root||'')+'/owner/create'} component={OwnerCreate } />
      <Route path={(props.root||'')+'/owner/view/:id'} component={OwnerView } />
      <Route path={(props.root||'')+'/owner/edit/:id'} component={OwnerEdit } />
      <Route path={(props.root||'')+'/owner/copy/:id'} component={OwnerCopy } />
      <Route path={(props.root||'')+'/owner/delete/:id'} component={OwnerDelete } />
      <Route path={(props.root||'')+'/owner'} render={(params)=> <OwnerList {...params} root={props.root} />} /> 
    </Switch>
  );
};
