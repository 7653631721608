import React from 'react';
import { History } from 'history';
import { Input, InputMultiline, InputDate, InputNumber, InputMoney, InputSelect, Picklist } from '../../components';
import BaseView from '../base/base-view.component'; 
import Model from './journal-model';  //editable..

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string },
    }
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class JournalView extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    render() {
        return (
            //editable..
            <BaseView history={this.props.history} match={this.props.match} caption={'View '+ Model.caption} 
                model={Model} getDataRow={this.getDataRow}>              
                
                <Input name='name' label='Name' value={this.state.dataRow.name} readOnly/>
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} readOnly/>
                <InputSelect name='status' label='status' value={this.state.dataRow.status} options={Model.optionStatus} readOnly/>
                <InputDate name='journaldate' label='Date' value={this.state.dataRow.journaldate} readOnly/>
                <InputNumber name='seq' label='Sequence' value={this.state.dataRow.seq} readOnly/>
                <InputSelect name='sign' label='Debet/Credit' value={this.state.dataRow.sign} options={Model.optionSign} readOnly/>
                <InputMoney name='value' label='Value' value={this.state.dataRow.value} readOnly/>
                <Picklist name='coa' label='COA' value={this.state.dataRow.coa?.toString()} caption={this.state.dataRow.coaCaption} readOnly/>
                <Picklist name='transaction' label='Transaction' value={this.state.dataRow.transaction?.toString()} caption={this.state.dataRow.transactionCaption} readOnly/>

            </BaseView>
        );
    }
}