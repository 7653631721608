import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import TransactionList from './transaction-list.component';
import TransactionCreate from './transaction-create.component';
import TransactionView from './transaction-view.component';
import TransactionEdit from './transaction-edit.component';
import TransactionCopy from './transaction-copy.component';
import TransactionDelete from './transaction-delete.component';

interface Props {
  root?: string;
}

export const TransactionRouteGeneral: React.FunctionComponent<Props> = (props) => {
  const disableFields = ['owner','transactionType','employee','customer','vendor','transactiondetail','transactionamount','transactionpayment'];
  
  return (
    <Switch>
        <Route path={(props.root||'')+'/transactiongeneral/create'} render={(params)=> <TransactionCreate {...params} root={props.root} caption={'General Transaction'} transactionType={'2'} disableFields={disableFields} />} />        
        <Route path={`${props.root||''}/transactiongeneral/view/:id`} render={(params)=> <TransactionView {...params} root={props.root} caption={'General Transaction'} disableFields={disableFields} />} />        
        <Route path={`${props.root||''}/transactiongeneral/edit/:id`} render={(params)=> <TransactionEdit {...params} root={props.root} caption={'General Transaction'} disableFields={disableFields} />} />        
        <Route path={`${props.root||''}/transactiongeneral/copy/:id`} render={(params)=> <TransactionCopy {...params} root={props.root} caption={'General Transaction'} disableFields={disableFields} />} />        
        <Route path={`${props.root||''}/transactiongeneral/delete/:id`} render={(params)=> <TransactionDelete {...params} root={props.root} caption={'General Transaction'} disableFields={disableFields} />} />        
        <Route path={(props.root||'')+'/transactiongeneral'} render={(params)=> <TransactionList {...params} root={props.root}  caption='General Transaction' type='general' url='/transactiongeneral'/>} /> 
    </Switch>
  );
};
