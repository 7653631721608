import React from 'react';
import { History } from 'history';
import { InputEmail } from '../../components';
import BaseCreate from '../base/base-create.component'; 
import Model from './user-model';  //editable..

interface IProps { 
    history: History;
    email?: string;
    disableBackButton?: boolean;
    disableCard?: boolean;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class UserAuthSendEmailConfirmation extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        this.onFieldValueChange = this.onFieldValueChange.bind(this);
    }

    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }

    private onSave = (data: any) => {
        var sent=data.sent; //triger for send email.
    };
    
    private onFieldValueChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    public componentDidMount(){
        const dataRowTemp: any = Model.defaultValueSendEmailConfirmation;
        this.setState({dataRow: dataRowTemp})
    }
    
    public componentDidUpdate(){
        if (!this.state.dataRow.email && this.props.email!=='' && this.props.email!==this.state.dataRow.email) 
            this.setState({ dataRow: { ...this.state.dataRow, 'email' : this.props.email || ''}});
    }

    render() {
        return (
            //editable..          
            <BaseCreate history={this.props.history} caption={'Send Email Confirmation'} buttonCaption='Send Email'
                endPoint={Model.endPointAuth+'/send-email-confirmation'} model={Model} dataRow={this.state.dataRow} validations={Model.validationSendEmailConfirmation} getDataError={this.getDataError} onSave={this.onSave} buttonOnBottom disableBackButton={this.props.disableBackButton} disableCard={this.props.disableCard}>              
    
                <InputEmail name='email' label='Your registered email' value={this.state.dataRow.email} error={this.state.dataError?.email} onChange={this.onFieldValueChange} />
            </BaseCreate>
        );
    }     
     
}