import * as React from 'react';
import { CircularProgress, Grid, Typography } from '@mui/material';
import IconFinish from '@mui/icons-material/Check';
import IconPercent from '@mui/icons-material/Percent';
import AppService from '../services/app.service';

interface Props {
  value: number;
  totalValue: number;
  color?: any;
  bgColor?: any;
  showValue?: boolean;
}

export const ProgressBar: React.FunctionComponent<Props> = (props) => {
  const isMobile = AppService.isMobile();
  const bgColor = props.bgColor || '#e3f0d3' || 'white';
  const color = props.color || '#357a38';
  const progressValue : number =  Math.floor((props.value/props.totalValue)*100) || 0;

  return (  
    <Grid container sx={{alignItems:'center', justifyContent:'center'}} >    
        <Grid item container sx={{borderRadius: '50%', height:  {xs: '70px', md: '100px'}, width: {xs: '70px', md: '100px'}, backgroundColor: bgColor}}/>
        <CircularProgress variant='determinate' size={isMobile? 70: 100} value={100} thickness={3} sx={{ color: color, position: 'absolute', opacity: 0.3}}/>
        <CircularProgress variant='determinate' size={isMobile? 70: 100} value={progressValue} thickness={3} sx={{ color: color, position: 'absolute'}}/>     
        {progressValue===100?
          <IconFinish sx={{color: color, fontSize: {xs: '65px', md: '90px'}, position: 'absolute'}} />
        :
          <Typography color={color} sx={{typography: {xs: 'h5', md: 'h4'}, position: 'absolute'}}>
            <b>{props.showValue? props.value : progressValue.toString() }<IconPercent sx={{color: color, fontSize: {xs: '15px', md: '20px'}}}/></b>
            
          </Typography>
        }
    </Grid>
    )
};


