import * as React from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Button } from './button';
import { Dialog , DialogContent, Typography} from '@mui/material';
import { ProgressBar } from '.';

interface Props {
  htmlId: string;
  fileOptions: any;
  buttonCaption?: string;
}

export const PrintPDF: React.FunctionComponent<Props> = (props) => {
  const [open, setOpen] = React.useState(false);
  const [progress, setProgress] = React.useState(0);

  const printDocument =  async() => {
    setOpen(true);

    var pdf = new jsPDF('p', 'mm', props.fileOptions.fileType || 'a4', true);
    pdf.setProperties(props.fileOptions.properties);
    var pdfWidth = pdf.internal.pageSize.getWidth();
    var pdfHeight = pdf.internal.pageSize.getHeight();
    var pdfMargin = props.fileOptions.margin || 20;
    var pdfMargin2 = pdfMargin*2;
    var pageNumber = 0;
    var sections = document.querySelectorAll('#'+props.htmlId);

    //create page per section
    for (let i = 0; i < sections.length; i++) {
      
        var section = sections[i] as HTMLElement;
        if (section) {
            await html2canvas(section, {scale: 1.5, useCORS: true}).then((canvas) => { 
              var canvasPage = document.createElement('canvas');
              var pageCtx = canvasPage.getContext('2d');
              canvasPage.width = canvas.width;
              var canvasPageHeight = Math.floor(canvas.width * (pdfHeight / pdfWidth));
              var canvasHeightLeft = canvas.height;   
              
              while (canvasHeightLeft>0) {
                  pageNumber++;
                  
                  //create image per page
                  var canvasPageHeightAdj = props.fileOptions.marginAdj?.filter((d: any) => d.pageNumber===pageNumber)?.[0]?.value || 0;
                  canvasPage.height = canvasPageHeight + canvasPageHeightAdj;
                  if (pageCtx) {
                      var w = canvasPage.width;
                      var h = canvasPage.height;
                      pageCtx.fillStyle = 'white';
                      pageCtx.fillRect(0, 0, w, h);
                      pageCtx.drawImage(canvas, 0, canvas.height - canvasHeightLeft, w, h, 0, 0, w, h);
                  }
                  var imgData = canvasPage.toDataURL('image/png', 1);
                  pdf.addImage(imgData, 'PNG', pdfMargin, pdfMargin, pdfWidth-pdfMargin2, 0);
                  
                  //header footer page
                  var headerTop = pdfMargin/2;
                  var footerTop = pdfHeight-headerTop;
                  pdf.setFontSize(9);
                  if (props.fileOptions.headerText && pageNumber>=props.fileOptions.headerText.start) {
                    pdf.text(props.fileOptions.headerText.left?.replace('$pagenumber',pageNumber), pdfMargin, headerTop, {align: 'left'});
                    pdf.text(props.fileOptions.headerText.center?.replace('$pagenumber',pageNumber), pdfWidth/2, headerTop, {align: 'center'});
                    pdf.text(props.fileOptions.headerText.right?.replace('$pagenumber',pageNumber), pdfWidth-pdfMargin, headerTop, {align: 'right'});
                  }
                  if (props.fileOptions.footerText && pageNumber>=props.fileOptions.footerText.start) {
                    pdf.text(props.fileOptions.footerText.left?.replace('$pagenumber',pageNumber), pdfMargin, footerTop, {align: 'left'});
                    pdf.text(props.fileOptions.footerText.center?.replace('$pagenumber',pageNumber), pdfWidth/2, footerTop, {align: 'center'});
                    pdf.text(props.fileOptions.footerText.right?.replace('$pagenumber',pageNumber), pdfWidth-pdfMargin, footerTop, {align: 'right'});
                  }

                  //calculate canvas left
                  canvasHeightLeft -= canvasPage.height;
                  if (canvasHeightLeft<67) canvasHeightLeft = 0; //skip minimum canvas size
                  if (canvasHeightLeft>0) pdf.addPage(); 
              }                     

              canvasPage.remove(); 
              setProgress(Math.floor(i/(sections.length-1)*100));
            })
            if (i < sections.length-1) pdf.addPage();
        }
    }
    pdf.save((props.fileOptions.fileName||'result')+'.pdf');
  }

  React.useEffect(() => {
    if (progress===100) {
      setOpen(false);
      setProgress(0);
    } 

  }, [progress]);

  return (
    <>
      {props.buttonCaption && 
        <Button variant='contained' label={props.buttonCaption} onClick={printDocument}/>
      }
      
      <Dialog open={open}>  
        <DialogContent>
          <Typography sx={{typography: {xs: 'caption', md: 'h6'}, marginBottom: 2}} >
            Please wait while document is generated. 
          </Typography>
          <ProgressBar value={progress} totalValue={100}/>
        </DialogContent>
      </Dialog>
    </>
  )
  
}