import * as React from 'react';
import { History } from 'history';
import { Link } from 'react-router-dom';
import { ActionButton, Button, Card, Icon, InputSearch, InputUpload } from '.';
import { Box, CardMedia, Checkbox, Chip, Grid, Pagination, Typography } from '@mui/material';
import IconDate from '@mui/icons-material/CalendarMonth';
import AppService from '../services/app.service';

interface Props {
  history?: History;
  urlCreate?: string;
  tableHeader: any;
  dataSet: Array<any>;
  currentId?: any,
  getDataRow?: (data?: any) => void;
  page:number;
  pageCount: number; 
  actions?: any;
  searchText?: string;
  isSearch?: boolean;
  isValidateError?: boolean;
  isLoading: boolean;
  isReady: boolean;
  isError?: boolean;
  onChangePage?: (e: React.ChangeEvent<unknown>, value: number) => void;
  onChangeSearch?: (fieldName: string, value: string) => void;
  onClickSearch?: () => void;
  onClickUpload?: () => void;
  cardNumber?: number;
  cardBorderRadius?: number;
  cardBoxShadow?: number;
  cardPadding?: number;
  cardTextAlign?: 'left'|'center'|'right';
  autoSelect?: boolean;
  checklistView?: boolean;
  cardView?: boolean;
  readOnly?: boolean;
  
}

export const Table: React.FunctionComponent<Props> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [selectedId, setSelectedId] = React.useState<string>(props.currentId);
  const [isUpload, setIsUpload] = React.useState<boolean>(true);
  

  const findId = (id: number) => {
    return props.currentId.findIndex((d: { id: number}) => d.id === id);
  }

  const findAction = (action: string) => {
   return props.actions?.filter((d: any) => d.name === action)?.[0];
  }

  const onClick = (dataRow: any) => (e: React.MouseEvent<HTMLButtonElement>)=>{
    if (props.readOnly) return;
    props.getDataRow?.(dataRow);
    if (!props.actions || props.actions.length>0) setAnchorEl(e.currentTarget);
  };
  
  const onClickCard = (dataRow: any) =>{
    if (props.readOnly) return;
    if (props.checklistView) {
      var idx = findId(dataRow.id);
      if (idx<0) props.currentId.unshift(dataRow);
      else props.currentId.splice(idx, 1);
      props.getDataRow?.(props.currentId);
    }
    else {
      setSelectedId(dataRow.id.toString());
      props.getDataRow?.(dataRow);
    }
  };

  
  const onClickUpload = (e: React.MouseEvent<HTMLButtonElement>)=>{
    props.onClickUpload?.();
    setIsUpload(false);
  }

  const getDataSetUpload = (dataRow: any) => {
    setIsUpload(true);
    props.getDataRow?.(dataRow)
  }

  const tableColumn = () => {
    return (
      <tr>
        {props.checklistView && <th colSpan={1}></th>}
        {props.tableHeader.map((column: any, i: number) => {
          if (column.caption){
            switch (column.type) {
              case 'number':    
              case 'money': return <th key={i} className='text-end'> {column.caption}&nbsp;&nbsp;</th>          
              case 'state':
              case 'validate': return <th key={i} className='text-center'>{column.caption}</th>
              default : return <th key={i}>{column.caption}</th>
            }
          }
        })}
      </tr>
    )
  }

  const tableContent = () => {
    if (!props.isReady)
      return <tr><td colSpan={props.tableHeader.length+1} className='text-center'><div className='spinner-border'/></td></tr>
    
    if (props.isError) 
      return <tr><td className='text-center' colSpan={props.tableHeader.length}><Chip label='Sorry.. Unable to load data, there is something error.' size='small' color='default'/></td></tr>

    if (props.dataSet.length===0) 
      return <tr><td className='text-center' colSpan={props.tableHeader.length}><Chip label='no data' size='small' color='default'/></td></tr>
      
    return props.dataSet.map(function (dataRow, i) {
      return (
        <tr key={i} style={{verticalAlign: 'middle', backgroundColor: dataRow.id && props.currentId===dataRow.id.toString()? 'ButtonFace' : ''}} >
          {props.checklistView && <td width={50}><Checkbox id={i.toString()} sx={{'& .MuiSvgIcon-root': {fontSize: 28}, margin: -0.5}} checked= {findId(dataRow.id)>=0} onChange={()=>onClickCard(dataRow)}/> </td>}
          {props.tableHeader.map((column: any, j: number) => {
            if (j===0 && !props.checklistView && !props.readOnly)
              return <td key={j}> <Button id={dataRow?.id || null} sx={{textAlign: 'left', paddingLeft: 0}} onClick={onClick(dataRow)} label={dataRow[column.name]}/></td>
            else
              switch (column.type) {
                case 'state': return <td key={j} className='text-center'> <Chip label={AppService.strCapitalize(dataRow[column.name])} sx={{size: {xs: 'small', md:'medium'}}} color={dataRow[column.name]===column.default? 'info':'default'} /></td>                  
                case 'number': return <td key={j} className='text-end'> {dataRow[column.name]}&nbsp;&nbsp;</td> 
                case 'money': return <td key={j} className='text-end'> {AppService.strToMoney(dataRow[column.name])}&nbsp;&nbsp;</td> 
                case 'validate': return <td key={j} className='text-center'> <Chip label={AppService.strCapitalize(dataRow[column.name]||column.default)} sx={{size: {xs: 'small', md:'medium'}}} color={dataRow[column.name]? 'error':'default'} /></td>                  
                case 'error': return <td key={j} className='text-danger'> {dataRow[column.name]}</td> 
                default : return <td key={j}> {dataRow[column.name]?.length>100? dataRow[column.name].substring(0,100)+' ...':dataRow[column.name]} </td>
              }
          })}
        </tr>
      )
    });

  };

  const tableContentCardView = () => {
    let cardNumber = props.cardNumber || 2;
    
    if (!props.isReady) 
      return <div className='spinner-border'/>
    
    if (props.isError) 
      return <div><Chip label='Sorry.. Unable to load data, there is something error.' size='small' color='default'/></div>

    if (props.dataSet.length===0)
      return  <Grid key={0} item xs={12}> <Chip label='no options to the select' size='small' color='default'/> </Grid>

    return props.dataSet.map(function (dataRow, i) {
      let cardMedia: React.ReactNode = '';
      let cardIcon: React.ReactNode = '';
      let cardContent: React.ReactNode = '';
      let cardNumberAdj = 0;
      let cardSelected = props.checklistView? findId(dataRow.id)>=0 : ((!props.currentId) && props.autoSelect && (i===0)) || (selectedId===dataRow.id.toString());

      props.tableHeader.forEach((column: any, j: number) => {  
        if (dataRow[column.name])
        if (column.type==='media')
          cardMedia = <CardMedia key={j} image={dataRow[column.name]} component='img'/> 
        else if (column.type==='state')
          cardContent = <>{cardContent} <Chip key={j} label={AppService.strCapitalize(dataRow[column.name])} sx={{size: {xs: 'small', md:'medium'}}} color={dataRow[column.name]===column.default? 'info':'default'} /> </>            
        else if (column.type==='caption')
          cardContent = <>{cardContent} <Grid container direction='row' alignItems='center'>{cardIcon}<Typography key={j} sx={{typography: {xs: 'subtitle1', md: 'h6'}}} textAlign={dataRow[column.name].length===1?'center':props.cardTextAlign} ml={1}>{dataRow[column.name]}</Typography></Grid></>
        else if (column.type==='number')
          cardContent = <>{cardContent} <Typography key={j} sx={{typography: {xs: 'body2', md: 'subtitle1'}}} color='text.secondary'> {dataRow[column.name]}</Typography>  </>                
        else if (column.type==='money')
          cardContent = <>{cardContent} <Typography key={j} sx={{typography: {xs: 'body2', md: 'subtitle1'}}} color='text.secondary'> {AppService.strToMoney(dataRow[column.name])}&nbsp;&nbsp;</Typography></>                
        else if (column.type==='date')
          cardContent = <>{cardContent} <Typography key={j} sx={{typography: {xs: 'body2', md: 'subtitle1'}}} color='text.secondary'> <IconDate/> {AppService.dateFormat(dataRow[column.name],'d mon y')}&nbsp;&nbsp;</Typography></>                
        else if (column.type==='icon')
          cardIcon = <Icon name={dataRow[column.name]} size='large'/>              
        else {
          cardContent = <>{cardContent} <Typography key={j} sx={{typography: {xs: 'caption', md: 'body2'}}} color='text.secondary' textAlign={props.cardTextAlign}>{dataRow[column.name]?.length>100? dataRow[column.name].substring(0,100)+' ...':dataRow[column.name]}</Typography></>
          cardNumberAdj = 2;
        }
      })
      return (
        <Grid key={i} item xs={cardNumberAdj>0? 12:4} sm={cardNumberAdj>0? 12:4} md={cardNumber+cardNumberAdj} lg={cardNumber+cardNumberAdj}>
          <Link to='#' replace={props.history? false:true} className={props.readOnly? 'nav-link disabled' : ''} style={{textDecoration: 'none'}} onClick={()=>onClickCard(dataRow)}> 
            <Card color={cardSelected? 'secondary.dark' : 'white'} borderColor= {cardSelected? 'secondary.dark':''} borderWidth={cardSelected? 5:1} borderRadius={props.cardBorderRadius} boxShadow={props.cardBoxShadow} padding={props.cardPadding} media={cardMedia}>
              {cardContent}
            </Card>
          </Link>
        </Grid>
      )
    });
  };

  const table = () =>{
    const actionCreate = findAction('create');
    const actionUpload = findAction('upload');
    
    if (props.cardView) return (
      <Grid container spacing={1}>
        {tableContentCardView()}   
      </Grid>
    )
    else return (
      <>
      {(props.pageCount>1 || props.searchText) && props.isSearch &&
        <InputSearch name='searchText' label='' value={props.searchText} onChange={props.onChangeSearch} onClick={props.onClickSearch} readOnly={props.isLoading}/>
      }
      {actionCreate &&
        <Button variant='outlined' startIcon={<Icon name='add'/>} onClick={()=>{props.history?.push(actionCreate.url)}} label='Add New' /> 
      }
      {actionUpload &&
        <InputUpload name='upload' label='Select file' outFormat='json' getDataSet={getDataSetUpload} buttonView/>
      }
      <Box sx={{height: 550, overflow: 'auto', overflowX: 'hidden'}}>
        <Typography component='div' sx={{typography: {xs: 'caption', md: 'body2'}}}>
          <table className='table table-sm table-hover mt-3'>
            <thead>
              {tableColumn()}
            </thead>
            <tbody>
              {tableContent()}
            </tbody>
          </table>
        </Typography>
      </Box>
      </>
    )
  }

  return (
    <>
    {table()}
    {props.pageCount>1 &&
      <Pagination count={props.pageCount} page={props.page} onChange={props.onChangePage} />
    }
    {findAction('upload') && !props.isValidateError && props.isReady && isUpload &&
      <Button variant='contained' sx={{marginTop: 2, marginBottom: 3}} startIcon={<Icon name='cloud_upload'/>} onClick={onClickUpload} label='Upload' /> 
    } 
    <ActionButton anchorEl={anchorEl} action= {props.actions} onClose={()=>setAnchorEl(null)} />      
    </>
  )
};
