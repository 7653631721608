import OwnerModel from '../owner/owner-model';

export default class TestingtoolresumeModel {
    id?: number;
    name: string;
    description: string;
    status: string;
    owner: any;
    ownerCaption: string;
    title: string;
    content: string;
    seq: number;
    valuemin: number;
    valuemax: number;
    scale: number;
    calc: string;
    factor: number;
    questionoption: any;
    questionoptionCaption: string;
    questionoptionValue: number;
    questionoptionQuestionType: string;
    testingtoolresume: any;
    testingtoolresumeCaption: string;

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.owner =  dataRow.attributes.owner?.data?.id || null;
        this.ownerCaption =  dataRow.attributes.owner?.data?.attributes.name || dataRow.attributes.owner?.data?.id;
        this.title = dataRow.attributes.title;
        this.content = dataRow.attributes.content;
        this.seq = dataRow.attributes.seq;
        this.valuemin = dataRow.attributes.valuemin;
        this.valuemax = dataRow.attributes.valuemax;
        this.scale = dataRow.attributes.scale;
        this.calc = dataRow.attributes.calc;
        this.factor = dataRow.attributes.factor<=0? 1: dataRow.attributes.factor;
        this.questionoption =  dataRow?.attributes?.questionoption?.data || null;
        this.questionoptionCaption =  '';
        this.questionoptionValue = 
            dataRow?.attributes?.questionoption?.data?.reduce( (t: any, d: any) => {
                var id = d.attributes.question?.data?.id;
                var idx = t.findIndex((d2: any) => d2.id === id);
                if(d.attributes.question?.data?.attributes.type === 'multiplematch') {
                    if (idx<0) t.push( {id: id, value: d.attributes.value })
                    else t[idx].value = t[idx].value===0 || d.attributes.value===0? 0 : 1;
                }
                else if (d.attributes.value>0) t.push( {id: id, value: d.attributes.value });
                return t;
            }, []).reduce( (v: number, d: any) => { return v + d.value; }, 0) || 0;

        this.questionoptionValue =  Math.round(this.questionoptionValue/this.factor);
        this.questionoptionQuestionType =  dataRow?.attributes?.questionoption?.data?.[0]?.attributes.question?.data?.attributes.type || '';
        this.testingtoolresume =  dataRow.attributes.testingtoolresume?.data?.filter((d: any) => this.questionoptionValue>= d.attributes.valuemin && this.questionoptionValue<= d.attributes.valuemax)|| null;
        this.testingtoolresumeCaption =  '';
        
    } 

    public static endPoint: string = 'testing-tool-resumes' 
    public static endPointFilter = ()=>{
        const owner = OwnerModel.getOwner();
        return ( '?populate=*'+(owner==='null' || owner===null? '' : '&filters[owner]='+owner)+'&filters[parent][id][$null]=true')
    } 
    public static endPointFilter2: string = '?populate=*&filters[parent]=';
    
    public static endPointFilter3: string = '?sort=seq'+
                                            '&populate[testingtoolresume][sort]=seq'+
                                            '&populate[questionoption][populate]=question'+
                                            '&filters[parent][id][$null]=true'+                                           
                                            '&populate[questionoption][filters][evaluationdetail][name][$startsWith]=';
    public static url: string = '/testingtoolresume';
    public static caption: string = 'Testing Tool Resume';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active',
        owner: OwnerModel.getOwner(),
        ownerCaption: OwnerModel.getOwnerCaption(),   
        title: '',
        content: '',
        seq: 0,
        valuemin: 0,
        valuemax: 0,
        scale: 0,
        calc: '',
        factor: 1,
        questionoption: null,
        questionoptionCaption: '',
        questionoptionValue: 0,  
        questionoptionQuestionType: '',
        testingtoolresume: null,
        testingtoolresumeCaption: '',
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'description', caption: 'Description' },
        { name: 'status', caption: 'State', type: 'state', default: 'active' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'description', caption: 'Description' },
    ]

    public static optionCalc = [
        { key: 'sum', value: 'Sum' },
        { key: 'average', value: 'Average' },
    ]
}