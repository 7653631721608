import OwnerModel from '../owner/owner-model';
import UserModel from '../user/user-model';

export default class TestingtoolModel {
    id?: number;
    name: string;
    description: string;
    status: string;
    owner: any;
    ownerCaption: string;
    parent: any;
    parentCaption: string;
    product: any;
    productCaption: string;
    testingtoolLength: number; //testingtool section length
    questionLength: number; 
    evaluationLength: number; 
    evaluationLengthEnddate: number; 
    evaluationEnddate: string;
    evaluationdetailLength: number; 
    title: string;
    content: string;
    seq: number;
    viewstyle: string;
    calc: string;
    timelimit: number;
    
    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.owner =  dataRow.attributes.owner.data?.id || null;
        this.ownerCaption =  dataRow.attributes.owner.data?.attributes.name || dataRow.attributes.owner.data?.id;
        this.parent =  dataRow.attributes.parent.data?.id || null;
        this.parentCaption =  dataRow.attributes.parent.data?.attributes.name || dataRow.attributes.parent.data?.id;
        this.product =  dataRow.attributes.product.data?.id || null;
        this.productCaption =  dataRow.attributes.product.data?.attributes.name || dataRow.attributes.product.data?.id;
        this.testingtoolLength =  dataRow.attributes.testingtool.data?.length || 0;
        this.questionLength =  dataRow.attributes.question.data.length || 0;
        this.evaluationLength =  dataRow.attributes.evaluation.data?.[0]?.attributes.evaluation?.data.length || 0;
        this.evaluationLengthEnddate =  dataRow.attributes.evaluation.data?.[0]?.attributes.evaluation?.data.filter((d: any) => d.attributes.enddate !== null).length|| 0;
        this.evaluationEnddate =  dataRow.attributes.evaluation.data?.[0]?.attributes.enddate || null;
        this.evaluationdetailLength =  dataRow.attributes.evaluation.data?.[0]?.attributes.evaluationdetail?.data?.length || 0;
        this.title = dataRow.attributes.title;
        this.content = dataRow.attributes.content;
        this.seq = dataRow.attributes.seq;
        this.viewstyle = dataRow.attributes.viewstyle;
        this.calc = dataRow.attributes.calc;
        this.timelimit = dataRow.attributes.timelimit;
    } 

    public static endPoint: string = 'testing-tools' 
    public static endPointFilter = ()=>{
        const owner = OwnerModel.getOwner();
        return ( '?populate=*'+(owner==='null' || owner===null? '' : '&filters[owner]='+owner)+'&filters[parent][id][$null]=true')
    } 
    public static endPointFilter2: string = '?populate=*&filters[parent]=';
    public static endPointFilter3: string = '?populate=*&sort=seq&filters[evaluation]=';
    public static endPointFilter4 = () => {
        const user = UserModel.getUser();
        return ('?populate[owner][sort]=id'+
                '&populate[parent][sort]=id'+
                '&populate[product][sort]=id'+
                '&populate[question][sort]=id'+
                '&populate[testingtool][sort]=id'+
                '&populate[evaluation][populate]=user'+
                '&populate[evaluation][populate]=evaluationdetail'+
                '&populate[evaluation][filters][user]='+user+
                '&filters[parent]='
        );
    }
    public static endPointFilter5 = () => {
        const user = UserModel.getUser();
        return ('?populate[owner][sort]=id'+
                '&populate[parent][sort]=id'+
                '&populate[product][sort]=id'+
                '&populate[question][sort]=id'+
                '&populate[testingtool][sort]=id'+
                '&populate[evaluation][populate]=user'+
                '&populate[evaluation][populate]=parent'+
                '&populate[evaluation][populate]=evaluation'+
                '&populate[evaluation][populate]=evaluationdetail'+
                '&populate[evaluation][filters][user]='+user+
                '&populate[evaluation][filters][parent][id][$null]=false'+                
                '&filters[evaluation]='
        );
    }
    public static url: string = '/testingtool';
    public static caption: string = 'Testing Tool';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active',
        owner: OwnerModel.getOwner(),
        ownerCaption: OwnerModel.getOwnerCaption(),   
        parent: null,
        parentCaption: '',
        product: null,
        productCaption: '',
        testingtoolLength: 0,
        questionLength: 0,
        evaluationLength: 0,
        evaluationLengthEnddate: 0,
        evaluationEnddate: '',
        evaluationdetailLength: 0,
        title: '',
        content: '',
        seq: 0,
        viewstyle: 'single',
        calc: 'baseon_answer',
        timelimit: 0,
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'description', caption: 'Description' },
        { name: 'status', caption: 'State', type: 'state', default: 'active' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'title', caption: 'Title' },
    ]
    
    public static optionViewstyle = [
        { key: 'single', value: 'Show one by one' },
        { key: 'all', value: 'Show all' },
    ]

    public static optionCalc = [
        { key: 'baseon_answer', value: 'Base on Answer' },
        { key: 'baseon_value', value: 'Base on Value' },
    ]

}