import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import SectionList from './section-list.component';
import SectionCreate from './section-create.component';
import SectionView from './section-view.component';
import SectionEdit from './section-edit.component';
import SectionCopy from './section-copy.component';
import SectionDelete from './section-delete.component';

interface Props {
  root?: string;
}

export const SectionRoute: React.FunctionComponent<Props> = (props) => {
  
  return (
    <Switch>
      <Route path={(props.root||'')+'/section/create/:id'} component={SectionCreate} />      
      <Route path={`${props.root||''}/section/view/:id`} render={(params)=> <SectionView {...params} root={props.root} key={params.match.params.id} />} />        
      <Route path={`${props.root||''}/section/edit/:id`} render={(params)=> <SectionEdit {...params} root={props.root} key={params.match.params.id} />} />        
      <Route path={(props.root||'')+'/section/copy/:id'} component={SectionCopy} />
      <Route path={`${props.root||''}/section/delete/:id`} render={(params)=> <SectionDelete {...params} root={props.root} key={params.match.params.id} />} />        
      <Route path={(props.root||'')+'/section'} render={(params)=> <SectionList {...params} root={props.root} />} /> 
    </Switch>
  );
};
