import React from 'react';
import { History } from 'history';
import { Input, InputMultiline, Switch, Picklist } from '../../components';
import BaseCreate from '../base/base-create.component'; 
import Model from './coa-model';  //editable..
import OwnerPicklist from '../owner/owner-picklist.component';
import CoaPicklist from './coa-picklist.component';
import CoagroupPicklist from '../coagroup/coagroup-picklist.component';
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class CoaCreate extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        this.onFieldValueChange = this.onFieldValueChange.bind(this);
    }

    
    private getDataRowOwner = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'owner' : data?.id || null, 'ownerCaption' : data?.name || ''  }  });
    };
    
    private getDataRowParent = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'parent' : data?.id || null, 'parentCaption' : data?.name || ''  }  });
    };
    
    private getDataRowCoagroup = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'coagroup' : data?.id || null, 'coagroupCaption' : data?.name || ''  }  });
    };
    
    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onFieldValueChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    render() {
        return (
            //editable..          
            <BaseCreate history={this.props.history} caption={'Create '+ Model.caption} 
                model={Model} dataRow={this.state.dataRow} getDataError={this.getDataError}>               
          
                <Input name='name' label='Name' value={this.state.dataRow.name} error={this.state.dataError?.name} onChange={this.onFieldValueChange} />
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} error={this.state.dataError?.description} onChange={this.onFieldValueChange} />
                <Switch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' error={this.state.dataError?.status} onChange={this.onFieldValueChange}/>
                {AppService.isAppFullVersion() &&
                <Picklist name='owner' label='Owner' value={this.state.dataRow.owner?.toString()} caption={this.state.dataRow.ownerCaption} error={this.state.dataError?.owner} onChange={this.onFieldValueChange} >
                    <OwnerPicklist currentId={this.state.dataRow.owner?.toString()} getDataRow={this.getDataRowOwner}/>
                </Picklist>
                }
                <Picklist name='parent' label='Parent' value={this.state.dataRow.parent?.toString()} caption={this.state.dataRow.parentCaption} clearValue={this.getDataRowParent} error={this.state.dataError?.parent} onChange={this.onFieldValueChange} >
                    <CoaPicklist currentId={this.state.dataRow.parent?.toString()} getDataRow={this.getDataRowParent}/>
                </Picklist>           
                <Picklist name='coagroup' label='Coa Group' value={this.state.dataRow.coagroup?.toString()} caption={this.state.dataRow.coagroupCaption} error={this.state.dataError?.coagroup} onChange={this.onFieldValueChange} >
                    <CoagroupPicklist currentId={this.state.dataRow.coagroup?.toString()} getDataRow={this.getDataRowCoagroup}/>
                </Picklist>             
            </BaseCreate>
        );
    }     
     
}