import * as React from 'react';
import { Box } from '@mui/material';

interface Props {
  factor?: number;
  height?: number;
  backgroundImageUrl?: string;
  backgroundElement?: React.ReactNode;
  children?: React.ReactNode;
}

export const ParallaxScroll: React.FunctionComponent<Props> = (props) => {;
  const [scrollTop, setScrollTop] = React.useState(0);
  
  const onScroll = React.useCallback((e: Event) => { 
    setScrollTop(window.scrollY * (props.factor || 0.5) || 0);
  }, []);

  React.useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    }
  }, [onScroll])

  
  return (
    <>
    <div style={{ 
      paddingTop: props.height || 0, 
      backgroundSize: '100%',
      backgroundImage: 'url("'+props.backgroundImageUrl+'")',      
      backgroundRepeat: 'no-repeat',
      backgroundPositionY: scrollTop,
      }}
    >
      <Box position='relative' top={scrollTop}  sx={{zIndex: 1}}>
        {props.backgroundElement}
      </Box>
      <Box position='relative' sx={{zIndex: 2}}>
        {props.children}
      </Box>
    </div>
    
    </>
    )
};
