import React from 'react';
import { Container, Box, Grid, Typography } from '@mui/material';
import { Button, Card, Icon, Numbering, Trapezoid } from '../../../components';
import AppService from '../../../services/app.service';

interface IProps { 
    dataRow: any;
}

interface IState {
}

export default class Student extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
        }
    }

    private isMobile: boolean = AppService.isMobile();

    private onClick = (link: string) => {
        window.location.href = link;
    }

    private materi01 () {
        return (
            <>
            <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}, textAlign: 'justify'}} >
                Adalah suatu keadaan sehat secara menyeluruh  mencakup fisik, mental, dan kehidupan sosial yang berkaitan  dengan alat, fungsi serta proses reproduksi yang pemikiran  kesehatan reproduksi bukannya kondisi yang bebas dari  penyakit melainkan bagaimana seseorang dapat memiliki  kehidupan seksual yang aman dan memuaskan sebelum dan  sesudah menikah (Kemenkes, 2016).
                <br/><br/>Kesehatan reproduksi adalah suatu keadaan sejahtera fisik, mental,  dan sosial secara utuh tidak semata-mata bebas dari penyakit atau  kecacatan dalam suatu yang berkaitan dengan system reproduksi,  fungsi dan prosesnya (WHO).
            </Typography>
            <Typography sx={{typography: {xs: 'h5', md: 'h4'}}} >
                <br/><br/><b>Kenapa Penting Belajar Kesehatan Reproduksi?</b>
            </Typography>
            <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}, textAlign: 'justify'}} >
                <br/>Agar dapat memahami dan menghargai tubuh kamu sendiri, jika kamu sudah kenal tubuh mu maka kamu harus menyayangi dan menjaga tubuh mu.
                <br/>Dan kamu akan lebih siap menghadapi masa pubertas.

                <br/><br/>Sekarang Kita akan Belajar Mengenali anggota tubuh dengan komik berikut
                <br/><br/><Button variant='contained' label='Mulai Belajar' onClick={()=>this.onClick('https://komik.pendidikan.id/online/komik/kenali_anggota_dan_fungsi_tubuh/#p=16')}/>

                <br/><br/>Sekarang Kita akan Belajar Perbedaan Laki-laki dan Perempuan
                <br/><br/><Button variant='contained' label='Lihat Perbedaan' onClick={()=>this.onClick('https://komik.pendidikan.id/online/komik/perbedaan_laki_laki_dan_perempuan/index.html#p=2')}/>
                
            </Typography>
            </>
        )
    }

    private materi02(){
        return (
            <>
            <Typography sx={{typography: {xs: 'h5', md: 'h4'}}} >
                <b>Cara menjaga organ reproduksi, diantaranya:</b>
                <br/><br/>
            </Typography>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <Numbering size={40} spacing={2} data={[
                        'Pakai handuk yang lembut, kering, bersih, dan tidak berbau atau lembab.',
                        'Memakai celana dalam dengan bahan yang mudah menyerap keringat.',
                        'Pakaian dalam diganti minimal 2 kali dalam sehari',
                        'Bagi perempuan, sesudah buang air kecil, membersihkan alat kelamin sebaiknya dilakukan dari arah depan menuju belakang agar kuman yang terdapat pada anus tidak masuk ke dalam organ reproduksi.',
                        'Bagi laki-laki, dianjurkan untuk dikhitan atau disunat agar mencegah terjadinya penularan penyakit menular seksual serta menurunkan risiko kanker penis.'
                    ]}/>
                </Grid>
                <Grid item xs={12} md={6} textAlign='center'>
                    <img src={process.env.PUBLIC_URL + '/assets/handsonkespro/handsonkespro-caramenjaga-1.png'} width='auto' height={this.isMobile? 400 : 600} alt='img' />
                </Grid>
            </Grid>
            <Grid container spacing={1} textAlign='center' mt={5} p={1}>
                <Grid item xs={12} md={4}>
                    <Card color='whitesmoke'>
                        <img src={process.env.PUBLIC_URL + '/assets/handsonkespro/handsonkespro-caramenjaga-2a.png'} width='auto' height={this.isMobile? 400 : 600} alt='img' />
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card color='secondary.light'>
                        <img src={process.env.PUBLIC_URL + '/assets/handsonkespro/handsonkespro-caramenjaga-2b.png'} width='auto' height={this.isMobile? 400 : 600} alt='img' />
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card color='whitesmoke'>
                        <img src={process.env.PUBLIC_URL + '/assets/handsonkespro/handsonkespro-caramenjaga-2c.png'} width='auto' height={this.isMobile? 400 : 600} alt='img' />
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card color='secondary.light'>
                        <Typography component='div' sx={{typography: {xs: 'subtitle1', md: 'h6'}, lineHeight: {xs: 1.2, md: 1.5}}}>
                            <img src={process.env.PUBLIC_URL + '/assets/handsonkespro/handsonkespro-caramenjaga-3.png'} width='auto' height={this.isMobile? 100 : 200} alt='img' />
                            <br/><br/><b>Alat kelamin tidak boleh dan dilihat oleh orang lain.</b>
                            <br/><br/><></>
                        </Typography>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card color='whitesmoke'>
                        <Typography component='div' sx={{typography: {xs: 'subtitle1', md: 'h6'}, lineHeight: {xs: 1.2, md: 1.5}}} color='primary.light'>
                            <img src={process.env.PUBLIC_URL + '/assets/handsonkespro/handsonkespro-caramenjaga-4.png'} width='auto' height={this.isMobile? 100 : 200} alt='img' />
                            <br/><br/><b>Tidak ada satupun yang boleh melihat / menyentuh anggota tubuhmu yang bersifat 'pribadi'.</b>
                        </Typography>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card color='secondary.light'>
                        <Typography component='div' sx={{typography: {xs: 'subtitle1', md: 'h6'}, lineHeight: {xs: 1.2, md: 1.5}}} >
                            <img src={process.env.PUBLIC_URL + '/assets/handsonkespro/handsonkespro-caramenjaga-5.png'} width='auto' height={this.isMobile? 100 : 200} alt='img' />
                            <br/><br/><b>Teriak dan katakan 'Tidak Mau!'.</b>
                            <br/><br/><br/><></>
                        </Typography>
                     </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card color='whitesmoke'>
                        <Typography component='div' sx={{typography: {xs: 'subtitle1', md: 'h6'}, lineHeight: {xs: 1.2, md: 1.5}}} color='primary.light'>
                            <img src={process.env.PUBLIC_URL + '/assets/handsonkespro/handsonkespro-caramenjaga-6.png'} width='auto' height={this.isMobile? 100 : 200} alt='img' />
                            <br/><br/><b>Jaga diri sendiri dan waspada.</b>
                            <br/><br/><br/><></>
                        </Typography>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card color='secondary.light'>
                        <Typography component='div' sx={{typography: {xs: 'subtitle1', md: 'h6'}, lineHeight: {xs: 1.2, md: 1.5}}} >
                            <img src={process.env.PUBLIC_URL + '/assets/handsonkespro/handsonkespro-caramenjaga-7.png'} width='auto' height={this.isMobile? 100 : 200} alt='img' />
                            <br/><br/><b>Lari dan teriak minta tolong jika ada seseorang yang ingin melihat / menyentuh area pribadimu.</b>
                        </Typography>
                    </Card>
                </Grid>
            </Grid>
            </>
        )
    }
    
    private materi03(){
        return (
            <>            
            <Typography sx={{typography: {xs: 'h5', md: 'h4'}}} >
                <b>Apakah Pubertas itu?</b>
            </Typography>
            <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}, textAlign: 'justify'}} >
                <br/>Pubertas adalah proses pematangan fisik, yang tujuan utamanya adalah Mampu bereproduksi secara seksual, tubuh sedang mengalami perubahan. Perubahan dari struktur tubuh anak-anak hingga struktur tubuh manusia Dewasa. Perubahan kematangan fisik, psikis dan seksual. Di antara anak laki-laki antara usia 11-12, lebih lambat dari wanita dengan Dari 8-10 tahun. Tapi ini tidak mutlak, karena kondisi fisik setiap orang orang yang berbeda.
                <br/><br/>Anak laki-laki dan perempuan akan mengalami perubahan tersebut. Perubahan tersebut dikarenakan adanya perubahan hormon. Pada masa pubertas, pertumbuhan terkadang terasa sangat cepat. Terkadang terjadi perubahan perasaan pada saat memasuki masa pubertas. Perubahan tersebut tentu akan membuatmu tidak nyaman. Namun jangan khawatir, ini adalah hal yang wajar. Mengalami pubertas artinya tubuhmu normal dan sehat.
            </Typography>
            <Typography component='div' sx={{typography: {xs: 'h5', md: 'h4'}}} >
                <br/><br/><b>Ciri-ciri Pubertas?</b>
                <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}, color: 'primary.light'}} >
                    <b>(Untuk siswa dan siswi di bawah 13 tahun)</b>
                </Typography>
            </Typography>
            <Grid container mt={5}>
                <Grid item xs={12} md={6}>
                    <Numbering size={40} spacing={2} data={[
                        'Badan menjadi lebih tinggi dan kekar, Pertumbuhan otot dan tulang akan meningkatkan berat badan.',
                        'Tumbuhnya rambut di bawah ketiak dan sekitar 2 area penis. Untuk sebagian laki-laki rambut juga tumbuh dibagian muka, dada, dan kaki.',
                    ]}/>
                </Grid>
                <Grid item xs={12} md={6} textAlign='center'>
                    <img src={process.env.PUBLIC_URL + '/assets/handsonkespro/handsonkespro-pubertas-1.png'} width='auto' height={this.isMobile? 100 : 200} alt='img' />
                    <img src={process.env.PUBLIC_URL + '/assets/handsonkespro/handsonkespro-pubertas-2.png'} width='auto' height={this.isMobile? 100 : 200} alt='img' />
                </Grid>
                <Grid item xs={12} md={8}>
                    <Numbering size={40} start={3} spacing={2} data={[
                        'Perubahan suara. Perubahan ada yang menghasilkan suara melengking, serak atau parau. Laring anak laki-laki tumbuh sangat cepat selama masa pubertas, sehingga kadang memperbesar kapasitas pita suara. Laring dan pita suara anak laki-laki tumbuh secara signifikan dari waktu ke waktu terutama pada masa pubertas. Inilah yang membuat suara mereka menjadi lebih berat atau dalam.',
                    ]}/>
                </Grid>
                <Grid item xs={12} md={4} textAlign='center'>
                    <img src={process.env.PUBLIC_URL + '/assets/handsonkespro/handsonkespro-pubertas-3.png'} width='auto' height={this.isMobile? 100 : 200} alt='img' />
                </Grid>
                <Grid item xs={12} md={8}>
                    <Numbering size={40} start={4} spacing={2} data={[
                        'Mimpi Basah. Hormon testosteron akan menyebabkan penis anak laki-laki tumbuh lebih besar. Hormon testosteron juga akan menyebabkan alat reproduksi mulai menghasilkan sperma. Mimpi basah adalah proses ejakulasi alami atau keluarnya sperma dari kantung testis. Hal ini karena kantung testis terlalu penuh berisi sperma. Tubuh merespon mengeluarkan melalui mimpi basah.',
                    ]}/>
                </Grid>
                <Grid item xs={12} md={4} textAlign='center'>
                    <img src={process.env.PUBLIC_URL + '/assets/handsonkespro/handsonkespro-pubertas-4.png'} width='auto' height={this.isMobile? 100 : 200} alt='img' />
                </Grid>
            </Grid>
            <Typography component='div' sx={{typography: {xs: 'subtitle1', md: 'h6'}}} >
                <br/><br/><b>Perubahan Masa Pubertas pada Anak Perempuan :</b>
                <br/><br/>Anak perempuan biasanya mengalami masa pubertas lebih cepat daripada anak laki-laki. Usia pubertas anak perempuan biasanya dimulai antara usia 8-13 tahun. Ciri-ciri pubertas pada anak perempuan adalah:
            </Typography>
            
            <Grid container mt={5}>
                <Grid item xs={12} md={8}>
                    <Numbering size={40} spacing={2} data={[
                        'Payudara mulai tumbuh besar. Pada awal pertumbuhan terasa sakit dan tidak nyaman. Perempuan membutuhkan bra untuk menopang payudara.',
                        'Perubahan juga terjadi di dalam tubuh. Perubahan terjadi pada alat reproduksi. Pada saat pertumbuhan alat reproduksi telah optimal, perempuan mengalami proses menstruasi. Pertumbuhan alat reproduksi memungkinkan seorang perempuan hamil dan melahirkan.',
                    ]}/>
                </Grid>
                <Grid item xs={12} md={4} textAlign='center'>
                    <img src={process.env.PUBLIC_URL + '/assets/handsonkespro/handsonkespro-pubertas-5.png'} width='auto' height={this.isMobile? 100 : 200} alt='img' />
\                </Grid>
            </Grid>
            <Typography component='div' sx={{typography: {xs: 'h5', md: 'h4'}}} >
                <br/><br/><b>Bagaimana Terjadinya Pubertas Pada Anak Perempuan dan Anak Laki-laki?</b>
                <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}, color: 'primary.light'}} >
                    <b>(Untuk siswa dan siswi di atas 13 tahun)</b>
                </Typography>
            </Typography>
            <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}, textAlign: 'justify'}} >
                <br/>Penyebab munculnya Pubertas adalah hormon yang dipengaruhi oleh Hipofisis. Pubertas terjadi karena tubuh mulai memproduksi hormon-hormon seks sehingga alat reproduksi telah berfungsi dan tubuh mengalami perubahan.  Pada anak perempuan ditandai dengan menstruasi pertama kali dan pada anak laki-laki ditandai dengan mimpi basah yang pertama kali.
            </Typography>
            <Typography component='div' sx={{typography: {xs: 'h5', md: 'h4'}}} >
                <br/><br/><b>Apa Itu Menstruasi?</b>
                <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}, textAlign: 'justify', color: 'primary.light'}} >
                    <b>(Khusus untuk siswi)</b>
                </Typography>
            </Typography>
            <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}, textAlign: 'justify'}} mb={3}>
                <br/>Penyebab munculnya Pubertas adalah hormon yang dipengaruhi oleh Hipofisis. Pubertas terjadi karena tubuh mulai memproduksi hormon-hormon seks sehingga alat reproduksi telah berfungsi dan tubuh mengalami perubahan.  Pada anak perempuan ditandai dengan menstruasi pertama kali dan pada anak laki-laki ditandai dengan mimpi basah yang pertama kali.
                <br/><br/>Menstruasi adalah proses yang dialami semua perempuan untuk jadi dewasa. Ditandai dengan keluarnya darah. Ini artinya alat reproduksi kamu mulai aktif hingga nantinya siap jadi ibu.
                <br/><br/>Siklus menstruasi biasanya terjadi selama 3-7 hari dan berulang setiap 21 hingga 30 hari. Rata-rata sih 28 hari, tapi bisa berbeda di tiap orang.
                <br/><br/>Saat pertama kali menstruasi, kamu mungkin merasa takut, malu atau panik. Jangan khawatir, menstruasi adalah proses alami. Bicaralah pada ibu, kakak, atau ibu guru.
            </Typography>            
            <Card>
            <Icon name='format_quote' size='extralarge' color='primary.light'/>
            <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}, textAlign: 'justify', color:'primary.light', fontStyle: 'italic'}} ml={3} mr={5} mb={3}>
                Yang perlu di perhatikan adalah Jika  darah menstruasi terus keluar hingga 15 hari, segera saja pergi ke puskesmas, rumah sakit atau klinik untuk diperiksa dokter atau bidan ya.
                <br/><br/>Catatlah selalu tanggal hari pertama haid!. 
            </Typography>
            </Card>
            <Typography component='div' sx={{typography: {xs: 'h5', md: 'h4'}}} >
                <br/><br/><b>Apa Itu Pembalut?</b>
                <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}, textAlign: 'justify', color: 'primary.light'}} >
                    <b>(Khusus untuk siswi)</b>
                </Typography>
            </Typography>
            <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}, textAlign: 'justify'}} >
                <br/>Benda yang berguna untuk menampung darah menstruasi. Pembalut sangat dibutuhkan saat menstruasi. Gunanya menampung darah agar tidak bocor dan bikin kita tetap bersih.
                <br/><br/><b></b>Ada Dua Macam Pembalut!
            </Typography>
            <Typography component='div' sx={{typography: {xs: 'caption', md: 'subtitle1'}, textAlign: 'center'}} mt={3}>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <img src={process.env.PUBLIC_URL + '/assets/handsonkespro/handsonkespro-pembalut-1.png'} width='auto' height={this.isMobile? 150 : 300} alt='img' />
                        <br/>Pembalut sekali pakai
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <img src={process.env.PUBLIC_URL + '/assets/handsonkespro/handsonkespro-pembalut-2.png'} width='auto' height={this.isMobile? 150 : 300} alt='img' />
                        <br/>Pembalut Kain
                    </Grid>
                </Grid>
            </Typography>
            <Typography component='div' sx={{typography: {xs: 'subtitle1', md: 'h6'}}} >
                <br/><br/><b>Apa Itu Pembalut Sekali Pakai?</b>
                <br/><br/>Pembalut sekali pakai sangat praktis digunakan dan mudah didapatkan di toko atau warung terdekat.
                <br/><br/><b><i>Cara pakai pembalut</i></b>
                <br/><br/><img src={process.env.PUBLIC_URL + '/assets/handsonkespro/handsonkespro-pembalut-3.png'} width='auto' height={this.isMobile? 300 : 500} alt='img' />
                <br/><br/><b>Bagaimana Membuat Pembalut Kain?</b>
                <br/><br/>Pembalut kain, bisa dibuat sendiri dari bahan kain atau handuk.
                <br/><br/><b><i>Cara bikin</i></b>
                <br/><br/><img src={process.env.PUBLIC_URL + '/assets/handsonkespro/handsonkespro-pembalut-4.png'} width='auto' height={this.isMobile? 100 : 200} alt='img' />
            </Typography>
            <Typography component='div' sx={{typography: {xs: 'h5', md: 'h4'}}} >
                <br/><br/><b>Apa Itu Manajemen Kebersihan Menstruasi</b>
                <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}, textAlign: 'justify', color: 'primary.light'}} >
                    <b>(Khusus untuk siswi)</b>
                </Typography>
            </Typography>
            <Typography component='div' sx={{typography: {xs: 'subtitle1', md: 'h6'}, textAlign: 'justify'}} >
                <br/><br/>Pengelolaan kebersihan dan kesehatan pada saat perempuan mengalami menstruasi.
                <br/><br/>
                <Numbering size={40} spacing={2} data={[
                    'Menjaga kebersihan dengan mandi dua kali sehari.',
                    'Menggunakan sabun mandi biasa, pada saat mandi organ reproduksi luar perlu cermat dibersihkan.',
                    'Mengganti pembalut minimal 4 kali sehari terutama sehabis buang air kecil.',
                    'Bila perut terutama daerah sekitar rahim terasa nyeri, dan masih dapat diatasi (ringan), tidak usah dibiasakan minum obat penghilang rasa sakit, kecuali sangat mengganggu seperti misalnya hingga menyebabkan pingsan.',
                    'Makan-makanan bergizi, terutama yang banyak mengandung zat besi dan vitamin, seperti hati ayam/sapi, daging, telur, sayur dan buah.',
                    'Aktivitas harian tidak perlu diubah kecuali bila ada aktivitas fisik yang berlebihan misalnya olahraga berat, terutama pada siswi sekolah perlu dipertimbangkan'
                ]}/>
            </Typography>
            <Typography component='div' sx={{typography: {xs: 'h5', md: 'h4'}}} >
                <br/><br/><b>Berapa Kali Dalam Sehari Pembalut Harus Diganti?</b>
                <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}, textAlign: 'justify', color: 'primary.light'}} >
                    <b>(Khusus untuk siswi)</b>
                </Typography>
            </Typography>
            <Typography component='div' sx={{typography: {xs: 'subtitle1', md: 'h6'}, textAlign: 'justify'}} >
                <br/><br/>
                <Numbering size={40} spacing={2} data={[
                    'Pembalut sebaiknya diganti setiap 4 sampai 5 jam sekali dan bisa lebih sering apabila darah keluar banyak.',
                    'Waktu yang dianjurkan untuk mengganti pembalut bagi anak perempuan usia sekolah ialah saat mandi pagi, saat di sekolah, setelah pulang sekolah, saat mandi sore, dan sebelum tidur.',
                    'Pembalut harus sering diganti untuk mencegah infeksi saluran reproduksi, saluran kencing, dan iritasi kulit. Ingat untuk selalu mencuci tangan sebelum dan sesudah mengganti pembalut.',
                    'Pembalut sekali pakai harus dibuang setelah digunakan. ',
                    'Bungkus pembalut dengan kertas atau kantung kertas bekas dan masukkan ke tempat sampah.',
                    'Jangan membuang pembalut di lubang jamban atau kloset karena hal ini dapat menyebabkan lubang jamban atau kloset tersumbat.'
                ]}/>
            </Typography>
            <Typography component='div' sx={{typography: {xs: 'h5', md: 'h4'}}} >
                <br/><br/><b>Bagaimana Proses Terjadinya Mimpi Basah?</b>
                <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}, textAlign: 'justify', color: 'primary.light'}} >
                    <b>(Khusus untuk siswa)</b>
                </Typography>
            </Typography>
            <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}, textAlign: 'justify'}} >
                <br/>Ketika seorang laki-laki memasuki masa pubertas maka mulai terjadi proses pematangan sperma yang terjadi di testis. Produksi sperma sangat dipengaruhi oleh faktor nutrisi, istirahat, rokok, narkoba, alkohol dan lain-lain. Sperma yang telah diproduksi ini akan dikeluarkan dari testis melalui saluran/vas deferens kemudian berada dalam cairan mani yang diproduksi oleh kelenjar prostat dan kelenjar lainnya.
                <br/>Airmani yang telah mengandung sperma akan keluar dari dalam tubuh laki-laki melalui saluran kemih di batang penis, pengeluaran sperma itu disebut ejakulasi dan ejakulasi yang alami tanpa rangsangan terjadi saat mimpi basah.
            </Typography>
            <Typography component='div' sx={{typography: {xs: 'h5', md: 'h4'}}} >
                <br/><br/><b>Pada Laki-laki Berapa Kalikah Dalam Sebulan Meraka Mengalami Mimpi Basah?</b>
                <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}, textAlign: 'justify', color: 'primary.light'}} >
                    <b>(Khusus untuk siswa)</b>
                </Typography>
            </Typography>
            <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}, textAlign: 'justify'}} >
            <br/>Mimpi basah umumnya terjadi secara periodik, bekisar setiap 2 - 3 minggu. Sel sperma diproduksi oleh tubuh manusia dari makanan yang masuk setiap hari ke dalam tubuh kita. Sperma ini akan disimpan dalam kantung mani, jika penuh akan secara otomatis keluar, dan jika tidak terjadi pengeluaran sperma ini akan diserap kembali oleh tubuh. Mereka yang sudah dewasa/menikah jarang mengalami mimpi basah karena mereka teratur mengeluarkannya melalui hubungan seksual dengan pasangan/istri.
            </Typography>
            <Typography component='div' sx={{typography: {xs: 'h5', md: 'h4'}}} >
                <br/><br/><b>Apakah Yang Dimaksud Dengan Sunat Atau Sirkumsisi?</b>
                <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}, textAlign: 'justify', color: 'primary.light'}} >
                    <b>(Khusus untuk siswa)</b>
                </Typography>
            </Typography>
            <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}, textAlign: 'justify'}} >
                <br/>Pada laki-laki sunat dilakukan dengan cara membuang kulit/preputium. Dalam beberapa suku bangsa hal ini merupakan bagian dari budaya. Dan menurut ajaran agama Islam, sirkumsisi bahkan sangat dianjurkan. Dari sisi medis sunat sangat bermanfaat karena kebersihan penis menjadi lebih terjaga. Preputium dapat menjadi tempat berkumpulnya sisa-sisa air seni dan kotoran lain yang membentuk zat berwarna putih disebut smegma, ini sangat potensial sebagai sumber infeksi. Dengan membuang kulit/preputium maka risiko terkena infeksi dan penyakit lain menjadi lebih kecil. Walaupun tetap kebersihan penis harus dijaga.
            </Typography>
            <Typography component='div' sx={{typography: {xs: 'h5', md: 'h4'}}} >
                <br/><br/><b>Bagaimana Cara Menjaga Organ Reproduksi Laki-laki?</b>
                <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}, textAlign: 'justify', color: 'primary.light'}} >
                    <b>(Khusus untuk siswa)</b>
                </Typography>
            </Typography>
            <Typography component='div' sx={{typography: {xs: 'subtitle1', md: 'h6'}, textAlign: 'justify'}} >
                <br/><br/>
                <Numbering size={40} spacing={2} data={[
                    'Konsumsi makanan yang bergizi, rendah lemak, dan lakukan aktivitas fisik secara teratur. Jika Kamu mengonsumsi makanan yang tinggi lemak secara berlebihan, hal ini akan meningkatkan risiko obesitas.',
                    'Mulailah untuk berhenti merokok karena penelitian menunjukkan bahwa pria yang merokok memiliki risiko menderita disfungsi ereksi.',
                    'Bersihkan area genital secara teratur untuk mencegah penumpukan kotoran dan kuman yang bisa menyebabkan infeksi.',
                    'Gunakanlah handuk yang bersih, lembut, tidak berbau dan lembap, serta kering.',
                    'Gunakanlah celana dalam yang bahannya mudah menyerap keringat sehingga tidak lembap. Cobalah sebisa mungkin untuk mengganti celana dalam setidaknya 2 kali dalam sehari.',
                    'Dianjurkan untuk dikhitan atau disunat. Hal ini untuk mencegah risiko kanker penis dan penularan penyakit menular seksual.'
                ]}/>
            </Typography>
        </>
        )
    }
    
    private materi04(){
        return (
            <>
            <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}, textAlign: 'justify'}} >
                <br/>Gizi adalah substansi organik berupa zat pada makanan yang dibutuhkan organisme untuk menjaga fungsi dari sistem tubuh, pertumbuhan, pemeliharaan jaringan tubuh, dan kesehatan.
            </Typography>
            <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}} >
                <br/><br/><b>Dalam makanan terdapat 5 (lima) kelompok zat gizi yaitu :</b>
            </Typography>
            <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}, textAlign: 'justify'}} >
                <br/><br/><b>HIDRAT ARANG atau KARBOHIDRAT</b>
                <br/><br/>Makanan pokok menghasilkan tenaga yang satuannya kalori. Sumber tenaga ini dibutuhkan untuk bekerja, bernafas dan lain-lain. Contoh makanan sumber karbohidrat: nasi, kentang, ubi, singkong, mie, roti, sagu, jagung dan lain-lain. 
                <br/><br/><b>PROTEIN</b>
                <br/><br/>Protein terdiri dari protein nabati dan protein hewani. Banyak terdapat dalam lauk pauk, protein nabati (tumbuhan) seperti tahu, tempe, kacang kedelai dan kacang-kacangan yang lain. Protein hewani seperti daging, telur, ikan dan lain-lain. 
                <br/><br/><b>LEMAK</b>
                <br/><br/>Banyak terdapat dalam lauk-pauk (daging berlemak) dan minyak (minyak goreng).
            </Typography>
            </>
        )
    }
    
    private materi(){
        switch (this.props.dataRow.id) {
            case 0: return this.materi01();
            case 1: return this.materi02();
            case 2: return this.materi03();
            case 3: return this.materi04();
        }
    }

    render() {
        
        return (
            //editable..
            <>
            <Trapezoid id='header' position='bottomLeft' colorOthers='white'>
                <Grid container>
                    <Grid item xs={8} md={6} pl={3}> 
                        <Typography sx={{typography: {xs: 'h5', md: 'h4'}, lineHeight: {xs: 1, md: 1}}} color='primary.light'>
                        <b>{this.props.dataRow.name}</b> <br/>
                        </Typography>  
                        <Typography sx={{typography: {xs: 'h4', md: 'h3'}, lineHeight: {xs: 1, md: 1}}} color='white'>
                            <b>{this.props.dataRow.description}</b>
                        </Typography>  
                    </Grid>
                </Grid>
            </Trapezoid>
            <Box p={3} bgcolor='white'>
                <Container>
                <Grid container>
                    {this.materi()}
                </Grid>
                </Container>
            </Box>
            </>
        );
    }
}