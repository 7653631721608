import React from 'react';
import { History } from 'history';
import { Input, InputNumber, InputMultiline, InputSelect, Layout, Switch, Picklist } from '../../components';
import BaseDelete from '../base/base-delete.component'; 
import Model from './testingtool-model';  //editable..
import QuestionList from '../question/question-list.component';
import TestingtoolList from './testingtool-list.component';
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string },
    };
    root?: string;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class TestingtoolDelete extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    render() {
        return (
            //editable..
            <BaseDelete history={this.props.history} match={this.props.match} caption={'Delete '+ Model.caption} 
                model={Model} getDataRow={this.getDataRow} disableCard>              

                <Layout content={[
                    {caption: 'Details', child: 
                    <>
                        <Input name='name' label='Name' value={this.state.dataRow.name} readOnly/>
                        <InputMultiline name='description' label='Description' value={this.state.dataRow.description} readOnly/>
                        <Switch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' readOnly/>
                        {AppService.isAppFullVersion() &&
                        <Picklist name='owner' label='Owner' value={this.state.dataRow.owner?.toString()} caption={this.state.dataRow.ownerCaption} readOnly/>
                        }
                        <Picklist name='parent' label='Parent' value={this.state.dataRow.parent?.toString()} caption={this.state.dataRow.parentCaption} readOnly/>
                        <Input name='title' label='Title' value={this.state.dataRow.title} readOnly/>
                        <InputMultiline name='content' label='Content' value={this.state.dataRow.content} readOnly/>
                        <InputNumber name='seq' label='Seq' value={this.state.dataRow.seq} readOnly/>
                        <InputSelect name='viewstyle' label='View Style' value={this.state.dataRow.viewstyle} options={Model.optionViewstyle} readOnly/>
                        <InputSelect name='calc' label='Calculation' value={this.state.dataRow.calc} options={Model.optionCalc} readOnly/>
                        <InputNumber name='timelimit' label='Time Limit (Seconds)' value={this.state.dataRow.timelimit} readOnly/>
                    </>
                    },

                    {caption: this.state.dataRow.testingtoolLength>0? 'Sections' : 'Questions', child: 
                    this.state.dataRow.id &&
                    <>
                        {this.state.dataRow.testingtoolLength>0?
                        <TestingtoolList history={this.props.history} root={this.props.root} caption='Testing Tool Section' parentId={this.state.dataRow.id.toString()} actions={['view']} disableBackButton disableCard/>
                        : <QuestionList history={this.props.history} root={this.props.root} testingtoolId={this.state.dataRow.id.toString()} actions={['view']} disableBackButton disableCard/>
                        }
                    </>
                    },

                    ]}
                    
                />                
                                
            </BaseDelete>
        );
    }
}