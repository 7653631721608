import * as React from 'react';
import { TextField, MenuItem } from '@mui/material';

interface Props {
  name: string;
  label: string;
  placeHolder?: string;
  value: string;
  options: any;
  readOnly?: boolean;
  onChange?: (fieldName: string, value: string) => void;
  error?: string;
}

export const InputSelect: React.FunctionComponent<Props> = (props) => {
  const onChange = (props: Props) => (e: React.ChangeEvent<HTMLInputElement>) => { 
    props.onChange?.(e.target.name, e.target.value);
  };
  
  const getDesc = () => {
    return props.options.filter((d: any) => d.key === props.value)?.[0]?.value || '';
  }

  return (
    <TextField
      select={!props.readOnly}
      margin='normal'
      id={props.name}
      name={props.name}
      label={props.label} 
      value={props.readOnly? getDesc() : (props.value || '')}
      placeholder={props.placeHolder}
      InputProps={{
        readOnly: props.readOnly || false,
        sx: {fontSize: {xs: '14px', md: '16px'}},
      }}
      InputLabelProps={{
        sx: {fontSize: {xs: '14px', md: '16px'}}
      }}
      variant='standard'
      fullWidth
      helperText={props.error}
      error= {props.error? true: false}
      onChange={onChange(props)}
    >
      {
        props.options.map((option: any) => (
          <MenuItem key={option.key} value={option.key}>
            {option.value}
          </MenuItem>
        ))
      }
    </TextField>
  )
};