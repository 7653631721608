import React from 'react';
import BaseView from '../base/base-view.component'; 
import Model from './evaluationdetail-model';  //editable..
import EvaluationdetailEdit from './evaluationdetail-edit.component';
import EvaluationdetailCreate from './evaluationdetail-create.component';

interface IProps { 
    match:{ 
        isExact: boolean
        params: { id:string },
    };
    questionId: string;
    onSave?: (data: any)=>void;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class EvaluationdetailViewUser extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
    }


    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    private onSave = (data:any) => {
        this.setState({ dataRow: { ...this.state.dataRow, id: data.id}});
        this.props.onSave?.(data);
    }

    render() {
        return (
            //editable..
            <BaseView match={this.props.match} caption=' '
                endPointFilter={Model.endPointFilter2+this.props.questionId+'&filters[evaluation]='} model={Model} getDataRow={this.getDataRow} disableBackButton disableCard>
                <>
                {this.state.dataRow?.id?
                    <EvaluationdetailEdit match={{isExact:true, params: {id: this.state.dataRow.id.toString()}}} caption=' ' buttonCaption=' ' questionId={this.props.questionId.toString()} autoSave disableAutoBack disableBackButton disableCard/>
                    :<EvaluationdetailCreate match={this.props.match} caption=' ' buttonCaption=' ' questionId={this.props.questionId.toString()} onSave={this.onSave} autoSave disableAutoBack disableBackButton disableCard/>
                }      
                </>
            </BaseView> 
        
            
        );
    }
}