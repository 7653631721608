import React from 'react';
import { History } from 'history';
import { Input, InputNumber, InputMultiline, Switch, Picklist, InputSelect, Layout } from '../../components';
import BaseView from '../base/base-view.component'; 
import Model from './testingtoolresume-model';  //editable..
import TestingtoolresumeList from './testingtoolresume-list.component';
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string },
    };
    root?: string;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class TestingtoolresumeView extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    render() {
        return (
            //editable..
            <BaseView history={this.props.history} match={this.props.match} caption={'View '+ Model.caption} 
                model={Model} getDataRow={this.getDataRow}>              
                
                <Layout content={[
                    {caption: 'Details', child: 
                    <>
                        <Input name='name' label='Name' value={this.state.dataRow.name} readOnly/>
                        <InputMultiline name='description' label='Description' value={this.state.dataRow.description} readOnly/>
                        <Switch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' readOnly/>
                        {AppService.isAppFullVersion() &&
                        <Picklist name='owner' label='Owner' value={this.state.dataRow.owner?.toString()} caption={this.state.dataRow.ownerCaption} readOnly/>
                        }
                        <Input name='title' label='Title' value={this.state.dataRow.title} readOnly/>
                        <InputMultiline name='content' label='Content' value={this.state.dataRow.content} readOnly/>
                        <InputNumber name='seq' label='Seq' value={this.state.dataRow.seq} readOnly/>
                        <InputNumber name='valuemin' label='Value Min.' value={this.state.dataRow.valuemin} readOnly/>
                        <InputNumber name='valuemax' label='Value Max.' value={this.state.dataRow.valuemax} readOnly/>
                        <InputNumber name='scale' label='Scale' value={this.state.dataRow.scale} readOnly/>
                        <InputSelect name='calc' label='Calc Type' value={this.state.dataRow.calc} options={Model.optionCalc} readOnly/>
                        <InputNumber name='factor' label='Factor' value={this.state.dataRow.factor} readOnly/>
                    </>
                    },

                    {caption: 'Sections', child: 
                    this.state.dataRow.id &&
                        <TestingtoolresumeList history={this.props.history} root={this.props.root} caption='Testing Tool Resume Section' parentId={this.state.dataRow.id.toString()} actions={['view']} disableBackButton disableCard/>
                    },

                    ]}
                    
                />                  
            </BaseView>
        );
    }
}