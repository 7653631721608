import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import PersonalinfoList from './personalinfo-list.component';
import PersonalinfoCreate from './personalinfo-create.component';
import PersonalinfoView from './personalinfo-view.component';
import PersonalinfoEdit from './personalinfo-edit.component';
import PersonalinfoCopy from './personalinfo-copy.component';
import PersonalinfoDelete from './personalinfo-delete.component';

interface Props {
  root?: string;
}

export const PersonalinfoRouteEmployee: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>     
      <Route path={`${props.root||''}/employee/create`} render={(params)=> <PersonalinfoCreate {...params} caption='Employee' type='employee' />} />    
      <Route path={`${props.root||''}/employee/view/:id`} render={(params)=> <PersonalinfoView {...params} root={props.root} caption='Employee' />} />        
      <Route path={`${props.root||''}/employee/edit/:id`} render={(params)=> <PersonalinfoEdit {...params} root={props.root} caption='Employee' type='employee' />} />        
      <Route path={`${props.root||''}/employee/copy/:id`} render={(params)=> <PersonalinfoCopy {...params} caption='Employee' type='employee' />} />     
      <Route path={`${props.root||''}/employee/delete/:id`} render={(params)=> <PersonalinfoDelete {...params} caption='Employee' root={props.root}/>} />        
      <Route path={(props.root||'')+'/employee'} render={(params)=> <PersonalinfoList {...params} root={props.root} caption='Employee' type='employee' />} /> 
    </Switch>
  );
};
