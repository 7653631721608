import * as React from 'react';
import { History } from 'history';
import { Button, Chip, Grid } from '@mui/material';
import IconBack from '@mui/icons-material/ArrowBack';
import IconNext from '@mui/icons-material/ArrowForward';
import { Card } from '../../components';
import Model from './question-model'; //editable..
import BaseList from '../base/base-list.component';
import EvaluationdetailViewUser from '../evaluationdetail/evaluationdetail-view-user.component';

interface IProps {  
  history: History;
  match:{ 
      isExact: boolean
      params: { id:string },
  };
  root?: string;
  testingtoolId: string;
  viewStyle: string;
  getDataRow: (data: any) => void;
  onSave?: (data: any)=>void;
  
}

interface IState {
  dataSet: Array<Model>;
  pageInfo: any;
  pageSize: number;
  page: number;
}

export default class QuestionListUser extends React.Component<IProps, IState> {
  
  constructor(props: IProps) {
    super(props);
    
    this.state = {
      dataSet: [],
      pageInfo: null,
      pageSize: this.props.viewStyle==='single'? 1:20,
      page: 1,
    }
  }

  private getDataSet = (data:any, pageInfo: any) => {
    this.setState({dataSet: data, pageInfo: pageInfo});
    if (this.props.getDataRow) {
      this.props.getDataRow({'total':pageInfo.total});
    } 
  }
  private goTop () {
    document.getElementById('QuestionLists')?.scrollIntoView(false);
  };

  public render(): React.ReactNode {
    return (
      <Grid container id='QuestionLists' spacing={1} m={0} mt={1}>
      <BaseList history={this.props.history} parentId={this.props.testingtoolId} caption={' '} endPointFilter={Model.endPointFilter+this.props.testingtoolId} model={Model} page={this.state.page} pageSize={this.state.pageSize} getDataSet={this.getDataSet} disableTable/>
      
      {this.state.dataSet.map((dataRow: any) => { 
          if (dataRow.id) return (
            <Card key={dataRow.id} color='white' caption={dataRow.name}>
                <EvaluationdetailViewUser match={this.props.match} questionId={dataRow.id}  onSave={this.props.onSave} />
            </Card>
          )
      })}

      {this.state.pageInfo?.pageCount>0?
          <Grid item>
          {this.state.pageInfo.pageCount<=1? '':
            <Chip label= {'Page '+ this.state.page.toString()+ ' / ' + this.state.pageInfo.pageCount.toString()} />
          }
          {this.state.page<=1? '':
              <Button startIcon={<IconBack/>} size='large' onClick={()=>{this.setState({page: this.state.page-1}); this.goTop()}}>Back</Button>
          }
          {this.state.page===this.state.pageInfo.pageCount? '':
              <Button endIcon={<IconNext/>} size='large' onClick={()=>{this.setState({page: this.state.page+1}); this.goTop()}}>Next</Button>
          }
          </Grid>
      :''}
      </Grid>
      );
  }
}
