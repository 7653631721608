import React from 'react';
import { History } from 'history';
import { Input, InputNumber, InputMultiline, InputSelect, Layout, Switch, Picklist } from '../../components';
import BaseEdit from '../base/base-edit.component'; 
import Model from './testingtool-model';  //editable..
import OwnerPicklist from '../owner/owner-picklist.component';
import TestingtoolPicklist from './testingtool-picklist.component';
import QuestionList from '../question/question-list.component';
import TestingtoolList from './testingtool-list.component';
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string },
    };
    root?: string;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class TestingtoolEdit extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        this.onFieldValueChange = this.onFieldValueChange.bind(this);
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    private getDataRowOwner = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'owner' : data?.id || null, 'ownerCaption' : data?.name || ''  }  });
    };
    
    private getDataRowParent = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'parent' : data?.id || null, 'parentCaption' : data?.name || ''  }  });
    };
    
    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onFieldValueChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    render() {
        return (
            //editable..
            <BaseEdit history={this.props.history} match={this.props.match} caption={'Edit '+ Model.caption} 
                model={Model} dataRow={this.state.dataRow} getDataRow={this.getDataRow} getDataError={this.getDataError} disableCard>              

                <Layout content={[
                    {caption: 'Details', child: 
                    <>
                        <Input name='name' label='Name' value={this.state.dataRow.name} error={this.state.dataError?.name} onChange={this.onFieldValueChange} />
                        <InputMultiline name='description' label='Description' value={this.state.dataRow.description} error={this.state.dataError?.description} onChange={this.onFieldValueChange} />
                        <Switch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' error={this.state.dataError?.status} onChange={this.onFieldValueChange}/>
                        {AppService.isAppFullVersion() &&
                        <Picklist name='owner' label='Owner' value={this.state.dataRow.owner?.toString()} caption={this.state.dataRow.ownerCaption} error={this.state.dataError?.owner} onChange={this.onFieldValueChange} >
                            <OwnerPicklist currentId={this.state.dataRow.owner?.toString()} getDataRow={this.getDataRowOwner}/>
                        </Picklist> 
                        }
                        <Picklist name='parent' label='Parent' value={this.state.dataRow.parent?.toString()} caption={this.state.dataRow.parentCaption} error={this.state.dataError?.parent} onChange={this.onFieldValueChange} >
                            <TestingtoolPicklist currentId={this.state.dataRow.parent?.toString()} getDataRow={this.getDataRowParent}/>
                        </Picklist> 
                        <Input name='title' label='Title' value={this.state.dataRow.title} error={this.state.dataError?.title} onChange={this.onFieldValueChange} />
                        <InputMultiline name='content' label='Content' value={this.state.dataRow.content} error={this.state.dataError?.content} onChange={this.onFieldValueChange} />
                        <InputNumber name='seq' label='Seq' value={this.state.dataRow.seq} error={this.state.dataError?.seq} onChange={this.onFieldValueChange} />
                        <InputSelect name='viewstyle' label='View Style' value={this.state.dataRow.viewstyle} options={Model.optionViewstyle} error={this.state.dataError?.viewstyle} onChange={this.onFieldValueChange} />
                        <InputSelect name='calc' label='Calculation' value={this.state.dataRow.calc} options={Model.optionCalc} error={this.state.dataError?.calc} onChange={this.onFieldValueChange} />
                        <InputNumber name='timelimit' label='Time Limit (Seconds)' value={this.state.dataRow.timelimit} error={this.state.dataError?.timelimit} onChange={this.onFieldValueChange} />
                    </>
                    },

                    {caption: this.state.dataRow.testingtoolLength>0? 'Sections' : 'Questions', child: 
                    this.state.dataRow.id &&
                    <>
                        {this.state.dataRow.testingtoolLength>0?
                        <TestingtoolList history={this.props.history} root={this.props.root} caption='Testing Tool Section' parentId={this.state.dataRow.id.toString()} disableBackButton disableCard/>
                        : <QuestionList history={this.props.history} root={this.props.root} testingtoolId={this.state.dataRow.id.toString()} disableBackButton disableCard/>
                        }
                    </>
                    },

                    ]}
                    
                /> 

            </BaseEdit>
        );
    }
}