import * as React from 'react';
import { IconButton, TextField } from '@mui/material';
import IconPicklist from '@mui/icons-material/DatasetOutlined';
import IconClear from '@mui/icons-material/Clear';
import { Dialog } from '.';


interface Props {
  name: string;
  label: string;
  placeHolder?: string;
  value: string;
  caption: string;
  readOnly?: boolean;
  disableAutoClose?: boolean;
  clearValue?: (data: any) => void;
  onChange?: (fieldName: string, value: string) => void;
  error?: string;
  children?: React.ReactNode;
}

export const Picklist: React.FunctionComponent<Props> = (props) => {
  const [open, setOpen] = React.useState(false);
  
  const onClickClear = () => {props.clearValue?.(null)};
  const onClick = () => {setOpen(true);};
  const onClose = () => {setOpen(false)};
  const onChange = (props: Props) => (e: React.ChangeEvent<HTMLInputElement>) => { 
    props.onChange?.(e.target.name, e.target.value);
  };
  
  React.useEffect(()=>{
    if (!props.disableAutoClose) onClose(); 
  }, [props])

  return (
    <TextField
      margin='normal'
      id={props.name}
      name={props.name}
      label={props.label}
      type={'input' }
      value={props.caption || ''}
      placeholder={props.placeHolder}
      InputProps={
        {
          readOnly: true,
          endAdornment: (
            props.readOnly?'':
            <>
            {
            props.clearValue?
            <IconButton onClick={onClickClear} style={{padding: 2}}>
              <IconClear sx= {{fontSize: {xs: '16px', md: '20px'}}}/>
            </IconButton>
            : ''
            }
            <IconButton onClick={onClick} style={{padding: 0}}>
              <IconPicklist sx= {{fontSize: {xs: '20px', md: '24px'}}}/>
            </IconButton>
            <Dialog open={open} onClose={onClose}>{open? props.children : ''}</Dialog>
            </>
          ),
          sx: {fontSize: {xs: '14px', md: '16px'}},
        }
      }
      InputLabelProps={{
        sx: {fontSize: {xs: '14px', md: '16px'}}
      }}
      variant='standard'
      fullWidth
      helperText={props.error}
      error= {props.error? true: false}
      onChange={onChange(props)}
    />
  )
};