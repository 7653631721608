import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import TransactiondetailList from './transactiondetail-list.component';
import TransactiondetailCreate from './transactiondetail-create.component';
import TransactiondetailView from './transactiondetail-view.component';
import TransactiondetailEdit from './transactiondetail-edit.component';
import TransactiondetailCopy from './transactiondetail-copy.component';
import TransactiondetailDelete from './transactiondetail-delete.component';

interface Props {
  root?: string;
}

export const TransactiondetailRoute: React.FunctionComponent<Props> = (props) => {
  
  return (
    <Switch>
        <Route path={(props.root||'')+'/transactiondetail/create/:id'} component={TransactiondetailCreate} />
        <Route path={(props.root||'')+'/transactiondetail/view/:id'} component={TransactiondetailView} />
        <Route path={(props.root||'')+'/transactiondetail/edit/:id'} component={TransactiondetailEdit} />
        <Route path={(props.root||'')+'/transactiondetail/copy/:id'} component={TransactiondetailCopy} />
        <Route path={(props.root||'')+'/transactiondetail/delete/:id'} component={TransactiondetailDelete} />
        <Route path={(props.root||'')+'/transactiondetail'} component={TransactiondetailList}/>
    </Switch>
  );
};
