import React from 'react';
import { History } from 'history';
import { Input, InputMultiline, Layout, Switch, Picklist } from '../../components';
import BaseView from '../base/base-view.component'; 
import Model from './coa-model';  //editable..
import CoabalanceList from '../coabalance/coabalance-list.component';
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string },
    };
    root?: string;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class CoaView extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    render() {
        return (
            //editable..
            <BaseView history={this.props.history} match={this.props.match} caption={'View '+ Model.caption} 
                model={Model} getDataRow={this.getDataRow} disableCard>              

                <Layout content={[
                    {caption: 'Details', child: 
                    <>
                        <Input name='name' label='Name' value={this.state.dataRow.name} readOnly/>
                        <InputMultiline name='description' label='Description' value={this.state.dataRow.description} readOnly/>
                        <Switch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' readOnly/>
                        {AppService.isAppFullVersion() &&
                        <Picklist name='owner' label='Owner' value={this.state.dataRow.owner?.toString()} caption={this.state.dataRow.ownerCaption} readOnly/>
                        }
                        <Picklist name='parent' label='Parent' value={this.state.dataRow.parent?.toString()} caption={this.state.dataRow.parentCaption} readOnly/>
                        <Picklist name='coagroup' label='Coa Group' value={this.state.dataRow.coagroup?.toString()} caption={this.state.dataRow.coagroupCaption} readOnly/>                       
                        
                    </>
                    },

                    {caption: 'Balances', child:
                    this.state.dataRow.id &&
                        <CoabalanceList history={this.props.history} root={this.props.root} coaId={this.state.dataRow.id.toString()}  actions={['view']} disableBackButton disableCard/> 
                    },
                    ]}
                    
                /> 

            </BaseView>
        );
    }
}