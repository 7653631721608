import * as React from 'react';
import { Card as CardMui, CardHeader, Collapse, Grid, IconButton, Typography } from '@mui/material';
import IconExpandMore from '@mui/icons-material/ExpandMore';
import IconExpandLess from '@mui/icons-material/ExpandLess';

interface Props {
  id?: string;
  caption?: string;
  color?: any;
  gradientColor?: any;
  borderColor?: any;
  borderRadius?: number;
  borderWidth?: number;
  boxShadow?: number;
  padding?: number;
  onClick?: ()=>void;
  media?: React.ReactNode;
  children?: React.ReactNode;
  
}

export const Card: React.FunctionComponent<Props> = (props) => {
  
  const [expanded, setExpanded] = React.useState(true);
  const [background, setBackground] = React.useState('');
  const [hover, setHover] = React.useState({});

  const onExpand = () => {
    setExpanded(!expanded);
  };

  React.useEffect(() => {
    if (props.gradientColor)
      setBackground('linear-gradient(90deg, '+props.gradientColor+', transparent)');

    if (props.boxShadow)
      setHover({ boxShadow: props.boxShadow, backgroundColor: 'secondary.main' });

  },[props.gradientColor, props.boxShadow])

  return (
    <>
    {props.children || props.media?
    <Grid id={props.id} item xs={12}>     
          
      <CardMui variant='outlined' sx={{background: background, backgroundColor: props.color || 'white', borderWidth: props.borderWidth , borderColor: props.borderColor || '', borderRadius: props.borderRadius || 5, ':hover': hover }}>
        {props.caption?
          <CardHeader 
            title={<Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}} color='primary.main'>{props.caption}</Typography>}
            action={ 
              <IconButton style={{marginLeft: 'auto'}} onClick={onExpand}> {expanded? <IconExpandMore/> : <IconExpandLess/>} </IconButton>
            }
          />
        :''}      
        <Collapse in={expanded} timeout='auto'unmountOnExit>
            {props.media}
            {props.children?
            <Grid padding={props.padding || 2} >
                {props.children }
            </Grid>
            :''}
        </Collapse>
      </CardMui>
    </Grid>    
    :''
    }
    </>
  )
};


