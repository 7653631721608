import * as React from 'react';
import { History } from 'history';
import Model from './civilnumber-model';
import BaseList from '../base/base-list.component';

interface IProps {  
  history: History;
  root?: string;
  personalinfoId?: string;
  actions?: any;
  disableBackButton?: boolean;
  disableCard?: boolean;
}

interface IState {
}

export default class CivilnumberList extends React.Component<IProps, IState> {
  public render(): React.ReactNode {
    return (
      <BaseList history={this.props.history} parentId={this.props.personalinfoId} caption={Model.caption+'s'} endPointFilter={Model.endPointFilter+this.props.personalinfoId} url={(this.props.root||'')+Model.url} model={Model} actions={this.props.actions} disableBackButton={this.props.disableBackButton} disableCard={this.props.disableCard}/>
    );
  }
}
