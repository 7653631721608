import React from 'react';
import { History } from 'history';
import { InputNumber, InputMoney, Picklist } from '../../components';
import BaseCreate from '../base/base-create.component'; 
import BaseView from '../base/base-view.component'; 
import Model from './transactiondetail-model';  //editable..
import transactionModel from '../transaction/transaction-model'; 
import ProductPicklist from '../product/product-picklist.component';
                
interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string},
    };
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class TransactiondetailCreate extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        
        this.onFieldValueChange = this.onFieldValueChange.bind(this);
    }
    
    private getDataRowTransaction = (data:any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 
            'transaction' : data?.id || null, 
            'transactiontype' : data?.transactiontype || null, 
            'transactiontypePricetype' : data?.transactiontypePricetype || null}  
        });
        
    }
    
    private getDataRowProduct = (data: any) => {
        
        const price = this.state.dataRow.transactiontypePricetype==='pricesale'? data?.pricesale : data?.pricebase + '';
        this.onFieldValueChange('price', price);
        this.setState({ dataRow: { ...this.state.dataRow, 'product' : data?.id || null, 'productCaption' : data?.name || '', 
            'price' : price, 'value' : price * this.state.dataRow.qty }  
        });
    };

    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onFieldValueChange(fieldName: string, value: string) { 
        
        var  valueField = this.state.dataRow.value;
        if (fieldName==='price') { valueField= this.state.dataRow.qty * +value }
        else if (fieldName==='qty') { valueField= this.state.dataRow.price * +value }
        
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value, value: valueField,
            }
        };
        this.setState(nextState);
    }

    

    render() {
        return (
            //editable..
            <>     
            <BaseView history={this.props.history} match={this.props.match} caption={''} 
                model={transactionModel} getDataRow={this.getDataRowTransaction}>              
            </BaseView>
            <BaseCreate history={this.props.history} caption={'Create '+ Model.caption} 
                model={Model} dataRow={this.state.dataRow} getDataError={this.getDataError}>               
          
                <Picklist name='product' label='Product' value={this.state.dataRow.product?.toString()} caption={this.state.dataRow.productCaption} error={this.state.dataError?.product} onChange={this.onFieldValueChange} >
                    <ProductPicklist currentId={this.state.dataRow.product?.toString()} getDataRow={this.getDataRowProduct}/>
                </Picklist>                 
                <InputMoney name='price' label='Price' value={this.state.dataRow.price} error={this.state.dataError?.price} onChange={this.onFieldValueChange} />
                <InputNumber name='qty' label='Qty' value={this.state.dataRow.qty} error={this.state.dataError?.qty} onChange={this.onFieldValueChange} />
                <InputMoney name='value' label='Total' value={this.state.dataRow.price * this.state.dataRow.qty} error={this.state.dataError?.value} onChange={this.onFieldValueChange} />
                      
            </BaseCreate>
            </>
        );
    }     
     
}