import React from 'react';
import { History } from 'history';
import { Input, InputMultiline, InputDate, InputImage, Switch, Picklist } from '../../components';
import BaseView from '../base/base-view.component'; 
import Model from './app-model';  //editable..
import MenugroupChecklist from '../menugroup/menugroup-checklist.component';
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string },
    }
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class AppView extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    render() {
        return (
            //editable..
            <BaseView history={this.props.history} match={this.props.match} caption={'View '+ Model.caption} 
                model={Model} getDataRow={this.getDataRow}>              
                
                <Input name='name' label='Name' value={this.state.dataRow.name} readOnly/>
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} readOnly/>
                <Switch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' readOnly/>
                {AppService.isAppFullVersion() &&
                <Picklist name='owner' label='Owner' value={this.state.dataRow.owner?.toString()} caption={this.state.dataRow.ownerCaption} readOnly/>
                }
                <Picklist name='product' label='Product' value={this.state.dataRow.product?.toString()} caption={this.state.dataRow.productCaption} readOnly/>
                <Input name='productkey' label='Product Key' value={this.state.dataRow.productkey} readOnly />
                <InputDate name='expireddate' label='Expired Date' value={this.state.dataRow.expireddate} readOnly />
                <Picklist name='menugroup' label='Menu Group' value='' caption={this.state.dataRow.menugroupCaption} readOnly={this.state.dataRow.menugroup? false: true} disableAutoClose>
                    <MenugroupChecklist history={this.props.history} currentId={this.state.dataRow.menugroup} readOnly disableBackButton/>
                </Picklist>
                <InputImage name='imageUrl' label='Logo' value={this.state.dataRow.imageUrl} readOnly/>
            </BaseView>
        );
    }
}