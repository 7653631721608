import React from 'react';
import { History } from 'history';
import { Input, InputMultiline, InputSelect, InputNumber, Switch, Picklist, Layout } from '../../components';
import BaseEdit from '../base/base-edit.component'; 
import Model from './region-model';  //editable..
import RegionPicklist from './region-picklist.component';
import RegionList from './region-list.component';

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string },
    };
    root?: string;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class RegionEdit extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        this.onFieldValueChange = this.onFieldValueChange.bind(this);
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }
    
    private getDataRowParent = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'parent' : data?.id || null, 'parentCaption' : data?.name || ''  }  });
    };

    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onFieldValueChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
        
    }

    render() {
        return (
            //editable..
            <BaseEdit history={this.props.history} match={this.props.match} caption={'Edit '+ Model.caption} 
                model={Model} dataRow={this.state.dataRow} getDataRow={this.getDataRow} getDataError={this.getDataError} disableCard>              
                
                <Layout content={[
                    {caption: 'Details', child: 
                    <>
                        <Input name='name' label='Name' value={this.state.dataRow.name} error={this.state.dataError?.name} onChange={this.onFieldValueChange} />
                        <InputMultiline name='description' label='Description' value={this.state.dataRow.description} error={this.state.dataError?.description} onChange={this.onFieldValueChange} />
                        <Switch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' error={this.state.dataError?.status} onChange={this.onFieldValueChange}/>
                        <InputSelect name='type' label='Type' value={this.state.dataRow.type} options={Model.optionType} readOnly />
                        {this.state.dataRow.type === 'village' &&
                        <>
                        <InputNumber name='zipcode' label='Zip Code' value={this.state.dataRow.zipcode} error={this.state.dataError?.zipcode} onChange={this.onFieldValueChange} />
                        <Input name='code' label='Region Code' value={this.state.dataRow.code} error={this.state.dataError?.code} onChange={this.onFieldValueChange} />
                        <InputNumber name='latitude' label='Latitude' value={this.state.dataRow.latitude} error={this.state.dataError?.latitude} onChange={this.onFieldValueChange} />
                        <InputNumber name='longitude' label='Longitude' value={this.state.dataRow.longitude} error={this.state.dataError?.longitude} onChange={this.onFieldValueChange} />
                        <InputNumber name='elevation' label='Elevation' value={this.state.dataRow.elevation} error={this.state.dataError?.elevation} onChange={this.onFieldValueChange} />
                        </>
                        }
                        {this.state.dataRow.type !== 'province' &&
                        <Picklist name='parent' label='Parent' value={this.state.dataRow.parent?.toString()} caption={this.state.dataRow.parentCaption} error={this.state.dataError?.parent} onChange={this.onFieldValueChange} >
                            <RegionPicklist currentId={this.state.dataRow.parent?.toString()} getDataRow={this.getDataRowParent}/>
                        </Picklist> 
                        }
                    </>
                    },

                    {caption: 'Sub Regions', hide: this.state.dataRow.type==='village', child:
                    this.state.dataRow.id &&
                        <RegionList history={this.props.history} root={this.props.root} caption='Sub Regions' parentId={this.state.dataRow.id.toString()} disableBackButton disableCard/>
                    },
                    ]}
                    
                /> 
                              
            </BaseEdit>
        );
    }
}