import * as React from 'react';
import { History } from 'history';
import Model from './evaluation-model';
import BasePicklistcard from '../base/base-picklistcard.component';

interface IProps {
  history: History;
  caption?: string;
  currentId?: string;
}

interface IState {
}

export default class EvaluationPicklistcard extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  private getDataRowEvaluation = (data: any) => {
    this.props.history.push(Model.url+'sheet/'+data.id);
  };

  public render(): React.ReactNode {
    return (
      <BasePicklistcard caption={this.props.caption || 'Select '+Model.caption+'s'} endPointFilter={Model.endPointFilterUser()} model={Model} currentId={this.props.currentId} getDataRow={this.getDataRowEvaluation}/>
    );
  }
}
