import OwnerModel from '../owner/owner-model';

export default class MenugroupModel {
    id?: number;
    name: string;
    description: string;
    status: string;
    owner: any;
    ownerCaption: string;
    menu: any;
    menuCaption: string;
    accessowner: any;
    accessownerCaption: string;
    user: any;
    userCaption: string;

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.owner =  dataRow.attributes.owner.data?.id || null;
        this.ownerCaption =  dataRow.attributes.owner.data?.attributes.name || dataRow.attributes.owner.data?.id;
        this.menu =  dataRow.attributes.menu.data || null;
        this.menuCaption =  dataRow.attributes.menu.data?.[0]? dataRow.attributes.menu.data?.[0]?.attributes.name+' ...' : '';
        this.accessowner =  dataRow.attributes.accessowner.data || null;
        this.accessownerCaption =  dataRow.attributes.accessowner.data?.[0]? dataRow.attributes.accessowner.data?.[0]?.attributes.name+' ...' : '';
        this.user =  dataRow.attributes.user.data || null;
        this.userCaption =  dataRow.attributes.user.data?.[0]? dataRow.attributes.user.data?.[0]?.attributes.username+' ...' : '';
        
    } 

    public static endPoint: string = 'menu-groups' 
    public static endPointFilter = ()=>{
        const owner = OwnerModel.getOwner();
        return ( '?populate=*'+(owner==='null' || owner===null? '' : '&filters[owner]='+owner))
    } 
    public static url: string = '/menugroup';
    public static caption: string = 'Menu Group';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active',
        owner: OwnerModel.getOwner(),
        ownerCaption: OwnerModel.getOwnerCaption(),   
        menu: [],
        menuCaption: '',   
        accessowner: [{id: +(OwnerModel.getOwner()||0)}],
        accessownerCaption: '',   
        user: [],
        userCaption: '',   
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'description', caption: 'Description' },
        { name: 'status', caption: 'State', type: 'state', default: 'active' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'description', caption: 'Description' },
    ]

}