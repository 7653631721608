export default class TransactionpaymentModel {
    id?: number;
    transaction: any;
    transactionCaption: string;
    paymenttype: any;
    paymenttypeCaption: string;
    value: number;
    
    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.transaction =  dataRow.attributes.transaction?.data?.id || null;
        this.transactionCaption =  dataRow.attributes.transaction?.data?.attributes.name || dataRow.attributes.transaction?.data?.id; 
        this.paymenttype =  dataRow.attributes.paymenttype?.data?.id || null;
        this.paymenttypeCaption =  dataRow.attributes.paymenttype?.data?.attributes.name || dataRow.attributes.paymenttype?.data?.id; 
        this.value = dataRow.attributes.value;
    } 

    public static endPoint: string = 'transaction-payments';
    public static endPointFilter: string = '?populate=*&filters[transaction]=';
    public static url: string = '/transactiondetailpayment';

    public static caption: string = 'Payment Details';

    public static defaultValue = ()=> {
        return {
        id: 0,
        transaction: null,
        transactionCaption: '',
        paymenttype: null,
        paymenttypeCaption: '',
        value: 0,

  
        }
    }
    
    public static columns = [
        { name: 'paymenttypeCaption', caption: '' },
        { name: 'value', caption: '', type: 'money' },
              
    ]

    public static columnPicklist = [
        { name: 'paymenttypeCaption', caption: 'Type' },
        { name: 'value', caption: 'Amount', type: 'money' },
    ]
    
}