import ApiService from "../../services/api.service";

export default class CivilnumberModel {
    id?: number;
    name: string;
    description: string;
    status: string;
    type: string;
    seq: number;
    image: any;
    imageId: any;
    imageUrl: string;
    personalinfo: any;
    personalinfoCaption: string;

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.type = dataRow.attributes.type || 'ktp';
        this.seq = dataRow.attributes.seq;
        this.personalinfo =  dataRow.attributes.personalinfo?.data?.id || null;
        this.personalinfoCaption =  dataRow.attributes.personalinfo?.data?.attributes.name || dataRow.attributes.personalinfo?.data?.id; 
        this.image = dataRow.attributes.image?.data || null;
        this.imageId = dataRow.attributes.image?.data?.[0]?.id || null;
        this.imageUrl = dataRow.attributes.image?.data?.[0]? ApiService.svrUrl+dataRow.attributes.image.data[0].attributes.url : '';
        
    } 

    public static endPoint: string = 'civil-numbers';
    public static endPointMedia: string = 'api::civil-number.civil-number';
    public static endPointFilter: string = '?populate=*&filters[personalinfo]=';
    public static url: string = '/civilnumber';
    public static caption: string = 'Civil Number';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active',    
        type: 'ktp',
        seq: 0,
        personalinfo: null,
        personalinfoCaption: '',
        image: null,
        imageId: null,
        imageUrl: '',
  
        }
    }
    
    public static columns = [
        { name: 'name', caption: 'Number' },
        { name: 'type', caption: 'Type' },
        { name: 'status', caption: 'State', type: 'state', default: 'active' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Number', type: 'caption' },
        { name: 'type', caption: 'Type' },
    ]

    public static optionType = [
        { key: 'ktp', value: 'KTP' },
        { key: 'kta', value: 'KTA' },
        { key: 'sim', value: 'SIM' },
        { key: 'npwp', value: 'NPWP' },
    ]
}