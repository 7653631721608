import * as React from 'react';
import { Dialog , DialogContent } from '@mui/material';

interface Props {
}

export const ScreenGuard: React.FunctionComponent<Props> = (props) => {;
  const [open, setOpen] = React.useState(false);

  const onKeyboardEvent = React.useCallback((e: KeyboardEvent) => { 
    if (
      (e.key==='PrintScreen')  || 
      (e.metaKey && e.shiftKey && (e.key==='S'))
    ) { setOpen(true) }
  }, []);
  const onMouseEvent = React.useCallback((e: MouseEvent) => { e.preventDefault() }, []);  
  const onClipboardEvent = React.useCallback((e: ClipboardEvent) => { e.preventDefault() }, []);  
  const onFocusEvent = React.useCallback((open: boolean) => (e: FocusEvent) => { setOpen(open)}, []);  
  
  React.useEffect(() => {
    window.addEventListener('keyup', onKeyboardEvent);
    window.addEventListener('contextmenu', onMouseEvent);
    document.addEventListener('copy', onClipboardEvent);
    document.addEventListener('cut', onClipboardEvent);
    window.addEventListener('blur', onFocusEvent(true));
    window.addEventListener('focus', onFocusEvent(false));

    return () => {
      window.removeEventListener('keyup', onKeyboardEvent);
      window.removeEventListener('contextmenu', onMouseEvent)
      document.removeEventListener('copy', onClipboardEvent);
      document.removeEventListener('cut', onClipboardEvent);
      window.removeEventListener('blur', onFocusEvent(true));
      window.removeEventListener('focus', onFocusEvent(false));
    }
  }, [onKeyboardEvent, onMouseEvent, onClipboardEvent, onFocusEvent])

  /*
  ##useEffect rules:
  useEffect( () => console.log('mount'), [] );
  useEffect( () => console.log('data1 update'), [ data1 ] );
  useEffect( () => console.log('any update') );
  useEffect( () => () => console.log('data1 update or unmount'), [ data1 ] );
  useEffect( () => () => console.log('unmount'), [] );
  */

  return (
    <Dialog open={open}  sx={{backgroundColor:'secondary.dark'}} onClose={()=>setOpen(false)}>  
        <DialogContent>Screen was temporary locked.</DialogContent> 
    </Dialog>
    )
};
