import React from 'react';
import { History } from 'history';
import { Input, InputEmail, InputImage, InputPassword, Picklist, Switch } from '../../components';
import BaseDelete from '../base/base-delete.component'; 
import Model from './user-model';  //editable..
import OwnerChecklist from '../owner/owner-checklist.component';
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string },
    }
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class UserDelete extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    render() {
        return (
            //editable..
            <BaseDelete history={this.props.history} match={this.props.match} caption={'Delete '+ Model.caption} 
                model={Model} getDataRow={this.getDataRow}>              
                
                <Input name='username' label='User Name' value={this.state.dataRow.username} readOnly />
                <InputEmail name='email' label='Email' value={this.state.dataRow.email} readOnly />
                <InputPassword name='password' label='Password' value={this.state.dataRow.password} readOnly />
                <Switch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' readOnly/>
                {AppService.isAppFullVersion() &&
                <Picklist name='owner' label='Owner' value='' caption={this.state.dataRow.ownerCaption} readOnly={this.state.dataRow.owner? false: true} disableAutoClose>
                    <OwnerChecklist history={this.props.history} currentId={this.state.dataRow.owner} readOnly disableBackButton/>
                </Picklist> 
                }
                <InputImage name='imageUrl' label='Avatar' value={this.state.dataRow.imageUrl} readOnly/>
            </BaseDelete>
        );
    }
}