import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import TestingtoolList from './testingtool-list.component';
import TestingtoolCreate from './testingtool-create.component';
import TestingtoolView from './testingtool-view.component';
import TestingtoolEdit from './testingtool-edit.component';
import TestingtoolCopy from './testingtool-copy.component';
import TestingtoolDelete from './testingtool-delete.component';

interface Props {
  root?: string;
}

export const TestingtoolRoute: React.FunctionComponent<Props> = (props) => {
  return (
    <Switch>
      <Route path={(props.root||'')+'/testingtool/create'} component={TestingtoolCreate} />
      <Route path={(props.root||'')+'/testingtool/create/:id'} component={TestingtoolCreate} />      
      <Route path={`${props.root||''}/testingtool/view/:id`} render={(params)=> <TestingtoolView {...params} root={props.root} key={params.match.params.id} />} />        
      <Route path={`${props.root||''}/testingtool/edit/:id`} render={(params)=> <TestingtoolEdit {...params} root={props.root} key={params.match.params.id} />} />        
      <Route path={`${props.root||''}/testingtool/copy/:id`} component={TestingtoolCopy} />
      <Route path={`${props.root||''}/testingtool/delete/:id`} render={(params)=> <TestingtoolDelete {...params} root={props.root} key={params.match.params.id} />} />        
      <Route path={(props.root||'')+'/testingtool'} render={(params)=> <TestingtoolList {...params} root={props.root} />} /> 
    </Switch>
  );
};
