import React from 'react';
import { History } from 'history';
import { Input } from '../../components';
import BaseCreate from '../base/base-create.component'; 
import Model from './user-model';  //editable..

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string },
    }
}

interface IState {
    dataRow: Model;
    dataError: any;
    save: boolean;
    link: string;
}

export default class UserAuthCheckToken extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            save: false,
            link: '',
        }
        this.onFieldValueChange = this.onFieldValueChange.bind(this);
    }

    private getDataError = (data: any) => {
        if (data) {
            Model.removeUser();
            this.props.history.replace('/');
        }
        //this.setState({dataError: data});
    }
    
    private onSave = (data: any) => {
        this.setState({save: false});
        if (!Model.isSignin()) Model.setUser(data.user?.id, data.user?.username, this.state.dataRow.code)
        this.props.history.replace('/'+this.state.link)
    }
    
    private onFieldValueChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    public componentDidMount(){
        const code= this.props.match.params.id;
        const link= this.props.history.location.search.split('=')[1];
        const dataRowTemp: any = Model.defaultValueGetToken;

        if (!Model.isSignin()) Model.setUser('','', code);
        this.setState({ dataRow: { ...dataRowTemp, 'code' : code}, link: link, save: true});
    }
    
    render() {
        return (
            //editable..          
            <BaseCreate history={this.props.history} caption={Model.caption+ ' Link Verification'} buttonCaption='Go'
                endPoint={Model.endPointAuth+'/check-token'} model={Model} dataRow={this.state.dataRow} validations={Model.validationResetPassword} getDataError={this.getDataError} onSave={this.onSave} save={this.state.save} buttonOnBottom disableAutoBack disableBackButton>              
            
                <Input name='code' label='Verification Code' value={this.state.dataRow.code} error={this.state.dataError?.code} onChange={this.onFieldValueChange} />
            </BaseCreate>
        );
    }     
     
}