import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import EvaluationList from './evaluation-list.component';
import EvaluationCreate from './evaluation-create.component';
import EvaluationView from './evaluation-view.component';
import EvaluationEdit from './evaluation-edit.component';
import EvaluationCopy from './evaluation-copy.component';
import EvaluationDelete from './evaluation-delete.component';

interface Props {
  root?: string;
}

export const EvaluationRoute: React.FunctionComponent<Props> = (props) => {
  
  return (
    <Switch>
      <Route path={(props.root||'')+'/evaluation/create'} component={EvaluationCreate} />
      <Route path={(props.root||'')+'/evaluation/create/:id'} component={EvaluationCreate} />      
      <Route path={`${props.root||''}/evaluation/view/:id`} render={(params)=> <EvaluationView {...params} root={props.root} key={params.match.params.id} />} />        
      <Route path={`${props.root||''}/evaluation/edit/:id`} render={(params)=> <EvaluationEdit {...params} root={props.root} key={params.match.params.id} />} />        
      <Route path={(props.root||'')+'/evaluation/copy/:id'} component={EvaluationCopy} />
      <Route path={`${props.root||''}/evaluation/delete/:id`} render={(params)=> <EvaluationDelete {...params} root={props.root} key={params.match.params.id} />} />        
      <Route path={(props.root||'')+'/evaluation'} render={(params)=> <EvaluationList {...params} root={props.root} />} /> 
    </Switch>
  );
};
