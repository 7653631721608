import React from 'react';
import { History } from 'history';
import { InputPassword } from '../../components';
import BaseCreate from '../base/base-create.component'; 
import Model from './user-model';  //editable..

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string },
    }
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class UserAuthResetPassword extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        this.onFieldValueChange = this.onFieldValueChange.bind(this);
    }

    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onSave = (data: any) => {
        Model.setUser(data.user?.id, data.user?.username, data.jwt)
        this.props.history.push('/');
    };
    
    private onFieldValueChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    public componentDidMount(){
        const dataRowTemp: any = Model.defaultValueResetPassword;
        this.setState({dataRow: {...dataRowTemp, code: this.props.match.params.id}});
    }
    
    render() {
        return (
            //editable..          
            <BaseCreate history={this.props.history} caption={Model.caption + ' Reset Password'} buttonCaption='Reset Password'
                endPoint={Model.endPointAuth+'/reset-password'} model={Model} dataRow={this.state.dataRow} validations={Model.validationResetPassword} getDataError={this.getDataError} onSave={this.onSave} buttonOnBottom disableBackButton disableAutoBack>              
                <InputPassword name='password' label='New Password' value={this.state.dataRow.password} error={this.state.dataError?.password} onChange={this.onFieldValueChange} />
                <InputPassword name='passwordConfirmation' label='Retype New Password' value={this.state.dataRow.passwordConfirmation} error={this.state.dataError?.passwordConfirmation} onChange={this.onFieldValueChange} />
            </BaseCreate>
        );
    }     
     
}