import React from 'react';
import { History } from 'history';
import { InputMoney, Picklist } from '../../components';
import BaseView from '../base/base-view.component'; 
import Model from './transactionpayment-model';  //editable..

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string },
    }
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class TransactionpaymentView extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    render() {
        return (
            //editable..
            <BaseView history={this.props.history} match={this.props.match} caption={'View '+ Model.caption} 
                model={Model} getDataRow={this.getDataRow}>              
                
                <Picklist name='transaction' label='Transaction' value={this.state.dataRow.transaction?.toString()} caption={this.state.dataRow.transactionCaption} readOnly/>
                <Picklist name='paymenttype' label='Type' value={this.state.dataRow.paymenttype?.toString()} caption={this.state.dataRow.paymenttypeCaption} readOnly/>
                <InputMoney name='value' label='Payment' value={this.state.dataRow.value} readOnly/>
                
              </BaseView>
        );
    }
}