import React from 'react';
import { History } from 'history';
import { Box, Container, Grid, Typography } from '@mui/material';
import { AppBar, Button, Card, Dialog, ParallaxScroll, Transition, Trapezoid } from '../../../components';
import AppModel from '../../app/app-model';
import OwnerModel from '../../owner/owner-model';
import UserModel from '../../user/user-model';
import UserAuthSignin from '../../user/user-auth-signin.component';
import MenuPicklistcard from '../../menu/menu-picklistcard.component';
import BaseView from '../../base/base-view.component';
import EvaluationPicklistcard from '../../evaluation/evaluation-picklistcard.component';
import LogoApp from './logoapp.component';
import AppService from '../../../services/app.service';

interface IProps {
  history: History;
}

interface IState {
  triggerTransition: boolean;
  dataRowApp: AppModel;
  dataRowOwner: OwnerModel;
  dataRowUser: UserModel;
  openDialog: boolean;
}

export default class Home extends React.Component<IProps, IState> {
  
  constructor(props: IProps) {
    super(props);
    this.state = {
      triggerTransition : false,
      dataRowApp: AppModel.defaultValue(),
      dataRowOwner: OwnerModel.defaultValue(),
      dataRowUser: UserModel.defaultValue(),
      openDialog: false,
    }
  }

  private getDataRowApp = (data:any) => {
    this.setState({dataRowApp: data})
  }

  private getDataRowOwner = (data:any) => {
    this.setState({dataRowOwner: data})
  }

  private getDataRowUser = (data:any) => {
      this.setState({dataRowUser: data})
      UserModel.setUserImage(data?.imageUrl || '')
  }

  private onClickAppBar = () =>{
    this.setState({ triggerTransition: true})
  }

  private onScrollEndAppBar = () =>{
    this.setState({ triggerTransition: false })
  }

  private avatarClick = () =>{
    if (UserModel.isSignin()) this.props.history.push('/user/profile')
    else this.setState({openDialog: true});
  }

  private UserSignin(){
    return (
      <Dialog open={this.state.openDialog} onClose={()=>this.setState({openDialog: false})}>
        <Card borderColor='white'>
          <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}} color='primary.main' mt={3} mb={3}>Login</Typography>
          <UserAuthSignin caption=' ' history={this.props.history} root='/' onSave={()=>this.setState({openDialog: false})} disableBackButton disableAutoBack/>
        </Card>
      </Dialog>
    )
  }

  private isMobile: boolean = AppService.isMobile();

  private header(){
    return ( 
      <Trapezoid position='bottomLeft'> 
        <ParallaxScroll height={this.isMobile? 10 : 50} 
          backgroundImageUrl='https://img.freepik.com/free-vector/colorful-mountains-landscape-background_52683-24001.jpg?w=996&t=st=1693055132~exp=1693055732~hmac=06e86ec88b1b137354d4a083a36785bee43952fc21b3465acb7ee4c706e3baeb'
        >    
          <Container>
            <Grid container pb={3}>

              <Grid item xs={12} md={6} pb={3}>
                <Transition variant='growCenter' direction='down' trigger>
                  <Typography sx={{typography: {xs: 'h3', md: 'h2'}, lineHeight: {md: 1}}} component='div' pt={10} >
                    <b>Kenalan yuk,</b> <br/>
                    dengan bakatmu <br/>
                    di  <Grid display='inline' color='white'>Kotak Bicara</Grid>
                  </Typography>
                </Transition>
                
                <Transition variant='slide' direction='right' trigger>
                <Box>
                <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}, lineHeight: {xs:1.2, md: 1.2}}} pt={2} mb={3}>
                  Dengan cara berbeda, keahlian tersembunyimu bisa digali sekarang.
                  Kamu bisa melakukannya dengan tenang dimana saja, 
                  sehingga kamu bisa dengan mudah menentukan arah selanjutnya.
                </Typography>
                <Button variant='contained' color='secondary' label='Mulai Dimana Saja'/>
                </Box>
                </Transition>
              </Grid>

              
              <Card borderColor={this.isMobile? 'primary.main':''} borderRadius={this.isMobile? 15 : 30} padding={this.isMobile? 3 : 5}>
              <Grid container spacing={this.isMobile? 1 : 15} textAlign='center'> 
                <Transition variant='zoom' trigger>        
                <Grid item xs={4} md={4}>
                  <Typography sx={{typography: {xs: 'h4', md: 'h3'}}} color='primary.main'>
                    <b>10k</b>+
                  </Typography> 
                  <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} color='primary.light' mt={this.isMobile? 0 : 2}>
                    <b>Peserta Tercerahkan</b>
                  </Typography>
                </Grid>
                </Transition>

                <Transition variant='zoom' addTime={500} trigger>
                <Grid item xs={4} md={4}>
                  <Typography sx={{typography: {xs: 'h4', md: 'h3'}}} color='primary.main'>
                    <b>99</b>+
                  </Typography>    
                  <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} color='primary.light' mt={this.isMobile? 0 : 2}>
                    <b>Test Berkualitas</b>
                  </Typography>
                </Grid>
                </Transition>

                <Transition variant='zoom' addTime={1000} trigger>
                <Grid item xs={4} md={4}>
                  <Typography sx={{typography: {xs: 'h4', md: 'h3'}}} color='primary.main'>
                    <b>20</b>+
                  </Typography>           
                  <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} color='primary.light' mt={this.isMobile? 0 : 2}>
                    <b>Pembimbing Berpengalaman</b>
                  </Typography>
                </Grid>
                </Transition>

              </Grid>
              </Card>
            </Grid>

          </Container>
        </ParallaxScroll>
      </Trapezoid>
    )
  }

  
  private evaluationlists(){
    return (
      <Box id='evaluationlists' sx={{backgroundColor: 'white'}} p={3}>
        <Transition parentId='evaluationlists' trigger={this.state.triggerTransition}>
          <Container >
          {UserModel.isSignin()?
            <Card color='secondary.light'>
              <EvaluationPicklistcard history={this.props.history} caption='Mulai Ujianmu Sekarang...'/>
            </Card>
          :''}
          </Container>
        </Transition>
    </Box>
    )
  }

  private products(){
    return (
      <Box id='products' sx={{backgroundColor: 'white'}} p={3}>
        <Transition parentId='products' variant='slide' trigger={this.state.triggerTransition}>
          <Container>
            <Grid container spacing={2} pt={10}>
              <Transition parentId='products' addTime={1000}>
                <Typography sx={{typography: {xs: 'h5', md: 'h4'}}} textAlign='center'>
                  <b>Test Berkualitas</b>
                </Typography>
              </Transition>
              <Card borderColor='white'>
                <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}} color='primary.main' mb={3} >Paling banyak dicari</Typography>
                {UserModel.isSignin()?
                <MenuPicklistcard history={this.props.history} caption=' ' cardNumber={4}/> 
                :''}
              </Card>
              <Card borderColor='white'>
                <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}} color='primary.main' mb={3} >Test Tingkat Manajemen</Typography>
                {UserModel.isSignin()?
                <MenuPicklistcard history={this.props.history} caption=' ' cardNumber={1}/> 
                :''}
              </Card>
            </Grid>
          </Container>
        </Transition>
      </Box>
    )
  }

  private mentors(){
    return (
      <Box id='mentors' p={3}>
        <Transition parentId='mentors' variant='fade' trigger={this.state.triggerTransition}>
          <Container>
            <Grid container direction='row' spacing={3} pt={10} pb={10} alignItems='center'>            
              <Grid item xs={12} md={3} textAlign='right'>
                <Transition parentId='mentors' addTime={2000}>
                  <Typography sx={{typography: {xs: 'h4', md: 'h3'}}} color='primary.main'>
                    <b>Pembimbing</b>
                  </Typography>
                </Transition>
                <Transition parentId='mentors' addTime={3000}>
                  <Typography sx={{typography: {xs: 'h5', md: 'h4'}, lineHeight: {xs: 1, md: 1}}} >
                    <b>yang telah Berpengalaman</b>
                  </Typography>
                </Transition>
                <Transition parentId='mentors' addTime={4000}>
                  <Typography sx={{typography: {xs: 'h6', md: 'h5'}, lineHeight: {xs: 1, md: 1}}} color='primary.main'>
                    50 tahun <br/>di bidangnya
                  </Typography>
                </Transition>
              </Grid>
              <Grid item xs={12} md={3}>
                <img src="https://img.freepik.com/free-photo/modern-muslim-woman-wearing-headscarf-walking-street_1303-27637.jpg?w=360&t=st=1687459247~exp=1687459847~hmac=b4eeb1f8c95efdb1979599a803584b26d8a6d03c91983e1c22c13d7e61f1899d" width={350} height='auto' alt="Hero img" />
              </Grid>
              <Grid item xs={12} md={3} >
                <img src="https://img.freepik.com/free-photo/asian-woman-glasses-smart-blouse-skirt-posing-studio-with-tablet_1098-17489.jpg?w=360&t=st=1687459012~exp=1687459612~hmac=41035a5f21d96fcffbe0db09ab2f625ae49fc3a467654db5079300549f302384" width={350} height='auto' alt="Hero img" />
              </Grid>
              <Grid item xs={12} md={2}>
                <img src="https://img.freepik.com/free-photo/confident-businessman_1098-16852.jpg?w=360&t=st=1687459085~exp=1687459685~hmac=0625f84521fff0f6e49c725f46b16e70301eecea5ad7ea3b68e5d819a8b20131" width={350} height='auto' alt="Hero img" />
              </Grid>
            </Grid>
          </Container>
        </Transition>
      </Box>
    )
  }

  private footer(){
    
    return (
      <Trapezoid id='footer' position='topLeft' color='secondary.light' colorOthers='whitesmoke'>
        <Transition parentId='footer' trigger={this.state.triggerTransition}>
          <Grid container spacing={5} sx={{ padding: {xs: 5, md:0}, paddingBottom: {xs: 5, md:5}}}>
            <Transition parentId='footer' addTime={1000}>
              <Grid item xs={12} md={4}>
                <LogoApp disableMargin/>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                {this.state.dataRowApp.description}
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                {this.state.dataRowOwner.name}
                </Typography>
              </Grid>
            </Transition>
            
            <Transition parentId='footer' addTime={2000}>
              <Grid item xs={12} md={2}>
                <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}}>
                  <b>Hubungi Kami</b>
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                  Call Center <br/>
                  {this.state.dataRowOwner.phone}
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                  Email <br/>
                  {this.state.dataRowOwner.email}
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                  Instagram
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                  Facebook
                </Typography>
              </Grid>
            </Transition>

            <Transition parentId='footer' addTime={3000}>
              <Grid item xs={12} md={2}>
                <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}}>
                  <b>Kebijakan</b>
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                  Kebijakan Pribadi
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                  Syarat & Ketentuan
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                  Sering ditanyakan
                </Typography>
              </Grid>
            </Transition>
          </Grid>
        </Transition>
      </Trapezoid>
    )
  }

  
  
  private home(){
    return (
      <>      
      <BaseView  history = {null} match={ {'isExact': true, 'params': { 'id':AppService.appId() }}} caption={''} 
        model={AppModel} getDataRow={this.getDataRowApp}>    
      </BaseView>
      
      <BaseView  history = {null} match={ {'isExact': true, 'params': { 'id':OwnerModel.getOwner() }}} caption={''} 
        model={OwnerModel} getDataRow={this.getDataRowOwner}>    
      </BaseView>

      {UserModel.isSignin() &&
        <BaseView  history = {null} match={ {'isExact': true, 'params': { 'id':UserModel.getUser() }}} caption={''} 
          model={UserModel} getDataRow={this.getDataRowUser}>    
        </BaseView>
      }
      
      {this.UserSignin()}
      {this.header()}
      {this.evaluationlists()}
      {this.products()}
      {this.mentors()}
      {this.footer()}
      </>
    )
  }
  
  public render(): React.ReactNode {  
    return (
      <AppBar
        logo = {<LogoApp disableMargin/>}
        menu = {[{caption:UserModel.isSignin()?'Evaluasi':'', section:'evaluationlists', icon: 'notealt'}, {caption:'Produk', section:'products', icon: 'inventory'}, {caption:'Mentor', section:'mentors', icon: 'face_4'}, {caption:UserModel.isSignin()?'Dashboard':'', url:'/dashboard', icon: 'widgets'}]}
        avatar = {{name:this.state.dataRowUser.username, imageUrl:this.state.dataRowUser.imageUrl, onClick: this.avatarClick}}
        onClick={this.onClickAppBar}
        onScrollEnd={this.onScrollEndAppBar}
      >
        {this.home()}
      </AppBar>
    )
  }
}