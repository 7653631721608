
export default class EvaluationdetailModel {
    id?: number;
    name: string;
    description: string;
    status: string;
    evaluation: any;
    evaluationCaption: string;
    testingtool: any;
    question: any;
    questionCaption: string;
    questionDescription: string;
    questionTitle: string;
    questionContent: string;
    questionType: string;
    questionImageurl: string;
    questionoption: any;
    questionoptionCaption: string;
    answer: string;

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.evaluation =  dataRow.attributes.evaluation?.data?.id || null;
        this.evaluationCaption =  dataRow.attributes.evaluation?.data?.attributes.name || dataRow.attributes.evaluation?.data?.id;
        this.testingtool =  dataRow.attributes.testingtool?.data?.id || null;
        this.question =  dataRow.attributes.question?.data?.id || null;
        this.questionCaption =  dataRow.attributes.question?.data?.attributes.name || dataRow.attributes.question?.data?.id;
        this.questionDescription =  dataRow.attributes.question?.data?.attributes.description || '';
        this.questionTitle =  dataRow.attributes.question?.data?.attributes.title || '';
        this.questionContent =  dataRow.attributes.question?.data?.attributes.content || '';
        this.questionType =  dataRow.attributes.question?.data?.attributes.type || '';
        this.questionImageurl = '';
        this.questionoption =  dataRow.attributes.questionoption?.data || [];
        this.questionoptionCaption = '';
        this.answer = dataRow.attributes.answer;
    } 

    public static endPoint: string = 'evaluation-details';
    public static endPointFilter: string = '?populate=*&filters[evaluation]=';
    public static endPointFilter2: string = '?populate=*&filters[question]=';
    public static url: string = '/evaluationdetail';
    public static caption: string = 'Evaluation Detail';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active', 
        evaluation: null,
        evaluationCaption: '',
        testingtool: null,
        question: null,
        questionCaption: '',
        questionDescription: '',
        questionTitle: '',
        questionContent: '',
        questionImageurl: '',
        questionType: '',
        questionoption: [],
        questionoptionCaption: '',
        answer: '',
        }
    }
    
    public static columns = [
        { name: 'questionCaption', caption: 'Name' },
        { name: 'questionDescription', caption: 'Description' },
        { name: 'questionTitle', caption: 'Title' },
    ]

    public static columnPicklist = [
        { name: 'questionCaption', caption: 'Question' },
        { name: 'questionoptionCaption', caption: 'Answer' },
    ]

    public static columnPicklistUser = [
        { name: 'questionCaption', caption: 'Question' },
    ]

}