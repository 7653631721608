import * as React from 'react';
import { Dialog as DialogMui, DialogContent, IconButton } from '@mui/material';
import IconClose from '@mui/icons-material/Close';
import { SwipeDrawer } from '.';
import AppService from '../services/app.service';

interface Props {
  id?: string;
  open: boolean;
  maxWidth?: 'xs'|'md'|'lg';
  onClose?: () => void;
  children?: React.ReactNode;
}

export const Dialog: React.FunctionComponent<Props> = (props) => {
  
  return (
    
    <>
    {AppService.isMobile()?
      <SwipeDrawer variant='bottom' open={props.open || false} onClose={props.onClose}>
        {props.children}
      </SwipeDrawer>
    :
      <DialogMui open={props.open || false} fullWidth={true} maxWidth={props.maxWidth || 'md'} > 
        <DialogContent sx={{overflow: 'hidden'}}>
          <IconButton size='large' sx={{ position: 'absolute', right: '2%', top: 30}} onClick={props.onClose}><IconClose sx={{fontSize : '28px'}} /></IconButton>
          {props.children}
        </DialogContent>
      </DialogMui>
    }
    </>
  );
};

export default Dialog;
