import * as React from 'react';
import Model from './user-model';
import BaseUpload from '../base/base-upload.component';

interface IProps {  
  caption?: string;
  dataSet: Array<any>;
  getDataSet?: (data: any) => void;
  disableBackButton?: boolean;
  disableCard?: boolean;
}

interface IState {
}

export default class UserUpload extends React.Component<IProps, IState> {

  private getDataSet = (data: any) => {
    this.props.getDataSet?.(data)
  };

  public render(): React.ReactNode {
    return (
      <BaseUpload caption={this.props.caption || ('Upload '+Model.caption+'s')} endPoint={Model.endPointAuth+'/local/register'} model={Model} defaultValue={{'password': 'password123'}} dataSet={this.props.dataSet} getDataSet={this.getDataSet} disableBackButton={this.props.disableBackButton} disableCard={this.props.disableCard}/>
    );
  }
}
