import AppService from '../../services/app.service';
import OwnerModel from '../owner/owner-model';

export default class TransactionModel {
    id?: number;
    name: string;
    description: string;
    status: string;
    owner: any;
    ownerCaption: string;    
    transactiontype: any;
    transactiontypeCaption: string;
    transactiontypePricetype: any;
    transactionref: any;
    transactionrefCaption: string;
    transactiondate: any;
    employee: any;
    employeeCaption: string;
    customer: any;
    customerCaption: string;
    vendor: any;
    vendorCaption: string;    
    subtotal: number;
    total: number;
    totalpayment: number;
    
    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'draft';
        this.owner =  dataRow.attributes.owner?.data?.id || null;
        this.ownerCaption =  dataRow.attributes.owner?.data?.attributes.name || dataRow.attributes.owner?.data?.id;
        this.transactiontype =  dataRow.attributes.transactiontype?.data?.id || null;
        this.transactiontypeCaption =  dataRow.attributes.transactiontype?.data?.attributes.name || dataRow.attributes.transactiontype?.data?.id;
        this.transactiontypePricetype =  dataRow.attributes.transactiontype?.data?.attributes.pricetype || null;
        this.transactionref =  dataRow.attributes.transactionref?.data?.id || null;
        this.transactionrefCaption =  dataRow.attributes.transactionref?.data?.attributes.name || dataRow.attributes.transactionref?.data?.id;
        this.transactiondate = dataRow.attributes.transactiondate;
        this.employee =  dataRow.attributes.employee?.data?.id || null;
        this.employeeCaption =  dataRow.attributes.employee?.data?.attributes.name || dataRow.attributes.employee?.data?.id;
        this.customer =  dataRow.attributes.customer?.data?.id || null;
        this.customerCaption =  dataRow.attributes.customer?.data?.attributes.name || dataRow.attributes.customer?.data?.id;
        this.vendor =  dataRow.attributes.vendor?.data?.id || null;
        this.vendorCaption =  dataRow.attributes.vendor?.data?.attributes.name || dataRow.attributes.vendor?.data?.id;
        this.subtotal = dataRow.attributes.subtotal;
        this.total = dataRow.attributes.total;        
        this.totalpayment = dataRow.attributes.totalpayment;        
        
    } 

    public static endPoint: string = 'transactions';
    public static endPointFilter = ()=>{
       const owner = OwnerModel.getOwner();
        return ( '?populate=*'+(owner==='null' || owner===null? '' : '&filters[owner]='+owner))
    } 
    public static url: string = '/transaction';
    public static caption: string = 'Transaction';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'draft',
        owner: OwnerModel.getOwner(),
        ownerCaption: OwnerModel.getOwnerCaption(),    
        transactiontype: null,
        transactiontypeCaption: '',
        transactiontypePricetype: null,
        transactionref: null,
        transactionrefCaption: '',
        transactiondate: AppService.dateCurrent(),
        employee: null,
        employeeCaption: '',
        customer: null,
        customerCaption: '',
        vendor: null,
        vendorCaption: '', 
        subtotal: 0,
        total: 0,
        totalpayment: 0,
  
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'description', caption: 'Description' },
        { name: 'status', caption: 'State', type: 'state', default: 'draft' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'description', caption: 'Description' },
    ]

    public static optionStatus = [
        { key: 'draft', value: 'Draft' },
        { key: 'approved', value: 'Approved' },
        { key: 'rejected', value: 'Rejected' },
    ]
}