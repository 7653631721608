import React from 'react';
import { History } from 'history';
import { Input, InputImage, InputMultiline, InputNumber, InputSelect } from '../../components';
import BaseCreate from '../base/base-create.component'; 
import Model from './section-model';  //editable..
import MedialibraryModel from '../medialibrary/medialibrary-model';

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string},
    }
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowMedia: any;
}

export default class SectionCreate extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            dataRowMedia: null,
        }
        this.onFieldValueChange = this.onFieldValueChange.bind(this);
    }

    private getDataRowPage = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'page' : data?.id || null, 'pageCaption' : data?.name || ''  }  });
    };
    
    private getFiles = (data:any) => {
        this.setState({dataRowMedia: {...this.state.dataRowMedia, endPoint: MedialibraryModel.endPoint, endPointDelete: MedialibraryModel.endPoint2, dataRow:{files: data, ref: Model.endPointMedia, field:'image'}}});
    }
    
    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onFieldValueChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    public componentDidMount(): void {        
        this.getDataRowPage({'id':this.props.match.params.id, 'name':this.props.match.params.id});
    }

    render() {
        return (
            //editable..          
            <BaseCreate history={this.props.history} caption={'Create '+ Model.caption} 
                model={Model} dataRow={this.state.dataRow} dataRowMedia={this.state.dataRowMedia} getDataError={this.getDataError}>               
          
                <Input name='name' label='Name' value={this.state.dataRow.name} error={this.state.dataError?.name} onChange={this.onFieldValueChange} />
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} error={this.state.dataError?.description} onChange={this.onFieldValueChange} />
                <InputSelect name='status' label='Status' value={this.state.dataRow.status} options={Model.optionStatus} error={this.state.dataError?.status} onChange={this.onFieldValueChange} />
                <Input name='title' label='Title' value={this.state.dataRow.title} error={this.state.dataError?.title} onChange={this.onFieldValueChange} />
                <InputMultiline name='content' label='Content' value={this.state.dataRow.content} error={this.state.dataError?.content} onChange={this.onFieldValueChange} />
                <InputImage name='imageUrl' label='Image' value={this.state.dataRow.imageUrl} getFiles={this.getFiles} error={this.state.dataError?.image} onChange={this.onFieldValueChange} />
                <InputNumber name='seq' label='Seq' value={this.state.dataRow.seq} error={this.state.dataError?.seq} onChange={this.onFieldValueChange} />
                <InputSelect name='type' label='Type' value={this.state.dataRow.type} options={Model.optionType} error={this.state.dataError?.type} onChange={this.onFieldValueChange} />
                    
            </BaseCreate>
        );
    }     
     
}