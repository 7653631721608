import ApiService from '../../services/api.service';
import OwnerModel from '../owner/owner-model';

export default class PersonalinfoModel {
    id?: number;
    name: string;
    description: string;
    status: string;
    owner: any;
    ownerCaption: string;
    personalinfotype: any;
    personalinfotypeCaption: string;
    phone: string;
    email: any;
    birthplace: any;
    birthplaceCaption: string;
    birthdate: any;
    image: any;
    imageId: any;
    imageUrl: string;
    user: any;
    userCaption: string;

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.owner =  dataRow.attributes.owner.data?.id || null;
        this.ownerCaption = dataRow.attributes.owner.data?.attributes.name || dataRow.attributes.owner.data?.id;
        this.personalinfotype =  dataRow.attributes.personalinfotype.data?.id || null;
        this.personalinfotypeCaption = dataRow.attributes.personalinfotype.data?.attributes.name || dataRow.attributes.personalinfotype.data?.id;
        this.phone = dataRow.attributes.phone;
        this.email = dataRow.attributes.email;
        this.birthplace =  dataRow.attributes.birthplace.data?.id || null;
        this.birthplaceCaption = dataRow.attributes.birthplace.data?.attributes.name || dataRow.attributes.birthplace.data?.id;
        this.birthdate = dataRow.attributes.birthdate;
        this.image = dataRow.attributes.image?.data || null;
        this.imageId = dataRow.attributes.image?.data?.[0]?.id || null;
        this.imageUrl = dataRow.attributes.image?.data?.[0]? ApiService.svrUrl+dataRow.attributes.image.data[0].attributes.url : '';
        this.user =  dataRow.attributes.user?.data?.id || null;
        this.userCaption = dataRow.attributes.user?.data?.attributes.username || dataRow.attributes.user?.data?.id;
        
    } 

    public static endPoint: string = 'personal-infos';
    public static endPointMedia: string = 'api::personal-info.personal-info';
    public static endPointFilter = ()=>{
       const owner = OwnerModel.getOwner();
        return ( '?populate=*'+(owner==='null' || owner===null? '' : '&filters[owner]='+owner))
    } 
    public static url: string = '/personalinfo';
    public static caption: string = 'Personal Info';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active',
        owner: OwnerModel.getOwner(),
        ownerCaption: OwnerModel.getOwnerCaption(),   
        personalinfotype: null,   
        personalinfotypeCaption: '',   
        phone: '',
        email: null,
        birthplace: null,
        birthplaceCaption: '',   
        birthdate: null,
        image: null,
        imageId: null,
        imageUrl: '',
        user: null,
        userCaption: '',
  
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
        { name: 'personalinfotype', required: true },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'description', caption: 'Description' },
        { name: 'status', caption: 'State', type: 'state', default: 'active' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'description', caption: 'Description' },
    ]
}