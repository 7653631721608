export default class CoabalanceModel {
    id?: number;
    balancedate: string;
    value: number;
    coa: any;
    coaCaption: string;

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.balancedate = dataRow.attributes.balancedate;
        this.value = dataRow.attributes.value;
        this.coa =  dataRow.attributes.coa?.data?.id || null;
        this.coaCaption =  dataRow.attributes.coa?.data?.attributes.name || dataRow.attributes.coa?.data?.id; 
        
    } 

    public static endPoint: string = 'coa-balances';
    public static endPointFilter: string = '?populate=*&sort[balancedate]=desc&filters[coa]=';
    public static url: string = '/coabalance';
    public static caption: string = 'Balance Detail';

    public static defaultValue = ()=> {
        return {
        id: 0,
        balancedate: '',
        value: 0,
        coa: null,
        coaCaption: '',

  
        }
    }
    
    public static columns = [
        { name: 'balancedate', caption: 'Period' },
        { name: 'value', caption: 'Value', type: 'money' },
    ]

    public static columnPicklist = [
        { name: 'balancedate', caption: 'Period' },
        { name: 'value', caption: 'Value', type: 'money' },
    ]
    
}