import * as React from 'react';
import { Box, Grid } from '@mui/material';
import AppService from '../services/app.service';

interface Props {
  color?: any;
  logo?: React.ReactNode;
  width?: number;
  children?: React.ReactNode;
}

export const SideBar: React.FunctionComponent<Props> = (props) => {
  const isMobile = AppService.isMobile();
  const widthCorner = 40;
  const width = props.width || 400;

  return (
    <Grid container position='fixed' height='100%' width={width} bgcolor='secondary.light'>
      <Box position='fixed'>{props.logo}</Box>
      <Box height='80%' width={isMobile? '100%' : width-widthCorner} p={2} sx={{marginTop : {xs: 8, md: 13}, backgroundColor: props.color || 'secondary.light', overflow: 'auto', overflowX: 'hidden'}}>
            {props.children}
      </Box>
      {!isMobile && <Box height='100%' width={widthCorner} sx={{bgcolor: 'background.default', borderTopLeftRadius: 100, borderBottomLeftRadius: 100, boxShadow: '-1px 0 0 0 silver' }}/>}
    </Grid>
  );
};
