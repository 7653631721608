import React from 'react';
import { Logo } from '../../../components';

interface IProps { 
  disableMargin?: boolean;
}

interface IState {
}

export default class LogoApp extends React.Component<IProps, IState> {

    constructor(props: IProps) {
      super(props);
      this.state = {
      }
    }

    render() {
        return (
          //editable..
          <Logo label={document.title || ''} url='/' titleColor='white' disableMargin={this.props.disableMargin}></Logo>
        );
    }
}