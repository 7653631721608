import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import TransactionList from './transaction-list.component';
import TransactionCreate from './transaction-create.component';
import TransactionView from './transaction-view.component';
import TransactionEdit from './transaction-edit.component';
import TransactionCopy from './transaction-copy.component';
import TransactionDelete from './transaction-delete.component';

interface Props {
  root?: string;
}

export const TransactionRoute: React.FunctionComponent<Props> = (props) => {
    
  return (
    <Switch>
        <Route path={(props.root||'')+'/transaction/create'} component={TransactionCreate} />
        <Route path={(props.root||'')+'/transaction/view/:id'} component={TransactionView} />
        <Route path={(props.root||'')+'/transaction/edit/:id'} component={TransactionEdit} />
        <Route path={(props.root||'')+'/transaction/copy/:id'} component={TransactionCopy} />
        <Route path={(props.root||'')+'/transaction/delete/:id'} component={TransactionDelete} />
        <Route path={(props.root||'')+'/transaction'} render={(params)=> <TransactionList {...params} root={props.root} />} /> 
    </Switch>
  );
};
