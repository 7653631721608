import * as React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  text?: string;
  lang?: string;
  children?: React.ReactNode;
}

declare global {
  interface Window { responsiveVoice: any}
}

export const TextToSpeech: React.FunctionComponent<Props> = (props) => {
  const responsiveVoiceJsId = 'responsiveVoice';

  const responsiveVoiceJs = () => {    
    if (isReady()) return;
    const script = document.createElement('script');
    script.id = responsiveVoiceJsId;
    script.type = 'text/javascript';
    script.src = 'https://code.responsivevoice.org/responsivevoice.js?key=OxNgWgee';
    document.body.appendChild(script);
    console.log('ready')
  };

  const talk = (text: string, lang: string) =>{
    if (!window.responsiveVoice) return;
    if (window.responsiveVoice.isPlaying()) window.responsiveVoice.cancel();
    if (text) setTimeout(window.responsiveVoice.speak(text, lang || 'Indonesian Male'),0);
  }

  const isReady = () =>{
    return document.getElementById(responsiveVoiceJsId)
  }

  React.useEffect(() => {
    //responsiveVoiceJs();
  }, []);
  
  
  return (
      <>
      {props.text?
      <Link to='#' replace={true} style={{textDecoration: 'none', color: 'inherit'}} onClick={()=>talk(props.text || '', props.lang || 'Indonesian Female' )}> 
        {props.children }
      </Link>
      :talk('','')
      }
      </>
  )
};


