
import { Skeleton as SkeletonMui, Stack } from '@mui/material';

interface Props {
}

export const Skeleton: React.FunctionComponent<Props> = (props) => {
  
  return (
    <Stack spacing={1}>
      <SkeletonMui variant='rounded' height={30} width='90%' animation='wave'/>
      <SkeletonMui variant='rounded' height={30} width='90%' animation='wave'/>
      <SkeletonMui variant='rounded' height={60} width='90%' animation='wave'/>
    </Stack>    
  )
};



