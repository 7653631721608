import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import TransactionList from './transaction-list.component';

interface Props {
  root?: string;
}

export const TransactionRouteApproved: React.FunctionComponent<Props> = (props) => {
  return (
    <Switch>
        <Route path={(props.root||'')+'/transactionapproved'} render={(params)=> <TransactionList {...params} root={props.root} caption='Transaction Approved' status='approved' url='/transactionapproved'/>} /> 

    </Switch>
  );
};
