import * as React from 'react';
import { History } from 'history';
import { Chip, Grid, Typography  } from '@mui/material';
import IconBack from '@mui/icons-material/ArrowBack';
import IconNext from '@mui/icons-material/ArrowForward';
import { Button, Card, ProgressBar } from '../../components';
import Model from './testingtool-model'; //editable..
import EvaluationModel from '../evaluation/evaluation-model';
import BaseList from '../base/base-list.component';
import BaseEdit from '../base/base-edit.component';
import EvaluationViewUser from '../evaluation/evaluation-view-user.component';
import AppService from '../../services/app.service';

interface IProps {  
  history: History;
  root?: string;
  parentId?: string;
  evaluationParentId?: string;
  evaluationId: string;
  viewStyle: string;
  getDataRow?: (data: any) => void;
  
}

interface IState {
  dataSet: Array<Model>;
  dataRowEvaluation: EvaluationModel;
  pageInfo: any;
  pageSize: number;
  page: number;
}

export default class TestingtoolListUser extends React.Component<IProps, IState> {
  
  constructor(props: IProps) {
    super(props);
    
    this.state = {
      dataSet: [],
      dataRowEvaluation: EvaluationModel.defaultValue(),
      pageInfo: null,
      pageSize: 20,
      page: 1,
    }
  }

  private getDataSet = (data:any, pageInfo: any) => {
    this.setState({dataSet: data, pageInfo: pageInfo});
    if (this.props.getDataRow) {
      this.props.getDataRow({'total':pageInfo.total});
    } 
  }

  private getDataRowEvaluation = (data:any) => {
    var data1 = data;
    data1.enddate = AppService.dateCurrent()
    this.setState({dataRowEvaluation: data1}); 
  }
  
  public render(): React.ReactNode {
    var isNext: boolean = true;
    var isDone: boolean = true;
    var counter: number = 0;
    
    return (
      <>
      {this.props.parentId?
        <BaseList history={this.props.history} parentId={this.props.parentId} caption=' ' endPointFilter={Model.endPointFilter4()+this.props.parentId} model={Model} page={this.state.page} pageSize={this.state.pageSize} getDataSet={this.getDataSet} disableTable/>
        : <BaseList history={this.props.history} caption=' ' endPointFilter={Model.endPointFilter5()+this.props.evaluationParentId} model={Model} page={this.state.page} pageSize={this.state.pageSize} getDataSet={this.getDataSet} disableTable/>
      }
      
      {this.state.dataSet.map((dataRow: any, i: number) => { 
        if (dataRow.id && isNext) {
          if ((this.props.parentId) && this.props.viewStyle==='single' && (!dataRow.evaluationEnddate)) isNext = false;
          if (isDone &&  (!dataRow.evaluationEnddate)) isDone = false;
          counter = i;
          
          return (
              <Card key={dataRow.id} caption={dataRow.title}>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mb={3} style={{whiteSpace: 'pre-line'}}>
                  {dataRow.content}
                </Typography>
                {dataRow.questionLength>0?
                  <ProgressBar value={dataRow.evaluationEnddate? dataRow.questionLength : dataRow.evaluationdetailLength} totalValue={dataRow.questionLength} />
                :''}
                {dataRow.testingtoolLength>0?
                  <ProgressBar value={dataRow.evaluationLengthEnddate} totalValue={dataRow.testingtoolLength} />
                :''}
                {dataRow.evaluationEnddate?'':
                <Grid container justifyContent={{xs: 'center', md: 'right'}} mt={3}>
                  <EvaluationViewUser history={this.props.history} parentId={this.props.evaluationId} testingtoolId={dataRow.id.toString()}/>
                </Grid>
                }            
              </Card>

          )
        }
        return null;
      })}

      {this.props.parentId && (this.state.dataSet.length===counter+1) && isNext && isDone? 
          <Card>
              <BaseEdit history={this.props.history} match={{isExact:true, params: {id: this.props.evaluationId}}} caption='Yeay! Kamu berhasil menyelesaikan bagian ini.. Ayo lanjut!' buttonCaption='Misi Berikutnya'
                  model={EvaluationModel} dataRow={this.state.dataRowEvaluation} getDataRow={this.getDataRowEvaluation} disableBackButton disableCard>  
              </BaseEdit>
          </Card>
      : ''}

      {this.state.pageInfo?.pageCount>0?
          <Grid item>
            {this.state.pageInfo.pageCount<=1? '':
              <Chip label= {'Test '+ this.state.page.toString()+ ' / ' + this.state.pageInfo.pageCount.toString()} />
            }
            {this.state.page<=1? '':
                <Button startIcon={<IconBack/>} size='large' onClick={()=>{this.setState({page: this.state.page-1})}} label='Back' />
            }
            {this.state.page===this.state.pageInfo.pageCount? '':
                <Button endIcon={<IconNext/>} size='large' onClick={()=>{this.setState({page: this.state.page+1})}} label='Next' />
            }
          </Grid>
      :''}
      </>
      );
  }
}
