export default class JournalgroupdetailModel {
    id?: number;
    journalgroup: any;
    journalgroupCaption: string;
    transactiontype: any;
    transactiontypeCaption: string;
    sign: string;
    coa: any;
    coaCaption: string;

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.journalgroup =  dataRow.attributes.journalgroup.data?.id || null;
        this.journalgroupCaption =  dataRow.attributes.journalgroup.data?.attributes.name || dataRow.attributes.journalgroup.data?.id;
        this.transactiontype =  dataRow.attributes.transactiontype.data?.id || null;
        this.transactiontypeCaption =  dataRow.attributes.transactiontype.data?.attributes.name || dataRow.attributes.transactiontype.data?.id;
        this.sign = dataRow.attributes.sign || 'debet';
        this.coa =  dataRow.attributes.coa.data?.id || null;
        this.coaCaption =  dataRow.attributes.coa.data?.attributes.name || dataRow.attributes.coa.data?.id;
        
    } 

    public static endPoint: string = 'journal-group-details';     
    public static endPointFilter: string = '?populate=*&filters[journalgroup]=';
    public static url: string = '/journalgroupdetail';
    public static caption: string = 'Journal Group Detail';

    public static defaultValue = ()=> {
        return {
        id: 0,
        journalgroup: null,
        journalgroupCaption: '',         
        transactiontype: null,
        transactiontypeCaption: '',         
        sign: 'debet',
        coa: null,
        coaCaption: '',
  
        }
    }
    
    public static columns = [
        { name: 'transactiontypeCaption', caption: 'Transaction Type' },
        { name: 'sign', caption: 'Debet/Credit' },
        { name: 'coaCaption', caption: 'COA' },
    ]

    public static columnPicklist = [
        { name: 'transactiontypeCaption', caption: 'Transaction Type' },
        { name: 'sign', caption: 'Debet/Credit' },
        { name: 'coaCaption', caption: 'COA' },
    ]
    
    public static optionSign = [
        { key: 'debet', value: 'Debet' },
        { key: 'credit', value: 'Credit' },
    ]

}