import * as React from 'react';
import { Table } from '../../../components';

interface IProps {
  getDataRow: (data: any) => void;
}

interface IState {
  dataSet: Array<any>;
  tableHeader: Array<any>;
}

export default class StudentPicklistCard extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      dataSet: this.dataSet,
      tableHeader: this.tableHeader,
    };
    
  }

  public dataSet = [
    { id: 0, name: 'Materi 1', description: 'Konsep Kesehatan Reproduksi' },
    { id: 1, name: 'Materi 2', description: 'Menjaga Organ Reproduksi' },
    { id: 2, name: 'Materi 3', description: 'Pubertas' },
    { id: 3, name: 'Materi 4', description: 'Gizi' },
  ]

  public tableHeader = [
    { name: 'name', caption: 'Name'},
    { name: 'description', caption: 'Description', type: 'caption' },
  ]

  private table(){
    return <Table tableHeader={this.state.tableHeader} dataSet={this.state.dataSet} getDataRow={this.props.getDataRow} page={1} pageCount={1} isLoading={false} isReady={true} cardNumber={1} cardBoxShadow={2} cardView/> 
  }

  public render(): React.ReactNode {      
    return (
      <>
        {this.table()}
      </>
    );
  }
}
