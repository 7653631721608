import React from 'react';
import { History } from 'history';
import BaseCreate from '../base/base-create.component'; 
import Model from './user-model';  //editable..

interface IProps { 
    history: History;
    email?: string;
    getDataRow?: (data: any) => void;
}

interface IState {
    dataRow: Model;
    dataError: any;
    save: boolean;
}

export default class UserAuthGetToken extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            save: false,
        }
        this.onFieldValueChange = this.onFieldValueChange.bind(this);
    }
    
    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }

    private onSave = (data: any) => {
        this.props.getDataRow?.(data)
        this.setState({save: false});
    }
    
    private onFieldValueChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    public componentDidMount(){
        const dataRowTemp: any = Model.defaultValueGetToken;
        dataRowTemp.email = this.props.email;
        this.setState({dataRow: dataRowTemp, save: true})
    }

    render() {
        return (
            //editable..          
            <BaseCreate history={this.props.history} caption=' ' buttonCaption=' ' successCaption='Token created.'
                endPoint={Model.endPointAuth+'/get-token'} model={Model} dataRow={this.state.dataRow} getDataError={this.getDataError} onSave={this.onSave} save={this.state.save} disableAutoBack disableBackButton>              
            </BaseCreate>
        );
    }     
     
}