import * as React from 'react';
import { TextField } from '@mui/material';

interface Props {
  name: string;
  label: string;
  placeHolder?: string;
  value: number;
  readOnly?: boolean;
  onBlur?: () => void;
  onChange?: (fieldName: string, value: string) => void;
  error?: string;
}

export const InputNumber: React.FunctionComponent<Props> = (props) => {

  const onBlur = (props: Props) => (e: React.FocusEvent<HTMLInputElement>) => { 
    props.onBlur?.();
  };
  
  const onChange = (props: Props) => (e: React.ChangeEvent<HTMLInputElement>) => { 
    props.onChange?.(e.target.name, e.target.value);
  };
  
  return (
    <TextField
      margin='normal'
      id={props.name}
      name={props.name}
      label={props.label}
      type='tel'
      value={props.value || '0'}
      placeholder={props.placeHolder}
      InputProps={{
        readOnly: props.readOnly || false,
        sx: {fontSize: {xs: '14px', md: '16px'}},
        inputProps: {
          sx: { textAlign: 'right' },
        }
      }}
      InputLabelProps={{
        sx: {fontSize: {xs: '14px', md: '16px'}}
      }}
      variant='standard'
      className='textEnd'
      fullWidth
      helperText={props.error}
      error= {props.error? true: false}
      onBlur={onBlur(props)}
      onChange={onChange(props)}
    />
  )
};