import OwnerModel from '../owner/owner-model';

export default class PageModel {
    id?: number;
    name: string;
    description: string;
    status: string;
    owner: any;
    ownerCaption: string;
    type: string;
    datasource: string;
    section: any;
    sectionCaption: string;

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.owner =  dataRow.attributes.owner.data?.id || null;
        this.ownerCaption =  dataRow.attributes.owner.data?.attributes.name || dataRow.attributes.owner.data?.id;
        this.type = dataRow.attributes.type || 'general';
        this.datasource = dataRow.attributes.datasource;
        this.section =  dataRow.attributes.section.data || null;
        this.sectionCaption = '';
        
    } 

    public static endPoint: string = 'pages' 
    public static endPointFilter = ()=>{
       const owner = OwnerModel.getOwner();
        return ( '?populate=*'+(owner==='null' || owner===null? '' : '&filters[owner]='+owner))
    } 
    public static endPointFilter2 = 
        '?populate[owner][sort]=id'+
        '&populate[section][sort]=seq'+
        '&populate[section][populate]=image'+
        '&filters[id]=';
        
    public static url: string = '/page';
    public static caption: string = 'Page';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active',
        owner: OwnerModel.getOwner(),
        ownerCaption: OwnerModel.getOwnerCaption(),         
        type: 'general',
        datasource: '',
        section: null,
        sectionCaption: '',
  
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'description', caption: 'Description' },
        { name: 'status', caption: 'State', type: 'state', default: 'active' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'description', caption: 'Description' },
    ]

    public static optionType = [
        { key: 'general', value: 'General' },
        { key: 'infographic', value: 'Infographic' },
        { key: 'invitation', value: 'Invitation' },
        { key: 'invoice', value: 'Invoice' },
        { key: 'overview', value: 'Overview' },
        { key: 'page', value: 'Page' },
    ]
}