import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import PersonalinfotypeList from './personalinfotype-list.component';
import PersonalinfotypeCreate from './personalinfotype-create.component';
import PersonalinfotypeView from './personalinfotype-view.component';
import PersonalinfotypeEdit from './personalinfotype-edit.component';
import PersonalinfotypeCopy from './personalinfotype-copy.component';
import PersonalinfotypeDelete from './personalinfotype-delete.component';

interface Props {
  root?: string;
}

export const PersonalinfotypeRoute: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>
      <Route path={(props.root||'')+'/personalinfotype/create'} component={PersonalinfotypeCreate} />
      <Route path={(props.root||'')+'/personalinfotype/view/:id'} component={PersonalinfotypeView} />
      <Route path={(props.root||'')+'/personalinfotype/edit/:id'} component={PersonalinfotypeEdit} />
      <Route path={(props.root||'')+'/personalinfotype/copy/:id'} component={PersonalinfotypeCopy} />
      <Route path={(props.root||'')+'/personalinfotype/delete/:id'} component={PersonalinfotypeDelete} />
      <Route path={(props.root||'')+'/personalinfotype'} render={(params)=> <PersonalinfotypeList {...params} root={props.root} />} />    
    </Switch>
  );
};
