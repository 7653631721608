import React from 'react';
import { History } from 'history';
import { Input, InputEmail, InputImage, InputPassword, Picklist, Switch } from '../../components';
import BaseCreate from '../base/base-create.component'; 
import Model from './user-model';  //editable..
import MedialibraryModel from '../medialibrary/medialibrary-model';
import OwnerChecklist from '../owner/owner-checklist.component';
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
    match:{ 
        isExact: boolean
        params: { id:string},
    };
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowMedia: any;
}

export default class UserCreate extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            dataRowMedia: null,
        }
        this.onFieldValueChange = this.onFieldValueChange.bind(this);
    }

    private getDataRowOwner = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'owner' : data || null}  });
    };  

    private getFiles = (data:any) => {
        this.setState({dataRowMedia: {...this.state.dataRowMedia, endPoint: MedialibraryModel.endPoint, endPointDelete: MedialibraryModel.endPoint2, dataRow:{id:this.state.dataRow.imageId, files: data, ref: Model.endPointMedia, field:'image'}}});
    };
    
    private getDataError = (data: any) => {
        this.setState({dataError: data});
    };
    
    private onFieldValueChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    render() {
        return (
            //editable..          
            <BaseCreate history={this.props.history} caption={'Create '+ Model.caption} 
                endPoint={Model.endPointAuth+'/local/register'} model={Model} dataRow={this.state.dataRow} getDataError={this.getDataError}>              
          
                <Input name='username' label='User Name' value={this.state.dataRow.username} error={this.state.dataError?.username || this.state.dataError?.[0]} onChange={this.onFieldValueChange} />
                <InputEmail name='email' label='Email' value={this.state.dataRow.email} error={this.state.dataError?.email} onChange={this.onFieldValueChange} />
                <InputPassword name='password' label='Password' value={this.state.dataRow.password} error={this.state.dataError?.password} onChange={this.onFieldValueChange} />
                <Switch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' error={this.state.dataError?.status} onChange={this.onFieldValueChange}/>
                {AppService.isAppFullVersion() &&
                <Picklist name='owner' label='Owner' value={this.state.dataRow.owner?.toString()} caption={this.state.dataRow.ownerCaption} disableAutoClose clearValue={this.getDataRowOwner} error={this.state.dataError?.owner} onChange={this.onFieldValueChange} >
                    <OwnerChecklist history={this.props.history} currentId={this.state.dataRow.owner} getDataRow={this.getDataRowOwner} disableBackButton/>
                </Picklist> 
                }
                <InputImage name='imageUrl' label='Avatar' value={this.state.dataRow.imageUrl} getFiles={this.getFiles} error={this.state.dataError?.image} onChange={this.onFieldValueChange} />
            </BaseCreate>
        );
    }     
     
}